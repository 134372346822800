import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/promotores.service';

@Component({
  selector: 'app-promotores',
  templateUrl: './promotores.component.html',
  styleUrls: ['./promotores.component.css'],
  providers:[CatalogosService]
})
export class PromotoresComponent implements OnInit {
	nombre="Promotores";
	promotores:any=[];
	inmobiliaria:any=[];
	promotor:any;
  constructor(private services:CatalogosService) { }

  ngOnInit() {
  	this.services.getPromotores().subscribe(result=>{
  		if (result!=null) {
  			this.promotores=JSON.parse(JSON.stringify(result)).Datas;
  		}
  	});

  	this.services.getCatalogo("inmobiliarias").subscribe(result=>{
  		if (result!=null) {
  			this.inmobiliaria=JSON.parse(JSON.stringify(result)).Datas;
  		}
  	});
  }
  setid(data){
  	this.promotor=data;
  }

  postPromotor(form){
  	this.services.postPromotores(form.form.value).subscribe(result=>{
  		location.reload();
  	});
  }
  putPromotor(form){
  	this.services.putPromotores(form.form.value,this.promotor.promotor_id).subscribe(result=>{
  		location.reload();
  	});
  }

}
