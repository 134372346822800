import { Component, OnInit } from '@angular/core';
import { AvaluosService } from '../services/avaluos.service';
import { Router,ActivatedRoute, Params } from "@angular/router";


@Component({
  selector: 'app-avaluos',
  templateUrl: './avaluos.component.html',
  styleUrls: ['./avaluos.component.css'],
  providers:[AvaluosService]
})
export class AvaluosComponent implements OnInit {
	nombre="Avaluos";
	avaluos:any=[];
	esquemas:any=[];
	inmuebles:any=[];
	valudores:any=[];
	status_avaluos:any=[];
	avlauo:any;

	constructor(private router:Router,private services:AvaluosService) { }

	ngOnInit() {
		if (sessionStorage.getItem('token')==null) {
     		this.router.navigate(["/login"]);
    	}


		this.services.getAvaluos().subscribe(result=>{
			if (result!=null) {
				console.log(result);
				this.avaluos=JSON.parse(JSON.stringify(result)).Datas;
			}
		});

		this.services.getCatalogo("esquemas").subscribe(result=>{this.esquemas=JSON.parse(JSON.stringify(result)).Datas;});
		this.services.getInmuebles().subscribe(result=>{this.inmuebles=JSON.parse(JSON.stringify(result)).Datas;});
		this.services.getCatalogo("valuadores").subscribe(result=>{this.valudores=JSON.parse(JSON.stringify(result)).Datas;});
		this.services.getCatalogo("status_avaluos").subscribe(result=>{this.status_avaluos=JSON.parse(JSON.stringify(result)).Datas;});
		
	}


	postAvaluos(form){
		this.services.postAvaluo(form.form.value).subscribe(result=>{
			location.reload();
		});
	}

	setid(data){
		this.avlauo=data;
	}
	putAvaluo(form){
		this.services.putAvaluo(form.form.value,this.avlauo.avaluo_id).subscribe(result=>{
			location.reload();
		});
	}

}
