import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service'

@Component({
  selector: 'app-manual-grafic',
  templateUrl: './manual-grafic.component.html',
  styleUrls: ['./manual-grafic.component.css']
})
export class ManualGraficComponent implements OnInit {
			page_now="Producción";
			
  
    
    Titulo_grafica:any;
    fecha:any;
    totalDia:any;
    subtitulo:any;
    date_cards:any;
    dia_hinabil:any;
    unaBara_date:any;
    barra_adm:any;
    sinClasificar:any;
    fecha_sv:any;
    graficaValor:any;

     view: any[] = [700, 300];
     view_bar=[700,90];
     two_bar=[700,200];
     two_bar_scrap=[700,400]
  legend: boolean = true;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  one_bar=[];

  color_graficss={
    domain:['#7AA3E5FF','#A27EA8FF']
  }

  cat_productos:any=[];

  constructor(private service:UsersService) { }



  ngOnInit() {
    this.num=3;
    this.fecha='2020-08-31';
     this.service.get_lastDate('rp_produccion_diario').subscribe(res=>{
       res!=null ? this.fecha=JSON.parse(JSON.stringify(res)).Datas[0].Data.fecha:null;
    })
    this.fecha_sv=this.fecha;
    
    this.subtitulo="Producción del Día";
    this.service.getcod_ventas().subscribe(res=>{
      if (res!=null) {
        this.cat_productos=JSON.parse(JSON.stringify(res)).Datas;

      }
      this.switch_uni();
    });

     

  
  }



  ngIntSrapt() {
    this.num=3;
   

    this.service.get_lastDate('rp_scrap_pp_diario').subscribe(res=>{
      res!=null ? this.fecha=JSON.parse(JSON.stringify(res)).Datas[0].Data.fecha:null;
      this.fecha_sv=this.fecha;
      console.log(this.fecha);
      this.getDatasScrap();
    })
   
    this.subtitulo="Scrap del Día"
    
     
    this.fecha='2020-09-11';
     


     //this.cambioFecha_scrap();
  
  }


  getDatasScrap(){
    let obj:any=[0,0,0,0,0];
      this.subtitulo="Scrap del Día";
      this.dia_hinabil="";
    
    this.service.getrSincro(this.fecha,'rp_scrap_pp_diario').subscribe(res=>{
      
      if (res!=null) {
         let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
         //console.log(sincro);
         this.service.getrp_info_unidad(sincro.sincro_id,'rp_scrap_pp_diario').subscribe(res=>{
           //console.log(res);
           if(res!=null){
             let scrap_pp=JSON.parse(JSON.stringify(res)).Datas;
             for(let scrap of scrap_pp){
               if(scrap.Data.origen=='SALIDA A CONTROL DE CALIDAD'){
                 let prod=this.cat_productos.find(cod=>cod.Data.codigo==scrap.Data.codigo);
                   
                   scrap.Data.cantidad=this.setUnidades(prod,scrap);
                 obj[0]=parseFloat(scrap.Data.cantidad);
               }
               if(scrap.Data.origen=="SCRAP CUBERA"){
                 let prod=this.cat_productos.find(cod=>cod.Data.codigo==scrap.Data.codigo);
                   
                   scrap.Data.cantidad=this.setUnidades(prod,scrap);
                 obj[2]=parseFloat(scrap.Data.cantidad);
               }

               if(scrap.Data.origen=="SCRAP CUARTO DE CURADO"){
                 let prod=this.cat_productos.find(cod=>cod.Data.codigo==scrap.Data.codigo);
                   
                   scrap.Data.cantidad=this.setUnidades(prod,scrap);
                 obj[1]=parseFloat(scrap.Data.cantidad);
               }

               
             }
             this.setGrafi_srapt(obj[0],obj[1],obj[2],obj[3],obj[4]);
             
           }
         });
      }else{
        this.dia_hinabil=this.dia_hinabil+"Sin Información de Scrap PP";
        this.setGrafi_srapt(obj[0],obj[1],obj[2],obj[3],obj[4]);
      }
    });


    this.service.getrSincro(this.fecha,'rp_scrap_pt_diario').subscribe(res=>{
      
      if (res!=null) {
         let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
         //console.log(sincro);
         this.service.getrp_info_unidad(sincro.sincro_id,'rp_scrap_pt_diario').subscribe(res=>{
           //console.log(res);
           if(res!=null){
             let scrap_pp=JSON.parse(JSON.stringify(res)).Datas;
             for(let scrap of scrap_pp){
               if(scrap.Data.origen=='SALIDA A CONTROL DE CALIDAD'){
                let prod=this.cat_productos.find(cod=>cod.Data.codigo==scrap.Data.codigo);
                   
                   scrap.Data.cantidad=this.setUnidades(prod,scrap);
                 obj[3]=obj[3]+parseFloat(scrap.Data.cantidad);
               }
              

               if(scrap.Data.origen=="SCRAP VENTA"){
                   let prod=this.cat_productos.find(cod=>cod.Data.codigo==scrap.Data.codigo);
                   
                   scrap.Data.cantidad=this.setUnidades(prod,scrap);
                  
                 obj[4]=obj[4]+parseFloat(scrap.Data.cantidad);
               }

               
             }
             
           }
           this.setGrafi_srapt(obj[0],obj[1],obj[2],obj[3],obj[4]);

          
         });
      }else{
        this.dia_hinabil=this.dia_hinabil+"Sin Información de Scrap PT";
         this.setGrafi_srapt(obj[0],obj[1],obj[2],obj[3],obj[4]);
      }
    });

   console.log(obj);
    


  }

  grafic_total_insumos:any;
  cardColor: string = '#232837';
  view_test: any[] = [750, 110];
  grafic_insumos:any;
  grafic_cemento:any;
  view_bar_cem=[700,150]


  setUnidades(prod,scrap){
      console.log(scrap.Data.cantidad + " " + prod.Data['UNIDAD DE MEDIDA']);

           if(prod.Data['UNIDAD DE MEDIDA']=='T1-TARIMA 150 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*150;
           }

           if(prod.Data['UNIDAD DE MEDIDA']=='T10-TARIMA 200 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*200;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T11-TARIMA 250 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*250;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T12-TARIMA 320 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*320;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T13-TARIMA 350 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*350;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T14-TARIMA 420 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*420;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T15-TARIMA 500 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*500;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T2-TARIMA 120 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*120;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T3-TARIMA 90 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*90;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T7-TARIMA 64 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*64;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T8-TARIMA 180 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*180;
           }

          console.log(scrap.Data.cantidad);

        return scrap.Data.cantidad;

  }

  ngIntInsumos() {
    this.num=3;
    this.fecha='2020-09-21';
    this.dia_hinabil="";
    this.fecha_sv=this.fecha;
    this.totalDia="";
    this.subtitulo="Insumos del Día";

    this.cambioFecha_insumos();

     
  }

  setInsumos(CEMENTO,TRITURADO,GRAVA){



    this.grafic_insumos=[{
        "name":"Insumos" ,
       
        "series": 
          [
            
             {
              "name": "TRITURADO 3/16 A FINOS",
              "value":TRITURADO,
               "extra":{codigo:"20LACN", val_form:""+TRITURADO}
             },

             {
              "name": "GRAVA/ SELLO  3/8",
              "value":GRAVA,
               "extra":{codigo:"20LACN", val_form:""+GRAVA
              },}
    
          ]
        },
        
      
    ];

    this.grafic_cemento=[
      {
        "name":"Insumos" ,
       
        "series": 
          [
            {
    

      
              "name": "CEMENTO A GRANEL",
              "value":CEMENTO,
               "extra":{codigo:"20LACN", val_form:""+CEMENTO}},
             
    
          ]
        },
        
      
    ];

    this.grafic_total_insumos=[
             {
    

      
              "name": "CEMENTO A GRANEL",
              "value":CEMENTO,
               "extra":{codigo:"20LACN", val_form:""+CEMENTO}},
             {
              "name": "TRITURADO 3/16 A FINOS",
              "value":TRITURADO,
               "extra":{ val_form:""+TRITURADO}
             },

             {
              "name": "GRAVA/ SELLO  3/8",
              "value":GRAVA,
               "extra":{ val_form:""+GRAVA}
             }, 

    ]  

  }



  setGrafi_srapt(s_control_1,cuerta_curado,cubera,s_control_2,ventas){
    console.log("graficas");
    console.log(s_control_1,cuerta_curado,cubera,s_control_2,ventas);
    this.totalDia=new Intl.NumberFormat('es-MX').format(s_control_1+cuerta_curado+cubera+s_control_2+ventas)+" Pzas.";
      this.grafica_1=[{
      "name":"Scrap PP" ,
     
        "series": [
                {
                  "name": "SALIDA A CONTROL DE CALIDAD",
                  "value":s_control_1,
                   "extra":{codigo:"20LACN", val_form:""+s_control_1  }},
                 {
                  "name": "SCRAP CUARTO DE CURADO",
                  "value":cuerta_curado,
                   "extra":{codigo:"20LACN", val_form:""+cuerta_curado}
                 },

                 {
                  "name": "SCRAP CUBERA",
                  "value":cubera,
                  "extra":{codigo:"20LACN", val_form:""+cubera}
                 },



               
              ]
          },
        {
      "name":"Scrap PT" ,
     
        "series": [
                {
                  "name": "SALIDA A CONTROL DE CALIDAD",
                  "value":s_control_2,
                   "extra":{codigo:"20LACN", val_form:""+s_control_2 }},
                 {
                  "name": "SCRAP PT VENTA",
                  "value":ventas,
                   "extra":{codigo:"20LACN", val_form:""+ ventas}
                 },

               
              ]
          }
      
       ];

       ;
  }


  cambioFecha(form){

    this.switch_uni();
    
  }


  cambioFecha_scrap(){

      this.subtitulo="Scrap del Día";
      this.dia_hinabil="";

      switch (this.fecha) {
        case "2020-08-01":
             this.setGrafi_srapt(0,0,0,0,-0.08);
          break;
          case "2020-08-02":
             this.setGrafi_srapt(0,0,0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
          case "2020-08-03":
             this.setGrafi_srapt(0,0,0,0,-0.25);
          break;
          case "2020-08-04":
             this.setGrafi_srapt(0,0,-.32,0,-0.26);
          break;
          case "2020-08-05":
             this.setGrafi_srapt(0,0,-0.42,0,-0.31);
          break;
          case "2020-08-06":
             this.setGrafi_srapt(0,0,-0.33,0,-0.19);
            
          break;
          case "2020-08-07":
             this.setGrafi_srapt(0,0,-0.28,0,-.39);
          break;
          case "2020-08-08":
             this.setGrafi_srapt(0,0,-0.1,0,0);
          break;
          case "2020-08-09":
             this.setGrafi_srapt(0,0,0,0,0);
              this.dia_hinabil="Día Inhábil";
          break;
          case "2020-08-10":
             this.setGrafi_srapt(-0.08,0,-0.67,0,-.37);
          break;
          case "2020-08-11":
             this.setGrafi_srapt(0,0,0,0,-.31);
          break;
          case "2020-08-12":
             this.setGrafi_srapt(0,0,-0.21,-0.04,-.41);
          break;
          case "2020-08-13":
             this.setGrafi_srapt(0,-0.03,-0.99,0,-.39);
          break;
          case "2020-08-14":
             this.setGrafi_srapt(-0.22,0,-0.63,0,-.23);
          break;
          case "2020-08-15":
             this.setGrafi_srapt(0,0,-0.22,0,-.17);
          break;
          case "2020-08-16":
             this.setGrafi_srapt(0,0,0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
          case "2020-08-17":
             this.setGrafi_srapt(0,-0.06,-0.17,0,-.22);
          break;
          case "2020-08-18":
             this.setGrafi_srapt(-0.11,0,-0.29,0,-.22);
          break;
          case "2020-08-19":
             this.setGrafi_srapt(-10,-0.06,-0.07,0,-.22);
          break;

          case "2020-08-20":
             this.setGrafi_srapt(0,0,-0.23,0,-.23);
          break;
          case "2020-08-21":
             this.setGrafi_srapt(0,0,-0.66,0,-.21);
          break;
          case "2020-08-22":
             this.setGrafi_srapt(0,0,-0.63,0,-.7);
          break;

          //////

           case "2020-08-30":
             this.setGrafi_srapt(0,0,0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
           case "2020-08-23":
             this.setGrafi_srapt(0,0,0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
          case "2020-09-06":
             this.setGrafi_srapt(0,0,0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;

           case "2020-08-24":
             this.setGrafi_srapt(0,0,-0.34,0,-0.17);
             
          break;
           case "2020-08-25":
             this.setGrafi_srapt(0,0,-0.05,0,-0.32);
             
          break;
           case "2020-08-26":
             this.setGrafi_srapt(0,0,0,0,0);
             
          break;
           case "2020-08-27":
             this.setGrafi_srapt(0,0,0,0,-0.51);
             
          break;
           case "2020-08-28":
             this.setGrafi_srapt(0,0,-0.17,0,-0.2);
             
          break;
           case "2020-08-29":
             this.setGrafi_srapt(0,0,0,0,-0.24);
             
          break;
           case "2020-08-31":
             this.setGrafi_srapt(0,0,-0.25,0,-0.37);
             
          break;
          case "2020-09-02":
             this.setGrafi_srapt(0,0,-0.04,0,-0.43);
             
          break;
          case "2020-09-03":
             this.setGrafi_srapt(0,0,-0.04,-0.51,0);
             
          break;
          case "2020-09-04":
             this.setGrafi_srapt(-0.62,0,0,-0.17,0);
             
          break;
           case "2020-09-05":
             this.setGrafi_srapt(0,0,-0.63,0,-0.13);
             
          break;

          case "2020-09-07":
             this.setGrafi_srapt(-0.11,0,-0.24,0,-0.17);
             
          break;

            case "2020-09-11":
             this.setGrafi_srapt(0,0,-0.31,0,-0.22);
             
          break;

            
          ///
        
        default:
          this.setGrafi_srapt(0,0,0,0,0);
            this.dia_hinabil="Sin Información";
          break;
      }
  }

  switch_uni(){

    let total=0;
    let obj_grafica:any=[];
    let obj_mayor:any=[];
    let obj_menores:any=[];

    this.service.getrSincro(this.fecha,'rp_produccion_diario').subscribe(res=>{
      if (res!=null) {
        let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
        if(sincro.justificacion=='actualización diaria'){
          this.service.getrp_info_unidad(sincro.sincro_id,'rp_produccion_diarias').subscribe(res=>{
            if (res!=null) {
               

                let produccion=JSON.parse(JSON.stringify(res)).Datas;

                for(let prod of produccion){
                  
                  
                  
                  prod.Data.name=this.cat_productos.find(cat=>cat.Data.codigo==prod.Data.codigo);

                  if(prod.Data.name!=undefined && prod.Data.name!=null){
                    prod.Data.cantidad=this.setUnidades(prod.Data.name,prod)
                    prod.Data.name=prod.Data.name.Data.nombre;
                  
                  }else{
                  
                    prod.Data.name='Sin Nombre('+prod.Data.codigo+')';
                  
                  }

                  if(obj_grafica!=null && obj_grafica.length!=0){
                    
                    //console.log(obj_grafica);
                    let cambio=false;

                    for(let obj of obj_grafica){
                      if(!cambio){
                         if(obj.extra.codigo==prod.Data.codigo){
                           obj.value=obj.value+parseFloat(prod.Data.cantidad);
                           obj.extra.val_form=obj.value;
                           cambio=true;
                         }
                      }
                    }

                    if(!cambio){
                      obj_grafica.push({
                      name: prod.Data.name,
                      value: parseFloat(prod.Data.cantidad) , 
                      extra:{codigo:prod.Data.codigo, val_form:prod.Data.cantidad}
                      });
                    }


                  }else{
                    obj_grafica.push({
                      name: prod.Data.name,
                      value: parseFloat(prod.Data.cantidad) , 
                      extra:{codigo:prod.Data.codigo, val_form:prod.Data.cantidad}
                    });
                  }

                  total=total+parseFloat(prod.Data.cantidad);

                  //console.log(prod);

                   
                }

                console.log(obj_grafica[0]);
                this.totalDia=new Intl.NumberFormat('es-MX').format(total)+" Pzas.";

                   this.subtitulo="Producción del Día"
                   this.num=5;
                   this.dia_hinabil="";

                   obj_menores=obj_grafica.filter(l=> l.name != obj_grafica[0].name);

                   console.log(obj_menores);

                   if(obj_menores.length!=0){
                     this.grafica_1=obj_menores;
                     this.one_bar=[obj_grafica[0]];

                   }else{
                     this.grafica_1=obj_grafica;
                      this.one_bar=[];
                   }



                  

                  

            }else{
              //info en 0
            }
          });

        }else{
          
          if(sincro.justificacion=='dia inhabil'){
             this.totalDia=new Intl.NumberFormat('es-MX').format(0)+" Tarimas";
             this.grafica_1=[];
                  
                   this.subtitulo="Producción del Día"
                   this.num=5;
                   this.dia_hinabil="Día Inhábil";
                   this.one_bar=[];
          }else{
            //sin info del dia
          }
        }
      }else{

         this.totalDia=new Intl.NumberFormat('es-MX').format(0)+" Tarimas";
         this.grafica_1=[];
                  
                   this.subtitulo="Producción del Día"
                   this.num=5;
                   this.dia_hinabil="Sin Información del Día";
        //si no sincornizacion en la fecha
      }
    });

    /*
    switch (this.fecha) {

      

      case "2020-09-03":
               
               this.totalDia=new Intl.NumberFormat('es-MX').format(119.07)+" Tarimas";
               this.subtitulo="Producción del Día"
               this.num=5;
               this.dia_hinabil="";
                 this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 15 liso normal" ,value: 114 , extra:{codigo:"20LACN", val_form:114} },
                 
                                
                ]);
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                  {nombre:"Bloque 12 liso rojo ocre" ,value: 4.19 , extra:{codigo:"20LACN", val_form:4.19} },
                  {nombre:"Bloque 12 dala u rojo ocre" ,value: .88 , extra:{codigo:"20LACN", val_form:.88 } },
                  
                     
                    
                ]);
              this.fecha_sv=  this.fecha;
      break;
  

      case "2020-08-01":
             this.one_bar=this.grafic_1_set([]);
             this.totalDia=new Intl.NumberFormat('es-MX').format(24)+" Tarimas";
             this.subtitulo="Producción del Día"
             this.num=5;
             this.dia_hinabil="";

             this.grafica_1=this.grafica_1=this.grafic_1_set([

                {nombre:"Bloque 15 cara de piedra café rosa" ,value: 16 , extra:{codigo:"20LACN", val_form:16} },
                {nombre:"Bloque 20 cara de piedra amarillo paja" ,value: 5 , extra:{codigo:"20LACN", val_form:5 } },
                {nombre:"Bloque 20 liso amarillo paja" ,value: 3 , extra:{codigo:"20LACN", val_form:3} },
                   
                  
              ]);
            this.fecha_sv=  this.fecha;
      break;

      case "2020-08-03":
           this.fecha_sv=this.fecha;
              this.totalDia=new Intl.NumberFormat('es-MX').format(100.67)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 15 liso normal" ,value: 58 , extra:{codigo:"20LACN", val_form:58} },
               
                              
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([
               
                
                {nombre:"Bloque 12 alta resistencia" ,value: 27.67 , extra:{codigo:"20LACN", val_form:27.67} },
                {nombre:"Bloque 12 liso rojo ocre  " ,value: 15 , extra:{codigo:"20LACN", val_form:15} },
                
                              
              ]);
            this.fecha_sv=  this.fecha;
      break;
      case "2020-08-04":
                 
              this.totalDia=new Intl.NumberFormat('es-MX').format(89)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 15 liso normal" ,value: 56 , extra:{codigo:"20LACN", val_form:56} },
               
                              
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([
               
                
                {nombre:"Bloque 15 liso normal" ,value: 24 , extra:{codigo:"20LACN", val_form:24} },
                {nombre:"Bloque 20 cara de piedra amarillo paja " ,value: 6 , extra:{codigo:"20LACN", val_form:6} },
                {nombre:"Bloque 20 liso amarillo paja " ,value: 3 , extra:{codigo:"20LACN", val_form:3} },
                
                              
              ]);
            this.fecha_sv=  this.fecha;
      break;
      case "2020-08-05":
              this.totalDia=new Intl.NumberFormat('es-MX').format(80)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                
               {nombre:"Bloque 15 cara de piedra amarillo paja" ,value: 2 , extra:{codigo:"20LACN", val_form:2} },
                              
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([
               
                
               
                {nombre:"Bloque 15 liso normal" ,value: 72 , extra:{codigo:"20LACN", val_form:72} },
                
                              
              ]);
            this.fecha_sv=  this.fecha;
            this.fecha_sv=  this.fecha;
      break;
      
      case "2020-08-06":
              this.totalDia=new Intl.NumberFormat('es-MX').format(116.59)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Adoquin venecia rojo ocre" ,value: .59 , extra:{codigo:"20LACN", val_form:.59} },
               
                              
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([
               
                
               
                {nombre:"Bloque 15 alta resistencia" ,value: 57 , extra:{codigo:"20LACN", val_form:57} },
                 {nombre:"Bloque 15 liso normal" ,value: 45 , extra:{codigo:"20LACN", val_form:45} },
                  {nombre:"Bloque 15 cara de piedra café rosa" ,value: 14 , extra:{codigo:"20LACN", val_form:14} },
                
                              
              ]);
            this.fecha_sv=  this.fecha;
      break;
      case "2020-08-07":
            this.totalDia=new Intl.NumberFormat('es-MX').format(115)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                              
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([
               
                
               
                
                 {nombre:"Bloque 15 liso normal" ,value: 115 , extra:{codigo:"20LACN", val_form:115} },
              
                              
              ]);
        this.fecha_sv=  this.fecha;

            
      break;
      case "2020-08-08":
            this.totalDia=new Intl.NumberFormat('es-MX').format(58)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                              
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([
               
                
               
                
                 {nombre:"Bloque 15 liso normal" ,value: 58 , extra:{codigo:"20LACN", val_form:58} },
              
                              
              ]);
            this.fecha_sv=  this.fecha;
      break;
   
      case "2020-08-10":
            this.totalDia=new Intl.NumberFormat('es-MX').format(94)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
               
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                 {nombre:"Bloque 15 liso normal" ,value: 83 , extra:{codigo:"20LACN", val_form:83} },
                  {nombre:"Bloque 15 cara de piedra café rosa" ,value: 11 , extra:{codigo:"20LACN", val_form:11} },
          
              ]);
            this.fecha_sv=  this.fecha;
      break;
      case "2020-08-11":
            this.totalDia=new Intl.NumberFormat('es-MX').format(40)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 15 liso normal" ,value: 26 , extra:{codigo:"20LACN", val_form:26} },
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                
                  {nombre:"Bloque 20 cara de piedra amarillo paja" ,value: 8 , extra:{codigo:"20LACN", val_form:8} },
                  {nombre:"Bloque 12 liso normal" ,value: 5 , extra:{codigo:"20LACN", val_form:5} },
                  {nombre:"Bloque 15 cara de piedra café rosa" ,value: 1 , extra:{codigo:"20LACN", val_form:1} },
          
              ]);
            this.fecha_sv=  this.fecha;
      break;

      case "2020-08-12":
              this.totalDia=new Intl.NumberFormat('es-MX').format(102.49)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 12 liso normal" ,value: 79 , extra:{codigo:"20LACN", val_form:79} },
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                
                  {nombre:"Bloque 20 liso alta resistencia" ,value: 17 , extra:{codigo:"20LACN", val_form:17} },
                  {nombre:"Bloque 20 cara de piedra amarillo paja" ,value: 4 , extra:{codigo:"20LACN", val_form:4} },
                  {nombre:"Bloque 20 liso amarillo paja" ,value: 2 , extra:{codigo:"20LACN", val_form:2} },
          
              ]);
            this.fecha_sv=  this.fecha;
      break;
      
      case "2020-08-13":

             this.totalDia=new Intl.NumberFormat('es-MX').format(110)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 15 cara de piedra rojo ocre" ,value: 9 , extra:{codigo:"20LACN", val_form:9} },
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                
                  {nombre:"Bloque 20 liso alta resistencia" ,value: 101 , extra:{codigo:"20LACN", val_form:101} },
                  
          
              ]);
            this.fecha_sv=  this.fecha;
      break;
      case "2020-08-14":

             this.totalDia=new Intl.NumberFormat('es-MX').format(113)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 15 cara de piedra rojo ocre" ,value: 9 , extra:{codigo:"20LACN", val_form:9} },
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                
                  {nombre:"Bloque 20 liso alta resistencia" ,value: 104 , extra:{codigo:"20LACN", val_form:104} },
                  
          
              ]);
            this.fecha_sv=  this.fecha;
      break;

      case "2020-08-15":
          this.totalDia=new Intl.NumberFormat('es-MX').format(45)+" Tarimas";
          this.subtitulo="Producción del Día"
          this.one_bar=this.grafic_1_set([
            {nombre:"Bloque 15 cara de piedra rojo ocre" ,value: 3 , extra:{codigo:"20LACN", val_form:3} },
          ]);
          
          this.dia_hinabil="";
           this.grafica_1=this.grafica_1=this.grafic_1_set([

            
              {nombre:"Bloque 20 liso alta resistencia" ,value: 42 , extra:{codigo:"20LACN", val_form:42} },
              
      
          ]);
        this.fecha_sv=  this.fecha;
      
      break;
  
      case "2020-08-17":

             this.totalDia=new Intl.NumberFormat('es-MX').format(107)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 20 liso alta resistencia" ,value: 98 , extra:{codigo:"20LACN", val_form:98} },
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                {nombre:"Bloque 15 cara de piedra café rosa" ,value: 7 , extra:{codigo:"20LACN", val_form:7} },
                  {nombre:"Bloque 15 cara de piedra rojo ocre" ,value: 2 , extra:{codigo:"20LACN", val_form:2} },
                  
                  
          
              ]);
            this.fecha_sv=  this.fecha;
      break;
      case "2020-08-18":
            
             this.totalDia=new Intl.NumberFormat('es-MX').format(62.27)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                {nombre:"Bloque 20 liso alta resistencia" ,value: 25 , extra:{codigo:"20LACN", val_form:25} },
                {nombre:"Bloque 15 solido gris" ,value: 23.17 , extra:{codigo:"20LACN", val_form:23.17} },
                 {nombre:"Bloque 15 cara de piedra café rosa" ,value: 9.10 , extra:{codigo:"20LACN", val_form:9.10} },
                  {nombre:"Bloque 12 liso alta resistencia" ,value: 3, extra:{codigo:"20LACN", val_form:3} },
                 
                  
          
              ]);
            this.fecha_sv=  this.fecha;
      break;
      case "2020-08-19":
              this.totalDia=new Intl.NumberFormat('es-MX').format(106.10)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 15 solido gris" ,value: 5.10 , extra:{codigo:"20LACN", val_form:5.10} },
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                {nombre:"Bloque 12  alta resistencia" ,value: 101 , extra:{codigo:"20LACN", val_form:101} },
                
                 
                  
          
              ]);
            this.fecha_sv=  this.fecha;
      break;
      case "2020-08-20":
              this.totalDia=new Intl.NumberFormat('es-MX').format(106.10)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 12 alta resistencia" ,value: 103 , extra:{codigo:"20LACN", val_form:103} },
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                {nombre:"Bloque 12 liso rojo ocre" ,value: 14 , extra:{codigo:"20LACN", val_form:14} },
                {nombre:"Bloque 15 estriado café rosa" ,value: 9.67 , extra:{codigo:"20LACN", val_form:9.67} },
                 {nombre:" Bloque 15 cara de piedra café rosa" ,value:1.50, extra:{codigo:"20LACN", val_form:1.50} },
               
                 
                  
          
              ]);
            this.fecha_sv=  this.fecha;
      break;

      case "2020-08-21":
              this.totalDia=new Intl.NumberFormat('es-MX').format(102)+" Tarimas";
              this.subtitulo="Producción del Día"
              this.one_bar=this.grafic_1_set([
                {nombre:"Bloque 12 alta resistencia" ,value: 1 , extra:{codigo:"20LACN", val_form:1} },
              ]);
              
              this.dia_hinabil="";
               this.grafica_1=this.grafica_1=this.grafic_1_set([

                {nombre:"Bloque 12 liso normal" ,value: 101 , extra:{codigo:"20LACN", val_form:101} },
                
               
                 
                  
          
              ]);
            this.fecha_sv=  this.fecha;
      break;


      case "2020-08-22":
                this.fecha_sv=this.fecha;
                this.totalDia=new Intl.NumberFormat('es-MX').format(93.35)+" Tarimas";
                this.subtitulo="Producción del Día"
                this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 15 liso normal" ,value: 89 , extra:{codigo:"20LACN", val_form:89} },
                 
                                
                ]);
                
                this.dia_hinabil="";
                 this.grafica_1=this.grafica_1=this.grafic_1_set([
                 
                  {nombre:"Bloque 15 cara de piedra rojo ocre  " ,value: 3 , extra:{codigo:"20LACN", val_form:3 } },
                  {nombre:"Adoquin venecia rojo ocre  " ,value: 1 , extra:{codigo:"20LACN", val_form:1} },
                  {nombre:"Bloque 12 liso rojo ocre  " ,value: .19 , extra:{codigo:"20LACN", val_form:.19} },
                  {nombre:"Bloque 15 liso rojo ocre  " ,value: 0.16 , extra:{codigo:"20LACN", val_form:0.16} },
                                
                ]);
            this.fecha_sv=  this.fecha;
      break;
      /////actualizacion 

      case "2020-08-24":
                this.fecha_sv=this.fecha;
                this.totalDia=new Intl.NumberFormat('es-MX').format(96.09)+" Tarimas";
                this.subtitulo="Producción del Día"
                this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 15 liso café rosa" ,value: 39.09 , extra:{codigo:"20LACN", val_form:39.09} },
                 
                                
                ]);
                
                this.dia_hinabil="";
                 this.grafica_1=this.grafica_1=this.grafic_1_set([
                    {nombre:"Bloque 15 liso normal " ,value: 39 , extra:{codigo:"20LACN", val_form:39} },
                 
                  {nombre:"Bloque 15 alta resistencia " ,value: 18 , extra:{codigo:"20LACN", val_form:18 } },
                 
                
                                
                ]);
            this.fecha_sv=  this.fecha;
      break;
        case "2020-08-25":
                this.fecha_sv=this.fecha;
                this.totalDia=new Intl.NumberFormat('es-MX').format(73.4)+" Tarimas";
                this.subtitulo="Producción del Día"
                this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 15 liso normal" ,value: 40.81 , extra:{codigo:"20LACN", val_form:89} },
                 
                                
                ]);
                
                this.dia_hinabil="";
                 this.grafica_1=this.grafica_1=this.grafic_1_set([
                 
                  {nombre:"Bloque 15 alta resistencia " ,value: 14.50 , extra:{codigo:"20LACN", val_form:14.50 } },
                  {nombre:"Bloque 15 cara de piedra rojo ocre  " ,value: 2 , extra:{codigo:"20LACN", val_form:2} },
                  {nombre:"Bloque 15 cara de piedra amarillo paja  " ,value: 2 , extra:{codigo:"20LACN", val_form:2} },
                  {nombre:"Bloque 15 liso amarillo paja  " ,value: 1.09 , extra:{codigo:"20LACN", val_form:1.09} },
                                
                ]);
            this.fecha_sv=  this.fecha;
      break;


        case "2020-08-26":
                this.fecha_sv=this.fecha;
                this.totalDia=new Intl.NumberFormat('es-MX').format(57)+" Tarimas";
                this.subtitulo="Producción del Día"
                this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 15 cara de piedra café rosa" ,value: 17 , extra:{codigo:"20LACN", val_form:17} },
                 
                                
                ]);
                
                this.dia_hinabil="";
                 this.grafica_1=this.grafica_1=this.grafic_1_set([
                 
                  {nombre:"Bloque 15 alta resistencia" ,value: 40 , extra:{codigo:"20LACN", val_form:40 } },
  
                                
                ]);
            this.fecha_sv=  this.fecha;
        break;

        case "2020-08-27":
                this.fecha_sv=this.fecha;
                this.totalDia=new Intl.NumberFormat('es-MX').format(116)+" Tarimas";
                this.subtitulo="Producción del Día"
                this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 15 alta resistencia" ,value: 103 , extra:{codigo:"20LACN", val_form:103} },
                 
                                
                ]);
                
                this.dia_hinabil="";
                 this.grafica_1=this.grafica_1=this.grafic_1_set([
                 
                  {nombre:"Bloque 15 cara de piedra amarillo paja" ,value: 6 , extra:{codigo:"20LACN", val_form:6 } },
                  {nombre:"Bloque 15 cara de piedra café rosa  " ,value: 7 , extra:{codigo:"20LACN", val_form:7} },
                 
                                
                ]);
            this.fecha_sv=  this.fecha;
        break;

        case "2020-08-28":
                this.fecha_sv=this.fecha;
                this.totalDia=new Intl.NumberFormat('es-MX').format(118.1)+" Tarimas";
                this.subtitulo="Producción del Día"
                this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 15 liso normal" ,value: 71 , extra:{codigo:"20LACN", val_form:71} },
                 
                                
                ]);
                
                this.dia_hinabil="";
                 this.grafica_1=this.grafica_1=this.grafic_1_set([
                 
                  {nombre:"Bloque 15 alta resistencia" ,value: 18 , extra:{codigo:"20LACN", val_form:18 } },
                  {nombre:"Bloque 12 liso rojo ocre " ,value: 15 , extra:{codigo:"20LACN", val_form:15} },
                  {nombre:"Bloque 12 liso normal " ,value: 9 , extra:{codigo:"20LACN", val_form:9} },
                  {nombre:"Bloque 12 dala u rojo ocre  " ,value: 4 , extra:{codigo:"20LACN", val_form:4} },
                                
                ]);
            this.fecha_sv=  this.fecha;
        break;

        case "2020-08-29":
                this.fecha_sv=this.fecha;
                this.totalDia=new Intl.NumberFormat('es-MX').format(111.68)+" Tarimas";
                this.subtitulo="Producción del Día"
                this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 12 liso normal" ,value: 96 , extra:{codigo:"20LACN", val_form:96} },
                 
                                
                ]);
                
                this.dia_hinabil="";
                 this.grafica_1=this.grafica_1=this.grafic_1_set([
                 
                  {nombre:"Bloque 15 cara de piedra rojo ocre" ,value: 11.49 , extra:{codigo:"20LACN", val_form:11.49 } },
                  {nombre:"Bloque 12 alta resistencia " ,value: 2.83 , extra:{codigo:"20LACN", val_form:2.83} },
                  {nombre:"Bloque 15 liso normal" ,value: .37 , extra:{codigo:"20LACN", val_form:.37} },
                                 
                ]);
            this.fecha_sv=  this.fecha;
        break;

        case "2020-08-31":
                this.fecha_sv=this.fecha;
                this.totalDia=new Intl.NumberFormat('es-MX').format(93.35)+" Tarimas";
                this.subtitulo="Producción del Día"
                this.one_bar=this.grafic_1_set([
                  {nombre:"Bloque 12 liso normal" ,value: 29.43 , extra:{codigo:"20LACN", val_form:29.43} },
                 
                                
                ]);
                
                this.dia_hinabil="";
                 this.grafica_1=this.grafica_1=this.grafic_1_set([
                 
                  {nombre:"Bloque 15 cara de piedra café rosa " ,value: 24 , extra:{codigo:"20LACN", val_form:24 } },
                  {nombre:"Bloque 12 alta resistencia " ,value: 21 , extra:{codigo:"20LACN", val_form:21} },
                  {nombre:"Bloque 15 liso normal " ,value: 1.75 , extra:{codigo:"20LACN", val_form:1.75} },
                  
                                
                ]);
            this.fecha_sv=  this.fecha;
        break;

        case "2020-08-23":
            this.totalDia=new Intl.NumberFormat('es-MX').format(0);
            this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
            this.dia_hinabil="Día Inhábil";
            
                  this.grafica_1=this.grafic_1_set(
                  [
                ])
            this.fecha_sv=  this.fecha;
        break;

        case "2020-08-30":
            this.totalDia=new Intl.NumberFormat('es-MX').format(0);
            this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
            this.dia_hinabil="Día Inhábil";
            
                  this.grafica_1=this.grafic_1_set(
                  [
                ])
            this.fecha_sv=  this.fecha;
        break;
       
      ///actualizacio Fin
        case "2020-08-02":
            this.totalDia=new Intl.NumberFormat('es-MX').format(0);
            this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
            this.dia_hinabil="Día Inhábil";
            
                  this.grafica_1=this.grafic_1_set(
                  [
                ])
            this.fecha_sv=  this.fecha;
        break;

        case "2020-08-09":
            this.totalDia=new Intl.NumberFormat('es-MX').format(0);
            this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
            this.dia_hinabil="Día Inhábil";
            
                  this.grafica_1=this.grafic_1_set(
                  [
                ])
            this.fecha_sv=  this.fecha;
        break;

        case "2020-08-16":
            this.totalDia=new Intl.NumberFormat('es-MX').format(0);
            this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
            this.dia_hinabil="Día Inhábil";
            
                  this.grafica_1=this.grafic_1_set(
                  [
                ])
            this.fecha_sv=  this.fecha;
        break;

        default:
                         

                 this.totalDia=new Intl.NumberFormat('es-MX').format(0);
                this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
                this.dia_hinabil="No se encontro Información para esta fecha";
                
                      this.grafica_1=this.grafic_1_set(
                      [
                    ])
                this.fecha_sv=  this.fecha;
          break;
    }*/
  }

  cambioFecha_insumos(){

      this.subtitulo="Insumos del Día";
      this.dia_hinabil="";

      switch (this.fecha) {
        case "2020-08-01":
             this.setInsumos(0,0,0);
          break;
          case "2020-08-02":
             this.setInsumos(0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
          case "2020-08-03":
             this.setInsumos(13070.00,61.41,26.83);
          break;
          case "2020-08-04":
             this.setInsumos(11282.00,78.29,21.71);
          break;
          case "2020-08-05":
             this.setInsumos(10688.00,82.22,21.79);
          break;
          case "2020-08-06":
             this.setInsumos(12752.00,99.08,24.92);
            
          break;
          case "2020-08-07":
             this.setInsumos(13459.00,104.70,25.97);
          break;
          case "2020-08-08":
             this.setInsumos(8736.00,63.29,11.37);
          break;
          case "2020-08-09":
             this.setInsumos(0,0,0);
              this.dia_hinabil="Día Inhábil";
          break;
          case "2020-08-10":
             this.setInsumos(11114.00,92.33,19.66);
          break;
          case "2020-08-11":
             this.setInsumos(10950.00,79.68,16.32);
          break;
          case "2020-08-12":
             this.setInsumos(11109.00,85.60,13.07);
          break;
          case "2020-08-13":
             this.setInsumos(12470.00,91.20,19.50);
          break;
          case "2020-08-14":
             this.setInsumos(12903.00,90.81,22.67);
          break;
          case "2020-08-15":
             this.setInsumos(8302.00,57.20,8.20);
          break;
          case "2020-08-16":
             this.setInsumos(0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
          case "2020-08-17":
             this.setInsumos(14487.00,109.73,19.53);
          break;
          case "2020-08-18":
             this.setInsumos(9804.00,73.19,15.05);
          break;
          case "2020-08-19":
             this.setInsumos(14731.00,122.57,18.27);
          break;

          case "2020-08-20":
             this.setInsumos(14160.00,122.52,16.71);
          break;
          case "2020-08-21":
             this.setInsumos(13243.00,0,15.40);
          break;
          case "2020-08-22":
             this.setInsumos(10739.00,88.17,15.40);
          break;

           case "2020-08-23":
             this.setInsumos(0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
           case "2020-08-30":
             this.setInsumos(0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
          case "2020-09-06":
             this.setInsumos(0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;
          case "2020-09-20":
             this.setInsumos(0,0,0);
             this.dia_hinabil="Día Inhábil";
          break;

           case "2020-08-24":
             this.setInsumos(15540.00,110.96,21.22);
          break;
           case "2020-08-25":
             this.setInsumos(14560,107.41,17.5);
          break;
           case "2020-08-26":
             this.setInsumos(13624,103.72,21.42);
          break;
           case "2020-08-27":
             this.setInsumos(14419.00,116.07,25.57);
          break;
           case "2020-08-28":
             this.setInsumos(14278,116.08,29.12);
          break;
           case "2020-08-29":
             this.setInsumos(15561,113.64,26.76);
          break;
           case "2020-08-31":
             this.setInsumos(6027,53.09,11.81);
          break;
          case "2020-09-01":
             this.setInsumos(4641.00,39.22,5.79);
          break;
            case "2020-09-03":
             this.setInsumos(14175.00,49.28,18.74);
          break;
          case "2020-09-04":
             this.setInsumos(5940,46.2,6.75);
          break;
          case "2020-09-05":
             this.setInsumos(6486,48.88,7.8);
          break;
          case "2020-09-07":
             this.setInsumos(11950.00,94.26,21.76);
          break;
          case "2020-09-11":
             this.setInsumos(8732,65.87,10.42);
          break;
          case "2020-09-12":
             this.setInsumos(12241.00,101.00,12.53);
          break;
          case "2020-09-13":
             this.setInsumos(5932,52.16,7.5);
          break;
          case "2020-09-14":
             this.setInsumos(14897.00,126.06,18.25);
          break;
          case "2020-09-16":
             this.setInsumos(5853,46.4533,6.9333);
          break;
          case "2020-09-17":
             this.setInsumos(8187,66.40,16.93);
          break;
           case "2020-09-18":
             this.setInsumos(14322.00,117.57,20.14);
          break;
          case "2020-09-19":
             this.setInsumos(8052.00,64.64,12.71);
          break;
          case "2020-09-21":
             this.setInsumos(13194.00,98.65,19.99);
          break;

        
        default:
           
             this.setInsumos(0,0,0);
              this.dia_hinabil="Sin Información";
          
          break;
      }
  }



  grafic_1_set(objeto_1){

        let objs=[];
        for(let obj of objeto_1){
            objs.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(obj.value)}  ,
                  name:obj.nombre  ,
                  value:obj.value});
        }

        return objs;


  }

  setData_cards(financiera,anticipos,maniobras){
    let val=[
      {
       "name": "Venta Financiera del Día",
       "value": financiera

      },
       {
           "name": "Anticipos Financieros del Día",
           "value": anticipos

      },
      {
           "name": "Maniobras Financieras del Día",
           "value": maniobras
      
      }];

      return val;


  }


  setData_cards_unidad(financiera,anticipos,maniobras,unidad_anticpo,unidad_maniobar){
    let val=[
      {
       "name": "Ventas por Unidades del Día",
       "value": financiera

      },
       {
           "name": "Número de Anticipos del Día",
           "value": anticipos

      },
      {
           "name": "Número de Maniobras del Día",
           "value": maniobras
      
      }];

      return val;


  }

  page="p1";

  single=[{
  		
        "name": "Total producido",
        "value": 53621
   
  }];
  grafica_1:any=[
	{	extra:{codigo:"15LNCN", val_form:new Intl.NumberFormat('es-MX').format(24940.00 )}	,name:"Bloque 15 liso normal"	,value:24940.00 
},
	{	extra:{codigo:"15LACN", val_form:new Intl.NumberFormat('es-MX').format(21411.00 )}	,name:"Bloque 15 alta resistencia"	,value:21411.00 
},	
	{	extra:{codigo:"15PACN", val_form:new Intl.NumberFormat('es-MX').format(2962.00 )}	,name:"Bloque 15 cara de piedra gris"		,value:2962.00 
},
	{ extra:{codigo:"15PACA" ,val_form:new Intl.NumberFormat('es-MX').format(1680.00 )}	,name:"Bloque 15 cara de piedra amarillo paja"	,value:1680.00 
},	
	{ extra:{codigo:"CUADRADOCR", val_form:new Intl.NumberFormat('es-MX').format(1500.00 )}	,name:"Adoquin cuadrado rojo ocre",value:1500.00 
},			
	{ extra:{codigo:"12LNCN", val_form:new Intl.NumberFormat('es-MX').format(450.00)} ,name:"	Bloque 12 liso normal"		,value:450.00 },	
	{ extra:{codigo:"15LACR", val_form:new Intl.NumberFormat('es-MX').format(360)}	,name:"Bloque 15 liso rojo ocre"		,value:360.00 },	
	{ extra:{codigo:"VENECIACR", val_form:new Intl.NumberFormat('es-MX').format(318)}	,name:"Adoquin venecia rojo ocre"			,value:318.00 },

]

  

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient_bar: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';

  num=4;


  solar:any={
     
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  
  }

 

  loadPage(page){
  	this.page_now=page;
  }

  parseTitlesCard(value){
    return new Intl.NumberFormat('es-MX').format(value);
  }

  parseTitlesCards(value){
    console.log(value)
    if(value.label=="CEMENTO A GRANEL"){
      return new Intl.NumberFormat('es-MX').format(value.value) +" Kg";
    }
    if(value.label=="TRITURADO 3/16 A FINOS"){
      return new Intl.NumberFormat('es-MX').format(value.value) +" M3";
    }
    if(value.label=="GRAVA/ SELLO  3/8"){
      return new Intl.NumberFormat('es-MX').format(value.value) +" M3";
    }

 



  }

}
