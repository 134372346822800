import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit {
	inTeam:any=true;
	inIndicador:any=false;
	title:any;
  user:any=[];

	public barChartOptions: ChartOptions = {
      responsive: true,
      
     plugins: {

         
        datalabels: {
          anchor: 'center',
          align: 'center',
          color:'#000000FF',
           formatter: function(value, context) {
                      //return "$"+new Intl.NumberFormat("es-MX").format(value);;
                  },
           
          font: {
            size: 12,
            weight: 'bold',
          }
          
        }
      }
    };

    options = {
          animation: false,
         // scaleLabel:
          //function(label){return  ' $' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");}
          
    };

    public paiOpcion: ChartOptions = {

    	responsive: true,

    
	    plugins: {

	      datalabels: {
	        listeners: {
	        click: function(value) {
	         
	        }
	      },

        
	        align: 'center',
	        color:'#000000FF',
	         formatter: function(value, context) {
	                   // return "$"+new Intl.NumberFormat("es-MX").format(value);;
	                },

	        font: {
	          size: 12,
	          weight: 'bold',

	        }
        
      	}
    	}
  	};

  	public pieChartLabels = ['Total Cerrado','Por Realizar'];
  	public pieChartData = [];
  	public pieChartType = 'pie';
  	public chartColors: any[] = [
      { 
        backgroundColor:["#5BC23CFF", "#D92535FF", "#4ED963FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#5CBC42FF", "#B12B1FFF", "#475EB2FF"] 
      }];


	public pieChartLabelsfaltas = ['c/Faltas','c/Retardos','c/Asistencias'];
  	public pieChartDatafaltas = [];
  	public pieChartTypefaltas = 'pie';
  	public chartColorsfaltas: any[] = [
      { 
        backgroundColor:["#D92535FF", "#E38A1BFF", "#5BC23CFF","#FFD800FF","#4800FFFF","#A0A0A0FF","#5CBC42FF", "#B12B1FFF", "#475EB2FF"] 
      }];


  	public barChartLabels: Label[] = [];
  	
  	public barChartType: ChartType = 'bar';
  	
  	public barChartLegend = true;
 	
 	public barChartData: ChartDataSets[] = [
    	{ data: [0], label: 'Series A' }   
  	];




   allRols:any=[];
   cerrados:any=[];
   abiertos:any=[];
   faltas:any=[];
   retardos:any=[];
   integrantes:any=[];
   asistencias=0;
   users:any;
   bakAllRols:any=[];
   bakFaltas:any=[];
   bakRetardos:any=[];
   participantes:any=[];
   bakpart:any=[];
   filtroPart:any=[];
   Assis_closes:any=[];
   bakAsis:any=[];
   bakliders:any;
   de:any=null;
   hasta:any=null;
  constructor(private services:UsersService) { }

  ngOnInit() {
    this.de="";
    this.hasta="";

    this.user=[]
    this.participantes=[];
    this.filtroPart=[];
  	localStorage.setItem('page','8');

  	this.services.getUsers().subscribe(res=>{
    		res != null ? this.users=JSON.parse(JSON.stringify(res)).Datas : null;
    		let users=[];

    		for(let user of this.users){
    			
    			users.push(user.Data.nombre);
    		}
        this.bakliders=this.users;
        console.log(this.users);
  	});

  	this.services.getAlleq_rol().subscribe(res=>{
  		
      if(res != null){
  			console.log(res);
  			this.allRols=JSON.parse(JSON.stringify(res)).Datas;
        
          this.services.getrol_InAllInegratesByID().subscribe(res=>{
          
            if(res!=null){
              let obj=JSON.parse(JSON.stringify(res)).Datas;
             for(let perti of obj)
             {
                this.participantes.push(perti.Data);
             }

             this.bakpart=this.participantes;

             this.getAllRetardos();
            }
          });

        

  			this.bakAllRols=this.allRols;

  			this.cerrados=this.allRols.filter(d=>d.Data.abierto == '2');


  			this.abiertos=this.allRols.filter(d=>d.Data.abierto == '1');

  			this.pieChartData=[this.cerrados.length,this.abiertos.length];




  			
  			this.barChartData = [{ data: [this.abiertos.length], label: 'Por Realizar' },
  			{ data: [this.cerrados.length], label: 'Roles Cerradas' }];
 
  		}
  	});


  	
  	
  	
  }

	getAllRetardos(){
		this.services.getrol_InAllRetardo().subscribe(res=>{
      
			res != null ? this.retardos=JSON.parse(JSON.stringify(res)).Datas:null;
			this.getAllFaltas();
      this.getRetardosDetalles();
		});
	}
  

	getAllFaltas(){
	  	this.services.getrol_InAllFaltas().subscribe(res=>{

	  		res !=null ? this.faltas=JSON.parse(JSON.stringify(res)).Datas:null;
	  		
        console.log(this.faltas);
	  		this.getAllAassisClose();
        this.getFaltasDetalles();
	  	});




      


	}

  getAllAassisClose(){
     this.services.getAllAsistenicasCerradas().subscribe(res=>{
       res != null ? this.Assis_closes=JSON.parse(JSON.stringify(res)).Datas : null;
      
        this.asistencias=this.Assis_closes.length-this.faltas.length;
         
        for(let falta of this.faltas){
           
            this.Assis_closes=this.Assis_closes.filter(d=>d.Data.integrante_id != falta.Data.integrante_id);
            
        }
        this.getAssiDetalles();
        this.bakAsis=this.Assis_closes;

        this.pieChartDatafaltas=[this.faltas.length,this.retardos.length,this.asistencias]
        this.bakFaltas=this.faltas;
        this.bakRetardos=this.retardos;
     });
  }


  getFaltasDetalles(){
    for(let falta of this.faltas){
            this.services.getDetalles(falta.Data.eq_rol_id).subscribe(res=>{
             
              res != null ? falta.Data.detalles=JSON.parse(JSON.stringify(res)).Datas[0]:null;
               console.log(falta);
            });
        }
  }

  getRetardosDetalles(){
    for(let falta of this.retardos){
            this.services.getDetalles(falta.Data.eq_rol_id).subscribe(res=>{
             
              res != null ? falta.Data.detalles=JSON.parse(JSON.stringify(res)).Datas[0]:null;
               console.log(falta);
            });
        }
  }

   getAssiDetalles(){
    for(let falta of this.Assis_closes){
            this.services.getDetalles(falta.Data.eq_rol).subscribe(res=>{
             
              res != null ? falta.Data.detalles=JSON.parse(JSON.stringify(res)).Datas[0]:null;
               console.log(falta);
            });
        }
  }

  inToIndicador(myIndicador){
  		this.title=myIndicador;
  		this.inTeam=false;
  		this.inIndicador=true;
  }
  returnTeams(){
  		this.inTeam=true;
  		this.inIndicador=false;
      this.ngOnInit();
  }

	buscar(buscar){

		if(buscar.form.value.user_id=="todos"){
      this.user=[];
      this.ngOnInit();
    
    }else{


      this.user=this.users.find(d=>d.Data.user_id==buscar.form.value.user_id);


		  this.allRols=this.bakAllRols.filter(d=>d.Data.user_id == buscar.form.value.user_id);

		
			this.cerrados=this.allRols.filter(d=>d.Data.abierto == '2');

			this.abiertos=this.allRols.filter(d=>d.Data.abierto == '1');
			    
		 

			let validFlatas=[];
			let validRetardo=[];
      let faltas=[];
      let asistencias=[];

			if(this.allRols.length != 0){
        let newPart=[];
        let sabePart=[];

        
        for(let rol of this.allRols)
        {
          newPart=[];
          
          let obFaltas=[];
          let assis=[];


			     newPart=this.bakpart.filter(d=>d.eq_rol== rol.Data.eq_rol);

           obFaltas=this.bakFaltas.filter(d=>d.Data.eq_rol==rol.Data.eq_rol);

           assis=this.bakAsis.filter(d=>d.Data.eq_rol==rol.Data.eq_rol);


           for(let part of newPart){
             sabePart.push(part);
           }

           for(let part of obFaltas){
             faltas.push(part);
           }

           for(let part of assis){
             asistencias.push(part);
           }


        }

        
        this.Assis_closes=asistencias;
        this.faltas=faltas;
        this.participantes=sabePart;



	  		this.asistencias=this.Assis_closes.length-this.faltas.length;

          for(let falta of this.faltas){
            console.log(falta);
            this.Assis_closes=this.Assis_closes.filter(d=>d.Data.integrante_id != falta.Data.integrante_id);
            console.log(this.Assis_closes);
            }


        this.pieChartDatafaltas=[this.faltas.length,this.retardos.length,this.asistencias]

			}else{
			

          this.retardos=[];

          this.asistencias=0;
          this.Assis_closes=[];
          this.Assis_closes=[];
          this.faltas=[];
			}

        this.asistencias=this.Assis_closes.length-this.faltas.length;
  			
  			this.barChartData = [{ data: [this.abiertos.length], label: 'Por Realizar' },{ data: [this.cerrados.length], label: 'Totales Cerrados' }];

  			this.pieChartData=[this.cerrados.length,this.abiertos.length];
  			this.pieChartDatafaltas=[this.faltas.length,this.retardos.length,this.asistencias];
	  }

  }



  getByDate(form){
    if(form.form.value.de!="" && form.form.value.hasta!= ""){

      this.de=form.form.value.de;
      this.hasta=form.form.value.hasta;
    this.services.getAlleq_eq_rol_dates(form.form.value).subscribe(res=>{
       

      if(res != null){
        
        this.allRols=JSON.parse(JSON.stringify(res)).Datas;
        this.bakAllRols=this.allRols;

        this.cerrados=this.allRols.filter(d=>d.Data.abierto == '2');

        this.abiertos=this.allRols.filter(d=>d.Data.abierto == '1');
     

        let validFlatas=[];
        let validRetardo=[];
        let faltas=[];
        let asistencias=[];
        let lideres=[];

        if(this.allRols.length != 0){
            let newPart=[];
            let sabePart=[];

            
            for(let rol of this.allRols){
              console.log(rol);
              newPart=[];
              
              let obFaltas=[];
              let assis=[];
              let retardos=[];
              let lider_byRol=[];

               newPart=this.bakpart.filter(d=>d.eq_rol== rol.Data.eq_rol);

               obFaltas=this.bakFaltas.filter(d=>d.Data.eq_rol==rol.Data.eq_rol);

               assis=this.bakAsis.filter(d=>d.Data.eq_rol==rol.Data.eq_rol);
               retardos=this.bakRetardos.filter(d=>d.Data.eq_rol==rol.Data.eq_rol);
               lider_byRol=this.bakliders.filter(d=>d.Data.user_id==rol.Data.user_id);

               for(let part of newPart){
                 sabePart.push(part);
               }

               for(let part of obFaltas){
                 faltas.push(part);
               }
               for(let part of assis){
                 asistencias.push(part);
               }
               for(let part of retardos){
                 validRetardo.push(part);
               }
               for(let part of lider_byRol){
                 lideres.push(part);
               }


             }

           
            this.Assis_closes=asistencias;
            this.faltas=faltas;
            this.participantes=sabePart;
            this.retardos=validRetardo;
            this.users=lideres;



          
            let faltaVsAssi=0;
            for(let falta of this.faltas){
           
            if(d=>d.Data.integrante_id == falta.Data.integrante_id){
                faltaVsAssi=faltaVsAssi+1;
            };
            
            }
            this.asistencias=this.Assis_closes.length-faltaVsAssi;

            this.pieChartDatafaltas=[this.faltas.length,this.retardos.length,this.asistencias]

       
        }else{
          this.allRols=[];
          this.faltas=[];
          this.retardos=[];
          this.asistencias=0;
          this.Assis_closes=[];
          this.Assis_closes=[];
          this.participantes=[];

        }

     

      } else{
          this.allRols=[];
          this.faltas=[];
          this.retardos=[];
          this.asistencias=0;
          this.Assis_closes=[];
          this.abiertos=[];
          this.cerrados=[];
          this.participantes=[];
      }

      
      
      this.barChartData = [{ data: [this.abiertos.length], label: 'Por Realizar' },{ data: [this.cerrados.length], label: 'Totales Cerrados' }];

      this.pieChartData=[this.cerrados.length,this.abiertos.length];
      this.pieChartDatafaltas=[this.faltas.length,this.retardos.length,this.asistencias];
              
    });

  }else{
    alert("Es necesario que ingreses las fechas de y hasta.");
  }

  }

}
