import { Component, OnInit } from '@angular/core';
import { InmuebleService } from '../services/inmuebles.service';

@Component({
  selector: 'app-inmuebles',
  templateUrl: './inmuebles.component.html',
  styleUrls: ['./inmuebles.component.css'],
  providers:[InmuebleService]
})

export class InmueblesComponent implements OnInit {


  tipos_inmuebles:any=[];
  status_inmubles:any=[];
  inmuebles:any=[];
  status_venta:any=[];
  desarrollos:any=[];
  inmueble:any=[];

  id:any;
  constructor(private services:InmuebleService) { }

  ngOnInit() {	

  	localStorage.setItem("usuario","edgar");
  	this.getinmuebles();
  	this.services.getCatalogo('tipos_inmuebles').subscribe(result=>{
  		if (result!=null) {
  			this.tipos_inmuebles=JSON.parse(JSON.stringify(result)).Datas;
  		}
  	},error=>{
  		console.log(error);
  	});


  	this.services.getCatalogo('status_inmubles').subscribe(result=>{
  		if (result!=null) {
  			this.status_inmubles=JSON.parse(JSON.stringify(result)).Datas;
  		}
  	},error=>{
  		console.log(error);
  	});
  	this.services.getCatalogo('status_ventas').subscribe(result=>{
  		if (result!=null) {
  			console.log(result);
  			this.status_venta=JSON.parse(JSON.stringify(result)).Datas;
  		}
  	},error=>{
  		console.log(error);
  	});

	this.services.getCatalogo('desarrollos').subscribe(result=>{
	if (result!=null) {
		console.log(result);
		this.desarrollos=JSON.parse(JSON.stringify(result)).Datas;
	}
  	},error=>{
  		console.log(error);
  	});
  }

  getinmuebles(){
  	this.services.getInmuebles().subscribe(result=>{
  		this.inmuebles=JSON.parse(JSON.stringify(result)).Datas;
  	},error=>{
  		console.log(Error);
  	});
  }

  setid(id){
  		console.log(localStorage.getItem("usuario"));
  		this.id=id;
  	  	this.services.getInmueble(id).subscribe(result=>{
  		console.log(result);
  		this.inmueble=JSON.parse(JSON.stringify(result)).Datas;
  	},error=>{
  		console.log(Error);
  	});

  }

  postInmueble(form){
  	console.log(form.form.value);
    
  	this.services.postInmuebles(form.form.value).subscribe(result=>{
  		location.reload();
  	},error=>{
  		console.log(error);
  	});
  }

  putInmueble(form){
  	form.form.value.id=this.id;
  	
  	this.services.putInmuebles(form.form.value).subscribe(result=>{
  		location.reload();
  	},error=>{
  		console.log(error);
  	});
  }

}
