import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class InmobiliariasServices{
	public url="http://67.205.145.224/durango/dashboard_api.php?type=";
	constructor(private http: HttpClient){}

	getCatalogo(catalogo){
		return this.http.get(this.url+"sc_getCatalogo&tabla="+catalogo);
	}
	postInmobiliarios(data){
		return this.http.get(this.url+"sc_postinmobiliarias&nombre="
			+data.nombre+"&calle="+data.calle+"&num_ext="+data.num_ext+"&num_int="
			+data.num_int+"&telefono="+data.telefono+"&correo="+data.correo);
	}

	putInmobiliarios(data,id){
		return this.http.get(this.url+"sc_putinmobiliarias&nombre="
			+data.nombre+"&calle="+data.calle+"&num_ext="+data.num_ext+"&num_int="
			+data.num_int+"&telefono="+data.telefono+"&correo="+data.correo+"&id="+id);
	}

	deleteCatalogo(catalogo,identificador,id){
		return this.http.get(this.url+"sc_DeleteCatalogo&table="+catalogo+"&columna="+identificador+"&id="+id);
	}
	
}