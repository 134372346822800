import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { DashboardService } from '../services/dashboard.service';
import { CatalogosService } from '../services/promotores.service';
import { NotariasService } from '../services/notarias.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-infocontrol',
  templateUrl: './infocontrol.component.html',
  styleUrls: ['./infocontrol.component.css'],
  providers: [DashboardService,CatalogosService,NotariasService]
})
export class InfocontrolComponent implements OnInit {
	TotalVentas:any;
	enProceso:any;
	enFinalizados:any;
	finalizada:any=0;
	pagos_resta:any=0;
	inmuebles_resta:any=0;
	promotores:any=[];
	valuadores:any=[];
	ventas:any=[];
	pagos:any=[];
	cliente:any=[];
	inmueble:any=[];
	promotor:any=[];

	ventas_filtros:any=[];

	cargando:any=null;

  constructor(private router:Router,private valuadoresService:NotariasService ,private service: DashboardService,private catalogosService:CatalogosService) {
    
   }
    paiOpcion: ChartOptions = {   
    plugins: {

      datalabels: {
        listeners: {
        click: function(value) {    
        }
      },

        
       
        align: 'end',
        color:'#000000FF',
         formatter: function(value, context) {
                    return "$"+new Intl.NumberFormat("es-MX").format(value);;
                },

        font: {
          size: 12,
          weight: 'bold',

        }
        
      }
    }
  };

  paiOpcion2: ChartOptions = {   
    plugins: {

      datalabels: {
        listeners: {
        click: function(value) {    
        }
      },


        
       
        align: 'end',
        color:'#000000FF',
         formatter: function(value, context) {
                    return value+'%';
                },

        font: {
          size: 11,
          weight: 'bold',

        }
        
      }
    }
  };





  barChartPlugins = [pluginDataLabels];

  pieChartLabels = ['Ventas', 'Pronostico'];
  pieChartData :any = [];
  pieData2:any=[];
  pieChartType = 'pie';
  chartColors: any[] = [
      { 
        backgroundColor:[ "#347ECAFF","#C03E40FF", "#475EB2FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#5CBC42FF", "#B12B1FFF", "#475EB2FF"] 
      }];

  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartData: ChartDataSets[] = [];
  barColors: any[] = [
      { 
        backgroundColor:["#5CBC42FF", "#B12B1FFF", "#475EB2FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#FF9160FF"] 
      },
      {
        backgroundColor:["#5CBC42FF", "#B12B1FFF", "#475EB2FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#FF9160FF"] 
      }];


  barChartOptions: ChartOptions = {
    responsive: true,
    
    
    plugins: {

       
      datalabels: {
        anchor: 'end',
        align: 'end',
        color:'#000000FF',
         formatter: function(value, context) {
                    return "$"+new Intl.NumberFormat("es-MX").format(value);;
                },
         
        font: {
          size: 10,
          weight: 'bold',
        }
        
      }
    }
  };


 async ngOnInit() {
    this.barChartData.push({ data: [0], label: '' });
    this.barChartLabels.push('Venta');
    this.catalogosService.getPromotores().subscribe(result=>{
      if(result!=null){
        this.promotores=JSON.parse(JSON.stringify(result)).Datas;
      }
    });
    this.valuadoresService.getNotarias().subscribe(result=>{
      if(result!=null){
        this.valuadores=JSON.parse(JSON.stringify(result)).Datas;
      }
    });

     let cambios =await  this.loadDate();
   
    


  }

  loadDate(){
    this.service.getVentas().subscribe(
      result=>{
        if(result!=null){
            let datas=JSON.parse(JSON.stringify(result)).Datas;
            this.ventas=datas;
            let ventasByPagado=this.ventas.filter(d=>d.Data.status_venta_id!=9);
            this.setVentas(ventasByPagado);
        }
      
      },error=>{
        console.log(error);
      }
    );

    this.service.getPagos().subscribe(result=>{
      if (result!=null){
        this.pagos=JSON.parse(JSON.stringify(result)).Datas;
       // this.setPagos(this.pagos);
      }
        
    },error=>{
      console.log(error);
    });
    return true;
  }

  async setVentas(datas){

    let vendidos:any=[];
    let suma_Total=0;
    let finalizada=0; 
    let sumaEnProceso=0;


    for(let dato of datas){
      let nume=parseFloat(dato.Data.valor_operacion);
      suma_Total=suma_Total+nume;

      dato.Data.status_venta_id ==0 || dato.Data.status_venta_id == 1 || dato.Data.status_venta_id == 2 || dato.Data.status_venta_id ==3 
      ? sumaEnProceso=sumaEnProceso+nume : finalizada=finalizada+nume;

      nume!=0 ? vendidos.push({data:  [nume], label: '$'+nume }) : console.log();
      
      this.service.getCliente(dato.Data.cliente_id).subscribe(result=>{
        if (result!=null){
          let cliente=JSON.parse(JSON.stringify(result)).Clientes[0];
          dato.Data.cliente=cliente.Cliente;
        }
      },error=>{
        console.log(error);
      });

      this.service.getInmueble(dato.Data.inmueble_id).subscribe(result=>{
        if(result!=null){
            dato.Data.inmueble=JSON.parse(JSON.stringify(result)).Datas[0].Data;
          }else{
           dato.Data.inmueble=null;
          }
      });


      this.service.getstatus(dato.Data.status_venta_id).subscribe(result=>{
        if(result!=null){
            dato.Data.statusDescripcion=JSON.parse(JSON.stringify(result)).Datas[0].Data;
          }else{
           dato.Data.statusDescripcion=null;
          }
      },error=>{
        console.log(error);
      });

      this.service.getPromotor(dato.Data.promotor_id).subscribe(result=>{
        if(result!=null){
            dato.Data.promotor=JSON.parse(JSON.stringify(result)).Datas[0].Data;
          }else{
           dato.Data.promotor=null;
          }
      },error=>{
        console.log(error);
      });

    

      dato.Data.valor_operaionMNM="$"+new Intl.NumberFormat("es-MX").format(nume);


     }

    this.ventas_filtros=datas;
    this.ventas=datas;
    this.barChartData=vendidos;

    this.TotalVentas="$"+new Intl.NumberFormat("es-MX").format(suma_Total);
    this.enProceso=datas.length;
   
     this.pieChartData=[suma_Total,0]
    this.pieData2=[100,0];

    
    
  }


  getCliente(id){
    
  }


  setPagos(pagosObj){
    this.enFinalizados=[];
    let pendientes:any;
    let pagados:any;
    let pendinte:any=0;
    let pagado:any=0;

    pendientes=pagosObj.filter(d=> d.Data.status_pago_id === "2");
    pagados=pagosObj.filter(d=> d.Data.status_pago_id ==="1");
          
    for(let pen of pendientes){
      let suma=parseFloat(pen.Data.cantidad)
      pendinte=pendinte+suma;
    }

    for(let pag of pagados){
      let suma=parseFloat(pag.Data.cantidad)
      pagado=pagado+suma;
    }

    let sumaPags=0;
    sumaPags=pagado+pendinte;
    this.pieChartData=[0,1]
    this.pieData2=[pendientes.length,pagados.length];
  }

  cargar(data){
    this.TotalVentas=0;
    this.TotalVentas="$"+new Intl.NumberFormat("es-MX").format(0);
    this.enProceso="$"+new Intl.NumberFormat("es-MX").format(0);
    this.finalizada="$"+new Intl.NumberFormat("es-MX").format(0);
    this.barChartData=[];
    this.pagos_resta=0;
    let ventas=[];
    let pagos=[];
    this.pieChartData=[];
    this.pieData2=[];
  }

  cambio_notarias(data){

    if(data==='600006'){
      this.loadDate();
    }else{
      
      this.TotalVentas=0;
      this.enFinalizados=[];
      this.enProceso=[];
      this.enFinalizados=[];
      this.pieChartData=[0,0]
      this.pieData2=[0,0]
      this.barChartData=[];
      this.pagos_resta=0;
      let ventas=[];
      let pagos=[];
      ventas=this.ventas.filter(d=>d.Data.notaria_id=== data); 
      ventas!=null ? this.setVentas(ventas):console.log("Sin Ventas");
    
    }
  }

  cambio_Promotor(data){

    if(data==='600006'){
      this.loadDate();
      //this.setPagos(this.pagos);
    }else{
      
      this.TotalVentas=0;
      this.enFinalizados=[];
      this.enProceso=[];
      this.enFinalizados=[];
      this.pieChartData=[0,0]
      this.pieData2=[0,0]
      this.barChartData=[];
      this.pagos_resta=0;
      let ventas=[];
      let pagos=[];
      ventas=this.ventas.filter(d=>d.Data.promotor_id=== data);
      ventas!=null ? this.setVentas(ventas):console.log("Sin Ventas");
     //this.setPagos(this.pagos);
    }
  }

  cambio_fecha(data){
      let ifTodo=false;
      let de:any;
      let hasta:any;

      switch (data) {
        case '1':
          ifTodo=false;
          break;
        case '2': 
          ifTodo=true;
          de='2020-1-1';
          hasta='2020-1-31';
        break;
        case '3': 
          ifTodo=true;
          de='2020-2-1';
          hasta='2020-2-29';
        break;
        case '4': 
          ifTodo=true;
          de='2020-3-1';
          hasta='2020-3-31';
        break;
        case '5': 
          ifTodo=true;
          de='2020-4-1';
          hasta='2020-4-30';
        break;
        case '6': 
          ifTodo=true;
          de='2020-5-1';
          hasta='2020-5-31';
        break;
        case '7': 
          ifTodo=true;
          de='2020-6-1';
          hasta='2020-6-30';
        break;
        case '8': 
          ifTodo=true;
          de='2020-7-1';
          hasta='2020-7-31';
        break;
        case '9': 
          ifTodo=true;
          de='2020-8-1';
          hasta='2020-8-31';
        break;
        case '10': 
          ifTodo=true;
          de='2019-9-1';
          hasta='2019-9-30';
        break;
        case '11': 
          ifTodo=true;
          de='2019-10-1';
          hasta='2019-10-31';
        break;
        case '12': 
          ifTodo=true;
          console.log("entra aqui");
          de='2019-11-1';
          hasta='2019-11-30';
        break;
        case '13': 
          ifTodo=true;
          de='2019-12-1';
          hasta='2019-12-31';
        break;
      }

      if (ifTodo) {
        this.service.getVentasByFecha(de,hasta).subscribe(result=>{
            console.log(result);
            if (result!=null) {
              let obj=JSON.parse(JSON.stringify(result)).Datas;
              console.log(obj);
              let ventasByPagado=obj.filter(d=>d.Data.status_venta_id!=9);
              this.setVentas(ventasByPagado); 
            }else{
              this.cargar("edgar");
            }
          },error=>{
            console.log(error);
        });
      }else{
        this.loadDate();
      }
  }

  irCliente(id){
    this.router.navigate(["cliente/"+id]);
  }

}
