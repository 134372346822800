import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-equipos',
  templateUrl: './equipos.component.html',
  styleUrls: ['./equipos.component.css']
})
export class EquiposComponent implements OnInit {
	users:any;
	equipos:any;
	equipo:any;
	integrantes:any;
  allRoles:any;
  rolsEqs:any;
  agreagarActividad:any=[];
  actividades:any;
  empleados:any;
  old_empleados:any;
  new_empleados:any;
  rol:any;
  oldEquipos:any;

  newEquipo:any={
    nombre:"",
    user_id:""
  };

  constructor(private services:UsersService) { }


  ngOnInit() {

    localStorage.setItem('page','2');
    
  	this.services.getUsers().subscribe(result => {
			if (result!=null) {
				this.users=JSON.parse(JSON.stringify(result)).Datas;
				console.log(this.users);
			}
		});

    this.services.getEmleados().subscribe(res=>{
      res!=null ? this.empleados=JSON.parse(JSON.stringify(res)).Datas : null;
      console.log("Emplados= "+ this.empleados.length)
      this.old_empleados=this.empleados;
        console.log(this.empleados);
    });





    this.services.getActividades().subscribe(result=>{
      if ( result != null ) {
        this.actividades=JSON.parse(JSON.stringify(result)).Datas;
        console.log(this.actividades);
      }
    });

  	this.services.getEquipos().subscribe(result => {
			if (result!=null) {
				this.equipos=JSON.parse(JSON.stringify(result)).Datas;
				console.log(this.equipos);
        this.oldEquipos=this.equipos;
			}
		});

   this.getRolesAll();


  }

getRolesAll(){
  this.services.getRoles().subscribe(result=>{
      if(result!=null){
        this.allRoles=JSON.parse(JSON.stringify(result)).Datas;

  
      }
    });

}


  addRolEq(form){

    if(this.agreagarActividad.length!=0){
      
      this.services.postrol(form.form.value).subscribe(result=>{
          
          console.log(result);
          
          let obj=JSON.parse(JSON.stringify(result)).Datas;
          
          let lastId=obj[obj.length-1].Data.rol_id;

          this.pushActividad(lastId,form);
      });

        this.getRolesAll();
        
     
    }else{
      alert("Para crear un Rol es necesario agregar Actividades");
    }



  }


  pushActividad(id,data){

    console.log(this.agreagarActividad);


    for(let actividad of this.agreagarActividad){
      
      if(actividad.actividad_id !=0 ){
        this.services.postAct_rol(id,actividad.actividad_id).subscribe(result=>{
          console.log(result);
        });
      }
      
    }

    this.agregarRols2(id,data);
      

  }


  quitarInegrante(data){

    let mensaje=confirm("¿Seguro que desea quitar esté integrante del equipo?");
    if (mensaje) {
      this.services.deleteIntegrante(data.integrante_id).subscribe(res=>{
         this.getIntegrantes();
      },
      err=>{
         this.getIntegrantes();
      });
               

    }else{

    }
  }


  addActividades(addActividad){

    this.services.postActividad(addActividad.form.value).subscribe(res=>{
      addActividad.resetForm();
       this.reloadAct();
    });
 
  }

  reloadAct(){
    this.services.getActividades().subscribe(res=>{
        let obs=JSON.parse(JSON.stringify(res)).Datas;
        let pos=obs.length-1;
        this.actividades=obs;
        
        this.agreagarActividad.push({ nombre :obs[pos].Data.nombre,actividad_id: obs[pos].Data.actividad_id});
         
         this.cerrar('ActividadCrear');
          alert("Actividad Agregada");

    });
  }

  addCliente(form){
  	let data=form.form.value;
    console.log(data);
   
    if(form.form.value.nombre !='' && form.form.value.user_id != ''){
	  this.services.postEquipo(data).subscribe(result=>{
		  
      $('#crearEquipo').modal('hide');
      this.ngOnInit();
	     alert("Se Creo nueo Equipo");
    });

    }else{
      alert("Es necesario el nombre del Equipo y asignar un lider.");
    }
    
  }

  equipoSet(equipo){
  	this.integrantes=[];
    this.rolsEqs=[];
  	this.equipo=equipo;
  	this.getIntegrantes();
    this.getRolesByEquipos();

  }

  getIntegrantes(){
    this.integrantes=[];
  	this.services.getInegrantes(this.equipo.eqTrabajo_id).subscribe(result => {
			if (result!=null) {
				this.integrantes=JSON.parse(JSON.stringify(result)).Datas;
				
			}
		});
  }

  getRolesByEquipos(){

    this.rolsEqs=[];

    this.services.getRolsbyeqp(this.equipo.eqTrabajo_id).subscribe(result => {
      console.log(result);
      if (result!=null) {
        this.rolsEqs=JSON.parse(JSON.stringify(result)).Datas;

        for(let rol of this.rolsEqs){
           let fecha=new Date(rol.Data.fecha_programada);
           let str=fecha+"";
           if(str != "Invalid Date"){
             let mes=fecha.getMonth()+1;
             let dia=fecha.getDate()+1;  
          

             rol.Data.fecha_for=dia+"/"+mes+"/"+fecha.getFullYear();
           }else{
             rol.Data.fecha_for="N/S"
           }

           if (rol.Data.hora != null && rol.Data.hora!="" && rol.Data.hora!="null"){
             rol.Data.hora_r=rol.Data.hora;
           }else{
             rol.Data.hora_r="N/S";
           }

           console.log(fecha.toLocaleDateString('en-MX'));
        }
      }
    });
  }



  addIntegrantes(id,form){
  	this.services.postIntegrantes(id,this.equipo.eqTrabajo_id).subscribe(result=>{
      alert("Nuevo Integrante Añadido");
      form.resetForm();
      this.empleados=this.old_empleados;
		  this.getIntegrantes();
	});

  }

  postRols(form){
  	console.log(form.form.value);
  }


  agregarRols(form){

    //con buscador
    form.form.value.eq_id=this.equipo.eqTrabajo_id;

    this.services.postEqRol(form.form.value).subscribe(result=>{
     this.getlistact(JSON.parse(JSON.stringify(result)).Datas);
     
    });
    form.resetForm();

    console.log(form.form.value);
  }

  getlistact(data){

    console.log(data);

    let position=data.length-1;

    let id=data[position].Data.eq_rol;

    let rol_id=data[position].Data.rol_id;

    let actividades=[];
    this.services.getRol_act_idacrualiza(rol_id).subscribe(res=>{
      actividades=JSON.parse(JSON.stringify(res)).Datas;
      
      for(let actividad of actividades){
      
      if(actividad.Data.actividad_id!=0 ){
          this.services.postAct_eq(id,actividad.Data.actividad_id).subscribe(result=>{
            console.log(result);
          });
        }
      
      }
    });

    console.log(actividades)




    

    alert("Se Agrego Rol");
        this.getRolesByEquipos();
        this.cerrar('Actividadteam');
  }


  agregarRols2(id,form){

    form.form.value.eq_id=this.equipo.eqTrabajo_id;

    form.form.value.rol_id=id;

    this.services.postEqRol(form.form.value).subscribe(result=>{

        this.getRols(JSON.parse(JSON.stringify(result)).Datas);
        
    });
    console.log(form.form.value);
  
  }

    limpiarRol(){
    this.agreagarActividad=[];
  }



  getRols(data){

    let position=data.length-1;
    let id=data[position].Data.eq_rol;
    let rol_id=data[position].Data.rol_id;

    for(let actividad of this.agreagarActividad){
      
      if(actividad.actividad_id!=0 ){
        this.services.postAct_eq(id,actividad.actividad_id).subscribe(result=>{
          console.log(result);
        });
      }
      
    }

    
    this.getRolesByEquipos();
    this.cerrar('Rol');

    
  }

  setActEq(id,data){


   
  }


  agregarlist(data){

  
    
    this.agreagarActividad.push({ nombre :data.nombre,actividad_id:data.actividad_id});
    alert("Actividad Agregada");

  }


  quitarList(lista){
    console.log(lista);
      let value=this.agreagarActividad.filter(d=>d.actividad_id != lista.actividad_id);

      this.agreagarActividad=value;
  }



  cerrar(modal){
    let md='#'+modal;
     $(md).modal('hide');
  }


filtro(ev)


    {
      if (ev=='') {
       this.empleados=this.old_empleados;

      }else{
        this.empleados=this.old_empleados;
        let val = ev.target.value;
        console.log(val);
        
        if (val && val.trim() != '') {
        this.empleados = this.empleados.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.empleados=this.old_empleados;
      }
     }
    }


 deleteRol_act(rol)
 {
   console.log(rol);


    let mensaje=confirm("¿Seguro que desea quitar esté Rol del equipo?");
    if (mensaje) {
        this.services.deleteRoldeActividas(rol.eq_rol).subscribe(res=>{
       this.getRolesByEquipos();
       });

      this.services.delete_Rol_eqp(rol.eq_rol).subscribe(res=>{
         
       });

      alert("Se elimino Rol del Equipo");
     
       
    }
   
 }

 updateRolEq(form){
   console.log(form.form.value);
   this.services.updateEqp_rol(this.rol.eq_rol,form.form.value).subscribe(res=>{
    
   });
    alert('Se actualizo Rol del Equipo');
   this.getRolesByEquipos();

 }

 saveRol(data){
   console.log(data);
   this.rol=data;
   
    this.services.getRol_act_idacrualiza(this.rol.rol_id).subscribe(res=>{
      console.log(res)
      res!=null?this.rol.actividades=JSON.parse(JSON.stringify(res)).Datas:null;
    
    });
 }
   filtross(ev)
    {

      console.log(this.equipos);
      if (ev=='') {
       this.equipos=this.oldEquipos;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.equipos = this.equipos.filter((item) => {
          return (item.Data.nombreEquipo.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.equipos=this.oldEquipos;
      }
     }
    }

}
