import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';


@Component({
  selector: 'app-tipos-esquemas',
  templateUrl: './tipos-esquemas.component.html',
  styleUrls: ['./tipos-esquemas.component.css'],
   providers:[CatalogosService]
})
export class TiposEsquemasComponent implements OnInit {

 	esquemas:any=[];
	nombre:any;
	id_update:any;
	descrip:any;
	titulo:any;
  	constructor(private servicios:CatalogosService) { }

  	ngOnInit(){

  		  this.nombre="Tipos Esquemas";
            this.servicios.getCatalogo("esquemas").subscribe(result=>{
               if(result!=null){
               	console.log(result);
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.esquemas=temp2.Datas;
               }
            });
  	}

  	setid2(id,tit,des){
	    this.id_update=id;
	    this.descrip=des;
	    this.titulo=tit;
  	}

  	setiddelete(id){
    this.id_update=id;
  }
   postStatus(form){
   	 this.servicios.postCatalogoTipos('esquemas','esquema_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
        location.reload();
      },error=>console.log(error)); 
   }
   putStatus(form){
   		this.servicios.putCatalogoTipos('esquemas','esquema_id',form.form.value,this.id_update).subscribe(result=>{
        	location.reload();
      	},error=>console.log(error)); 
   }

   deleteCatlogo(){
   		this.servicios.deleteCatalogo('esquemas','esquema_id',this.id_update).subscribe(result=>{
        	location.reload();
      	},error=>console.log(error));  
   }

}
