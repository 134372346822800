import { Component, OnInit } from '@angular/core';
import { QueriesLogoService } from '../services/queries-logo.service';
import { Router,ActivatedRoute, Params } from "@angular/router";

declare var $: any;
@Component({
  selector: 'app-add-preventa',
  templateUrl: './add-preventa.component.html',
  styleUrls: ['./add-preventa.component.css'],
  providers: [QueriesLogoService]
})
export class AddPreventaComponent implements OnInit {

  desarrollos: any;
  desa: any;
  inmuebles: any;
  inmu: any;

  promotores:any;
  prom:any;

  notarias:any;
  nota:any;
  constructor(public servicio: QueriesLogoService,private router:Router) { }

  ngOnInit() {

    if (sessionStorage.getItem('token')==null) {
      this.router.navigate(["/login"]);
    }
    
    this.servicio.getDesarrollos().subscribe(
      result => {
        console.log(result);
        var eco = JSON.stringify(result);
        var json = JSON.parse(eco);
        this.desarrollos = json.Desarrollo;


        for (var i = this.desarrollos.length - 1; i >= 0; i--) {

          this.desa = this.desarrollos[i].Desarrollos.desarrollo_id;

        }

        this.servicio.getInmuebles(this.desa,1).subscribe(
          result => {
            console.log(result);
            var eco = JSON.stringify(result);
            var json = JSON.parse(eco);
            this.inmuebles = json.Inmueble;
            for (var i = this.inmuebles.length - 1; i >= 0; i--) {

              this.inmu = this.inmuebles[i].Inmuebles.inmueble_id;
    
    
    
            }

          },
          error => {
            console.log(<any>error);
          }
        );

      },
      error => {
        console.log(<any>error);
      }
    );

    this.servicio.getPromotores().subscribe(
      result => {
        console.log(result);
        var eco = JSON.stringify(result);
        var json = JSON.parse(eco);
        this.promotores = json.Promotor;


        for (var i = this.promotores.length - 1; i >= 0; i--) {

          this.prom = this.promotores[i].Promotores.promotor_id;

        }

      },
      error => {
        console.log(<any>error);
      }
    );

    this.servicio.getNotarias().subscribe(
      result => {
        console.log(result);
        var eco = JSON.stringify(result);
        var json = JSON.parse(eco);
        this.notarias = json.Notaria;


        for (var i = this.notarias.length - 1; i >= 0; i--) {

          this.nota = this.notarias[i].Notarias.notaria_id;

        }

      },
      error => {
        console.log(<any>error);
      }
    );


  }

  obtenerInmuebles() {
    console.log($('#desarrollo').val());
    this.servicio.getInmuebles($('#desarrollo').val(),1).subscribe(
      result => {
        console.log(result);
        if(result == null){
          alert("El desarrollo no tiene inmuebles ");
          this.inmuebles = [];
        }else{
          var eco = JSON.stringify(result);
          var json = JSON.parse(eco);
          this.inmuebles = json.Inmueble;

          for (var i = this.inmuebles.length - 1; i >= 0; i--) {

            this.inmu = this.inmuebles[i].Inmuebles.inmueble_id;
          }
        }
        

      },
      error => {
        console.log(<any>error);
        
      }
    );
  }

  obtenerInmueble() {
    console.log($('#desarrollo').val());
    this.servicio.getInmuebles($('#desarrollo').val(),1).subscribe(
      result => {
        console.log(result);
        if(result == null){
          alert("El desarrollo no tiene inmuebles ");
          this.inmuebles = [];
        }else{
          var eco = JSON.stringify(result);
          var json = JSON.parse(eco);
          this.inmuebles = json.Inmueble;

          for (var i = this.inmuebles.length - 1; i >= 0; i--) {

            this.inmu = this.inmuebles[i].Inmuebles.inmueble_id;
          }
        }
        

      },
      error => {
        console.log(<any>error);
        
      }
    );
  }

  guardar(){
    // console.log($('#inmueble').val());
    // console.log($('#n_escritura').val());
    // console.log($('#v_operacion').val());
    // console.log($('#f_operacion').val());
    // console.log($('#promotor').val());
    // console.log($('#p_promotor').val());
    // console.log($('#f_p_promotor').val());
    // console.log($('#s_venta').val());
    // console.log($('#ff_c_compromiso').val());
    // console.log($('#a_voluntario').val());
    // console.log($('#p_seguro').val());
    // console.log($('#i_derechos').val());
    // console.log($('#notaria').val());
    // console.log($('#f_entrega').val());
    // console.log($('#s_firma').val());
    // console.log($('#f_firma').val());

    // tslint:disable-next-line: max-line-length
    /*this.servicio.insertVentas($('#inmueble').val(),$('#n_escritura').val(),$('#t_compra').val(),$('#v_operacion').val(),$('#f_operacion').val(),$('#promotor').val(),$('#p_promotor').val(),$('#f_p_promotor').val(),$('#s_venta').val(),$('#ff_c_compromiso').val(),$('#a_voluntario').val(),$('#p_seguro').val(),$('#i_derechos').val(),$('#notaria').val(),$('#f_entrega').val(),$('#s_firma').val(),$('#f_firma').val()).subscribe(
      result=>{
        console.log("Bien");
        alert("Guardado con Exito!!");
      },error=>{
        console.log(<any>error);
         console.log("mal");
    });*/
  }

}
