import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-rp-ingresos',
  templateUrl: './rp-ingresos.component.html',
  styleUrls: ['./rp-ingresos.component.css']
})
export class RpIngresosComponent implements OnInit {
		page=1;
		titulo="Ingresos";
		fecha='2020-09-30';
		fecha1='2020-09-01';
		fecha2='2020-09-30';
		datos:any=[];
		info='';
		user='';
  constructor(private service:UsersService) { }

  ngOnInit() {
  	this.user=sessionStorage.getItem('id');
  	console.log(this.user);
  	this.info='';
  	this.datos.repEfectivo=0;
  	this.datos.efectivo=0;
  	this.datos.rep_tarjeta_bancaria=0;
  	this.datos.tarjeta_bancaria=0;
  	this.datos.rep_traferencia=0;
  	this.datos.trasferencias=0;
  	this.datos.rep_cheque=0;
  	this.datos.cheque_bancario=0;
  	this.datos.pagos_rpf=0;


  	this.datos.gcl_repEfectivo=0;
  	this.datos.gcl_efectivo=0;
  	this.datos.gcl_rep_tarjeta_bancaria=0;
  	this.datos.gcl_tarjeta_bancaria=0;
  	this.datos.gcl_rep_traferencia=0;
  	this.datos.gcl_trasferencias=0;
  	this.datos.gcl_rep_cheque=0;
  	this.datos.gcl_cheque_bancario=0;
  	this.datos.gcl_pagos_rpf=0;
  	this.datos.remesas=0;
  	this.datos.gastos=0;
  	this.datos.remante=0;



  }

 	initAcumulado(){

	  this.ngOnInit();
	  /*

	  this.datos.pagos_rpf=	'257,002.93';
	  this.datos.gcl_pagos_rpf='142,635.00';
	  this.datos.rep_traferencia='179,461.15';
	  this.datos.tarjeta_bancaria='94,039.36';	
	  this.datos.trasferencias='1,640,098.72';
	  this.datos.efectivo='103,111.34';
	  this.datos.cheque_bancario='9,130.01';

	  this.datos.gastos="-51,315.00";
	  this.datos.remesas="-311,897.00";

	  this.datos.remante="36,320.93";*/

	  this.service.get_ingresos_betwen('2020-09-01','2020-09-30').subscribe(res=>{
	  	if(res!=null){
	  		let pagoRPF=0;
	  		
	  		let ingresos=JSON.parse(JSON.stringify(res)).Datas;

	  		let pagos_rpf = ingresos.filter(ing=>ing.Data.nombre=='Pago RPF');
	  		for(let pago_rpf of pagos_rpf){ this.datos.pagos_rpf=	parseFloat(this.datos.pagos_rpf)+ parseFloat(pago_rpf.Data.total); }
	  			
	  			pagoRPF=parseFloat(this.datos.pagos_rpf.toFixed(2));

	  		this.datos.pagos_rpf=new Intl.NumberFormat('es-MX').format(this.datos.pagos_rpf.toFixed(2));

	  		let gcl_pagos_rpf = ingresos.filter(ing=>ing.Data.nombre=='gcl Pago RPF');
	  		
	  		for(let pago_rpf of gcl_pagos_rpf){ this.datos.gcl_pagos_rpf=	parseFloat(this.datos.gcl_pagos_rpf)+ parseFloat(pago_rpf.Data.total); }
	  			pagoRPF=pagoRPF+parseFloat(this.datos.gcl_pagos_rpf.toFixed(2));
	  		this.datos.gcl_pagos_rpf=new Intl.NumberFormat('es-MX').format(this.datos.gcl_pagos_rpf.toFixed(2));
	  		
	  		

  				this.datos.repEfectivo=0;


  					let efectivo = ingresos.filter(ing=>ing.Data.nombre=='Efectivo');

	  		
	  		for(let pago_rpf of efectivo){ this.datos.efectivo=	parseFloat(this.datos.efectivo)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.efectivo=new Intl.NumberFormat('es-MX').format(this.datos.efectivo.toFixed(2));
	  		
	  	

	  				let rep_traferencia = ingresos.filter(ing=>ing.Data.nombre=='Rep Transferencia');

	  		
	  		for(let pago_rpf of rep_traferencia){ this.datos.rep_traferencia=	parseFloat(this.datos.rep_traferencia)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.rep_traferencia=new Intl.NumberFormat('es-MX').format(this.datos.rep_traferencia.toFixed(2));
	  		
	  		console.log(this.datos.rep_traferencia);


	  				let tarjeta_bancaria = ingresos.filter(ing=>ing.Data.nombre=='Tarjetas Bancarias');

	  		
	  		for(let pago_rpf of tarjeta_bancaria){ this.datos.tarjeta_bancaria=	parseFloat(this.datos.tarjeta_bancaria)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.tarjeta_bancaria=new Intl.NumberFormat('es-MX').format(this.datos.tarjeta_bancaria.toFixed(2));
	  		
	  		

	  					let trasferencias = ingresos.filter(ing=>ing.Data.nombre=='Transferencia');

	  		
	  		for(let pago_rpf of trasferencias){ this.datos.trasferencias=	parseFloat(this.datos.trasferencias)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.trasferencias=new Intl.NumberFormat('es-MX').format(this.datos.trasferencias.toFixed(2));
	  		
	  		
	  		let cheque_bancario = ingresos.filter(ing=>ing.Data.nombre=='Cheque Bancario');

	  		
	  		for(let pago_rpf of cheque_bancario){ this.datos.cheque_bancario=	parseFloat(this.datos.cheque_bancario)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.cheque_bancario=new Intl.NumberFormat('es-MX').format(this.datos.cheque_bancario.toFixed(2));
	  		
	  	



	  		let gastos = ingresos.filter(ing=>ing.Data.nombre=='pago');

	  		
	  		for(let pago_rpf of gastos){ this.datos.gastos=	parseFloat(this.datos.gastos)+ parseFloat(pago_rpf.Data.total); }

	  				
	  			pagoRPF=pagoRPF+parseFloat(this.datos.gastos.toFixed(2));
	  		this.datos.gastos=new Intl.NumberFormat('es-MX').format(this.datos.gastos.toFixed(2));
	  		
	  		console.log(this.datos.gastos);


	  		let remesas = ingresos.filter(ing=>ing.Data.nombre=='remesas');

	  		console.log(remesas);

	  		for(let pago_rpf of remesas){ this.datos.remesas=	parseFloat(this.datos.remesas)+ parseFloat(pago_rpf.Data.total); }

	  				
	  			pagoRPF=pagoRPF+parseFloat(this.datos.remesas.toFixed(2));
	  		this.datos.remesas=new Intl.NumberFormat('es-MX').format(this.datos.remesas.toFixed(2));
	  		
	  		console.log(this.datos.remesas);



	  		this.datos.remante=pagoRPF.toFixed(2);
	  		this.datos.remante=new Intl.NumberFormat('es-MX').format(this.datos.remante);
	  		console.log(this.datos.remante);
	  		
	  		
  			


	  	}

	  });

	  




  }

  initconsultas(){

  	this.ngOnInit();

	  this.datos.remante=new Intl.NumberFormat('es-MX').format(-51315.00+-173127.00+257002.93+132530.00);


	  this.service.get_ingresos_betwen(this.fecha1,this.fecha2).subscribe(res=>{
	  	if(res!=null){
	  		let pagoRPF=0;
	  		
	  		let ingresos=JSON.parse(JSON.stringify(res)).Datas;

	  		let pagos_rpf = ingresos.filter(ing=>ing.Data.nombre=='Pago RPF');
	  		for(let pago_rpf of pagos_rpf){ this.datos.pagos_rpf=	parseFloat(this.datos.pagos_rpf)+ parseFloat(pago_rpf.Data.total); }
	  			
	  			pagoRPF=parseFloat(this.datos.pagos_rpf.toFixed(2));

	  		this.datos.pagos_rpf=new Intl.NumberFormat('es-MX').format(this.datos.pagos_rpf.toFixed(2));

	  		let gcl_pagos_rpf = ingresos.filter(ing=>ing.Data.nombre=='gcl Pago RPF');
	  		
	  		for(let pago_rpf of gcl_pagos_rpf){ this.datos.gcl_pagos_rpf=	parseFloat(this.datos.gcl_pagos_rpf)+ parseFloat(pago_rpf.Data.total); }
	  			pagoRPF=pagoRPF+parseFloat(this.datos.gcl_pagos_rpf.toFixed(2));
	  		this.datos.gcl_pagos_rpf=new Intl.NumberFormat('es-MX').format(this.datos.gcl_pagos_rpf.toFixed(2));
	  		
	  		

  				this.datos.repEfectivo=0;


  					let efectivo = ingresos.filter(ing=>ing.Data.nombre=='Efectivo');

	  		
	  		for(let pago_rpf of efectivo){ this.datos.efectivo=	parseFloat(this.datos.efectivo)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.efectivo=new Intl.NumberFormat('es-MX').format(this.datos.efectivo.toFixed(2));
	  		
	  	

	  				let rep_traferencia = ingresos.filter(ing=>ing.Data.nombre=='Rep Transferencia');

	  		
	  		for(let pago_rpf of rep_traferencia){ this.datos.rep_traferencia=	parseFloat(this.datos.rep_traferencia)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.rep_traferencia=new Intl.NumberFormat('es-MX').format(this.datos.rep_traferencia.toFixed(2));
	  		
	  		console.log(this.datos.rep_traferencia);


	  				let tarjeta_bancaria = ingresos.filter(ing=>ing.Data.nombre=='Tarjetas Bancarias');

	  		
	  		for(let pago_rpf of tarjeta_bancaria){ this.datos.tarjeta_bancaria=	parseFloat(this.datos.tarjeta_bancaria)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.tarjeta_bancaria=new Intl.NumberFormat('es-MX').format(this.datos.tarjeta_bancaria.toFixed(2));
	  		
	  		

	  					let trasferencias = ingresos.filter(ing=>ing.Data.nombre=='Transferencia');

	  		
	  		for(let pago_rpf of trasferencias){ this.datos.trasferencias=	parseFloat(this.datos.trasferencias)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.trasferencias=new Intl.NumberFormat('es-MX').format(this.datos.trasferencias.toFixed(2));
	  		
	  		
	  		let cheque_bancario = ingresos.filter(ing=>ing.Data.nombre=='Cheque Bancario');

	  		
	  		for(let pago_rpf of cheque_bancario){ this.datos.cheque_bancario=	parseFloat(this.datos.cheque_bancario)+ parseFloat(pago_rpf.Data.total); }

	  				

	  		this.datos.cheque_bancario=new Intl.NumberFormat('es-MX').format(this.datos.cheque_bancario.toFixed(2));
	  		
	  	



	  		let gastos = ingresos.filter(ing=>ing.Data.nombre=='pago');

	  		
	  		for(let pago_rpf of gastos){ this.datos.gastos=	parseFloat(this.datos.gastos)+ parseFloat(pago_rpf.Data.total); }

	  				
	  			pagoRPF=pagoRPF+parseFloat(this.datos.gastos.toFixed(2));
	  		this.datos.gastos=new Intl.NumberFormat('es-MX').format(this.datos.gastos.toFixed(2));
	  		
	  		console.log(this.datos.gastos);


	  		let remesas = ingresos.filter(ing=>ing.Data.nombre=='remesas');

	  		console.log(remesas);

	  		for(let pago_rpf of remesas){ this.datos.remesas=	parseFloat(this.datos.remesas)+ parseFloat(pago_rpf.Data.total); }

	  				
	  			pagoRPF=pagoRPF+parseFloat(this.datos.remesas.toFixed(2));
	  		this.datos.remesas=new Intl.NumberFormat('es-MX').format(this.datos.remesas.toFixed(2));
	  		
	  		console.log(this.datos.remesas);



	  		this.datos.remante=pagoRPF.toFixed(2);
	  		this.datos.remante=new Intl.NumberFormat('es-MX').format(this.datos.remante);
	  		console.log(this.datos.remante);
	  		
	  		
  			


	  	}

	  });
  
  }


  cambio_filtro(){
  	this.ngOnInit();
  		 this.service.get_ingresos_betwen(this.fecha1,this.fecha2).subscribe(res=>{
			  	if(res!=null){
			  		let pagoRPF=0;
			  		
			  		let ingresos=JSON.parse(JSON.stringify(res)).Datas;

			  		let pagos_rpf = ingresos.filter(ing=>ing.Data.nombre=='Pago RPF');
			  		for(let pago_rpf of pagos_rpf){ this.datos.pagos_rpf=	parseFloat(this.datos.pagos_rpf)+ parseFloat(pago_rpf.Data.total); }
			  			
			  			pagoRPF=parseFloat(this.datos.pagos_rpf.toFixed(2));

			  		this.datos.pagos_rpf=new Intl.NumberFormat('es-MX').format(this.datos.pagos_rpf.toFixed(2));

			  		let gcl_pagos_rpf = ingresos.filter(ing=>ing.Data.nombre=='gcl Pago RPF');
			  		
			  		for(let pago_rpf of gcl_pagos_rpf){ this.datos.gcl_pagos_rpf=	parseFloat(this.datos.gcl_pagos_rpf)+ parseFloat(pago_rpf.Data.total); }
			  			pagoRPF=pagoRPF+parseFloat(this.datos.gcl_pagos_rpf.toFixed(2));
			  		this.datos.gcl_pagos_rpf=new Intl.NumberFormat('es-MX').format(this.datos.gcl_pagos_rpf.toFixed(2));
			  		
			  		

		  				this.datos.repEfectivo=0;


		  					let efectivo = ingresos.filter(ing=>ing.Data.nombre=='Efectivo');

			  		
			  		for(let pago_rpf of efectivo){ this.datos.efectivo=	parseFloat(this.datos.efectivo)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.efectivo=new Intl.NumberFormat('es-MX').format(this.datos.efectivo.toFixed(2));
			  		
			  	

			  				let rep_traferencia = ingresos.filter(ing=>ing.Data.nombre=='Rep Transferencia');

			  		
			  		for(let pago_rpf of rep_traferencia){ this.datos.rep_traferencia=	parseFloat(this.datos.rep_traferencia)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.rep_traferencia=new Intl.NumberFormat('es-MX').format(this.datos.rep_traferencia.toFixed(2));
			  		
			  		console.log(this.datos.rep_traferencia);


			  				let tarjeta_bancaria = ingresos.filter(ing=>ing.Data.nombre=='Tarjetas Bancarias');

			  		
			  		for(let pago_rpf of tarjeta_bancaria){ this.datos.tarjeta_bancaria=	parseFloat(this.datos.tarjeta_bancaria)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.tarjeta_bancaria=new Intl.NumberFormat('es-MX').format(this.datos.tarjeta_bancaria.toFixed(2));
			  		
			  		

			  					let trasferencias = ingresos.filter(ing=>ing.Data.nombre=='Transferencia');

			  		
			  		for(let pago_rpf of trasferencias){ this.datos.trasferencias=	parseFloat(this.datos.trasferencias)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.trasferencias=new Intl.NumberFormat('es-MX').format(this.datos.trasferencias.toFixed(2));
			  		
			  		
			  		let cheque_bancario = ingresos.filter(ing=>ing.Data.nombre=='Cheque Bancario');

			  		
			  		for(let pago_rpf of cheque_bancario){ this.datos.cheque_bancario=	parseFloat(this.datos.cheque_bancario)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.cheque_bancario=new Intl.NumberFormat('es-MX').format(this.datos.cheque_bancario.toFixed(2));
			  		
			  	



			  		let gastos = ingresos.filter(ing=>ing.Data.nombre=='pago');

			  		
			  		for(let pago_rpf of gastos){ this.datos.gastos=	parseFloat(this.datos.gastos)+ parseFloat(pago_rpf.Data.total); }

			  				
			  			pagoRPF=pagoRPF+parseFloat(this.datos.gastos.toFixed(2));
			  		this.datos.gastos=new Intl.NumberFormat('es-MX').format(this.datos.gastos.toFixed(2));
			  		
			  		console.log(this.datos.gastos);


			  		let remesas = ingresos.filter(ing=>ing.Data.nombre=='remesas');

			  		console.log(remesas);

			  		for(let pago_rpf of remesas){ this.datos.remesas=	parseFloat(this.datos.remesas)+ parseFloat(pago_rpf.Data.total); }

			  				
			  			pagoRPF=pagoRPF+parseFloat(this.datos.remesas.toFixed(2));
			  		this.datos.remesas=new Intl.NumberFormat('es-MX').format(this.datos.remesas.toFixed(2));
			  		
			  		console.log(this.datos.remesas);



			  		this.datos.remante=pagoRPF.toFixed(2);
			  		this.datos.remante=new Intl.NumberFormat('es-MX').format(this.datos.remante);
			  		console.log(this.datos.remante);
			  		
			  		
		  			


			  	}

	  });
  }

  initFecha(){


  		this.cambio_diario();


  }

  cambio_diario(){
  	this.ngOnInit();
  	console.log(this.fecha);
  	this.service.get_ingresos_fecha(this.fecha).subscribe(res=>{
			  	if(res!=null){
			  		let pagoRPF=0;
			  		
			  		let ingresos=JSON.parse(JSON.stringify(res)).Datas;

			  		let pagos_rpf = ingresos.filter(ing=>ing.Data.nombre=='Pago RPF');
			  		for(let pago_rpf of pagos_rpf){ this.datos.pagos_rpf=	parseFloat(this.datos.pagos_rpf)+ parseFloat(pago_rpf.Data.total); }
			  			
			  			pagoRPF=parseFloat(this.datos.pagos_rpf.toFixed(2));

			  		this.datos.pagos_rpf=new Intl.NumberFormat('es-MX').format(this.datos.pagos_rpf.toFixed(2));

			  		let gcl_pagos_rpf = ingresos.filter(ing=>ing.Data.nombre=='gcl Pago RPF');
			  		
			  		for(let pago_rpf of gcl_pagos_rpf){ this.datos.gcl_pagos_rpf=	parseFloat(this.datos.gcl_pagos_rpf)+ parseFloat(pago_rpf.Data.total); }
			  			pagoRPF=pagoRPF+parseFloat(this.datos.gcl_pagos_rpf.toFixed(2));
			  		this.datos.gcl_pagos_rpf=new Intl.NumberFormat('es-MX').format(this.datos.gcl_pagos_rpf.toFixed(2));
			  		
			  		

		  				this.datos.repEfectivo=0;


		  					let efectivo = ingresos.filter(ing=>ing.Data.nombre=='Efectivo');

			  		
			  		for(let pago_rpf of efectivo){ this.datos.efectivo=	parseFloat(this.datos.efectivo)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.efectivo=new Intl.NumberFormat('es-MX').format(this.datos.efectivo.toFixed(2));
			  		
			  	

			  				let rep_traferencia = ingresos.filter(ing=>ing.Data.nombre=='Rep Transferencia');

			  		
			  		for(let pago_rpf of rep_traferencia){ this.datos.rep_traferencia=	parseFloat(this.datos.rep_traferencia)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.rep_traferencia=new Intl.NumberFormat('es-MX').format(this.datos.rep_traferencia.toFixed(2));
			  		
			  		console.log(this.datos.rep_traferencia);


			  				let tarjeta_bancaria = ingresos.filter(ing=>ing.Data.nombre=='Tarjetas Bancarias');

			  		
			  		for(let pago_rpf of tarjeta_bancaria){ this.datos.tarjeta_bancaria=	parseFloat(this.datos.tarjeta_bancaria)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.tarjeta_bancaria=new Intl.NumberFormat('es-MX').format(this.datos.tarjeta_bancaria.toFixed(2));
			  		
			  		

			  					let trasferencias = ingresos.filter(ing=>ing.Data.nombre=='Transferencia');

			  		
			  		for(let pago_rpf of trasferencias){ this.datos.trasferencias=	parseFloat(this.datos.trasferencias)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.trasferencias=new Intl.NumberFormat('es-MX').format(this.datos.trasferencias.toFixed(2));
			  		
			  		
			  		let cheque_bancario = ingresos.filter(ing=>ing.Data.nombre=='Cheque Bancario');

			  		
			  		for(let pago_rpf of cheque_bancario){ this.datos.cheque_bancario=	parseFloat(this.datos.cheque_bancario)+ parseFloat(pago_rpf.Data.total); }

			  				

			  		this.datos.cheque_bancario=new Intl.NumberFormat('es-MX').format(this.datos.cheque_bancario.toFixed(2));
			  		
			  	



			  		let gastos = ingresos.filter(ing=>ing.Data.nombre=='pago');

			  		
			  		for(let pago_rpf of gastos){ this.datos.gastos=	parseFloat(this.datos.gastos)+ parseFloat(pago_rpf.Data.total); }

			  				
			  			pagoRPF=pagoRPF+parseFloat(this.datos.gastos.toFixed(2));
			  		this.datos.gastos=new Intl.NumberFormat('es-MX').format(this.datos.gastos.toFixed(2));
			  		
			  		console.log(this.datos.gastos);


			  		let remesas = ingresos.filter(ing=>ing.Data.nombre=='remesas');

			  		console.log(remesas);

			  		for(let pago_rpf of remesas){ this.datos.remesas=	parseFloat(this.datos.remesas)+ parseFloat(pago_rpf.Data.total); }

			  				
			  			pagoRPF=pagoRPF+parseFloat(this.datos.remesas.toFixed(2));
			  		this.datos.remesas=new Intl.NumberFormat('es-MX').format(this.datos.remesas.toFixed(2));
			  		
			  		console.log(this.datos.remesas);



			  		this.datos.remante=pagoRPF.toFixed(2);
			  		this.datos.remante=new Intl.NumberFormat('es-MX').format(this.datos.remante);
			  		console.log(this.datos.remante);
			  		
			  		
		  			


			  	}else{

			  		this.service.getrSincro(this.fecha,'rp_ingresos_dgo').subscribe(res=>{
			  			if(res!=null){
			  					let ingresos=JSON.parse(JSON.stringify(res)).Datas[0].Data.justificacion;
			  					if(ingresos=='dia inhabil'){
			  						this.info="Día Inhábil";
			  					}
			  					
			  			}else{
			  				this.info="Sin Información";
			  			}
			  		})

			  	}

	  });

  	/*
  	switch (this.fecha) {
  		case "2020-09-18":
  				this.initFecha();
  			break;

  			case "2020-09-17":
  				this.ngOnInit();
  				this.datos.rep_traferencia='13,454.90';
  				this.datos.efectivo='48,157.61';
  				this.datos.tarjeta_bancaria='1,369.48';
  				this.datos.trasferencias='321,038.61';
  				this.datos.pagos_rpf='41,802.46';


  		
  			break;

  			


  			case "2020-09-16":
  				this.ngOnInit();
  			
  				this.datos.trasferencias='8,649.00';
  				this.datos.pagos_rpf='15,649.39';


  		
  			break;

  		case "2020-09-15":
  				this.ngOnInit();

  				this.datos.gcl_pagos_rpf='95,630.01';

  				this.datos.trasferencias='1,040,748.26';
  				this.datos.pagos_rpf='104,733.14';
  				this.datos.rep_traferencia='68,239.76';
  				this.datos.tarjeta_bancaria='18,280.88';



  		
  			break;

  			case "2020-09-14":
  				this.ngOnInit();
  				this.datos.gcl_pagos_rpf='36,899.99';

  				this.datos.rep_traferencia='6,515.95';
  				this.datos.efectivo='5,430.01';
  				this.datos.tarjeta_bancaria='3,932.20';

  				this.datos.trasferencias='55,835.30';
  				this.datos.pagos_rpf='19,188.50';

  				this.datos.gastos='-7,000.00'


  		
  			break;
  			case "2020-09-13":
  				this.ngOnInit();
  			


  		
  			break;
  			case "2020-09-12":
  				this.ngOnInit();
  				
  				
  				this.datos.pagos_rpf='3,158.50';
  				this.datos.remesas='-173,127.00';

  		
  			break;

  			case "2020-09-11":
  				this.ngOnInit();
  				
  				this.datos.rep_traferencia='26,736.01';
  				this.datos.tarjeta_bancaria='6,264.50';


  				this.datos.trasferencias='51,186.93';
  				this.datos.pagos_rpf='14,586.66';


  		
  			break;

  			case "2020-09-10":
  				this.ngOnInit();
  				this.datos.gcl_pagos_rpf='10,000.00';

  				this.datos.efectivo='49,523.72';
  				this.datos.tarjeta_bancaria='32,490.30';

  				this.datos.trasferencias='118,069.89';
  				this.datos.cheque_bancario='9,130.01';

  				this.datos.pagos_rpf='15,597.41';



  		
  			break;


  		
  		default:
  			// code...
  			break;
  	}*/

  }

		pagosInit(form,frente,nombre){
				
				form.form.value.frente=frente;
				form.form.value.nombre=nombre;
				let fecha=new Date();
				let hora=fecha.getHours()+':'+fecha.getMinutes();
				form.form.value.hora=hora;
				console.log(form.form.value);

				this.service.get_insert_pagos(form.form.value).subscribe(res=>{
					if (res!=null) {
						alert("Se Agrego Registro con Exito");
						form.reset();
					}
				});
  }

}
