import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class ClientesService{

public url="http://67.205.145.224/durango/dashboard_api_v1.php?type=";
public urls="http://67.205.145.224/durango/dashboard_api.php";


	constructor(private http: HttpClient) {

		
	}


	getClientes(){
		return this.http.get(this.url+'sc_getClientes');
	}
	getCliente(id){
		return this.http.get(this.url+'sc_getCliente&ids='+id);
	}
	unsertCliente(data,id){
		console.log(data.nombres);
		return this.http.get(this.url+'sc_insertCliente&nombres='+
			data.nombres+'&ap1='+data.ap1+'&ap2='+data.ap2+'&celular='
			+data.cel+'&telefono='+data.tel+'&correo='+data.correo+"&id="+id);

	}
	postConyugue(data){
		return this.http.get(this.url+'sc_insertConyugue&id='+data.id+'&nombres='+data.nombres+'&ap1='+data.ap1+'&ap2='+data.ap2
			+'&tel='+data.tel+'&cel='+data.cel+'&correo='+data.correo+'&curp='+data.curp+'&empresa='+data.empresa
			+'&ext='+data.ext+'&nrp='+data.nrp+'&nss='+data.nss+'&rfc='+data.rfc+'&tel_empresa='+data.tel_empresa
			+'&escolaridad='+data.escolaridad);
	}

	putConyugue(data){
		return this.http.get(this.url+'sc_putConyugue&id='+data.id+'&nombres='+data.nombres+'&ap1='+data.ap1+'&ap2='+data.ap2
			+'&tel='+data.tel+'&cel='+data.cel+'&correo='+data.correo+'&curp='+data.curp+'&empresa='+data.empresa
			+'&ext='+data.ext+'&nrp='+data.nrp+'&nss='+data.nss+'&rfc='+data.rfc+'&tel_empresa='+data.tel_empresa
			+'&escolaridad='+data.escolaridad);
	}
	

	getConyugue(id){
		return this.http.get(this.url+'sc_getConyugue&id='+id);
	}
	getPromotores(){
		return this.http.get(this.url+'sc_getPromotores');
	}
	getDocumenos(){
		return this.http.get(this.url+'sc_getDocumentos');
	}

	putDatosProfecionales(data){
		return this.http.get(this.url+'sc_putDatosPersonales&id='+data.id+'&calle='+data.calle
			+'&col='+data.col+'&cp='+data.cp+'&curp='+data.curp+'&fecha_nac='+data.fecha_nac+'&lugar_nac='+data.lugar_nac
			+'&municipio='+data.municipio+'&nss='+data.nss+'&num_ext='+data.num_ext+'&num_int='+data.num_int
			+'&profesion='+data.profesion+'&rfc='+data.rfc+"&pais="+data.pais+"&estado="+data.estado);
	}
	getReferencia(id){
		return this.http.get(this.url+'sc_getReferencia&id='+id);
	}
	getDocumnetosCliente(id){
		return this.http.get(this.url+'sc_getClienteDocumento&id='+id);
	}
	postDocumentCliente(id,id_doc){
		return this.http.get(this.url+'sc_postClienteDocumento&id='+id+"&id_doc="+id_doc);
	}

	postReferencia(data){
		return this.http.get(this.url+'sc_postEmpresas&id='+data.id+"&nombre="+data.nombre+
			"&telefono="+data.telefono+"&nrp="+data.nrp+"&extension="+data.extension);
	}
	putReferencia(data){
		return this.http.get(this.url+'sc_putEmpresas&id='+data.id+"&nombre="+data.nombre+
			"&telefono="+data.telefono+"&nrp="+data.nrp+"&extension="+data.extension+"&data"+data);
	}


	putDatosContacto(data){
		return this.http.get(this.url+'sc_putDatosContacto&id='+data.id
			+"&ap1="+data.ap1+"&ap2="+data.ap2+"&celular="+data.celular+"&correo="
			+data.correo+"&nombres="+data.nombres+"&telefono="+data.telefono);
	}


	prueba(id){
		return this.http.post(this.urls,id);
	}

	



}