import { Component, OnInit } from '@angular/core';
import { NotariasService } from '../services/notarias.service';

@Component({
  selector: 'app-notarias',
  templateUrl: './notarias.component.html',
  styleUrls: ['./notarias.component.css'],
  providers:[NotariasService]
})
export class NotariasComponent implements OnInit {
	nombre="Notarias";
	notarias:any=[];
	notaria:any=[];
  constructor(private services:NotariasService) { }

  ngOnInit() {
  	this.services.getNotarias().subscribe(resut=>{
  		if (resut!=null) {
  			this.notarias=JSON.parse(JSON.stringify(resut)).Datas;
  		}
  	},error=>{
  		console.log(error);
  	});
  }


  postNotaria(form){
  	this.services.postNotaria(form.form.value).subscribe(resul=>{location.reload();},error=>{console.log(error);});
  }

  setid(data){
  	this.notaria=data;
  }
  putNotaria(form){
  	this.services.putNotaria(form.form.value,this.notaria.notaria_id).subscribe(resul=>{location.reload();},error=>{console.log(error);});
  }
   deleteCatlogo(){
   	this.services.deleteNotaria('notarias','notaria_id',this.notaria.notaria_id).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
   }

}
