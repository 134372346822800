import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { Router,ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:[UsersService]
})
export class LoginComponent implements OnInit {

  constructor(private servicios:UsersService,private router:Router, private ar:ActivatedRoute) { }

  ngOnInit() {

  		localStorage.setItem('page','0');
      
  	 if(sessionStorage.getItem('id')!=null){
      this.router.navigate(["incio"]);
    }else{
     
    }
  	
  }

  login(form){

  	this.servicios.login(form.form.value).subscribe(result=>{
  		if (result!=null) {
  		 sessionStorage.setItem('id',JSON.parse(JSON.stringify(result)).Datas[0].Data.empleado_id);
       sessionStorage.setItem('status',JSON.parse(JSON.stringify(result)).Datas[0].Data.status);
  		 location.reload();

  		}else{
  			alert("no hay ningun usuario");
  		}
  	});
  	console.log(form.form.value);
  }

}
