import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class UsersService{
	
	//public url="http://www.promovr.com.mx/durango/db_rest001.php/?type=";
	//public url="http://localhost/db_rest001.php?type="http://logosmart.mx/
	//public url="http://covelti.com/php/db_rest001.php?type=";

	public url="http://logosmart.mx/php/db_rest001_dev.php?type=";
	public url_sincro="http://logosmart.mx/php/updateBD_Clientes.php.php?"

	constructor(private http: HttpClient){}

	login(data){
		return  this.http.get(this.url+"login&pass="+data.pass+"&email="+data.user);
	}

	getInegrantes(id){
		return  this.http.get(this.url+"getInegrantes&id="+id);
	}
	getActividades(){
		return  this.http.get(this.url+"getActividades");
	}
	getEmleados(){
		return  this.http.get(this.url+"sc_Emplados");
	}

	getPermisos(token){
		return this.http.get(this.url+"permisos&token="+token);
	}
	deleteIntegrante(id){
		return this.http.get(this.url+"sc_DeleteIntegrante&id="+id);
	}

		
	postActividad(data){
		return this.http.get(this.url+"sc_postActivdad&nombre="+
			data.nombre+"&descripcion="+data.descripcion+
			"&departamento_id="+data.departamento_id+"&rol="+data.rol+"&user_id="+data.user_id+"&tipo="+data.tipo);
	}

	updateActividad(data){
		return this.http.get(this.url+"sc_updateActividad&nombre="+data.nombre+"&descripcion="+data.descripcion+"&id="+data.id);
	}

	UpRol_eq(id,data){
		return this.http.get(this.url+"sc_UpRol_eq&id="+id+"&observacion="+data.observa);
	}

	postrol(data){
		return this.http.get(this.url+"sc_postRol&nombre="+data.nombre+"&lvl_puesto_id="+data.lvl_puesto_id);
	}

	postUser(data){
		return this.http.get(this.url+"sc_insertUsuario&nombre="+data.nombre
			+"&email="+data.email+"&apellido_paterno="+data.apellido_paterno+"&apellido_materno="+data.apellido_materno
			+"&password="+data.password+"&nivel_id="+data.nivel_id);
	}

	postUser2(data){
		return this.http.get(this.url+"sc_insertUsuario_empleado&nombre="+data.nombre
			+"&email="+data.email+"&apellido_paterno="+data.apellido_paterno+"&apellido_materno="+data.apellido_materno
			+"&password="+data.password+"&empleado_id="+data.empleado_id+"&nivel_id="+data.nivel_id);
	}

	updateUser(id,data){
		return this.http.get(this.url+"sc_UpdateUsuario&nombre="+data.nombre
			+"&apellido_materno="+data.apellido_materno+"&apellido_paterno="+data.apellido_paterno+"&email="+data.email
			+"&password="+data.password+"&id="+id+"&nivel_id="+data.nivel_id);
	}


	insertEntre_act(act_id,entre_id){
			return this.http.get(this.url+"sc_insertEntre_act&entregable_id="+entre_id
			+"&actividad_id="+act_id);
	}

	postEqRol(data){
		return this.http.get(this.url+"sc_insert_eqRol&eq_id="+data.eq_id
			+"&rol_id="+data.rol_id+"&fecha="+data.fecha+"&hora="+data.hora);
	}

	getRolsbyeqp(id){
		return this.http.get(this.url+"sc_getRolsbyEq&id="+id);
	}

	geteq_rol(id){
		return this.http.get(this.url+"sc_getEq_rol&id="+id);
	}
	getrolByid(id){
		return this.http.get(this.url+"sc_getRolbyId&id="+id);
	}
	getEqpbyId(id){
		return this.http.get(this.url+"sc_getEqByRols&id="+id);
	}
	getEntregablebyId(id){
		return this.http.get(this.url+"getEntregableByID&id="+id);
	}

	deleteentreAct(id){
		
		return this.http.get(this.url+"sc_deleteEntregableActiviad&id="+id);
	}

	

	

	deleteAct_rol(id){
		return this.http.get(this.url+"sc_DeleteAct_rol&id="+id);
	}


	deleteAct_col(id){
		return this.http.get(this.url+"sc_DeleteAct_col&id="+id);
	}


	deleteAct_eq(id,id_act){
		return this.http.get(this.url+"sc_DeleteAct_Eq&id_eq="+id+"&id_act="+id_act);
	}


	deleteRoldeActividas(id){
		return this.http.get(this.url+"deleteEqp_Rol&id="+id);
	}





	

	postIntegrantes(id_user,eqTrabajo_id){
		return this.http.get(this.url+"sc_insertIntegrantes&eqTrabajo_id="+eqTrabajo_id
			+"&user_id="+id_user);
	}

	postAct_rol(rol_id,actividad_id){
		console.log("llamada service");
		return this.http.get(this.url+"sc_postAct_Rol&rol_id="+rol_id
			+"&actividad_id="+actividad_id);
	}


		postAct_col(rol_id,actividad_id){
		
		return this.http.get(this.url+"sc_postAct_col&user_id="+rol_id
			+"&actividad_id="+actividad_id);
	}

	getRoles(){
		return  this.http.get(this.url+"sc_getRoles");
	}

	getAct_rol_id(id){
		return  this.http.get(this.url+"sc_getactByrol&id="+id);
	}

	
	getRol_act_ids(id,rol_id){
		return  this.http.get(this.url+"sc_getrol_act_ids&id="+id+"&rol_id="+rol_id);
	}

	getRol_act_idacrualiza(id){
		return  this.http.get(this.url+"sc_getactByrol_id&id="+id);
	}

	getatc_col(id){
		return  this.http.get(this.url+"sc_geact_col&id="+id);
	}
	getEntre_act(id){
		return  this.http.get(this.url+"sc_getEntregablesbyAct&id="+id);
	}

	Delete_rol_eq(id){
		return  this.http.get(this.url+"deleteEqp_Rol&id="+id);
	}

	

	postAct_eq(id,act){
		return this.http.get(this.url+"sc_postAct_eq&id="+id
			+"&act="+act);
	}

	

	postEquipo(data){
		return this.http.get(this.url+"sc_insertEquipos&nombre="+data.nombre
			+"&user_id="+data.user_id);
	}

	updateActrol(id,data){
		return this.http.get(this.url+"sc_updActividadRol&inicio="+data.inicio+"&final="+data.final
			+"&realizado="+data.realizado+"&imagenes="+data.imagenes+"&Justificacion="+data.Justificacion+"&status="+data.status+"&id="+id);
	}


	updateobservacion(id){
		return this.http.get(this.url+"sc_updRol_Eqp&id="+id);
	}

	updateEqp_rol(id,data){
		return this.http.get(this.url+"	sc_updEq_rol&id="+id+"&rol_id="+data.rol_id+"&fecha="+data.fecha+"&hora="+data.hora);
	}


	getEquipos(){
		return this.http.get(this.url+"sc_getequipos");
	}

	putUser(data){
		return this.http.get(this.url+"sc_putUsuario&usuario="+data.usuario
			+"&correo="+data.correo
			+"&password="+data.password+"&id="+data.id);
	}
	getUsers(){
		return this.http.get(this.url+"sc_getUsurios");
	}

	deleteUser(id){
		return this.http.get(this.url+"sc_DeleteUsuario&id="+id);
	}

	getAllPermisos(){
		return this.http.get(this.url+"sc_getAllPermisos");
	}


	getMypermisosbyId(id){
		return this.http.get(this.url+"sc_getPermisosbyId&id="+id);
	}

	deletepermiso(id){
		return this.http.get(this.url+"sc_DeletepPermiso&id="+id);
	}

	
	delete_Rol_eqp(id){
		return this.http.get(this.url+"sc_DeleteAct_eqp&id="+id);
	}



	delete_Rol(id){
		return this.http.get(this.url+"sc_DeleteRol&id="+id);
	}
	
	delete_Acividad(id){
		return this.http.get(this.url+"sc_DeleteActividad&id="+id);
	}
	delete_Aciv_rol(id){
		return this.http.get(this.url+"sc_DeleteActi_rol&id="+id);
	}
	
	delete_Aciv_eq(id){
		return this.http.get(this.url+"sc_DeleteActi_eq&id="+id);
	}


	postPermiso(usuario_id,permiso_id){
		return this.http.get(this.url+"sc_insertPermisos&usuario_id="+usuario_id
			+"&permiso_id="+permiso_id);
	}
	delete_user(id){
		return this.http.get(this.url+"deleteUser&id="+id);
	}

	

	updateRol(id,form){
		return this.http.get(this.url+"Update_rol&id="+id+"&nombre="+form.nombre+"&departamento_id="+form.departamento_id
			+"&lvl_puesto_id="+form.lvl_puesto_id);
	}


	
	updatecolaborador(id,form){
		return this.http.get(this.url+"Update_Colaborador&id="+id
			+"&rol_id="+form.rol_id
			+"&lvl_colaborador_id="+form.lvl_colaborador_id+"&departamento_id="+form.departamento_id);
	}


	post_inacistencia(id,integrante,asistencia,form){
	
		return this.http.get(this.url+"sc_putInistencia&eq_rol_id="+id
			+"&asistencia="+asistencia
			+"&integrante_id="+integrante
			+"&comentario="+form.comentario);

	}

	

	getAsisByID(id){
		return this.http.get(this.url+"sc_getAsisById&id="+id);
	}
	
	getrol_active(id){
		return this.http.get(this.url+"sc_UpRol_eq_active&id="+id);
	}

	
	getrol_InAsistencias(id){
		return this.http.get(this.url+"sc_getAsisByIdonIntegrante&id="+id);
	}

	getrol_InAllInegratesByID(){
		return this.http.get(this.url+"sc_getALLAsisByIdonIntegrante");
	}

	getrol_InAllFaltas(){
		return this.http.get(this.url+"sc_getALlFaltas");
	}

	getrol_InAllRetardo(){
		return this.http.get(this.url+"sc_getALlRetarods");
	}




	

	getrol_Retardos(id){
		return this.http.get(this.url+"sc_getRetardos&id="+id);
	}

	getrol_acct_id(id){
		return this.http.get(this.url+"sc_getActividad&id="+id);
	}

	
	getAlleq_rol(){
		return this.http.get(this.url+"sc_Get_all_eq_rol");
	}

	
	getAlleq_int(){
		return this.http.get(this.url+"sc_Get_all_inter");
	}
	getAlleq_retardosAll(){
		return this.http.get(this.url+"sc_getRetardosAll");
	}
	getAlleq_Faltas(){
		return this.http.get(this.url+"sc_getAsisByIdonIntegranteAll");
	}
	getAlleq_eq_rol_dates(data){
		return this.http.get(this.url+"sc_Get_eq_rol_dates&de="+data.de+"&hasta="+data.hasta);
	}

	getAllAsistenicasCerradas(){
		return this.http.get(this.url+"sc_Get_asistencias_cerradas");
	}


	
	getDetalles(id){
		return this.http.get(this.url+"sc_Get_Detalles&id="+id);
	}


	getNiveles(){
		return this.http.get(this.url+"sc_getNiveles");
	}

	InsertNiveles(data){
		return this.http.get(this.url+"sc_insertNiveles&titulo="+data.titulo);
	}

	UpdateNiveles(data,id){
		return this.http.get(this.url+"sc_updateNiveles&titulo="+data.titulo+"&id="+id);
	}

	DeleteNiveles(id){
		return this.http.get(this.url+"sc_deleteNiveles&id="+id);
	}

	

	getEntregables(){
		return this.http.get(this.url+"sc_getEntregables");
	}

	InsertEntregables(data){
		return this.http.get(this.url+"sc_insertEntregables&titulo="+data.titulo+
			"&descripcion="+data.descripcion+"&periodicidad="+data.periodicidad+"&rol_id="+data.id_nombre);
	}

	UpdateEntregables(data,id){
		return this.http.get(this.url+"sc_updateEntregables&titulo="+data.titulo+
		"&descripcion="+data.descripcion+"&periodicidad="+data.periodicidad+"&id="+id+"&rol_id="+data.rol_id);
	}

	DeleteEntregable(id){
		return this.http.get(this.url+"sc_deleteEntregables&id="+id);
	}

	////////
InsertFecha_entre(id,data){
		return this.http.get(this.url+"sc_insertFecha_entre&entregable_id="+id+
			"&fecha="+data.fecha);
	}

	
	DeleteFecha_entre(id){
		return this.http.get(this.url+"sc_DeleteFecha_entre&id="+id);
	}

	getFechas_entreByid(id){
		return this.http.get(this.url+"sc_getFechas_entre&entregable_id="+id);
	}

	///

	getDepartamentos(){
		return this.http.get(this.url+"sc_getDepartamentos");
	}

	InsertDepartamento(data){
		return this.http.get(this.url+"sc_insertDepartamento&titulo="+data.titulo+
			"&area_id="+data.area_id+"&lvl_departamento_id="+data.lvl_departamento_id+"&user_id="+data.user_id);
	}

	UpdateDepartamento(data,id){
		return this.http.get(this.url+"sc_updateDepartamento&titulo="+data.titulo+
			"&area_id="+data.area_id+"&id="+id+"&lvl_departamento_id=0&user_id="+data.user_id);
	}

	DeleteDepartamento(id){
		return this.http.get(this.url+"sc_deleteDepartamento&id="+id);
	}

	getAreas(){
		return this.http.get(this.url+"sc_getAreas");
	}

	getColabyRol(id){
		return this.http.get(this.url+"sc_getColaboradorByrol&rol_id="+id);
	}



	InsertAreas(data){
		return this.http.get(this.url+"sc_insertAreas&titulo="+data.titulo+"&lvl_area_id="+data.lvl_area_id);
	}

	UpdateAreas(data,id){
		return this.http.get(this.url+"sc_updateAreas&titulo="+data.titulo+"&id="+id+"&lvl_area_id="+data.lvl_area_id);
	}

	DeleteAreas(id){
		return this.http.get(this.url+"sc_deleteAreas&id="+id);
	}

	getlvl(tbl){
		return this.http.get(this.url+"sc_getlvl&tbl="+tbl);
	}

		getlvlpuestos(tbl){
		return this.http.get(this.url+"sc_getlvl_puestos&tbl="+tbl);
	}

	
//#1F3369FF

	Insertlvl(data,col,tbl){
		return this.http.get(this.url+"sc_insertlvl&titulo="+data.titulo+"&lvl="+data.lvl+"&sublvl="+data.sublvl+"&col="+col+"&tbl="+tbl);
	}

	Updatelvl(data,id,col,tbl){
		return this.http.get(this.url+"sc_updatelvl&titulo="+data.titulo+"&id="+id+"&lvl="+data.lvl+"&sublvl="+data.sublvl+"&col="+col+"&tbl="+tbl);
	}

	Deletelvl(id,col,tbl){
		return this.http.get(this.url+"sc_deletelvl&id="+id+"&col="+col+"&tbl="+tbl);
	}




getDepByrol(id){
		return this.http.get(this.url+"	sc_getDeptarbyRol&id="+id);
	}
	getAreabyDep(id){
		return this.http.get(this.url+"sc_getAreabyDepart&id="+id);
	}


	getAllColaborador(){
			return this.http.get(this.url+"sc_getColaboradores");
	}

	getCol_detalles(id){
		return this.http.get(this.url+"sc_getcol_detalles&id="+id);
	}

	insertCol_ids(id){
		return this.http.get(this.url+"insert_col_new&id="+id);
	}

	getMe(id){
		return this.http.get(this.url+"sc_getMe&id="+id);
	}

	

	miColbyDep(id){
		return this.http.get(this.url+"sc_getMeColaborador&id="+id);
	}

	getColbyPuesto(id){
		return this.http.get(this.url+"sc_getCol_puets&id="+id);
	}

		getFuncionesTipo(id){
		return this.http.get(this.url+"sc_getFuncionesbytipo&tipo="+id);
	}

		getFuncionesTipo_byUser(id,user_id){
		return this.http.get(this.url+"sc_getFuncionesbytipo_user&tipo="+id+"&user_id="+user_id);
	}
	
	

	getDepbyid(id){
		return this.http.get(this.url+"sc_getdepByid&id="+id);
	}

	getPuestosBydep(id){
			return this.http.get(this.url+"sc_getPuestoBydep&id="+id);
	}



	

	getdepByAct(id){
			return this.http.get(this.url+"sc_getDepas_by_act&id="+id);
	}

	getCol_dep_rol(rol_id,departamento_id){
		return this.http.get(this.url+"sc_getColAtDEp_puets&rol_id="+rol_id+"&departamento_id="+departamento_id);
	}

		Delete_Act_Col(user_id,actividad_id){
		return this.http.get(this.url+"sc_DeleteActividad_by_col&user_id="+user_id+"&actividad_id="+actividad_id);
	}

	getpuestoByAct(id){
			return this.http.get(this.url+"sc_getPuesto_by_act&id="+id);
	}

getpuestoByAct_dep(id,dep_id){
			return this.http.get(this.url+"sc_getPuesto_by_act_by_dep&id="+id+"&dep_id="+dep_id);
	}

	

	sc_getDeps_by_act_by_dep(id){
			return this.http.get(this.url+"sc_getDeps_by_act&id="+id);
	}
	
		getcolByAct(id){
			return this.http.get(this.url+"sc_getcol_by_act&id="+id);
	}

	getcolByAct_dep(id,departamento_id){
			return this.http.get(this.url+"sc_getcol_by_act_by_dep&id="+id+"&departamento_id="+departamento_id);
	}


		getallCol(){
			return this.http.get(this.url+"sc_getAllColaborador");
	}

			getOtherFun(id,tipo){
			return this.http.get(this.url+"sc_getOtherFun&id="+id+"&tipo="+tipo);
	}

	getDepsByArea(id){
		return this.http.get(this.url+"sc_getDeps_by_area&id="+id);
	}

/*
	updColaboradorTemporal(id_nombre,nombre,id){
		return  this.http.get(this.url+"sc_temporal&id="+id+"&nombre="+nombre+"&id_nombre="+id_nombre);
	}*/

	insert_sincroUP(data){
		return this.http.get(this.url_sincro+"tipo_up="+data.tipo_up+"&tiulo_up="+data.titulo);
	}

	getSincros(){
		
	}
	getIndicadir001(){
			return this.http.get(this.url+"sc_getIndicadores001");
	}

	


	validation(id,pass){
		return this.http.get(this.url+"validacionPass&pass="+pass+"&id="+id);
	
	}

	getCatCod(){
		return this.http.get(this.url+"sc_getcat_cod");
	}
	postCatCod(data){
	
		return this.http.get(this.url+"postCatCod&cat_cod="+data.cat_cod+"&nombre="+data.nombre);
	
	}

	putCadCod(id,data){
		return this.http.get(this.url+"putCatCod&cat_cod="+data.cat_cod+"&nombre="+data.nombre+"&id="+id);
	}

	deleteCatCod(cat_cod){
		return this.http.get(this.url+"DeleteCatCod&cat_cod="+cat_cod);
	}

	incert_configIndicador(data){
		return this.http.get(this.url+"insert_configIndicador&nombre="+data.nombre+"&num_secciones="+data.num_secciones
			+"&unidades="+data.unidad+"&titulo="+data.titulo);
	}

	insertSegmentoPrincipla(data,id){
		return this.http.get(this.url+"insert_Segmentos_principal&clasificador="+data.clasificador
			+"&subclasificador="+data.subclasificador+"&algoritmo="+data.algoritmo+"&prod="+data.prod+"&num_seg="+data.num_seg+
			"&id="+id);
	}

	insertSecciones(data,id){
		return this.http.get(this.url+"insert_Secciones&titulo="+data.titulo
			+"&tipo_grafica="+data.tipo_grafica+"&unidades="+data.unidades+"&id="+id);
	}


	getCategorias(){
			return this.http.get(this.url+"getCatIndicadores");
	}

	getProdAndServers(subCat){
		return this.http.get(this.url+"getProdServer_by_subCat&subCat="+subCat);
		
	}
	
	setListIndicador(codigo,id){

		return this.http.get(this.url+"insert_Segmentos&clasificador="+codigo.clasificador+"&id="+id
			+"&subclasificador="+codigo.subclasificador+"&algoritmo="+codigo.algoritmo+
			"&prod="+codigo.prod+"&num_seg="+codigo.num_seg);
		
	}
		deleteListIndicador(id){
		return this.http.get(this.url+"update_list_indicador&id="+id);
		
	}
	getConfigIndicador(){
		return this.http.get(this.url+"getConfiIndicadores");
	}

	getConfigIndicador_id(id){
		return this.http.get(this.url+"getConfiIndicadores_id&id="+id);
	}

	get_secciones(id){
		return this.http.get(this.url+"sc_get_secciones_id&id="+id);
	}
	delete_secciones(id){
		return this.http.get(this.url+"sc_deleteSeccion&id="+id);
	}
	getListIndicador(id){
		return this.http.get(this.url+"getlisIndicadores&id="+id);
	}
	

		getRep_gastos(id){
		return this.http.get(this.url+"get_rp_gastos&id="+id);
	}

	
	updateconfigIndicador(id,nombre,titulo,unidades){
		return this.http.get(this.url+"update_config_indicador&id="+id+"&nombre="+nombre+"&titulo="+titulo+"&unidades="+unidades);
	}

	deleteconfigIndicador(id){
		return this.http.get(this.url+"delete_config_indicador&id="+id);
	}

	getclasificadores(){
		return this.http.get(this.url+"get_clasificaron");
	}
	getsubclasificadores(){
		return this.http.get(this.url+"get_subclasificaron");
	}
	
	getprdosserver(){
		return this.http.get(this.url+"get_prods_servers");
	}


getsegmentos(id){
		return this.http.get(this.url+"getSegmentos&id="+id);
}

getsegmentos_principal(id){
		return this.http.get(this.url+"getSegmentos_principal&id="+id);
}


insertSegementos(){
		return this.http.get(this.url+"get_prods_servers");
	}

	updateSegementosAfter(data){
		return this.http.get(this.url+"update_segmento&id="+data.segmento_id+"&clasificador="+data.clasificador
			+"&subclasificador="+data.sub_clasificador
			+"&algoritmo="+data.algoritmo+"&prod="+data.prod+"&num_seg="+data.num_seg);
	}
	deleteSegmentAfter(id){
		return this.http.get(this.url+"delete_segmento&id="+id);
	}

	getDatesbyCategoria(id){
		return this.http.get(this.url+"getDatesbyCategorias&id="+id);
	}
	getDatesbyCat_sub(cat,sub){
		return this.http.get(this.url+"getDatesbyCat_sub_Cat&cat="+cat+"&sub="+sub);
	}
	getDatesbyCat_prod(cat,prod){
		return this.http.get(this.url+"getDatesbyCat_sub_Cat&cat="+cat+"&prod="+prod);
	}
	getDatesbyCat_sub_prod(cat,sub,prod){
		return this.http.get(this.url+"getDatesbyCat_sub_Cat_prod&cat="+cat+"&sub="+sub+"&prod="+prod);
	}

	getDatesbysub_Cat(sub){
		return this.http.get(this.url+"getDatesbysub_Cat&sub="+sub);
	}

	getDatesbysub_Cat_prod(sub,prod){
		return this.http.get(this.url+"getDatesbysub_Cat_prod&sub="+sub+"&prod="+prod);
	}
	

	getDatesby_prod(prod){
		return this.http.get(this.url+"getDatesby_prod&prod="+prod);
	}

	getTypeGraficas(){
		return this.http.get(this.url+"get_typeGrafic");
	}

	getrSincro(fecha,tipo){
		return this.http.get(this.url+"get_rp_actual&fecha="+fecha+"&tipo="+tipo);
	}

	getrp_info(id,tabla){

		return this.http.get(this.url+"get_rp_info&sincor_id="+id+"&tabla="+tabla);
	}


		getcod_ventas(){

		return this.http.get(this.url+"get_cod_ventas");
	}


	getrp_info_unidad(id,tabla){

		return this.http.get(this.url+"get_rp_info_unidades&sincor_id="+id+"&tabla="+tabla);
	}

	getrp_info_unidad_cx(id,tabla){

		return this.http.get(this.url+"get_rp_info_CX&sincor_id="+id+"&tabla="+tabla);
	}

	getrp_agentes(){

		return this.http.get(this.url+"getrp_agentes");
	}

	get_cat_compras(){
		return this.http.get(this.url+"get_cod_compras");
	}

	get_lastDate(tipo){
		return this.http.get(this.url+"get_rp_lastDate&tipo="+tipo);
	}

	get_saldos_by_id(id){
		return this.http.get(this.url+"get_saldos_by_sincro&id="+id);
	}
	get_flujo_by_id(id){
		return this.http.get(this.url+"get_flujo_efectivo_by_sincro&id="+id);
	}

	get_ingresos_betwen(fecha1,fecha2){
		return this.http.get(this.url+"getingresosBetwen&fecha1="+fecha1+"&fecha2="+fecha2);
	}

		get_ingresos_fecha(fecha1){
		return this.http.get(this.url+"getingresosFecha&fecha1="+fecha1);
	}

		get_insert_pagos(data){


		return this.http.get(this.url+"rp_ingresos_dgo_gcl_insert_by_app&nombre="+data.nombre+"&fecha="+data.fecha+
								"&total="+data.total+
								"&frente="+data.frente+
								"&descripcion="+data.descripcion+"&hora="+data.hora);
	}


}