import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {routing,appRoutingProviders} from './app.routing';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';


import { AppComponent } from './app.component';
import { ChartsModule } from 'ng2-charts';
import { DemoDashbordComponent } from './demo-dashbord/demo-dashbord.component';
import { DemotablesComponent } from './demotables/demotables.component';
import { ConfiguradorComponent } from './configurador/configurador.component';
import { ConfiindicadorComponent } from './confiindicador/confiindicador.component';
import { ConfisucursalComponent } from './confisucursal/confisucursal.component';
import { Demo1Component } from './demo1/demo1.component';
import { VentasComponent } from './ventas/ventas.component';
import { CargaComponent } from './carga/carga.component';
import { CatalogosComponent } from './catalogos/catalogos.component';
import { AddInmueblesComponent } from './add-inmuebles/add-inmuebles.component';
import { AddClientesComponent } from './add-clientes/add-clientes.component';

import { AddConyugueComponent } from './add-conyugue/add-conyugue.component';
import { ClientesComponent } from './clientes/clientes.component';
import { LoginComponent } from './login/login.component';

import { AddVentasComponent } from './add-ventas/add-ventas.component';
import { AddPreventaComponent } from './add-preventa/add-preventa.component';
import { PrimerEncuentroComponent } from './primer-encuentro/primer-encuentro.component';
import { ClienteComponent } from './cliente/cliente.component';
import { CargandoComponent } from './cargando/cargando.component';
import { VentaComponent } from './venta/venta.component';
import { InmueblesComponent } from './inmuebles/inmuebles.component';
import { PreubaFormularioComponent } from './preuba-formulario/preuba-formulario.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DesarrollosComponent } from './desarrollos/desarrollos.component';
import { StatusVentasComponent } from './status-ventas/status-ventas.component';
import { StatusInmueblesComponent } from './status-inmuebles/status-inmuebles.component';
import { StatusPagosComponent } from './status-pagos/status-pagos.component';
import { TiposEsquemasComponent } from './tipos-esquemas/tipos-esquemas.component';
import { TiposContratosComponent } from './tipos-contratos/tipos-contratos.component';
import { TiposCreditosComponent } from './tipos-creditos/tipos-creditos.component';
import { TiposDescuentosComponent } from './tipos-descuentos/tipos-descuentos.component';
import { TiposGastosComponent } from './tipos-gastos/tipos-gastos.component';
import { TiposInmueblesComponent } from './tipos-inmuebles/tipos-inmuebles.component';
import { TiposPagosComponent } from './tipos-pagos/tipos-pagos.component';
import { TpDocumentosComponent } from './tp-documentos/tp-documentos.component';
import { AvaluosComponent } from './avaluos/avaluos.component';
import { NotariasComponent } from './notarias/notarias.component';
import { ValuadoresComponent } from './valuadores/valuadores.component';
import { StatusAvaluosComponent } from './status-avaluos/status-avaluos.component';
import { InmobiliariasComponent } from './inmobiliarias/inmobiliarias.component';
import { PromotoresComponent } from './promotores/promotores.component';
import { UsuariosAdminComponent } from './usuarios-admin/usuarios-admin.component';
import { Reporte1Component } from './reporte1/reporte1.component';
import { InicioComponent } from './inicio/inicio.component';
import { InfocontrolComponent } from './infocontrol/infocontrol.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialesComponent } from './materiales/materiales.component';
import { MenuComponent } from './menu/menu.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { EquiposComponent } from './equipos/equipos.component';
import { ActividadesComponent } from './actividades/actividades.component';
import { RolesComponent } from './roles/roles.component';
import { AddrolesComponent } from './addroles/addroles.component';
import { MyRolsComponent } from './my-rols/my-rols.component';
import { Rol2Component } from './rol2/rol2.component';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { ResportesComponent } from './resportes/resportes.component';
import { NivelesComponent } from './niveles/niveles.component';
import { DepartamentosComponent } from './departamentos/departamentos.component';
import { AreasComponent } from './areas/areas.component';
import { EntregablesComponent } from './entregables/entregables.component';
import { LblgenricComponent } from './lblgenric/lblgenric.component';
import { LblareaComponent } from './lblarea/lblarea.component';
import { LblcolaComponent } from './lblcola/lblcola.component';
import { LbldepComponent } from './lbldep/lbldep.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { OnlyComponent } from './only/only.component';
import { ColobydepsComponent } from './colobydeps/colobydeps.component';
import { FunfiComponent } from './funfi/funfi.component';
import { FunfdComponent } from './funfd/funfd.component';
import { FunfpComponent } from './funfp/funfp.component';
import { FunfcComponent } from './funfc/funfc.component';
import { SincronizacionComponent } from './sincronizacion/sincronizacion.component';
import { SegVentasComponent } from './seg-ventas/seg-ventas.component';
import { VenDgoBlockComponent } from './ven-dgo-block/ven-dgo-block.component';
import { DgoBlockVenMostradorComponent } from './dgo-block-ven-mostrador/dgo-block-ven-mostrador.component';
import { InfoColaboradorComponent } from './info-colaborador/info-colaborador.component';
import { ComVentaComponent } from './com-venta/com-venta.component';
import { GastodgoblockComponent } from './gastodgoblock/gastodgoblock.component';
import { EvEgresosComponent } from './ev-egresos/ev-egresos.component';
import { EvEgDgoblockComponent } from './ev-eg-dgoblock/ev-eg-dgoblock.component';
import { RhComponent } from './rh/rh.component';
import { CatIndicadoresComponent } from './cat-indicadores/cat-indicadores.component';
import { ParametrosComponent } from './parametros/parametros.component';
import { ColCruzaComponent } from './col-cruza/col-cruza.component';
import { ColGclComponent } from './col-gcl/col-gcl.component';
import { ColLogoComponent } from './col-logo/col-logo.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Indseccion1Component } from './indseccion1/indseccion1.component';
import { Indseccion2Component } from './indseccion2/indseccion2.component';
import { Indseccion3Component } from './indseccion3/indseccion3.component';
import { ManualGraficComponent } from './manual-grafic/manual-grafic.component';
import { ManualGraficTotalVentasComponent } from './manual-grafic-total-ventas/manual-grafic-total-ventas.component';
import { EvIngresosComponent } from './ev-ingresos/ev-ingresos.component';
import { EvIngresosDgoBlockComponent } from './ev-ingresos-dgo-block/ev-ingresos-dgo-block.component';
import { PcEgresosComponent } from './pc-egresos/pc-egresos.component';
import { PcIngresosComponent } from './pc-ingresos/pc-ingresos.component';
import { PcEgresosDgoBlockComponent } from './pc-egresos-dgo-block/pc-egresos-dgo-block.component';
import { PcIngresosDgoBlockComponent } from './pc-ingresos-dgo-block/pc-ingresos-dgo-block.component';
import { EvProduccionComponent } from './ev-produccion/ev-produccion.component';
import { SegFinanzasComponent } from './seg-finanzas/seg-finanzas.component';
import { SegFinanzasDgoblockComponent } from './seg-finanzas-dgoblock/seg-finanzas-dgoblock.component';
import { RpIngresosComponent } from './rp-ingresos/rp-ingresos.component';




@NgModule({
  declarations: [
    AppComponent,
    DemoDashbordComponent,
    DemotablesComponent,
    ConfiguradorComponent,
    ConfiindicadorComponent,
    ConfisucursalComponent,
    Demo1Component,
    VentasComponent,
    CargaComponent,
    CatalogosComponent,
    AddInmueblesComponent,
    AddClientesComponent,
    AddConyugueComponent,
    ClientesComponent,
    LoginComponent,

    AddVentasComponent,
    AddPreventaComponent,
    PrimerEncuentroComponent,
    ClienteComponent,
    CargandoComponent,
    VentaComponent,
    InmueblesComponent,
    PreubaFormularioComponent,
    DesarrollosComponent,
    StatusVentasComponent,
    StatusInmueblesComponent,
    StatusPagosComponent,
    TiposEsquemasComponent,
    TiposContratosComponent,
    TiposCreditosComponent,
    TiposDescuentosComponent,
    TiposGastosComponent,
    TiposInmueblesComponent,
    TiposPagosComponent,
    TpDocumentosComponent,
    AvaluosComponent,
    NotariasComponent,
    ValuadoresComponent,
    StatusAvaluosComponent,
    InmobiliariasComponent,
    PromotoresComponent,
    UsuariosAdminComponent,
    Reporte1Component,
    InicioComponent,
    InfocontrolComponent,
    MaterialesComponent,
    MenuComponent,
    Grafica1Component,
    EquiposComponent,
    ActividadesComponent,
    RolesComponent,
    AddrolesComponent,
    MyRolsComponent,
    Rol2Component,
    IndicadoresComponent,
    ResportesComponent,
    NivelesComponent,
    DepartamentosComponent,
    AreasComponent,
    EntregablesComponent,
    LblgenricComponent,
    LblareaComponent,
    LblcolaComponent,
    LbldepComponent,
    ColaboradoresComponent,
    OnlyComponent,
    ColobydepsComponent,
    FunfiComponent,
    FunfdComponent,
    FunfpComponent,
    FunfcComponent,
    SincronizacionComponent,
    SegVentasComponent,
    VenDgoBlockComponent,
    DgoBlockVenMostradorComponent,
    InfoColaboradorComponent,
    ComVentaComponent,
    GastodgoblockComponent,
    EvEgresosComponent,
    EvEgDgoblockComponent,
    RhComponent,
    CatIndicadoresComponent,
    ParametrosComponent,
    ColCruzaComponent,
    ColGclComponent,
    ColLogoComponent,
    Indseccion1Component,
    Indseccion2Component,
    Indseccion3Component,
    ManualGraficComponent,
    ManualGraficTotalVentasComponent,
    EvIngresosComponent,
    EvIngresosDgoBlockComponent,
    PcEgresosComponent,
    PcIngresosComponent,
    PcEgresosDgoBlockComponent,
    PcIngresosDgoBlockComponent,
    EvProduccionComponent,
    SegFinanzasComponent,
    SegFinanzasDgoblockComponent,
    RpIngresosComponent
  ],
  imports: [
    BrowserModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    routing,
    ChartsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    
  ],
  providers: [appRoutingProviders,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
