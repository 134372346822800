import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';

@Component({
  selector: 'app-status-pagos',
  templateUrl: './status-pagos.component.html',
  styleUrls: ['./status-pagos.component.css'],
  providers:[CatalogosService]
})
export class StatusPagosComponent implements OnInit {
	status_pagos:any=[];
	nombre:any;
	id_update:any;
	descrip:any;

  constructor(private servicios:CatalogosService) { }

  ngOnInit() {
  	 this.nombre="Estatus Pagos";
            this.servicios.getCatalogo("status_pagos").subscribe(result=>{
            	console.log(result);
               if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.status_pagos=temp2.Datas;
               }
            });
  }
  	setid(id,des){
    this.id_update=id;
    this.descrip=des;
  }
  setiddelete(id){
    this.id_update=id;
  }
    postStatus(form){
    	this.servicios.postCatalogo('status_pagos','status_pago_id',form.form.value.status).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
    }
   putStatus(form){
   	this.servicios.putCatalogo('status_pagos','status_pago_id',form.form.value.status,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));
   }
   deleteCatlogo(){
   	this.servicios.deleteCatalogo('status_pagos','status_pago_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
   }

}
