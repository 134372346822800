import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ev-ingresos',
  templateUrl: './ev-ingresos.component.html',
  styleUrls: ['./ev-ingresos.component.css']
})
export class EvIngresosComponent implements OnInit {

  constructor() { }
		page_now="Ingresos";
  ngOnInit() {
  }
  loadPage(page){
  	this.page_now=page;
  }

}
