import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class NotariasService{
	public url="http://67.205.145.224/durango/dashboard_api.php?type=";
	constructor(private http: HttpClient){}

	getNotarias(){
		return this.http.get(this.url+"sc_getNotarias");
	}
	getNotaria(id){

	}



	

	postNotaria(data){
		return this.http.get(this.url+"sc_postNotaria&nombre_notaria="+data.nombre_notaria
			+"&nombre_notario="+data.nombre_notario+"&descripcion="+data.descripcion+
			"&calle="+data.calle+"&num_ext="+data.num_ext+"&num_int="+data.num_int+"&telefono_notaria="
			+data.telefono_notaria+"&correo_notaria="+data.correo_notaria);
	}
	putNotaria(data,id){
		return this.http.get(this.url+"sc_putNotaria&nombre_notaria="+data.nombre_notaria
			+"&nombre_notario="+data.nombre_notario+"&descripcion="+data.descripcion+
			"&calle="+data.calle+"&num_ext="+data.num_ext+"&num_int="+data.num_int+"&telefono_notaria="
			+data.telefono_notaria+"&correo_notaria="+data.correo_notaria+"&id="+id);
	}
	
	deleteNotaria(tabla,columna,id){
		return this.http.get(this.url+"sc_DeleteCatalogo&table="+tabla+"&columna="+columna+"&id="+id);
	}


}