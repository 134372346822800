import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';


@Component({
  selector: 'app-tipos-inmuebles',
  templateUrl: './tipos-inmuebles.component.html',
  styleUrls: ['./tipos-inmuebles.component.css'],
  providers:[CatalogosService]
})
export class TiposInmueblesComponent implements OnInit {
	tipo_inmuebles:any=[];
	id_update:any;
	descrip:any;
	titulo:any;
	nombre:any;

  constructor(private servicios:CatalogosService) { }

  ngOnInit() {
  	 this.nombre="Tipos de Inmuebles";
            this.servicios.getCatalogo("tipos_inmuebles").subscribe(result=>{
               console.log(result);
                if(result!=null){
	                 let temp=JSON.stringify(result);
	                 let temp2=JSON.parse(temp);
                 this.tipo_inmuebles=temp2.Datas;
               }
            });
  }

    setid2(id,tit,des){
    this.id_update=id;
    this.descrip=des;
    this.titulo=tit;
  }

   setiddelete(id){

    this.id_update=id;
  }
   postStatus(form){
		this.servicios.postCatalogoTipos('tipos_inmuebles','tipo_inmueble_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
			location.reload();
		},error=>console.log(error));  
   }
   putStatus(form){
   	this.servicios.putCatalogoTipos('tipos_inmuebles','tipo_inmueble_id',form.form.value,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
   }
   deleteCatlogo(){
   		this.servicios.deleteCatalogo('tipos_inmuebles','tipo_inmueble_id',this.id_update).subscribe(result=>{
        	location.reload();
      	},error=>console.log(error));  
   }

}
