import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';


@Component({
  selector: 'app-gastodgoblock',
  templateUrl: './gastodgoblock.component.html',
  styleUrls: ['./gastodgoblock.component.css']
})
export class GastodgoblockComponent implements OnInit {

  fecha='2020-08-21';
  fecha_sv="2020-08-21";
  fecha_agen='2020-08-21';

 page="p1";
    
 view: any[] = [220, 110];
 view_test: any[] = [750, 110];
 view_pia_grid: any[] = [750, 400];
 totalDia:any=0;
 single=[];
  single2=[];
  single3=[];

 date_cards:any=[];












  solar:any={
     
    domain: [ '#E44D25', '#3CC099','#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  
  }






  
dia_hinabil="";

showLegend: boolean = false

 
  subtitulo='';
  

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient_bar: boolean = false;
  
  showXAxisLabel: boolean = true;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  xAxisLabel = "Productos \n ";


  cardColor: string = '#232837';
  constructor(private service:UsersService) { }
  grafica_1:any=[];

  Titulo_grafica="Ventas Por Productos (80/20)";
  set_format=true;

  Titulo_grafica_agentes="Ventas Por Agente";
  xAxisLabel_agente="Agentes";

  showLegendss: boolean = true;
  showLabelsss: boolean = true;
  cat_compras:any=[];
  ngOnInit() {
    this.fecha='2020-09-05';
     this.service.get_lastDate('rp_compras_diario').subscribe(res=>{
       res!=null ? this.fecha=JSON.parse(JSON.stringify(res)).Datas[0].Data.fecha:null;
       this.fecha_sv=this.fecha;
        this.fecha_agen=this.fecha;
       this.letSubcat_all('fin');
    })
     
   
       /*
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([






                {nombre:"MANTENIMIENTO Y ACTUALIZACION A EQUIPO DE COMPUTO" ,value: 25189.4,extra:{val_form:new Intl.NumberFormat('es-MX').format(25189.4)} },
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 10655.06,extra:{val_form:new Intl.NumberFormat('es-MX').format(10655.06)} },       
                {nombre:"MANTENIMIENTO EQUIPO DE ACARREO " ,value: 1936.91, extra:{val_form:new Intl.NumberFormat('es-MX').format( 1936.91)} },
               
                              
                                
              ]);*/
    this.Titulo_grafica="Todos los Clasificadores";
  }


  initAgentes(){
    this.fecha='2020-08-19';
    this.Titulo_grafica_agentes="Ventas Financeieras por Agente del Día";  
      this.totalDia="$"+new Intl.NumberFormat('es-MX').format(138830.00);
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
                 this.grafica_1=this.grafic_1_set([{  
                           
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(97432.12)}  ,
                    nombre:"Mostrador"  ,
                    value:70245.69
                  },
                  {  
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(4911.49)}  ,
                    nombre:"Sin Agente"  ,
                    value:4911.49
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(12996)}  ,
                    nombre:"Mercedes Pescador"  ,
                    value:12996
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(0)}  ,
                    nombre:"Berenice Solis"  ,
                    value:0
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(0)}  ,
                    nombre:"Susana Rodriguez"  ,
                    value:12011.99
                  },
                ])
              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta por Agentes"
  }

  grafic_diesel=[];

  initGasolina(){

    this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
    this.subtitulo="Gtos total Acumulado de Diesel"
    this.fecha='2020-08-14';
    this.dia_hinabil="Sin Información del Día"
    this.Titulo_grafica="";

    this.grafic_diesel=this.grafic_1_set([

      {nombre:"Gtos Acumulado de Diesel" ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
      
                      
    ]);
  }


  parseTitlesCard(value){
    



    let val=new Intl.NumberFormat('es-MX').format(value);

    
    return val;
  }

  parseTitlesCard2(value){

     
    if(value.label=="Anticipos"){
      return Intl.NumberFormat('es-MX').format(value.value);
    }
   
    if(value.label=="Ventas Por Productos Unidades"){
      return Intl.NumberFormat('es-MX').format(value.value)+"Pzas.";
    }
  }

  cambioFecha(form){

    this.dia_hinabil="";
    switch (form.form.value.cat) {
      case "all":
            
            
            this.letSubcat_all(form.form.value.subcat); 
             
              
        break;



        case "prod":
          this.Titulo_grafica="Gtos. Producción";
          
          this.subtitulo="Total Gtos. por Producción del Día."
          this.letSubcat_prod(form.form.value.subcat)
              
        break;
      
      default:
            alert("No se encontro Informacion valida para esta categoria");
        break;
    }
    
  }

  cambioFecha_diecel(){

    switch (this.fecha) {
      case "2020-08-02":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        this.subtitulo="Gtos total Acumulado de Diesel"
        
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
         case "2020-08-09":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        this.subtitulo="Gtos total Acumulado de Diesel"
       
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
         case "2020-08-16":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        this.subtitulo="Gtos total Acumulado de Diesel"
       
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
      
      default:
         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        this.subtitulo="Gtos total Acumulado de Diesel"
       
        
        this.Titulo_grafica="";
         this.dia_hinabil="Sin Información del Día";
        break;
    }

  }

  total=0;
  lable_advance='';

  letSubcat_all(subcat){
    this.dia_hinabil="";
     let grafic=[];
     let total=0;

    switch (subcat) {
      case "fin":

          
            
            //this.switch_fin_all(); 
           
            this.set_format=true;  
             this.subtitulo="Total Egresos del Día."
             this.lable_advance='Mayor Gasto';

              this.grafica_1=[];
              
               this.service.get_cat_compras().subscribe(res=>{
                 
                 if(res!=null){
                   this.cat_compras=JSON.parse(JSON.stringify(res)).Datas;
                   this.service.getrSincro(this.fecha,'rp_compras_diario').subscribe(res=>{
                      if(res!=null){
                        let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;

                
                        if(sincro.justificacion=='actualización diaria'){
                          this.service.getrp_info(sincro.sincro_id,'rp_compras_diarias').subscribe(res=>{
                           
                            if(res!=null){
                              let compras=JSON.parse(JSON.stringify(res)).Datas;
                                for(let compra of compras){
                                    
                                    total=total+parseFloat(compra.Data.efectivo);

                                    let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                    console.log(name);
                                    if (name!=null && name!=undefined) {
                                       grafic.push(
                                        { 
                                          "name":name.Data.nombre ,
                                          "value": compra.Data.efectivo,
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(compra.Data.efectivo)} 
                                        });
                            
                                    }else{
                                       grafic.push(
                                        { 
                                          "name":'Sin Nombre('+compra.Data.codigo+')',
                                          "value": compra.Data.efectivo,
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(compra.Data.efectivo)} 
                                        });
                                    }

                                    console.log(compra);
                                }
                                

                                this.grafica_1=grafic;
                                this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total);
                                this.total=total;
                                }
                            });
                        
                         }else{
                         this.dia_hinabil="Día Inhábil";
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                      }
                      }else{
                         this.dia_hinabil="Sin Información";
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                      }
                   });
                 }
               });
              
        break;

        case "serv":

          this.Titulo_grafica="Gtos. por Servicios por Clasificador";
          this.switch_uni_all(); 

          this.subtitulo="Total de Gtos. por Servicios de Día"
          this.set_format=false; 

           
             this.lable_advance='Mayor Gasto';

              this.grafica_1=[];
               
               this.service.get_cat_compras().subscribe(res=>{
                 
                 if(res!=null){
                   this.cat_compras=JSON.parse(JSON.stringify(res)).Datas;
                   this.service.getrSincro(this.fecha,'rp_compras_diario').subscribe(res=>{
                      if(res!=null){
                        let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;

                        if(sincro.justificacion=='actualización diaria'){
                        this.service.getrp_info(sincro.sincro_id,'rp_compras_diarias').subscribe(res=>{
                         
                          if(res!=null){
                            let compras=JSON.parse(JSON.stringify(res)).Datas;
                            for(let compra of compras){

                              if(compra.Data.unidad=='SERVICIO' || compra.Data.unidad==='SER' || compra.Data.unidad==='ser' ){

                              
                                
                                total=total+parseFloat(compra.Data.efectivo);

                                let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                console.log(name);
                                if (name!=null && name!=undefined) {
                                   grafic.push(
                                    { 
                                      "name":name.Data.nombre ,
                                      "value": compra.Data.efectivo,
                                      "extra":{val_form:new Intl.NumberFormat('es-MX').format(compra.Data.efectivo)} 
                                    });
                        
                                }else{
                                   grafic.push(
                                    { 
                                      "name":'Sin Nombre('+compra.Data.codigo+')',
                                      "value": compra.Data.efectivo,
                                      "extra":{val_form:new Intl.NumberFormat('es-MX').format(compra.Data.efectivo)} 
                                    });
                                }


                                }

                                console.log(compra);
                            }
                              

                            this.grafica_1=grafic;
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total);
                            this.total=total;
                          }
                        });
                         }else{
                         this.dia_hinabil="Día Inhábil";
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                      }
                      }else{
                        this.dia_hinabil="Sin Información";
                        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                      }
                   });
                 }
               });   
              
        break;
      
      default:
            alert("No se encontro Informacion valida para esta categoria");
        break;
    }

  }


  letSubcat_prod(subcat){
    this.dia_hinabil="";

     let grafic=[];
     let total=0;

    switch (subcat) {
      case "fin":

          
            
            //this.switch_fin_Prod(); 
           
            this.set_format=true;  
             this.subtitulo="Total Egresos del Día."

             this.lable_advance='Mayor Gasto';

              this.grafica_1=[];
              
               this.service.get_cat_compras().subscribe(res=>{
                 
                 if(res!=null){
                   this.cat_compras=JSON.parse(JSON.stringify(res)).Datas;
                   this.service.getrSincro(this.fecha,'rp_compras_diario').subscribe(res=>{
                      if(res!=null){
                        let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
                        if(sincro.justificacion=='actualización diaria'){


                          this.service.getrp_info(sincro.sincro_id,'rp_compras_diarias').subscribe(res=>{
                           
                            if(res!=null){
                              let compras=JSON.parse(JSON.stringify(res)).Datas;
                              
                              for(let compra of compras){

                                let partCodidg=compra.Data.codigo.split('');

                                let prod=partCodidg[0]+partCodidg[1]+partCodidg[2];

                                if(prod=='PRO'){
                               
                               
                                    
                                      
                                      total=total+parseFloat(compra.Data.efectivo);

                                      let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                      console.log(name);
                                      if (name!=null && name!=undefined) {
                                         grafic.push(
                                          { 
                                            "name":name.Data.nombre ,
                                            "value": compra.Data.efectivo,
                                            "extra":{val_form:new Intl.NumberFormat('es-MX').format(compra.Data.efectivo)} 
                                          });
                              
                                      }else{
                                         grafic.push(
                                          { 
                                            "name":'Sin Nombre('+compra.Data.codigo+')',
                                            "value": compra.Data.efectivo,
                                            "extra":{val_form:new Intl.NumberFormat('es-MX').format(compra.Data.efectivo)} 
                                          });
                                      }


                                     }

                                   

                                  console.log(compra);
                              }
                                

                              this.grafica_1=grafic;
                              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total);
                              this.total=total;
                            }
                          });

                         }else{
                           this.dia_hinabil="Día Inhábil";
                           this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                         }
                      }else{
                         this.dia_hinabil="Sin Información";
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                      }
                   });
                 }
               });


              
        break;

        case "serv":

          this.Titulo_grafica="Gtos. por Servicios de Producción";
        //  this.switch_uni_Prod(); 

          this.subtitulo="Total de Gtos. por Servicios de Día"
          this.set_format=false;   
           this.lable_advance='Mayor Gasto';

              this.grafica_1=[];
              
               this.service.get_cat_compras().subscribe(res=>{
                 
                 if(res!=null){
                   this.cat_compras=JSON.parse(JSON.stringify(res)).Datas;

                   this.service.getrSincro(this.fecha,'rp_compras_diario').subscribe(res=>{

                    if(res!=null){
                      let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
                      if(sincro.justificacion=='actualización diaria'){

                          this.service.getrp_info(sincro.sincro_id,'rp_compras_diarias').subscribe(res=>{
                           
                            if(res!=null){
                              let compras=JSON.parse(JSON.stringify(res)).Datas;
                              for(let compra of compras){

                                let partCodidg=compra.Data.codigo.split('');

                                let prod=partCodidg[0]+partCodidg[1]+partCodidg[2];

                                if(prod=='PRO'){
                               
                               
                                    if(compra.Data.unidad=='SERVICIO' || compra.Data.unidad==='SER' || compra.Data.unidad==='ser' ){

                                    
                                      
                                      total=total+parseFloat(compra.Data.efectivo);

                                      let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                      console.log(name);
                                      if (name!=null && name!=undefined) {
                                         grafic.push(
                                          { 
                                            "name":name.Data.nombre ,
                                            "value": compra.Data.efectivo,
                                            "extra":{val_form:new Intl.NumberFormat('es-MX').format(compra.Data.efectivo)} 
                                          });
                              
                                      }else{
                                         grafic.push(
                                          { 
                                            "name":'Sin Nombre('+compra.Data.codigo+')',
                                            "value": compra.Data.efectivo,
                                            "extra":{val_form:new Intl.NumberFormat('es-MX').format(compra.Data.efectivo)} 
                                          });
                                      }


                                      }

                                   }

                                  console.log(compra);
                              }
                                

                              this.grafica_1=grafic;
                              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total);
                              this.total=total;
                            }
                          });

                        }else{
                         console.log('dia inhabil');
                         this.dia_hinabil="Día Inhábil";
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                       }

                     }else{
                         console.log('dia sin info');
                         this.dia_hinabil="Sin Información";
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                      }
                   });

                 }
                 
               }); 
              
        break;
      
      default:
            alert("No se encontro Informacion valida para esta categoria");
        break;
    }

  }





  switch_fin_all(){

    switch (this.fecha) {
           case "2020-08-19":
          this.totalDia=new Intl.NumberFormat('es-MX').format(0);
          this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
          this.dia_hinabil="Sin Información del Día";
          
                this.grafica_1=this.grafic_1_set(
                [
              ])
          this.fecha_sv=  this.fecha;
        break;
       case "2020-08-16":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
              this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;

        case "2020-08-15":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
              this.dia_hinabil="Sin Información del Día";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-17":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
              this.dia_hinabil="Sin Información del Día";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
      


        case "2020-08-01":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4607.31);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 4607.31,extra:{val_form:new Intl.NumberFormat('es-MX').format(4607.31)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
                
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
            
            this.Titulo_grafica="Todos los Clasificadores";


              this.fecha_sv=  this.fecha;
        break;
         case "2020-08-02":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Egresos Total del Día."
              
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(4292)} },
                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },

                        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
               
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-09":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards(0,0,0);
              this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
         
        case "2020-08-03":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(32834.21);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 32574.21,extra:{val_form:new Intl.NumberFormat('es-MX').format(32574.21)} },
                {nombre:"GASTOS DE VENTAS " ,value: 260,extra:{val_form:new Intl.NumberFormat('es-MX').format(260)} },
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
                
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-04":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4607.31);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 113209.06,extra:{val_form:new Intl.NumberFormat('es-MX').format(113209.06)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:3459.20 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 3459.20)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
                
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-05":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(6013.54);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 3198.65,extra:{val_form:new Intl.NumberFormat('es-MX').format(3198.65)} },
                {nombre:"GASTOS DE VENTAS " ,value: 2814.89,extra:{val_form:new Intl.NumberFormat('es-MX').format(2814.89)} },          
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        
        case "2020-08-06":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(62210.21);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"Otros" ,value: 36609.6, extra:{val_form:new Intl.NumberFormat('es-MX').format(36609.6)} },
                {nombre:"GASTOS DE PRODUCCION " ,value: 17134.82,extra:{val_form:new Intl.NumberFormat('es-MX').format(17134.82)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:8465.79 , extra:{val_form:new Intl.NumberFormat('es-MX').format(8465.79)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
              
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-07":

              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(10189.45);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"Otros" ,value: 36609.6, extra:{val_form:new Intl.NumberFormat('es-MX').format(36609.6)} },
                {nombre:"GASTOS DE PRODUCCION " ,value: 8938.45,extra:{val_form:new Intl.NumberFormat('es-MX').format(8938.45)} },
                   {nombre:"GASTOS DE ADMINISTRACION " ,value:1251.00 , extra:{val_form:new Intl.NumberFormat('es-MX').format(1251.00)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
             
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";

              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-08":
                           this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value:0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
     
        case "2020-08-10":
                           this.totalDia="$"+new Intl.NumberFormat('es-MX').format(5901.75);
              this.subtitulo="Egresos Total del Día."
              
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 5530.09,extra:{val_form:new Intl.NumberFormat('es-MX').format(5530.09)} },
                {nombre:"Otros" ,value: 371.66, extra:{val_form:new Intl.NumberFormat('es-MX').format(371.66)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-11":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(9680.83);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"Otros" ,value: 8134.99, extra:{val_form:new Intl.NumberFormat('es-MX').format(8134.99)} },
                {nombre:"GASTOS DE PRODUCCION " ,value:1545.84,extra:{val_form:new Intl.NumberFormat('es-MX').format(1545.84)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
              
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-12":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1431.76);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"GASTOS DE ADMINISTRACION " ,value:859.05 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 859.05)} },
                {nombre:"GASTOS DE VENTAS " ,value: 572.71,extra:{val_form:new Intl.NumberFormat('es-MX').format(572.71)} },

                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        
        case "2020-08-13":


                           this.totalDia="$"+new Intl.NumberFormat('es-MX').format(16642.25);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 16642.25,extra:{val_form:new Intl.NumberFormat('es-MX').format(16642.25)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-14":
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },      
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
    
    
        case "2020-08-18":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(164.96);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"GASTOS DE VENTAS " ,value: 100,extra:{val_form:new Intl.NumberFormat('es-MX').format(100)} },       
                {nombre:"GASTOS DE PRODUCCION " ,value: 64.96,extra:{val_form:new Intl.NumberFormat('es-MX').format(64.96)} },
                 
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-20":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
      
        case "2020-08-21":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        /////////////actualiza
                case "2020-08-22":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(100);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"TELEFONO MOVIL" ,value: 100,extra:{val_form:new Intl.NumberFormat('es-MX').format(100)} },
                 
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-24":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(83283.65);
              this.subtitulo="Egresos Total del Día."
              
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL" ,value: 82123.65,extra:{val_form:new Intl.NumberFormat('es-MX').format(82123.65)} },
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 1160,extra:{val_form:new Intl.NumberFormat('es-MX').format(1160)} },       
                
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-25":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(12047.04);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"ENERGIA ELECTRICA (LUZ)" ,value: 10876.47,extra:{val_form:new Intl.NumberFormat('es-MX').format(10876.47)} },
                 {nombre:"GASTOS DIVERSOS EXENTOS" ,value: 562.57,extra:{val_form:new Intl.NumberFormat('es-MX').format(562.57)} },       
                
              
                {nombre:"Otros" ,value: 608.00, extra:{val_form:new Intl.NumberFormat('es-MX').format(608.00)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-26":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2165.59);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([



                {nombre:"GASOLINA" ,value: 1120.78,extra:{val_form:new Intl.NumberFormat('es-MX').format(1120.78)} },
                {nombre:"HERRAMIENTA GENERAL  " ,value: 729.34,extra:{val_form:new Intl.NumberFormat('es-MX').format(729.34)} },       
               
              
                {nombre:"Otros" ,value: 315.47, extra:{val_form:new Intl.NumberFormat('es-MX').format(315.47)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-27":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(83581.19);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL" ,value: 78941.19,extra:{val_form:new Intl.NumberFormat('es-MX').format(78941.19)} },
                {nombre:"MANTENIMIENTO EQUIPO DE TRANSPORTE" ,value: 4640,extra:{val_form:new Intl.NumberFormat('es-MX').format(4640)} },       
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-28":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(86285.99);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL" ,value: 79426.65,extra:{val_form:new Intl.NumberFormat('es-MX').format(79426.65)} },
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 3686,extra:{val_form:new Intl.NumberFormat('es-MX').format(3686)} },       
                {nombre:"MANTENIMIENTO EQUIPO DE TRANSPORTE" ,value: 3173.34, extra:{val_form:new Intl.NumberFormat('es-MX').format( 3173.34)} },
                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-29":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(80793.25);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL" ,value: 78887.25,extra:{val_form:new Intl.NumberFormat('es-MX').format(78887.25)} },
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 1906,extra:{val_form:new Intl.NumberFormat('es-MX').format(1906)} },       
                
      
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-31":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(37781.37);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([






                {nombre:"MANTENIMIENTO Y ACTUALIZACION A EQUIPO DE COMPUTO" ,value: 25189.4,extra:{val_form:new Intl.NumberFormat('es-MX').format(25189.4)} },
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 10655.06,extra:{val_form:new Intl.NumberFormat('es-MX').format(10655.06)} },       
                {nombre:"MANTENIMIENTO EQUIPO DE ACARREO " ,value: 1936.91, extra:{val_form:new Intl.NumberFormat('es-MX').format( 1936.91)} },
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;

         case "2020-08-30":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;

               case "2020-08-23":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Sin Información";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
          
        //////////actualiza
      
      default:
            this.fecha=this.fecha_sv;
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Sin Información";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
             

        break;
    }
  }

  switch_uni_all(){
    switch (this.fecha) {

           case "2020-08-19":
          this.totalDia=new Intl.NumberFormat('es-MX').format(0);
          this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
          this.dia_hinabil="Sin Información del Día";
          
                this.grafica_1=this.grafic_1_set(
                [
              ])
          this.fecha_sv=  this.fecha;
        break;
       case "2020-08-16":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-30":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;

            case "2020-08-15":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
              this.dia_hinabil="Sin Información del Día";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-17":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
              this.dia_hinabil="Sin Información del Día";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
      
      

     

        case "2020-08-01":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4607.31);
              
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 4607.31,extra:{val_form:new Intl.NumberFormat('es-MX').format(4607.31)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
                
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
          


                this.fecha_sv=  this.fecha;
          break;
           case "2020-08-02":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4292);
              
              
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"GASTOS DE VENTAS " ,value: 4292,extra:{val_form:new Intl.NumberFormat('es-MX').format(42924292)} },
                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },

                        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
               
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-09":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
              this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
      
         
        case "2020-08-03":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(32780.21);
              
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value:32574.21,extra:{val_form:new Intl.NumberFormat('es-MX').format(32574.21)} },
                {nombre:"GASTOS DE VENTAS " ,value: 260,extra:{val_form:new Intl.NumberFormat('es-MX').format(206)} },
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
                
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-04":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(117198.77);
              
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 113209.06,extra:{val_form:new Intl.NumberFormat('es-MX').format(113209.06)} },

                {nombre:"GASTOS DE ADMINISTRACION " ,value:3459.20 , extra:{val_form:new Intl.NumberFormat('es-MX').format(3459.20)} },
                
                {nombre:"GASTOS DE VENTAS " ,value: 530.51,extra:{val_form:new Intl.NumberFormat('es-MX').format(530.51)} },
                       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
                
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-05":
             this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4324.22);
              
              



             
              this.grafica_1=this.grafic_1_set([
                         
                {nombre:"GASTOS DE PRODUCCION " ,value: 2636.12,extra:{val_form:new Intl.NumberFormat('es-MX').format(2636.12)} },
                {nombre:"GASTOS DE VENTAS " ,value: 1688.10,extra:{val_form:new Intl.NumberFormat('es-MX').format(1688.10)} }, 
               
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        
        case "2020-08-06":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(8798.30);
              
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"GASTOS DE ADMINISTRACION " ,value:8465.79, extra:{val_form:new Intl.NumberFormat('es-MX').format(8465.792)} },
                {nombre:"GASTOS DE PRODUCCION " ,value: 2,extra:{val_form:new Intl.NumberFormat('es-MX').format(332.51)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
               
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-07":

              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(10189.45);
              this.subtitulo="Total Servicios del Día"
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 8938.45,extra:{val_form:new Intl.NumberFormat('es-MX').format(8938.45)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:1251.00, extra:{val_form:new Intl.NumberFormat('es-MX').format(1251.00)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },

                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    

              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-08":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Total Servicios del Día"
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value:0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
     
        case "2020-08-10":
                           this.totalDia="$"+new Intl.NumberFormat('es-MX').format(5530.09);
              
              
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 5530.09,extra:{val_form:new Intl.NumberFormat('es-MX').format(5530.09)} },
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                
                              
                                
              ]);
             
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-11":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1545.84);
              
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"Otros" ,value: 1545.84, extra:{val_form:new Intl.NumberFormat('es-MX').format(1545.84)} },
                {nombre:"GASTOS DE PRODUCCION " ,value:0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
              
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-12":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(32.87);
              
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value:1,extra:{val_form:new Intl.NumberFormat('es-MX').format(19.72)} },
                {nombre:"GASTOS DE VENTAS " ,value: 1,extra:{val_form:new Intl.NumberFormat('es-MX').format(13.15)} },        
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        
        case "2020-08-13":


                           this.totalDia="$"+new Intl.NumberFormat('es-MX').format(16642.25);
              
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 16642.25,extra:{val_form:new Intl.NumberFormat('es-MX').format(16642.25)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-14":
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },      
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
    
    
        case "2020-08-18":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(64.96);
               
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 64.96,extra:{val_form:new Intl.NumberFormat('es-MX').format(64.96)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-20":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
              
              
              this.fecha_sv=  this.fecha;
        break;
      
        case "2020-08-21":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
               
              
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"GASTOS DE PRODUCCION " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 {nombre:"GASTOS DE VENTAS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                {nombre:"ADQUISICIONES DE ACTIVO FIJO " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"GASTOS DE ADMINISTRACION " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"ALMACENAJE DE PRODUCTOS  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                {nombre:"Otros" ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;

                /////////////actualiza
                case "2020-08-22":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(100);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"TELEFONO MOVIL" ,value: 100,extra:{val_form:new Intl.NumberFormat('es-MX').format(100)} },
                 
                
              
                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-24":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1160);
              this.subtitulo="Egresos Total del Día."
              
             
              this.grafica_1=this.grafic_1_set([

                
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 1160,extra:{val_form:new Intl.NumberFormat('es-MX').format(1160)} },       
                
                
              
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-25":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(12047.04);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"ENERGIA ELECTRICA (LUZ)" ,value: 10876.47,extra:{val_form:new Intl.NumberFormat('es-MX').format(10876.47)} },
                 {nombre:"GASTOS DIVERSOS EXENTOS" ,value: 562.57,extra:{val_form:new Intl.NumberFormat('es-MX').format(562.57)} },       
                
              
                {nombre:"Otros" ,value: 608.00, extra:{val_form:new Intl.NumberFormat('es-MX').format(608.00)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-26":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(729.34+32.72);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([


                 {nombre:"HERRAMIENTA GENERAL  " ,value: 729.34,extra:{val_form:new Intl.NumberFormat('es-MX').format(729.34)} },       
                {nombre:"IEPS GASOLINA" ,value: 32.72,extra:{val_form:new Intl.NumberFormat('es-MX').format(32.72)} },
               
               
              
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-27":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4640);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

        
                {nombre:"MANTENIMIENTO EQUIPO DE TRANSPORTE" ,value: 4640,extra:{val_form:new Intl.NumberFormat('es-MX').format(4640)} },       
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-28":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(6859.34);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

               
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 3686,extra:{val_form:new Intl.NumberFormat('es-MX').format(3686)} },       
                {nombre:"MANTENIMIENTO EQUIPO DE TRANSPORTE" ,value: 3173.34, extra:{val_form:new Intl.NumberFormat('es-MX').format( 3173.34)} },
                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-29":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1906);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

               
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 1906,extra:{val_form:new Intl.NumberFormat('es-MX').format(1906)} },       
                
      
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-31":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(37781.37);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([






                {nombre:"MANTENIMIENTO Y ACTUALIZACION A EQUIPO DE COMPUTO" ,value: 25189.4,extra:{val_form:new Intl.NumberFormat('es-MX').format(25189.4)} },
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 10655.06,extra:{val_form:new Intl.NumberFormat('es-MX').format(10655.06)} },       
                {nombre:"MANTENIMIENTO EQUIPO DE ACARREO " ,value: 1936.91, extra:{val_form:new Intl.NumberFormat('es-MX').format( 1936.91)} },
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;

         case "2020-08-30":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;

               case "2020-08-23":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Sin Información";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
          
        //////////actualiza
      
      default:
            this.fecha=this.fecha_sv;
            alert("No se encontro Información para esta fecha");

        break;
    }
  }


  switch_fin_Prod(){
    switch (this.fecha) {
       case "2020-08-16":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;

        case "2020-08-19":
          this.totalDia=new Intl.NumberFormat('es-MX').format(0);
          this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
          this.dia_hinabil="Sin Información del Día";
          
                this.grafica_1=this.grafic_1_set(
                [
              ])
          this.fecha_sv=  this.fecha;
        break;

        case "2020-08-15":
          this.totalDia=new Intl.NumberFormat('es-MX').format(0);
          this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
          this.dia_hinabil="Sin Información del Día";
          
                this.grafica_1=this.grafic_1_set(
                [
              ])
          this.fecha_sv=  this.fecha;
        break;
        case "2020-08-17":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
              this.dia_hinabil="Sin Información del Día";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
      
      


        case "2020-08-01":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4607.31);
              
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 4607.31,extra:{val_form:new Intl.NumberFormat('es-MX').format(4607.31)} },
                
                                
              ]);
            
            


              this.fecha_sv=  this.fecha;
        break;
         case "2020-08-02":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
           
              this.grafica_1=this.grafic_1_set([
               
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-09":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards(0,0,0);
              this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
         
        case "2020-08-03":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(32574.21);
             
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"ENERGIA ELECTRICA (LUZ)  " ,value: 29317.33,extra:{val_form:new Intl.NumberFormat('es-MX').format(29317.33)} },
                {nombre:"GASTOS DIVERSOS EXENTOS " ,value:  2527.35,extra:{val_form:new Intl.NumberFormat('es-MX').format(260)} },
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value: 729.53, extra:{val_form:new Intl.NumberFormat('es-MX').format( 729.53)} },
                {nombre:" TRITURADO 3/16 A FINOS " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
               
                
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-04":
              

              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(113209.06);
             
            
              this.grafica_1=this.grafic_1_set([



                {nombre:"PRIMAS DE SEGURO  " ,value: 112721.56,extra:{val_form:new Intl.NumberFormat('es-MX').format(112721.56)} },
                {nombre:"HERRAMIENTA GENERAL  " ,value:487.50 , extra:{val_form:new Intl.NumberFormat('es-MX').format(487.50)} },
                {nombre:"CEMENTO A GRANEL  " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                       
               
                              
                                
              ]);

              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-05":
              

              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(3198.65);
             
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"PROMTTO003  MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value:2622.94,extra:{val_form:new Intl.NumberFormat('es-MX').format(2622.94)} },
                {nombre:"PROCUL003  GASOLINA   " ,value: 562.53,extra:{val_form:new Intl.NumberFormat('es-MX').format(562.53)} },          
                {nombre:"PROCUL006  IEPS GASOLINA   " ,value: 13.18, extra:{val_form:new Intl.NumberFormat('es-MX').format( 13.18)} },
                {nombre:"PROINS006  TRITURADO 3/16 A FINOS" ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                {nombre:"PROINS007  GRAVA/ SELLO  3/8 " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                
              
                
                              
                          

     
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        
        case "2020-08-06":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(17134.82);
             
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL  " ,value: 14899.97, extra:{val_form:new Intl.NumberFormat('es-MX').format(14899.97)} },
                {nombre:"LUBRICANTES  " ,value: 1902.34,extra:{val_form:new Intl.NumberFormat('es-MX').format(1902.34)} },
                {nombre:"GASTOS DIVERSOS  " ,value:278.11 , extra:{val_form:new Intl.NumberFormat('es-MX').format(278.11)} },
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value: 54.40 ,extra:{val_form:new Intl.NumberFormat('es-MX').format(54.40 )} },        
                {nombre:"TRITURADO 3/16 A FINOS " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
              
                {nombre:"GRAVA/ SELLO  3/8 " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
               
              ]);
   
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-07":

              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(8938.45);
    
              this.grafica_1=this.grafic_1_set([
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value: 8724.05, extra:{val_form:new Intl.NumberFormat('es-MX').format(8724.05)} },
                {nombre:"PAQUETERIA Y MENSAJERIA  " ,value:  214.40,extra:{val_form:new Intl.NumberFormat('es-MX').format( 214.40)} },
                   {nombre:"CEMENTO A GRANEL  " ,value:0 , extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                {nombre:"TRITURADO 3/16 A FINOS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },        
                {nombre:"GRAVA/ SELLO  3/8 " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                     
                                
              ]);
    

              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-08":

             this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              
             
              this.grafica_1=this.grafic_1_set([

                 {nombre:"TRITURADO 3/16 A FINOS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 
                {nombre:"GRAVA/ SELLO  3/8 " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                              
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
     
        case "2020-08-10":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 5530.09);
             
           
              this.grafica_1=this.grafic_1_set([

                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO " ,value:  5530.09,extra:{val_form:new Intl.NumberFormat('es-MX').format( 5530.09)} },
                {nombre:" CEMENTO A GRANEL " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                {nombre:"TRITURADO 3/16 A FINOS  " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },        
                {nombre:"GRAVA/ SELLO  3/8  " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
               
                
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-11":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1545.84);
              
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value:1189.02, extra:{val_form:new Intl.NumberFormat('es-MX').format(1189.02)} },
                {nombre:"HERRAMIENTA GENERAL  " ,value:356.82,extra:{val_form:new Intl.NumberFormat('es-MX').format(356.82)} },
                {nombre:"TRITURADO 3/16 A FINOS  " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                       
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-12":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
             
             
              this.grafica_1=this.grafic_1_set([
              {nombre:"TRITURADO 3/16 A FINOS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 
                {nombre:"GRAVA/ SELLO  3/8 " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        
        case "2020-08-13":


              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(16642.25);
              
             
              this.grafica_1=this.grafic_1_set([

           
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value: 15279.25,extra:{val_form:new Intl.NumberFormat('es-MX').format(15279.25)} },
                {nombre:"MANTENIMIENTO (EQUIPO DE SEGURIDAD)  " ,value: 1363.00,extra:{val_form:new Intl.NumberFormat('es-MX').format(1363.00)} },       
                
                                        
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-14":
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              
             
              this.grafica_1=this.grafic_1_set([

                 {nombre:"TRITURADO 3/16 A FINOS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 
                {nombre:"GRAVA/ SELLO  3/8 " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
    
    
        case "2020-08-18":
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(64.96);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([




                {nombre:"HERRAMIENTA GENERAL  " ,value: 64.96,extra:{val_form:new Intl.NumberFormat('es-MX').format(64.96)} },       
                {nombre:"TRITURADO 3/16 A FINOS " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 
                {nombre:"GRAVA/ SELLO  3/8 " ,value: 0, extra:{val_form:new Intl.NumberFormat('es-MX').format( 0)} },
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-20":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
             
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"TRITURADO 3/16 A FINOS" ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                 {nombre:"GRAVA/ SELLO  3/8" ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
      
        case "2020-08-21":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
             
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([





                {nombre:" CEMENTO A GRANEL " ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
                {nombre:"TRITURADO 3/16 A FINOS" ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },       
                
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;

        /////////////actualiza
                case "2020-08-22":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-24":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(82123.65);
              this.subtitulo="Egresos Total del Día."
              
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL" ,value: 82123.65,extra:{val_form:new Intl.NumberFormat('es-MX').format(82123.65)} },
                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-25":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-26":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1403.53);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([



                {nombre:"GASOLINA" ,value: 1120.78,extra:{val_form:new Intl.NumberFormat('es-MX').format(1403.53)} },
                       
               
              
    
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-27":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(78941.19);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL" ,value: 78941.19,extra:{val_form:new Intl.NumberFormat('es-MX').format(78941.19)} },
                       
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-28":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(79426.65);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL" ,value: 79426.65,extra:{val_form:new Intl.NumberFormat('es-MX').format(79426.65)} },
                
                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-29":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(78887.25);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"CEMENTO A GRANEL" ,value: 78887.25,extra:{val_form:new Intl.NumberFormat('es-MX').format(78887.25)} },
                    
                
      
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-31":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([






                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;

         case "2020-08-30":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;

               case "2020-08-23":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Sin Información";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
          
        //////////actualiza
      
      default:
            this.fecha=this.fecha_sv;
            alert("No se encontro Información para esta fecha");

        break;
    }
  }

  switch_uni_Prod(){
    switch (this.fecha) {
       case "2020-08-16":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;

        case "2020-08-19":
          this.totalDia=new Intl.NumberFormat('es-MX').format(0);
          this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
          this.dia_hinabil="Sin Información del Día";
          
                this.grafica_1=this.grafic_1_set(
                [
              ])
          this.fecha_sv=  this.fecha;
        break;

        case "2020-08-15":
          this.totalDia=new Intl.NumberFormat('es-MX').format(0);
          this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
          this.dia_hinabil="Sin Información del Día";
          
                this.grafica_1=this.grafic_1_set(
                [
              ])
          this.fecha_sv=  this.fecha;
        break;
        case "2020-08-17":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
              this.dia_hinabil="Sin Información del Día";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
      
      


        case "2020-08-01":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4607.31);
              
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 4607.31,extra:{val_form:new Intl.NumberFormat('es-MX').format(4607.31)} },
                
                                
              ]);
            
            


              this.fecha_sv=  this.fecha;
        break;
         case "2020-08-02":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
           
              this.grafica_1=this.grafic_1_set([
               
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-09":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards(0,0,0);
              this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
         
        case "2020-08-03":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(32574.21);
             
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"ENERGIA ELECTRICA (LUZ)  " ,value: 29317.33,extra:{val_form:new Intl.NumberFormat('es-MX').format(29317.33)} },
                {nombre:"GASTOS DIVERSOS EXENTOS " ,value:  2527.35,extra:{val_form:new Intl.NumberFormat('es-MX').format(260)} },
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value: 729.53, extra:{val_form:new Intl.NumberFormat('es-MX').format( 729.53)} },
               
               
                
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-04":
              

              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(113209.06);
             
            
              this.grafica_1=this.grafic_1_set([



                {nombre:"PRIMAS DE SEGURO  " ,value: 112721.56,extra:{val_form:new Intl.NumberFormat('es-MX').format(112721.56)} },
                {nombre:"HERRAMIENTA GENERAL  " ,value:487.50 , extra:{val_form:new Intl.NumberFormat('es-MX').format(487.50)} },
             
                       
               
                              
                                
              ]);

              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-05":
              

              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2636.12);
             
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"PROMTTO003  MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value:2622.94,extra:{val_form:new Intl.NumberFormat('es-MX').format(2622.94)} },
                         
                {nombre:"PROCUL006  IEPS GASOLINA   " ,value: 13.18, extra:{val_form:new Intl.NumberFormat('es-MX').format( 13.18)} },
                
                
              
                
                              
                          

     
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        
        case "2020-08-06":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(332.51);
             
             
              this.grafica_1=this.grafic_1_set([

          
                {nombre:"GASTOS DIVERSOS  " ,value:278.11 , extra:{val_form:new Intl.NumberFormat('es-MX').format(278.11)} },
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value: 54.40 ,extra:{val_form:new Intl.NumberFormat('es-MX').format(54.40 )} },        
               
               
              ]);
   
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-07":

              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(8938.45);
    
              this.grafica_1=this.grafic_1_set([
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value: 8724.05, extra:{val_form:new Intl.NumberFormat('es-MX').format(8724.05)} },
                {nombre:"PAQUETERIA Y MENSAJERIA  " ,value:  214.40,extra:{val_form:new Intl.NumberFormat('es-MX').format( 214.40)} },
                
                     
                                
              ]);
    

              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-08":

             this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              
             
              this.grafica_1=this.grafic_1_set([

                
                              
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
     
        case "2020-08-10":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(5530.09);
             
           
              this.grafica_1=this.grafic_1_set([

                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO " ,value:  5530.09,extra:{val_form:new Intl.NumberFormat('es-MX').format( 5530.09)} },
               
               
                
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-11":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1545.84);
              
             
              this.grafica_1=this.grafic_1_set([
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value:1189.02, extra:{val_form:new Intl.NumberFormat('es-MX').format(1189.02)} },
                {nombre:"HERRAMIENTA GENERAL  " ,value:356.82,extra:{val_form:new Intl.NumberFormat('es-MX').format(356.82)} },
                    
                       
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-12":
              
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
             
             
              this.grafica_1=this.grafic_1_set([
             
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        
        case "2020-08-13":


              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(16642.25);
              
             
              this.grafica_1=this.grafic_1_set([

           
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO  " ,value: 15279.25,extra:{val_form:new Intl.NumberFormat('es-MX').format(15279.25)} },
                {nombre:"MANTENIMIENTO (EQUIPO DE SEGURIDAD)  " ,value: 1363.00,extra:{val_form:new Intl.NumberFormat('es-MX').format(1363.00)} },       
                
                                        
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-14":
                         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
              
             
              this.grafica_1=this.grafic_1_set([

                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
    
    
        case "2020-08-18":
            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(64.96);
              
             
             
              this.grafica_1=this.grafic_1_set([




                {nombre:"HERRAMIENTA GENERAL  " ,value: 64.96,extra:{val_form:new Intl.NumberFormat('es-MX').format(64.96)} },       
                
               
                              
                                
              ]);
   
              this.fecha_sv=  this.fecha;
        break;
        case "2020-08-20":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
             
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                   
              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;
      
        case "2020-08-21":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
             
             
             
              this.grafica_1=this.grafic_1_set([




     
                
                              
                                
              ]);
    
              this.fecha_sv=  this.fecha;
        break;

            /////////////actualiza
                case "2020-08-22":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(100);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"TELEFONO MOVIL" ,value: 100,extra:{val_form:new Intl.NumberFormat('es-MX').format(100)} },
                 
                
              
                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-24":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1160);
              this.subtitulo="Egresos Total del Día."
              
             
              this.grafica_1=this.grafic_1_set([

                
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 1160,extra:{val_form:new Intl.NumberFormat('es-MX').format(1160)} },       
                
                
              
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-25":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(12047.04);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

                {nombre:"ENERGIA ELECTRICA (LUZ)" ,value: 10876.47,extra:{val_form:new Intl.NumberFormat('es-MX').format(10876.47)} },
                 {nombre:"GASTOS DIVERSOS EXENTOS" ,value: 562.57,extra:{val_form:new Intl.NumberFormat('es-MX').format(562.57)} },       
                
              
                {nombre:"Otros" ,value: 608.00, extra:{val_form:new Intl.NumberFormat('es-MX').format(608.00)} },
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-26":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(729.34+32.72);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([


                 {nombre:"HERRAMIENTA GENERAL  " ,value: 729.34,extra:{val_form:new Intl.NumberFormat('es-MX').format(729.34)} },       
                {nombre:"IEPS GASOLINA" ,value: 32.72,extra:{val_form:new Intl.NumberFormat('es-MX').format(32.72)} },
               
               
              
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-27":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(4640);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

        
                {nombre:"MANTENIMIENTO EQUIPO DE TRANSPORTE" ,value: 4640,extra:{val_form:new Intl.NumberFormat('es-MX').format(4640)} },       
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-28":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(6859.34);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

               
                {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 3686,extra:{val_form:new Intl.NumberFormat('es-MX').format(3686)} },       
                {nombre:"MANTENIMIENTO EQUIPO DE TRANSPORTE" ,value: 3173.34, extra:{val_form:new Intl.NumberFormat('es-MX').format( 3173.34)} },
                
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-29":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1906);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([

               
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 1906,extra:{val_form:new Intl.NumberFormat('es-MX').format(1906)} },       
                
      
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;
                case "2020-08-31":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(37781.37);
              this.subtitulo="Egresos Total del Día."
              this.date_cards=this.setData_cards(138830.00,171748.23,4650.01);
             
              this.grafica_1=this.grafic_1_set([






                {nombre:"MANTENIMIENTO Y ACTUALIZACION A EQUIPO DE COMPUTO" ,value: 25189.4,extra:{val_form:new Intl.NumberFormat('es-MX').format(25189.4)} },
                 {nombre:"MANTENIMIENTO MAQUINARIA Y EQUIPO" ,value: 10655.06,extra:{val_form:new Intl.NumberFormat('es-MX').format(10655.06)} },       
                {nombre:"MANTENIMIENTO EQUIPO DE ACARREO " ,value: 1936.91, extra:{val_form:new Intl.NumberFormat('es-MX').format( 1936.91)} },
               
                              
                                
              ]);
    this.Titulo_grafica="Todos los Clasificadores";
              this.fecha_sv=  this.fecha;
        break;

         case "2020-08-30":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Día Inhábil";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;

               case "2020-08-23":
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
              this.date_cards=this.setData_cards_unidad(0,0,0,'Anticipo','Maniobras');
             this.dia_hinabil="Sin Información";
              
                    this.grafica_1=this.grafic_1_set(
                    [
                  ])
              this.fecha_sv=  this.fecha;
        break;
          
        //////////actualiza
      
      default:
            this.fecha=this.fecha_sv;
            alert("No se encontro Información para esta fecha");

        break;
    }
  }







  

  single_Set(title,value){
    let val=[{
            "name": title,
            "value": value

       }];
    return  val;

  }

   


  grafic_1_set(objeto_1){

        let objs=[];
        for(let obj of objeto_1){
            objs.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(obj.value)}  ,
                  name:obj.nombre  ,
                  value:obj.value});
        }

        return objs;


  }

  setData_cards(financiera,anticipos,maniobras){
    let val=[
      {
       "name": "Venta Financiera del Día",
       "value": financiera

      },
       {
           "name": "Anticipos Financieros del Día",
           "value": anticipos

      },
      {
           "name": "Maniobras Financieras del Día",
           "value": maniobras
      
      }];

      return val;


  }


  setData_cards_unidad(financiera,anticipos,maniobras,unidad_anticpo,unidad_maniobar){
    let val=[
      {
       "name": "Ventas por Unidades del Día",
       "value": financiera

      },
       {
           "name": "Número de Anticipos del Día",
           "value": anticipos

      },
      {
           "name": "Número de Maniobras del Día",
           "value": maniobras
      
      }];

      return val;


  }





}

