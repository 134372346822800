import { Component, OnInit } from '@angular/core';
import { ConfiguracionesService } from '../services/configuraciones.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-demo1',
  templateUrl: './demo1.component.html',
  styleUrls: ['./demo1.component.css'],
  providers:[ConfiguracionesService]
})
export class Demo1Component implements OnInit {
	public total_ventas:any=0;
	public vetas_realizadas:any=0;

	public labels:any[]=new Array ();
	public arr_values:any=new Array ();


	  //Para Acomodar los numeros sobre la graficas.
   public barChartOptions: ChartOptions = {
      responsive: true,
      
      plugins: {

         
        datalabels: {
          anchor: 'end',
          align: 'end',
          color:'#000000FF',
           formatter: function(value, context) {
                      return "$"+new Intl.NumberFormat("es-MX").format(value);;
                  },
           
          font: {
            size: 12,
            weight: 'bold',
          }
          
        }
      }
    };
    options = {
          animation: false,
          scaleLabel:
          function(label){return  ' $' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");}
          
    };

    
  public paiOpcion: ChartOptions = {

    responsive: true,

    
    plugins: {

      datalabels: {
        listeners: {
        click: function(value) {
          // Receives `click` events only for labels of the first dataset.
          // The clicked label index is available in `context.dataIndex`.
          console.log(value.dataset.data[value.dataIndex]);
        }
      },

        
        align: 'center',
        color:'#000000FF',
         formatter: function(value, context) {
                    return "$"+new Intl.NumberFormat("es-MX").format(value);;
                },

        font: {
          size: 12,
          weight: 'bold',

        }
        
      }
    }
  };
  public barChartPlugins = [pluginDataLabels];

  public pieChartLabels = ['Sin Ventas'];
  public pieChartData = [0];
  public pieChartType = 'pie';
  public chartColors: any[] = [
      { 
        backgroundColor:["#5CBC42FF", "#B12B1FFF", "#475EB2FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#5CBC42FF", "#B12B1FFF", "#475EB2FF"] 
      }];


    public radarChartLabels = ['Q1', 'Q2', 'Q3', 'Q4'];
  public radarChartData = [{data:0,label:"Sin ventas"}
    
  ];
  public radarChartType = 'line';

  public barChartLabels: Label[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
 public barChartData: ChartDataSets[] = [
    { data: [0], label: 'Series A' }   
  ];
  public barColors: any[] = [
      { 
        backgroundColor:["#5CBC42FF", "#B12B1FFF", "#475EB2FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#FF9160FF"] 
      },
      {
        backgroundColor:"#B12B1FFF" 
      }];



  constructor(public _service:ConfiguracionesService) { }

  ngOnInit() {


  	this._service.getVentas().subscribe(
  		result=>{
  			
  			var eco = JSON.stringify(result);
    		var json = JSON.parse(eco);
    		var values=[];
    		var label=[];
    		this.vetas_realizadas=json.Ventas.length;
    		
    		for (var i = json.Ventas.length - 1; i >= 0; i--) {
    			this.total_ventas=parseInt(this.total_ventas)+parseInt(json.Ventas[i].Venta.precio);		
    			values.push(json.Ventas[i].Venta.precio);
    			label.push(json.Ventas[i].Venta.comentario);
    		}


    		this.total_ventas=new Intl.NumberFormat("es-MX").format(this.total_ventas);
  			
  			var conparacion=false;

  			this._service.getlabels().subscribe(
  				result=>{
  					var eco = JSON.stringify(result);
    				var json = JSON.parse(eco);
    				var lbl=json.Ventas;
					for (var i = json.Ventas.length - 1; i >= 0; i--) {
					this.labels.push(json.Ventas[i].Venta.nombre);
					}
					var arr_values=new Array (this.labels.length);
					var contador=0;
					for (var i = this.labels.length - 1; i >= 0; i--) {
						console.log(this.labels[i]);
						
						this._service.getvalues(this.labels[i]).subscribe(

							result=>{
								var eco = JSON.stringify(result);
			    				var json = JSON.parse(eco);
			    				var lbl=json.Ventas;
			    				
			    				this.arr_values.push(lbl[0].Venta.precio);
								
							},error=>{

							}

							);
						
						

					}

					this.barChartData=[{data: values ,label:""}];
					this.barChartLabels=label;
					this.radarChartLabels=this.labels;
				 	console.log(values);
					this.pieChartData=this.arr_values;
  					this.pieChartLabels=this.labels;
  					this.radarChartData = [
				    {data: this.arr_values, label: 'Ventas'}

				  ];
  				},error=>{

  				});



  				
  		},
  		error=>{

  		}
  		);

  	
  }

}
