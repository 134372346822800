import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params,NavigationEnd } from '@angular/router';
import { ClientesService } from '../services/clientes.service';


@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css'],
  providers:[ClientesService]
})
export class ClienteComponent implements OnInit {
	idcliente:any;
	cliente :any=[];
	conyugue:any=[];
	referencia:any=[];
  documentos:any=[];
	ifConyugue=false;
	promotor:any;
  nombre_cliente:any;
  cliente_doc:any=[];
  ifClienteDoc=false;

  constructor(private router:Router,private route:ActivatedRoute, private service:ClientesService) {
 
  }


  ngOnInit() {
  	this.route.params.subscribe((data:Params)=>{
  		this.idcliente=data.id;
  		this.getConyugue();
  		this.getReferencia();
      this.getDocuments();
      this.getDocsCliente();

      
  	});
  	this.getClinet();
  }
  getDocsCliente(){
    this.service.getDocumnetosCliente(this.idcliente).subscribe(result=>{
           console.log(result);
       if (result==null) {
        this.ifClienteDoc=false;
       }else{
         let eco = JSON.stringify(result);
         let json = JSON.parse(eco);
         this.cliente_doc=json.Datas;
         this.ifClienteDoc=true;
       }
    },error=>{console.log(error);});
  }

  getDocuments(){
    this.service.getDocumenos().subscribe(result=>{
     
       let eco = JSON.stringify(result);
       let json = JSON.parse(eco);

       this.documentos=json.Datas;
    },error=>{
      console.log(error);
    });
  }

  getClinet(){
  	this.service.getCliente(this.idcliente).subscribe(data=>{
  		let temp=JSON.stringify(data);
  		let json=JSON.parse(temp);
  		this.cliente=json.Clientes;
      this.nombre_cliente=json.Clientes[0].Cliente.nombre+" "+
      json.Clientes[0].Cliente.apellido_paterno+" "+json.Clientes[0].Cliente.apellido_materno;
  	},error=>{
  		console.log(error);
  	});
  }

    getConyugue(){
    
    this.service.getConyugue(this.idcliente).subscribe(data=>{
      if(data!=null){
        
        let eco = JSON.stringify(data);
        let json = JSON.parse(eco);
        this.conyugue=json.Clientes;
        this.ifConyugue=true;
      }
    },error=>{
        console.log(error);
    });
  }
   prmotorChange(){
    console.log(this.promotor);
  }
  savePromotor(){
    alert(this.promotor);
  }

  addPersonales(form){
    form.form.value.id=this.idcliente;
    this.service.putDatosProfecionales(form.form.value).subscribe(data=>{
     	location.reload();
    },error=>{
      console.log(error);
    });
  }
  getReferencia(){
    this.service.getReferencia(this.idcliente).subscribe(data=>{
    	if(data!=null){
    	let temp=JSON.stringify(data);
  		let json=JSON.parse(temp);
  		this.referencia=json.Datas;
    	}
    	
    },error=>console.log(error));
  }	


  postReferencias(form){
  	form.form.value.id=this.idcliente;
  	this.service.postReferencia(form.form.value).subscribe(data=>{
  		console.log(data);
  		location.reload();
  	},error=>{
  		console.log(error);
  	});

  }

   addConyugue(form){

    if(this.ifConyugue){
      alert("Ya Hay un coyugue Agregado");
    }else{
      form.form.value.id=this.idcliente;
    
      this.service.postConyugue(form.form.value).subscribe(
        data=>{
        	console.log(data);
          	location.reload();
          
        },error=>{
          console.log(error);
        });
    }
  }

  putConyugue(form){
    form.form.value.id=this.idcliente; 
    this.service.putConyugue(form.form.value).subscribe(
      data=>{
        console.log(data);
          location.reload();
        
      },error=>{
        console.log(error);
      });
  }

getClientes(){
	 this.router.navigate(["clientes"]);
}

updateContacto(form){
  form.form.value.id=this.idcliente;
  this.service.putDatosContacto(form.form.value).subscribe(data=>{
    this.getClinet();
  },error=>{
    console.log(error);
  });
}
putrefernecia(form){
  form.form.value.id=this.idcliente;
 
  this.service.putReferencia(form.form.value).subscribe(data=>{
    location.reload();
  },error=>{
    console.log(error);
  });

}

postDocCliente(id_doc){
 
  this.service.postDocumentCliente(this.idcliente,id_doc).subscribe(result=>{
    alert("Se agrago documento.");
    this.getDocsCliente();
  },error=>{
    console.log(error);
  });

}

recargar(){
  location.reload();
}




}
