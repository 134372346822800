import { Injectable } from '@angular/core'; 
import {Configuracion} from '../modals/configurador';
import { HttpClient , HttpHandler } from '@angular/common/http';


@Injectable()
export class DashboardService{
	public data:any;
	public url="http://67.205.145.224/durango/dashboard_api.php?type=";

	public urls="http://67.205.145.224/durango/consulta_api.php";
	

	constructor(private _http: HttpClient,private http:HttpHandler) {
		//this.data =new Configuracion(0,0,false,true,false,true,true,true,true,true,true,true);
		}

	
	getVentas(){
		return this._http.get(this.url+'ventas');
	}

	getPagos(){
		return this._http.get(this.url+'pagos');
	}

	getPagosbyVenta(id){
		console.log("Entra a servicios");
		return this._http.get(this.url+'pagos/id&id='+id);
	}

	getVentasByFecha(de,hasta){
		return this._http.get(this.url+'ventasByFecha&de='+de+"&hasta="+hasta);
	}
	getCliente(id){
		return this._http.get(this.url+'sc_getCliente&ids='+id);
	}

	getInmueble(id){
		return this._http.get(this.url+'sc_getInmueble&id='+id);
	}
	getstatus(id){
		return this._http.get(this.url+'stausventasByventa&id='+id);
	}
	getPromotor(id){
		return this._http.get(this.url+'promotorByventa&id='+id);
	}

	test(){
		return this._http.post(this.urls,{Edgar:'edgar'});
	}
	
}							