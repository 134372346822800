import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;


@Component({
  selector: 'app-funfi',
  templateUrl: './funfi.component.html',
  styleUrls: ['./funfi.component.css']
})
export class FunfiComponent implements OnInit {
		me_id:any;
  status:any;
  me:any;
  entregables:any=[];
  newEntregable:any=[];
  listaFechas:any=[];
  puestos:any=[];
  newEntregable_name="";
  listColaboradores:any=[];
  colaboradores:any=[];

  departamentos:any=[];

  crandoFuncion:any=false;
  paraTodos:any=false;

  funciones:any=[];

  allColaboradores:any=[];



  constructor(private services:UsersService) { }

  ngOnInit() {

  	this.loadDatas().then().catch(err=>{
  		console.log(err);
  	}).then(()=>{

    console.log(this.me_id);
    console.log(this.status);
  	});

    this.services.getallCol().subscribe(res=>{
      res!=null ? this.allColaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
    });

    
    


   

  }


  async loadDatas(){

    this.me_id= await sessionStorage.getItem('id');
     
    this.status= await sessionStorage.getItem('status');

   if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
       this.me={
         name:"Admin",
         user_id:this.me_id
       }
     
   }else{
     this.services.getMe(this.me_id).subscribe(user=>{
      user!=null? this.me=JSON.parse(JSON.stringify(user)).Datas[0].Data:this.me=null;  
      console.log(this.me);
        
    });
   }

   this.getEntregablesAll();
   this.getPuestos();
   this.getDepartamentos();
   this.getColaboradores();
   this.getFunciones();

  		
  }

  getFunciones(){
    this.funciones=[];
    this.services.getFuncionesTipo("fi").subscribe(res=>{
       res!=null?this.funciones=JSON.parse(JSON.stringify(res)).Datas:null;
    });

  }

  getColaboradores(){

    this.services.getEmleados().subscribe(res=>{
       res!=null? this.colaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }

  getDepartamentos(){

  		this.services.getDepartamentos().subscribe(res=>{
  			res!=null?this.departamentos=JSON.parse(JSON.stringify(res)).Datas:null;
  					console.log("------------------------");
  				console.log(this.departamentos);
  		});
  }

   getPuestos(){
    this.services.getRoles().subscribe(res=>{
     
      res!=null? this.puestos=JSON.parse(JSON.stringify(res)).Datas:null;

         console.log(this.puestos);
      
    });
  }

  getEntregablesAll(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.entregables);
    });
  }

  addListEntregableold(form){

    if(form.form.value.entregable!=""){
        let id=this.entregables.find(entr=>entr.Data.titulo==form.form.value.entregable);

        this.newEntregable.push(id);
        $('#AgregarEntregables').modal('hide');
        alert("Se agrego entregable con éxito");
        form.resetForm();
       	
    }else{
      alert("Es necesario seleccionar un Entregable para continuar");
    }


   
  }

  quitarEntregable(data){
  		console.log(data);
  				let seguro=confirm("¿Seguro que quieres quiatar este Entregable?");

  				if (seguro) {
  						this.newEntregable=this.newEntregable.filter(ent=>ent.Data.entregable_id!=data.entregable_id);
  				}

  	

  }

   addFecha(form){
   
    this.listaFechas.push(form.form.value);

    alert("Fecha Agregada");

    form.resetForm();

     $('#exampleModal').modal('hide');

  }


  insertArea(area){

          console.log(area);
      area.form.value.id_nombre=this.allColaboradores.find(puesto=>puesto.Data.nombre==area.form.value.rol_id).Data.id_nombre;
      console.log(area.form.value);
      if(area.form.value.periodicidad!='Diario'){
    
        if(this.listaFechas.length>=4){
           this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

          this.newEntregable=area.form.value.titulo;

          this.listaFechas=[];
          area.resetForm();
          $('#createEntrgable').modal('hide');
          this.getEntregablesAll();
          alert("Se creo Entregable con éxito");
          });
        }else{
           alert("Para crear este Tipo de Entregable es necesario ingresar 4 o más fechas de Entrega.");
        }


      }else{
          this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

          this.newEntregable=area.form.value.titulo;

          this.listaFechas=[];
          area.resetForm();
          $('#createEntrgable').modal('hide');
          this.getEntregablesAll();
          alert("Se creo Entregable con éxito");
          });
      }
  }



  	list_depas:any=[]


   addListDepas(form){

    if(form.form.value.entregable!=""){
        let id=this.departamentos.find(entr=>entr.Data.titulo==form.form.value.entregable);

        this.list_depas.push(id);

        $('#AgregarDepartamento').modal('hide');

        alert("Se agrego departamento con éxito");

        form.resetForm();
       	
    }else{
      alert("Es necesario seleccionar un departamento para continuar");
    }


   
  }


  quitarListDepas(data){
  			console.log(data);
  		let seguro=confirm("¿Seguro que desas quiatar este departamento?");
  		if(seguro){
  			this.list_depas=this.list_depas.filter(ent=>ent.Data.departamento_id!=data.departamento_id);
  		console.log(this.newEntregable);
  		}
  		
  }



  listPuesto:any=[];


   addListPuesto(form){

    if(form.form.value.entregable!=""){
        let id=this.puestos.find(entr=>entr.Data.nombre==form.form.value.entregable);

        this.listPuesto.push(id);

        $('#AgregarPuesto').modal('hide');

        alert("Se agrego puesto con éxito");

        form.resetForm();
       	
    }else{
      alert("Es necesario seleccionar un puesto para continuar");
    }


   
  }



  quitarListPuesto(data){
  			console.log(data);
  		let seguro=confirm("¿Seguro que desas quiatar este Puesto?");
  		if(seguro){
  			this.listPuesto=this.listPuesto.filter(ent=>ent.Data.rol_id!=data.rol_id);
  		console.log(this.newEntregable);
  		}
  		
  }

   addListColaborador(form){
     console.log(form.form.value);
    if(form.form.value.entregable!=""){
        let id=this.colaboradores.find(entr=>entr.Data.nombre==form.form.value.entregable);

        this.listColaboradores.push(id);

        $('#AgregarColaborador').modal('hide');

        alert("Se agrego Colaborador con éxito");

        form.resetForm();
         
    }else{
      alert("Es necesario seleccionar un Colaborador para continuar");
    }

  }


  quitarListCola(data){
        console.log(data);
      let seguro=confirm("¿Seguro que desas quiatar este Colaborador?");
      if(seguro){
        this.listColaboradores=this.listColaboradores.filter(ent=>ent.Data.id+ent.Data.nombre!=data.id+data.nombre);
      
      }
      
  }





  cerrarCrearFuncion(form){

    console.log(form.form.value);
    form.resetForm();
    $('#insertFuncion').modal('hide');

    this.listColaboradores=[];
    this.listPuesto=[];
    this.list_depas=[];
    this.newEntregable=[];
    this.listaFechas=[];
    this.newEntregable_name="";

    this.getFunciones();

  }


  creandoFun(form){

    if(form.valid){
      this.crandoFuncion=true;
      this.createFun(form);
    }else{
      alert("Para poder crear Una Nueva Función es necesario que llenes los datos que tiene un * Rojo");
    } 
  }

  async createFun(form){


    form.form.value.departamento_id="";
    form.form.value.user_id=this.me_id;
    form.form.value.rol="";
    form.form.value.tipo="fi";

    let resut:any=[];

   let res=await this.services.postActividad(form.form.value); 

   res.subscribe(res=>{
     resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
    

     this.set_insert_Entregables(resut,form);
     this.getFunciones();
      form.resetForm();
   });

  }

  set_insert_Entregables(actividad_id,form){
    for(let entregables of this.newEntregable)
    {
        this.services.insertEntre_act(
        actividad_id,
        entregables.Data.entregable_id).subscribe(res=>{
        
      });
    }


      if(this.paraTodos){
        this.paraTodosAdd(actividad_id,form);
     }else{

       this.liagarPorDepartamento(actividad_id);
      }
      
    

  }


  paraTodosAdd(actividad_id,form){

       for(let depa of this.departamentos){
         this.services.miColbyDep(depa.Data.departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

         });
           
      }
      this.crandoFuncion=false;
      this.cerrarCrearFuncion(form);
      alert("se Creo Función con Éxito");

  }

  liagarPorDepartamento(actividad_id){

      for(let depa of this.list_depas){
         this.services.miColbyDep(depa.Data.departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

         })
           
      }

      this.ligarPorPuesto(actividad_id);
    
  }

   ligarPorPuesto(actividad_id){

     for(let puesto of this.listPuesto){
        this.services.getColbyPuesto(puesto.Data.rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

        });
     }

     this.ligarporcolaborador(actividad_id);
     
   }

   ligarporcolaborador(actividad_id){
       for(let cola of this.listColaboradores){
                 this.services.postAct_col(cola.Data.id+cola.Data.nombre,actividad_id).subscribe(res=>{
                 
               });
             }

      this.crandoFuncion=false;
         alert("se Creo Función con Éxitos");

          $('#insertFuncion').modal('hide');

      this.listColaboradores=[];
      this.listPuesto=[];
      this.list_depas=[];
      this.newEntregable=[];
      this.listaFechas=[];
      this.newEntregable_name="";




   }

   funcionUp:any=[];
   entregablesUp:any=[];


   setFuncionUP(funcion){
     console.log(funcion);
     this.funcionUp=funcion;
     this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
       console.log(res);
       res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
     });
   }


   cerrarModalFuncioUP(form){

     this.getFunciones();
    form.resetForm();
    $('#Actualizar_fun').modal('hide');
      this.funcionUp=[];
      this.entregablesUp=[];
    

  }

  dep_by_act:any=[];
  dep_by_act_old:any=[];

  openDepasByAct(){
    this.services.getdepByAct(this.funcionUp.actividad_id).subscribe(res=>{
      
       res!=null?this.dep_by_act=JSON.parse(JSON.stringify(res)).Datas:null;
       for(let deps of this.dep_by_act){
          console.log(deps.Data.departamento_id);
          if(deps.Data.departamento_id!='0' && deps.Data.departamento_id!=0){
             deps.Data.departamento=this.departamentos.find(depa=>depa.Data.departamento_id==deps.Data.departamento_id);
          }else{
            deps.Data.departamento={Data:{titulo:'Departamento no Asignado'}}
          }
       }
       this.dep_by_act_old=this.dep_by_act;
    });
  }



  filtroDeps_act(ev){
    if (ev=='') {
       this.dep_by_act=this.dep_by_act_old;

      }else{
        this.dep_by_act=this.dep_by_act_old;

        let val = ev.target.value;
        
        
        if (val && val.trim() != '') {
        this.dep_by_act = this.dep_by_act.filter((item) => {
          return (item.Data.departamento.Data.titulo.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.dep_by_act=this.dep_by_act_old;
      }
    }
  }

  eliminarActByDEpa(depa){
    console.log(depa);

    let seguro=confirm("¿Seguro que desas continuar?, estas apunto de quitar esta Función a todos los integrantes del departamento.");
    if (seguro) {
       this.services.miColbyDep(depa.departamento.Data.departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             console.log(res);
             for(let cola of colaboradores){
               
               this.services.Delete_Act_Col(cola.Data.id_nombre,depa.actividad_id).subscribe(res=>{
         
               });
             }

             this.openDepasByAct();
             alert("Se quito Función con éxito de Depertamento");
           }
         });
    }
  }

  cerrarModalFuncioUPDescripcion(){
      this.dep_by_act=[];
      this.dep_by_act_old=[];
      
      $('#ver_fun_depa').modal('hide');


  }

  addADepasAfter(form){
    console.log(form.form.value);
    console.log(this.funcionUp.actividad_id);
    let departamento_id=this.departamentos.find(dep=>dep.Data.titulo==form.form.value.nombre).Data.departamento_id;
    console.log(departamento_id);
    
    this.services.miColbyDep(departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
         
               });
             }
             this.openDepasByAct();

           }

         });

      form.resetForm();
      alert("Se agrego Función con éxito");

      $('#AgregarDepartamento_after').modal('hide');
  }



  puesto_by_act=[];
  puesto_by_old=[];

   cerrarModalFuncioUPPuesto(){
      this.puesto_by_act=[];
      this.puesto_by_old=[];
      
      $('#ver_fun_puesto').modal('hide');


  }

  
  openPuestoByAct(){
      this.puesto_by_act=[];
      this.puesto_by_old=[];
     this.services.getpuestoByAct(this.funcionUp.actividad_id).subscribe(res=>{
       console.log(res);
       
       res!=null?this.puesto_by_act=JSON.parse(JSON.stringify(res)).Datas:null;
       for(let puesto of this.puesto_by_act){

           puesto.Data.puesto=this.puestos.find(depa=>depa.Data.rol_id==puesto.Data.rol_id);

       }

       console.log(this.puesto_by_act);
       this.puesto_by_old=this.puesto_by_act;
    });

  }


    eliminarActByPuesto(puesto){
    console.log(puesto);

    let seguro=confirm("¿Seguro que quieres continuar?, estas apunto de quitar esta Función a todos los integrantes del departamento.");
    if (seguro) {

      this.services.getColbyPuesto(puesto.puesto.Data.rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             console.log(colaboradores)
             
             for(let cola of colaboradores){
                 this.services.Delete_Act_Col(cola.Data.id_nombre,puesto.actividad_id).subscribe(res=>{
         
               });
             }
           }

           this.openPuestoByAct();
           alert("Se quito Funcion con éxito de Puesto");

        });
      
    
    }
  }

  addAPuestoAfter(puesto){
    console.log(puesto.form.value)

     let rol_id=this.puestos.find(dep=>dep.Data.nombre== puesto.form.value.nombre).Data.rol_id;
      console.log(rol_id);

      this.services.getColbyPuesto(rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
         
               });
             }
           }

           this.openPuestoByAct();
           alert("Se agrego Funcion con éxito de Puesto");
            $('#AgregarPuesto_after').modal('hide');
        });
       


  }


  filtroPuesto_act(ev){
      console.log(this.puesto_by_act);
    if (ev=='') {
       this.puesto_by_act=this.puesto_by_old;

      }else{
        this.puesto_by_act=this.puesto_by_old;

        let val = ev.target.value;
        
        
        if (val && val.trim() != '') {
        this.puesto_by_act = this.puesto_by_act.filter((item) => {
          return (item.Data.puesto.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.puesto_by_act=this.puesto_by_old;
      }
    }
  }


  cola_by_act=[];
  cola_by_act_old=[];


  openColaByAct(){
    this.cola_by_act=[];
    this.cola_by_act_old=[];
    this.services.getcolByAct(this.funcionUp.actividad_id).subscribe(res=>{
      res!=null?this.cola_by_act=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(res);
      this.cola_by_act_old=this.cola_by_act;
    });

  }

  filtroCol_act(ev){
      console.log(this.cola_by_act_old);
    if (ev=='') {
       this.cola_by_act=this.cola_by_act_old;

      }else{
        this.cola_by_act=this.cola_by_act_old;

        let val = ev.target.value;
        
        
        if (val && val.trim() != '') {
        this.cola_by_act = this.cola_by_act.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.cola_by_act=this.cola_by_act_old;
      }
    }
  }


  eliminarActByCol(colabordaro){


    console.log(colabordaro);

     let seguro=confirm("¿Seguro que quieres continuar?, estas apunto de quitar esta Función a este Colaborador.");
    if (seguro) {
      this.services.Delete_Act_Col(colabordaro.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
               this.openColaByAct();
               alert("Se quito Funcion con éxito de Colaborador");
            
           });
    }
  }


  cerrarModalFuncioUPCol(){
    this.cola_by_act=[];
    this.cola_by_act_old=[];
    $('#ver_fun_colaborador').modal('hide');
  }

  addColAfter(col){
    
  }


  addListEntregableoldAfter(form){
      console.log(form.form.value.entregable);

      let entregable_id=this.entregables.find(entre=>entre.Data.titulo==form.form.value.entregable).Data.entregable_id;
       console.log(entregable_id);
         this.services.insertEntre_act(
          this.funcionUp.actividad_id,
           entregable_id).subscribe(res=>{
            this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
               console.log(res);
               res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
               form.resetForm();
                $('#AgregarEntregablesAfter').modal('hide');
             });
        });
  }

  quitarEntregableAfter(entregable){
    console.log(entregable);
    let seguro=confirm("¿Seguro que quieres quitar este Entregable?");
    if (seguro) {
        this.services.deleteentreAct(entregable.ent_act_id).subscribe(res=>{
          this.entregablesUp=[];
          this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
             res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
               
             });
        });
    }
  }


  actualizarDatos(form){
    form.form.value.id=this.funcionUp.actividad_id;
     this.services.updateActividad(form.form.value).subscribe(res=>{
       alert("Se actualizaron datos correctamente");
     });
  }

elimiarActividad(){
  let seguro=confirm("¿Seguro que quiere Eliminar esta Función? al continuar se afectara a todos los Colaboradores que tengan esta Función.");
  if (seguro) {
      this.services.delete_Acividad(this.funcionUp.actividad_id).subscribe(res=>{
        console.log("Se Elimino Funcion con Éxito");
         $('#Actualizar_fun').modal('hide');
         this.getFunciones();
      });
  }
}



//para clonar XD
  myPuestosDep:any=[];

  getMyPuestos(){
    this.services.getPuestosBydep(this.me.departamento_id).subscribe(r=>{
      r!=null?this.myPuestosDep=JSON.parse(JSON.stringify(r)).Datas:null;
      console.log(this.myPuestosDep);
    });
  }

  myPuestosDep_list:any=[];

  add_pre_list_puesto(form){

         console.log(form.form.value.nombre);

      if(form.form.value.nombre !="" ){
        if(form.form.value.nombre ==="Seleccionar Todos"){
          this.myPuestosDep_list=this.myPuestosDep;
        }else{
           let id=this.myPuestosDep.find(entr=>entr.Data.nombre==form.form.value.nombre);

          this.myPuestosDep_list.push(id);

         
          console.log(this.myPuestosDep_list);
          alert("Se agrego puesto con éxito");

          form.resetForm();
          form.form.value.nombre="";
        }
        
         
    }else{
      alert("Es necesario seleccionar un puesto para continuar");
    }
  }

  quitarList_Puesto_pre(data){
        console.log(data);
      let seguro=confirm("¿Seguro que desas quiatar este Puesto?");
      if(seguro){
        this.myPuestosDep_list=this.myPuestosDep_list.filter(ent=>ent.Data.rol_id!=data.rol_id);
      console.log(this.myPuestosDep_list);
      }
      
  }


  async createFun_clon(){  
    let newClone=this.funcionUp;
    newClone.user_id=this.me_id;
    newClone.tipo="fp";
    let resut:any;
    let res=await this.services.postActividad(newClone); 
    res.subscribe(res=>{
      resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
      this.set_insert_Entregables_clon(resut,newClone);
    });

  }

  set_insert_Entregables_clon(actividad_id,form){

    for(let entregables of this.entregablesUp){

        this.services.insertEntre_act(
        actividad_id,
        entregables.Data.entregable_id).subscribe(res=>{});

    }
    this.ligarPorPuesto_clon(actividad_id);
  }


  ligarPorPuesto_clon(actividad_id){

     for(let puesto of this.myPuestosDep_list){

        this.services.getCol_dep_rol(puesto.Data.rol_id,this.me.departamento_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

        });


     }

     alert("Se clono Función con éxito.")
   }



  async clonarAmiDep(){
      let newClone=this.funcionUp;
    newClone.user_id=this.me_id;
    newClone.tipo="fd";
    let resut:any;
    let res=await this.services.postActividad(newClone); 
    res.subscribe(res=>{
      resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
      this.set_insert_Dep_clon(resut);
    });
  }

  set_insert_Dep_clon(actividad_id){
    this.services.miColbyDep(this.me.departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

         })

    alert("Se clono Función con éxito.");
  }  


  
     creandoFun2(form){
     let seguro = confirm("¿Seguro que desas crear esta Función para Todos los Puesto?")
    if(seguro){
      this.paraTodos=true;
      if(form.valid){
        this.crandoFuncion=true;
        this.createFun(form);
      }else{
        alert("Para poder crear Una Nueva Función es necesario que llenes los datos que tiene un * Rojo");
      }
     }
   }

}
