import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { DashboardService } from '../services/dashboard.service';
import { ConfiguradorService } from '../services/configurador.service';



@Component({
  selector: 'app-demo-dashbord',
  templateUrl: './demo-dashbord.component.html',
  styleUrls: ['./demo-dashbord.component.css'],
  providers:[DashboardService,ConfiguradorService]
})
export class DemoDashbordComponent implements OnInit {
  public dinero:any[]=new Array ();
  public tipo:any[]=new Array ();
  public total_diario:number=0;
  public total_semanal:number=0;
  public total_mensual:number=0;
  public tp_alimentos=0;
  public tp_vinos=0;
  public tp_bebias=0;
  public tp_licores=0;
  public tp_postres=0;
  public tp_cerveza=0;
  public tp_semana_alimentos=0;
  public tp_semana_vinos=0;
  public tp_semana_bebias=0;
  public tp_semana_licores=0;
  public tp_semana_postres=0;
  public tp_semana_cerveza=0;
  public tp_mes_alimentos=0;
  public tp_mes_vinos=0;
  public tp_mes_bebias=0;
  public tp_mes_licores=0;
  public tp_mes_postres=0;
  public tp_mes_cerveza=0;

  public labels:any[]=new Array ();
  public arr_datos:any[]=new Array ();
  public arr_datos_semana:any[]=new Array ();
  public arr_datos_mes:any[]=new Array ();

  public indicador:any=0;
  public tipo_vista:any=0;
  public alimentos:boolean;
  public bebidas:boolean;
  public vinos:boolean;
  public licores:boolean;
  public postres:boolean;
  public cerveza:boolean;
  public efectivo:boolean;
  public credito:boolean;
  public tarjeta_credito:boolean;
  public tarjeta_debito:boolean;
  public datos:any;
  public cargando=false;
  public exito=false;


  constructor( private _servers:DashboardService, private _config:ConfiguradorService) { }

  //Para Acomodar los numeros sobre la graficas.
   public barChartOptions: ChartOptions = {

    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        color:'#000000FF',
        font: {
          size: 12,
        }
        
      }
    }
  };
  public paiOpcion: ChartOptions = {

    responsive: true,

    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'center',
        color:'#000000FF',
        font: {
          size: 15,
        }
        
      }
    }
  };
  public barChartPlugins = [pluginDataLabels];

  public pieChartLabels = ['Ventas 60', 'Perdias 10', 'En Caja 30'];
  public pieChartData = [60, 10, 30];
  public pieChartType = 'pie';
  

  public pieChartLabels1 = ['Ventas', 'Perdias', 'En Caja'];
  public pieChartData1 = [75, 5, 20];
  public pieChartType1 = 'pie';
  public chartColors: any[] = [
      { 
        backgroundColor:["#5CBC42FF", "#B12B1FFF", "#475EB2FF","#FFD800FF","#4800FFFF","#A0A0A0FF"] 
      }];

  public barChartLabels: Label[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  
 public barColors: any[] = [
      { 
        backgroundColor:["#5CBC42FF", "#B12B1FFF", "#475EB2FF","#FFD800FF","#4800FFFF","#A0A0A0FF"] 
      },
      {
        backgroundColor:"#B12B1FFF" 
      }];



  ngOnInit() {
    this._config.getData().subscribe(
      result=>{
        console.log(result);
        var eco = JSON.stringify(result);
        var json = JSON.parse(eco);
        this.datos=json.Configuraciones[0].Configuracion;
        
        console.log(this.datos.indicador);
        this.indicador=this.datos.indicador;
        this.tipo_vista=this.datos.pos;
        this.alimentos=this.datos.alimentos==1?true:false;
        this.bebidas=this.datos.bebidas==1?true:false;
        this.vinos=this.datos.vinos==1?true:false;
        this.licores=this.datos.licores==1?true:false;
        this.postres=this.datos.postres==1?true:false;
        this.cerveza=this.datos.cerveza==1?true:false;
        this.efectivo=this.datos.efectivo==1?true:false;
        this.credito=this.datos.credito==1?true:false;
        this.tarjeta_credito=this.datos.tarjeta_credito==1?true:false;
        this.tarjeta_debito=this.datos.tarjeta_debito==1?true:false;
 
      },
      error =>{
        console.log(<any>error);
      }
      );
    
  	
  }

}
