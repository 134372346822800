import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params,NavigationEnd } from '@angular/router';
import { CatalogosService } from '../services/catalogos.service';
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-catalogos',
  templateUrl: './catalogos.component.html',
  styleUrls: ['./catalogos.component.css'],
  providers:[CatalogosService]
})
export class CatalogosComponent implements OnInit {
  nombre:any;
  type:any;
  status_venta:any=[];
  status_inmueble:any=[];
  status_pagos:any=[];
  documentos:any=[];
  tipo_contratos:any=[];
  tipo_creditos:any=[];
  tipo_descuentos:any=[];
  tipo_gastos:any=[];
  tipo_inmuebles:any=[];
  tipo_pagos:any=[];
  esquemas:any=[];
  esquemas_ids:any=[];
  id_update:any;
  descrip:any;
  titulo:any;
  esquema_id:any;
  desarrollos:any=[];

  iftipos=false;
  ifPagos=false;
  ifDesarrollos=false;


  constructor(private actRoute:ActivatedRoute ,private router:Router, private servicios:CatalogosService) {
  this.status_venta=null;
  this.status_inmueble=null;
  this.status_pagos=null;
  this.documentos=null;
  this.tipo_contratos=null;
  this.tipo_creditos=null;
 this.tipo_descuentos=null;
  this.tipo_gastos=null;
  this.tipo_inmuebles=null;
  this.tipo_pagos=null;
  this.esquemas=null;
  this.esquemas_ids=null;
  
 this.desarrollos=null;
    this.actRoute.params.subscribe((data:Params)=>{
      switch (data.catalogo) {
        case  '1':
            this.type=data.catalogo;
            this.nombre="Estatus Ventas";
            this.servicios.getCatalogo("status_ventas").subscribe(result=>{
               console.log(result);
               if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.status_venta=temp2.Datas;
               }
            });

          break;
          case  '2':
            this.type=data.catalogo;
            this.nombre="Estatus Inmuebles";
            this.servicios.getCatalogo("status_inmubles").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.status_inmueble=temp2.Datas;
               }
            });
          break;
          case  '3':

            this.type=data.catalogo;
            this.nombre="Estatus Pagos";
            this.servicios.getCatalogo("status_pagos").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.status_pagos=temp2.Datas;
               }
            });
          break;
          case  '4':
            this.iftipos=true;
            this.type=data.catalogo;
            this.nombre="Tipos de Contratos";
            this.servicios.getCatalogo("tipos_contratos").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.tipo_contratos=temp2.Datas;
               }
            });
          break;
          case  '5':
            this.iftipos=true;
            this.type=data.catalogo;
            this.nombre="Tipos de Creditos";
            this.servicios.getCatalogo("tipos_creditos").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.tipo_creditos=temp2.Datas;
               }
            });
          break;
          case  '6':
            this.iftipos=true;
            this.type=data.catalogo;
            this.nombre="Tipos de Descuentos";
            this.servicios.getCatalogo("tipos_descuentos").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.tipo_descuentos=temp2.Datas;
               }
            });
          break;
          case  '7':
            this.iftipos=true;
            this.type=data.catalogo;
            this.nombre="Tipos de Gastos";
            this.servicios.getCatalogo("tipos_gastos").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.tipo_gastos=temp2.Datas;
               }
            });
          break;
          case  '8':
            this.iftipos=true;
            this.type=data.catalogo;
            this.nombre="Tipos de Inmuebles";
            this.servicios.getCatalogo("tipos_inmuebles").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.tipo_inmuebles=temp2.Datas;
               }
            });
          break;
           case  '9':
            this.iftipos=true;
            this.ifPagos=true;
            this.type=data.catalogo;
            this.nombre="Tipos Pagos";
            this.servicios.getCatalogo("tipos_pagos").subscribe(result=>{
               //console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.tipo_pagos=temp2.Datas;
               }
            });
            this.servicios.getCatalogo("esquemas").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.esquemas_ids=temp2.Datas;
               }
            });
          break;
          case  '10':
            this.iftipos=true;
            this.type=data.catalogo;
            this.nombre="Tipos Esquemas";
            this.servicios.getCatalogo("esquemas").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.esquemas=temp2.Datas;
               }
            });
          break;
           case  '11':
            
            this.type=data.catalogo;
            this.nombre="Documentos fisicos";
            this.servicios.getCatalogo("documentos").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.documentos=temp2.Datas;
                 console.log(this.documentos);
               }
            });
          break;
           case  '12':
            this.ifDesarrollos=true;
            
            this.nombre="Desarrollos";
            this.servicios.getCatalogo("desarrollos").subscribe(result=>{
               
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.desarrollos=temp2.Datas;
                 console.log(this.desarrollos);
               }
            });
          break;
        
        default:
          // code...
          break;
      }
          
    });

  }
  ngOnInit() {
    
  }
  setid(id,des){
    this.id_update=id;
    this.descrip=des;
  }

   setid2(id,tit,des){
    this.id_update=id;
    this.descrip=des;
    this.titulo=tit;
  }
    setid3(id,tit,des,esquema_id){
    this.id_update=id;
    this.descrip=des;
    this.titulo=tit;
    this.esquema_id=esquema_id;
    
  }
  setiddelete(id){
    this.id_update=id;
  }

  postStatus(form){
   
    switch (this.type) {
      case "1":
      this.servicios.postCatalogo('status_ventas','status_venta_id',form.form.value.status).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
      case "2":
      this.servicios.postCatalogo('status_inmubles','status_inmueble_id',form.form.value.status).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "3":
      this.servicios.postCatalogo('status_pagos','status_pago_id',form.form.value.status).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "4":
      this.servicios.postCatalogoTipos('tipos_contratos','tipo_contrato_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "5":
      this.servicios.postCatalogoTipos('tipos_creditos','tipo_credito_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "6":
      this.servicios.postCatalogoTipos('tipos_descuentos','tipo_descuento_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "7":
     this.servicios.postCatalogoTipos('tipos_gastos','tipo_gasto_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "8":
      this.servicios.postCatalogoTipos('tipos_inmuebles','tipo_inmueble_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
         case "9":
          this.servicios.postTiposPagos(form.form.value.nombre,form.form.value.descripcion,form.form.value.id_indicador).subscribe(result=>{
            location.reload();
          },error=>{
            console.log(error);
          });
        break;
         case "10":
      this.servicios.postCatalogoTipos('esquemas','esquema_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
           case "11":
       this.servicios.postCatalogo('documentos','documento_id',form.form.value.status).subscribe(result=>{
        location.reload();
      },error=>console.log(error));   
        break;
      
      default:
        // code...
        break;
    }
  }

  putStatus(form){
   
    switch (this.type) {
      case "1":
      this.servicios.putCatalogo('status_ventas','status_venta_id',form.form.value.status,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
      case "2":
      this.servicios.putCatalogo('status_inmubles','status_inmueble_id',form.form.value.status,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "3":
      this.servicios.putCatalogo('status_pagos','status_pago_id',form.form.value.status,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "4":
      this.servicios.putCatalogoTipos('tipos_contratos','tipo_contrato_id',form.form.value,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "5":
      this.servicios.putCatalogoTipos('tipos_creditos','tipo_credito_id',form.form.value,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "6":
      this.servicios.putCatalogoTipos('tipos_descuentos','tipo_descuento_id',form.form.value,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "7":
      this.servicios.putCatalogoTipos('tipos_gastos','tipo_gasto_id',form.form.value,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "8":
      this.servicios.putCatalogoTipos('tipos_inmuebles','tipo_inmueble_id',form.form.value,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "9":
      this.servicios.putTiposPagos(form.form.value,this.id_update).subscribe(result=>{
         location.reload();
      },error=>console.log(error));  
        break;
         case "10":
      this.servicios.putCatalogoTipos('esquemas','esquema_id',form.form.value,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "11":
      this.servicios.putCatalogo('documentos','documento_id',form.form.value.status,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
      
      default:
        // code...
        break;
    }

  }


  deleteCatlogo(){
    alert(this.id_update);
    switch (this.type) {
      case "1":
      this.servicios.deleteCatalogo('status_ventas','status_venta_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
      case "2":
      this.servicios.deleteCatalogo('status_inmubles','status_inmueble_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "3":
      this.servicios.deleteCatalogo('status_pagos','status_pago_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "4":
      this.servicios.deleteCatalogo('tipos_contratos','tipo_contrato_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "5":
      this.servicios.deleteCatalogo('tipos_creditos','tipo_credito_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "6":
      this.servicios.deleteCatalogo('tipos_descuentos','tipo_descuento_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "7":
      this.servicios.deleteCatalogo('tipos_gastos','tipo_gasto_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "8":
      this.servicios.deleteCatalogo('tipos_inmuebles','tipo_inmueble_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
        case "9":
      this.servicios.deleteCatalogo('tipos_pagos','tipo_pago_id',this.id_update).subscribe(result=>{
         location.reload();
      },error=>console.log(error));  
        break;
         case "10":
      this.servicios.deleteCatalogo('esquemas','esquema_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
        break;
      
      default:
        // code...
        break;
    }
  }

  

}
