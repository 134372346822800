import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-add-conyugue',
  templateUrl: './add-conyugue.component.html',
  styleUrls: ['./add-conyugue.component.css']
})
export class AddConyugueComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  	if (sessionStorage.getItem('token')==null) {
      this.router.navigate(["/login"]);
    }
  }

}
