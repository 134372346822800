import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, Params } from "@angular/router";
import { UsersService } from "../services/users.service";

@Component({
  selector: 'app-only',
  templateUrl: './only.component.html',
  styleUrls: ['./only.component.css']
})
export class OnlyComponent implements OnInit {

 status:any;
 menu:any;
 isCollapsed:any=true;
 id:any;

 constructor(private router:Router, private ar:ActivatedRoute,private services : UsersService) { }

  ngOnInit() {

  this.id=sessionStorage.getItem('id');
    console.log(sessionStorage.getItem('status'));
  	if(sessionStorage.getItem('id')!=null){
        this.menu=localStorage.getItem('page');
        this.status=sessionStorage.getItem('status');

        
    }else{
      this.router.navigate(["login"]);
    }
  }

  irlvl(lvl){
   this.menu=lvl;
   localStorage.setItem('page',lvl);
   
  }


  	logout(){
    sessionStorage.clear();
    localStorage.clear();
    location.reload();
  }



}
