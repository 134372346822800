import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class InmuebleService{
	public url="http://67.205.145.224/durango/dashboard_api.php?type=";
	constructor(private http: HttpClient){}

	getInmuebles(){
		return this.http.get(this.url+"sc_getInmuebles");
	}

	getCatalogo(catalogo){
		return this.http.get(this.url+"sc_getCatalogo&tabla="+catalogo);
	}


	postInmuebles(data){
		return this.http.get(this.url+"sc_postInmueble&titulo="+data.titulo
			+"&descripcion="+data.descripcion+"&calle="+data.calle+"&lote="+data.lote+"&num_ext="+data.num_ext
			+"&num_int="+data.num_int+"&manzana="+data.manzana+"&desarrollo_id="+data.desarrollo_id
			+"&status_venta_id="+data.status_venta_id+"&status_inmueble_id="+data.status_inmueble_id+
			"&valor_vivienda="+data.valor_vivienda+"&tipo_inmueble_id="+data.tipo_inmueble_id);
	}

	getInmueble(id){
		return this.http.get(this.url+"sc_getInmueble&id="+id);
	}

	putInmuebles(data){
		return this.http.get(this.url+"sc_putInmueble&titulo="+data.titulo
			+"&descripcion="+data.descripcion+"&calle="+data.calle+"&lote="+data.lote+"&num_ext="+data.num_ext
			+"&num_int="+data.num_int+"&manzana="+data.manzana+"&desarrollo_id="+data.desarrollo_id
			+"&status_venta_id="+data.status_venta_id+"&status_inmueble_id="+data.status_inmuble_id+
			"&valor_vivienda="+data.valor_vivienda+"&tipo_inmueble_id="+data.tipo_inmueble_id+"&id="+data.id);
	}





}