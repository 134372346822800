import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css']
})
export class ParametrosComponent implements OnInit {
		ifPass=false;
		page1=false;
		page2=false;
		page_now="Parametros";
    type_graficas=[];
  user:any;

  catCods=[];
  catCod=[];
  catCod_id="";
  categorias=[];

  clasificadores:any=[];
  subclasificadores:any=[];
  prodServers:any=[];
  num_seg=1;

  segmetos_principal:any=[];
  segmetos_seccion_1:any=[];

  part1:any=[];
  encabezado:any=[];

  data1:any=[];
  num_seg_data1=0;


  segementos:any=[];
  upParte:any=[];

  list_segmentos_data1:any=[];

  data2:any=[];
  list_segmentos_data2:any=[];
  num_seg_data2=0;

  data3:any=[];
  list_segmentos_data3:any=[];
  num_seg_data3=0;

  data4:any=[];
  list_segmentos_data4:any=[];
  num_seg_data4=0;
  updateSegmento=1;

  constructor(private service:UsersService) { }

  ngOnInit(){
    this.updateSegmento=1;
  		this.user=sessionStorage.getItem("id");
  		
      this.clearVar();
      this.getConfigs();

      this.getClasificadores();
      this.getSubClasificadores();
      this.getprodsServers();

      this.service.getTypeGraficas().subscribe(res=>{
        res!=null? this.type_graficas=JSON.parse(JSON.stringify(res)).Datas:null;
      });

     // $('#step2_create_inidcador').modal('show');
        

  }

  clearVar(){
    this.part1={
      nombre:'',
      num_secciones:'',
      titulo:'',
      unidad:''
    };

    this.data1={
      clasificador:'',
      subclasificador:'',
      algoritmo:'',
      prod:''
    }
    this.data2={
      titulo:'',
      tipo_grafica:'',
      unidad:'',
      clasificador:'',
      subclasificador:'',
      algoritmo:'',
      prod:'',
      num_seg:0
    }
    this.data3={
      titulo:'',
      tipo_grafica:'',
      unidades:'',
      clasificador:'',
      subclasificador:'',
      algoritmo:'',
      prod:'',
      num_seg:0
    }

    this.data4={
      titulo:'',
      tipo_grafica:'',
      unidad:'',
      clasificador:'',
      subclasificador:'',
      algoritmo:'',
      prod:'',
      num_seg:0
    }

    this.list_segmentos_data2=[];
    this.num_seg_data2=1;
    this.list_segmentos_data3=[];
    this.num_seg_data3=1;
    this.list_segmentos_data4=[];
    this.num_seg_data4=1;

    this.list_segmentos_data1=[];
    this.num_seg_data1=1;

  }

  

  crear_indicador(){
    /*
    console.log(this.part1);
    console.log(this.encabezado);
    console.log(this.data1);
    console.log(this.list_segmentos_data1);
    console.log("_____________seg1__________________");
    console.log(this.data2);
    console.log(this.list_segmentos_data2);
    console.log("_____________seg2__________________");
    console.log(this.data3);
    console.log(this.list_segmentos_data3);
    console.log("_____________seg3__________________");
    console.log(this.data4);
    console.log(this.list_segmentos_data4);*/
    this.createSql_Indicator(this.part1);


  }

  addSegmento(){
    this.num_seg++;
  }

  addSegemntoData1(form){
    form.form.value.num_seg=this.num_seg_data1++;
    this.list_segmentos_data1.push(form.form.value); 
    $('#add_Segmento_data_principal').modal('hide');
    form.resetForm();
  }

   addSegemntoData2(form){
    form.form.value.num_seg=this.num_seg_data2++;
    this.list_segmentos_data2.push(form.form.value); 
    $('#add_Segmento_data_2').modal('hide');
    form.resetForm();
  }

  addSegemntoData3(form){
    form.form.value.num_seg=this.num_seg_data3++;
    this.list_segmentos_data3.push(form.form.value); 
    $('#add_Segmento_data_3').modal('hide');
    form.resetForm();
  }

  addSegemntoData4(form){
    form.form.value.num_seg=this.num_seg_data4++;
    this.list_segmentos_data4.push(form.form.value); 
    $('#add_Segmento_data_4').modal('hide');
    form.resetForm();
  }

  cerrarSegmentoData1(form){

    $('#add_Segmento_data_principal').modal('hide');
    form.resetForm();
  }

   cerrarSegmentoData2(form){

    $('#add_Segmento_data_2').modal('hide');
    form.resetForm();
  }

  cerrarSegmentoData3(form){

    $('#add_Segmento_data_3').modal('hide');
    form.resetForm();
  }
  cerrarSegmentoData4(form){

    $('#add_Segmento_data_4').modal('hide');
    form.resetForm();
  }

  eliminarsegmentoData1(segmento){
    let confirma=confirm('¿Seguro que quieres quitar este Segmento?');
    this.list_segmentos_data1=this.list_segmentos_data1.filter(seg=> seg.num_seg!=segmento.num_seg);
    $('#Data1UpdateSegmento').modal('hide');
  }

  eliminarsegmentoData2(segmento){
    let confirma=confirm('¿Seguro que quieres quitar este Segmento?');
    $('#Data2UpdateSegmento').modal('hide');
    this.list_segmentos_data2=this.list_segmentos_data2.filter(seg=> seg.num_seg!=segmento.num_seg);
  }

   cerrarUPSeg_data2(){
    $('#Data2UpdateSegmento').modal('hide');
    
  }

  eliminarsegmentoData3(segmento){
    let confirma=confirm('¿Seguro que quieres quitar este Segmento?');
    $('#Data3UpdateSegmento').modal('hide');
    this.list_segmentos_data3=this.list_segmentos_data3.filter(seg=> seg.num_seg!=segmento.num_seg);
  }

  eliminarsegmentoData4(segmento){
    let confirma=confirm('¿Seguro que quieres quitar este Segmento?');
    $('#Data4UpdateSegmento').modal('hide');
    this.list_segmentos_data4=this.list_segmentos_data4.filter(seg=> seg.num_seg!=segmento.num_seg);
  }

   cerrarUPSeg_data3(){
    $('#Data3UpdateSegmento').modal('hide');
    
  }
  cerrarUPSeg_data4(){
    $('#Data4UpdateSegmento').modal('hide');
    
  }




  cerrarStep1_create(){
    this.clearVar();
    $('#step1_create_inidcador').modal('hide');
  }
  cerrarStep2_create(){
    $('#step2_create_inidcador').modal('hide');
  }
  canCelarSegm(){
    $('#addSegmento').modal('hide');
    this.num_seg=this.num_seg-1;
  }

   canCelarSegmAfter(){
    $('#addSegmentoAfter').modal('hide');
    
  }

  setUpPart(parte){
    this.upParte=parte;
    console.log(this.upParte);
  }

  setUpdateParteAfter(parte){
    this.upParte=parte;
    console.log(this.upParte);
  }

  guardarUPAfterSegmente(){

     this.service.updateSegementosAfter(this.upParte).subscribe(res=>{
       console.log(res);
       $('#afterpartUpdateSegmento').modal('hide');
       alert("Se Actualizo el segmento.");
     });
  }

  eliminarsegmentoAfter(){
    let seguro = confirm("¿Seguro que quieres quitar este segmentos?");
    if (seguro) {
      this.service.deleteSegmentAfter(this.upParte.segmento_id).subscribe(res=>{
        $('#afterpartUpdateSegmento').modal('hide');
        this.setSeccion(this.seccionUp); 
        alert("Se Elimino el Segmento.");

      });
    }
  }

  eliminarSeccion(){
    let  seguro=confirm("¿Seguro que quieres Eliminar esta sección?");
    if (seguro) {
          this.service.delete_secciones(this.seccionUp.seccion_id).subscribe(res=>{
          this.setCofigUpdate(this.config_upRes);
          this.updateSegmento=1;
      });
    }
  }



  cerrarUPSeg_data1(){
    $('#Data1UpdateSegmento').modal('hide');
    
  }

   cerrarUPSegAfter(){
    $('#afterpartUpdateSegmento').modal('hide');
    
  }

  eliminarsegmento(){
    this.segementos=this.segementos.filter(d=>d.num_seg != this.upParte.num_seg);
  }

  getClasificadores(){

    this.service.getclasificadores().subscribe(res=>{
      res!=null?this.clasificadores=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.clasificadores);
    });
  }

  addpartSegemnto(form){
    
    form.form.value.num_seg=this.num_seg;
   


    this.segementos.push(form.form.value);
     console.log(this.segementos);
     $('#addSegmento').modal('hide');
     form.resetForm();
  }


  addpartSegemntoAfter(form){
    console.log(this.list_up[this.list_up.length-1]);
    let num_seg=parseInt(this.list_up[this.list_up.length-1].Data.num_seg)+1;
    console.log(num_seg);
    form.form.value.num_seg=num_seg;

     this.service.setListIndicador(form.form.value,this.seccionUp.seccion_id).subscribe(res=>{
    
        $('#addSegmentoAfter').modal('hide');
        form.resetForm();
        this.setSeccion(this.seccionUp);
        
        alert("Se Agrego el Segmento.");
        },err=>{
          console.log(err);
        });

  }

   getSubClasificadores(){
    
    this.service.getsubclasificadores().subscribe(res=>{
      res!=null?this.subclasificadores=JSON.parse(JSON.stringify(res)).Datas:null;
       console.log(this.subclasificadores);
    });
  }
   getprodsServers(){
    
    this.service.getprdosserver().subscribe(res=>{
      res!=null?this.prodServers=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }

  getCategorias(){
    this.service.getCategorias().subscribe(res=>{
      res!=null? this.categorias=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }
  sendPass(form){
  	this.service.validation(this.user,form.form.value.pass).subscribe(res=>{
  		res!=null? this.ifPass=true : alert("Contraseña incorrecta");
  	});
  }

  getCatCod(){
  		this.catCods=[];
  		this.service.getCatCod().subscribe(res=>{
  			res!=null? this.catCods=JSON.parse(JSON.stringify(res)).Datas:null;
  		});
  }

  addCatCod(form){
  	console.log(form.form.value);
  	this.service.postCatCod(form.form.value).subscribe(res=>{
  		if (res!=null) {
  			$('#addCatCod').modal('hide');
  			this.getCatCod();
  		}

  	});
  }

  setCatCod(data){
  	this.catCod=data;
  	this.catCod_id=data.cat_cod_id;
  }

  upCatCod(form){
  	console.log(form.form.value);
  	this.service.putCadCod(this.catCod_id,form.form.value).subscribe(res=>{
  		if (res!=null) {
  			$('#updateCatCod').modal('hide');
  			this.getCatCod();
  		}

  	});
  }

  deleteCatCod(){
  	let confirma=confirm("¿Seguro que desas Eliminar este Catalogo de Codificacion?");
  	if(confirma){
  		this.service.deleteCatCod(this.catCod_id).subscribe(res=>{
  		if (res!=null) {
  			$('#updateCatCod').modal('hide');
  			this.getCatCod();
  		}

  	});
  	}
  }

  paso_1_up=true;
  prodServer=[];
  val=[];

  seconStepUpIndicador(val){
    this.val=val.form.value;
    this.paso_1_up=false;
    this.prodServer=[];
    this.service.getProdAndServers(val.form.value.subCat).subscribe(res=>{
      res!=null? this.prodServer=JSON.parse(JSON.stringify(res)).Datas:null;
      for(let prod of this.prodServer){
          prod.Data.seleccion=true;
      }
    });
  }

  createSql_Indicator(val){

    console.log(val);

     this.service.incert_configIndicador(this.part1).subscribe(res=>{
       if (res!=null) {
         let id_idnicador =JSON.parse(JSON.stringify(res)).Datas[0].Data.config_indicador_id;

         console.log("Codigo de Indicador "+id_idnicador);
         this.data1.num_seg=0;
         console.log(this.data1);
         this.service.insertSegmentoPrincipla(this.data1,id_idnicador).subscribe(res=>{
           console.log(res);
         });

         for(let segmentos of this.list_segmentos_data1){
           this.service.insertSegmentoPrincipla(segmentos,id_idnicador).subscribe(res=>{
           console.log(res);
           });
         }

         if(this.part1.num_secciones == 1 || this.part1.num_secciones == 2 || this.part1.num_secciones == 3){
             console.log("---------------");
                  console.log(this.data2);
                  let dato_insertar=this.data2;
                console.log("---------------");

            this.service.insertSecciones(this.data2,id_idnicador).subscribe(res=>{
              console.log(res);

              if (res!=null) {
                let id =JSON.parse(JSON.stringify(res)).Datas[0].Data.seccion_id;

                

                this.service.setListIndicador(dato_insertar,id).subscribe(res=>{

                });

                for(let seg1 of this.list_segmentos_data2){
                  console.log(seg1);
                  this.service.setListIndicador(seg1,id).subscribe(res=>{

                  });
                }

              }

            });
         }

         if(this.part1.num_secciones == 2 || this.part1.num_secciones == 3){
            let data3=this.data3;
           this.service.insertSecciones(this.data3,id_idnicador).subscribe(res=>{
             if (res!=null) {
                let id =JSON.parse(JSON.stringify(res)).Datas[0].Data.seccion_id;

                this.service.setListIndicador(data3,id).subscribe(res=>{

                });

                for(let seg1 of this.list_segmentos_data3){
                  this.service.setListIndicador(seg1,id).subscribe(res=>{

                  });
                }
                
              }
            });
         }

         if(this.part1.num_secciones == 3){
           let data4=this.data4;
           this.service.insertSecciones(this.data4,id_idnicador).subscribe(res=>{
              if (res!=null) {
                let id =JSON.parse(JSON.stringify(res)).Datas[0].Data.seccion_id;

                this.service.setListIndicador(data4,id).subscribe(res=>{

                });
                for(let seg1 of this.list_segmentos_data4){
                  this.service.setListIndicador(seg1,id).subscribe(res=>{

                  });
                }
                
              }
            });


         }

         alert("Se Creo nuevo indicador");
           this.clearVar();
           this.getConfigs();

           $('#step1_create_inidcador').modal('hide');
           $('#step2_create_inidcador').modal('hide');
          
       }
     });
  }

  setList(id,form){

    
    form.num_seg=1;

     this.service.setListIndicador(form,id).subscribe(res=>{
        console.log(res);
      },err=>{
        console.log(err);
      });
    
     for(let codigo of this.segementos){
           

            console.log(codigo);
              this.service.setListIndicador(codigo,id).subscribe(res=>{
                console.log(res);
              },err=>{
                console.log(err);
              });
          
        }
      $('#addIndicador').modal('hide');
      this.paso_1_up=true;
      this.getConfigs();
       this.segementos=[];
       this.num_seg=1;

        alert("Se Creo Indicador Nuevo")
  }

  cerrarADDIndicadores(form){
    $('#addIndicador').modal('hide');
    form.resetForm();
    this.segementos=[];
    this.num_seg=1;
  }

  config_indicadores=[];

  getConfigs(){
    this.config_indicadores=[];  
    this.service.getConfigIndicador().subscribe(res=>{
      res!=null? this.config_indicadores=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(res);
    });
  }





  config_up:any=[];
  list_up:any=[];
  config_upRes:any=[];
  seccioneslistUp:any=[];

  setCofigUpdate(data){

    
    console.log(data);
    this.config_upRes=data;
    this.list_up=[];
    this.config_up=data;
    console.log(this.config_up);
    this.seccioneslistUp=[];
    this.service.get_secciones(data.config_indicador_id).subscribe(res=>{
      res!=null?this.seccioneslistUp=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.seccioneslistUp);
    });


  }

  seccionUp:any=[];
  setSeccion(seccion){
        this.updateSegmento=2;
        this.seccionUp=seccion;
        this.service.getsegmentos(seccion.seccion_id).subscribe(res=>{
         res!=null? this.list_up=JSON.parse(JSON.stringify(res)).Datas:null;
      
       console.log(res);
    });
  }

  cerrar_Up_segmento(){
    this.updateSegmento=1;
  }

  update(){
    console.log(this.list_up);
    this.service.updateconfigIndicador(this.config_up.config_indicador_id,this.config_up.nombre,this.config_up.titulo,this.config_up.unidades).subscribe(res=>{
      console.log(res);
    });

    for(let list of this.list_up){
       if (!list.Data.seleccion){

            console.log(list);
              this.service.deleteListIndicador(list.Data.list_indicador_id).subscribe(res=>{
                console.log(res);
              },err=>{
                console.log(err);
              });
          }
    }

    
     $('#UpIndicador').modal('hide');
     alert("Cambios Guardados");
  }

  eliminar(){
    let seguro=confirm("¿Seguro que quieres quitar este Indicador?");
    if (seguro) {
      this.service.deleteconfigIndicador(this.config_up.config_indicador_id).subscribe(res=>{
        console.log(res);
      });
      alert("Se Elimino Indicador");
    }

     $('#UpIndicador').modal('hide');

     this.getConfigs();
  }


  cancelarUpdate(){
    this.config_up=this.config_upRes;
    $('#UpIndicador').modal('hide');
    this.getConfigs();

  }




}
