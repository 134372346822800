import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-ev-produccion',
  templateUrl: './ev-produccion.component.html',
  styleUrls: ['./ev-produccion.component.css']
})
export class EvProduccionComponent implements OnInit {
			page_now="Producción";
			
  constructor(private service:UsersService) { }
    
    Titulo_grafica:any;
    fecha:any;
    totalDia:any;
    subtitulo:any;
    date_cards:any;
    dia_hinabil:any;
    unaBara_date:any;
    barra_adm:any;
    sinClasificar:any;
    fecha_sv:any;
    graficaValor:any;

     view_bar_cem=[700,150];
     two_bar=[700,200];
     two_bar_scrap=[700,400]

     view: any[] = [700, 300];
     view_bar=[700,90];
    legend: boolean = true;
    legendPosition: string = 'below';

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  one_bar=[];
  

  cat_productos:any=[];

  ngOnInit() {
  	


    this.num=3;
    this.fecha='2020-08-31';
    this.fecha_sv=this.fecha;
    this.totalDia=new Intl.NumberFormat('es-MX').format(1039.75)+" Tarimas";
    this.subtitulo="Producción Acumulada"
 
    
    this.dia_hinabil="";

    this.service.getcod_ventas().subscribe(res=>{
      if (res!=null) {
        this.cat_productos=JSON.parse(JSON.stringify(res)).Datas;

      }

      this.switch_uni();
    });

    /*
     this.grafica_1=this.grafica_1=this.grafic_1_set([
     
      {nombre:"	Bloque 15 liso normal " ,value: 429.00 , extra:{codigo:"20LACN", val_form:429.00 } },
      {nombre:"	Bloque 20 liso alta resistencia " ,value: 222.00 , extra:{codigo:"20LACN", val_form:222.00} },
      {nombre:"Bloque 12 liso normal  " ,value: 213.49 , extra:{codigo:"20LACN", val_form:213.49} },
      
     
      {nombre:"	otros " ,value: 170.26	 , extra:{codigo:"20LACN", val_form:170.26} },
                    
    ]);*/

  
  }

   cambioFecha_scrap(form){

      this.fecha=form.form.value.input;
      this.subtitulo="Scrap Acumulado";
      console.log(form.form.value);
      this.dia_hinabil="";

      switch (this.fecha) {
        
          case "2020-08-14":
             this.setGrafi_srapt(-32.7,-7.5,-475.2,-4.8,-381.6);
          break;
          
          case "2020-08-31":
             this.setGrafi_srapt(-53.1,-37.5,-897,-4.8,-687.10);
          break;
          case "2020-09-15":
             this.setGrafi_srapt(-46.998,-0,-396.396,0,-293.661);
          break;
        
        default:
          // code...
          break;
      }
  }

  ngIntInsumos() {
    this.num=3;
    this.fecha='2020-09-15';

    this.dia_hinabil="";
    this.fecha_sv=this.fecha;
    this.totalDia=167636.00+1351.00+215.42;
    this.subtitulo="Insumos Acumlados";
    this.grafic_total_insumos=[{
    

      
              "name": "CEMENTO A GRANEL",
              "value":167636.00,
               "extra":{codigo:"20LACN", val_form:""+167636.00}},
             {
              "name": "TRITURADO 3/16 A FINOS",
              "value":1351.00,
               "extra":{codigo:"20LACN", val_form:""+1351.00}
             },

             {
              "name": "GRAVA/ SELLO  3/8",
              "value":215.42,
               "extra":{codigo:"20LACN", val_form:""+215.42
}
             },
    ]


    
    this.grafic_cemento=[
      {
        "name":"Insumos" ,
       
        "series": 
          [
            {
    

      
              "name": "CEMENTO A GRANEL",
              "value":128533.00,
               "extra":{codigo:"20LACN", val_form:""+128533.00}},
             
    
          ]
        },
        
      
    ];

     this.grafic_insumos=[
      {
        "name":"Insumos" ,
       
        "series": 
          [
            
             {
              "name": "TRITURADO 3/16 A FINOS",
              "value":928.61,
               "extra":{codigo:"20LACN", val_form:""+928.61}
             },

             {
              "name": "GRAVA/ SELLO  3/8",
              "value":223.81,
               "extra":{codigo:"20LACN", val_form:""+223.81
              },}
    
          ]
        },
        
      
    ];

  
  }

  grafic_total_insumos:any;
  cardColor: string = '#232837';
  view_test: any[] = [750, 110];
  grafic_insumos:any;
  grafic_cemento:any;
    color_graficss={
    domain:['#7AA3E5FF','#A27EA8FF']
  }


  setInsumos(CEMENTO,TRITURADO,GRAVA){

    this.totalDia=(parseFloat(CEMENTO)+parseFloat(TRITURADO)+parseFloat(GRAVA)).toFixed(2);
    this.totalDia=new Intl.NumberFormat('es-MX').format(this.totalDia);

    this.grafic_insumos=[
      {
        "name":"Insumos" ,
       
        "series": 
          [
            
             {
              "name": "TRITURADO 3/16 A FINOS",
              "value":TRITURADO,
               "extra":{codigo:"20LACN", val_form:""+TRITURADO}
             },

             {
              "name": "GRAVA/ SELLO  3/8",
              "value":GRAVA,
               "extra":{codigo:"20LACN", val_form:""+GRAVA
              },}
    
          ]
        },
          
    ];

    this.grafic_cemento=[
      {
        "name":"Insumos" ,
       
        "series": 
          [
            {
    

      
              "name": "CEMENTO A GRANEL",
              "value":CEMENTO,
               "extra":{codigo:"20LACN", val_form:""+CEMENTO}},
             
    
          ]
        },
        
      
      ];

     this.grafic_total_insumos=[
             {
    

      
              "name": "CEMENTO A GRANEL",
              "value":CEMENTO,
               "extra":{codigo:"20LACN", val_form:""+CEMENTO}},
             {
              "name": "TRITURADO 3/16 A FINOS",
              "value":TRITURADO,
               "extra":{ val_form:""+TRITURADO}
             },

             {
              "name": "GRAVA/ SELLO  3/8",
              "value":GRAVA,
               "extra":{ val_form:""+GRAVA}
             }, ]  

  }


   cambioFecha_insumos(form){
     this.fecha=form.form.value.input;
     console.log(form.form.value);
      this.subtitulo="Insumos del Día";
      this.dia_hinabil="";

      switch (this.fecha) {
       
          case "2020-08-14":
             this.setInsumos(128533.00,928.61,223.81);
          break;
          case "2020-08-31":
             this.setInsumos(315243.00,2280.91,499.91);
          break;
          case "2020-09-15":
             this.setInsumos(167636.00,1351.00,215.42);
          break;
   
        
        default:
          // code...
          break;
      }
  }

  ngIntSrapt() {
    this.num=3;
    this.fecha='2020-09-15';
    this.fecha_sv=this.fecha;
    
    this.subtitulo="Scrap Acumulado"
    
    
    this.dia_hinabil="";
    
    this.setGrafi_srapt(-46.998,-0,-396.396,0,-293.661)

  
  }


  setGrafi_srapt(s_control_1,cuerta_curado,cubera,s_control_2,ventas){
    this.totalDia=new Intl.NumberFormat('es-MX').format(s_control_1+cuerta_curado+cubera+s_control_2+ventas)+" Pzas.";
      this.grafica_1=[{
      "name":"Scrap PP" ,
     
        "series": [
                {
                  "name": "SALIDA A CONTROL DE CALIDAD",
                  "value":s_control_1,
                   "extra":{codigo:"20LACN", val_form:""+s_control_1  }},
                 {
                  "name": "SCRAP CUARTO DE CURADO",
                  "value":cuerta_curado,
                   "extra":{codigo:"20LACN", val_form:""+cuerta_curado}
                 },

                 {
                  "name": "SCRAP CUBERA",
                  "value":cubera,
                  "extra":{codigo:"20LACN", val_form:""+cubera}
                 },



               
              ]
          },
        {
      "name":"Scrap PT" ,
     
        "series": [
                {
                  "name": "SALIDA A CONTROL DE CALIDAD",
                  "value":s_control_2,
                   "extra":{codigo:"20LACN", val_form:""+s_control_2 }},
                 {
                  "name": "SCRAP PT VENTA",
                  "value":ventas,
                   "extra":{codigo:"20LACN", val_form:""+ ventas}
                 },

               
              ]
          }
      
       ];

       ;
  }



  cambioFecha(form){

    console.log(form.form.value);
    this.fecha=form.form.value.input;
    this.switch_uni();
    
  }

   setUnidades(prod,scrap){
      console.log(scrap.Data.cantidad + " " + prod.Data['UNIDAD DE MEDIDA']);

           if(prod.Data['UNIDAD DE MEDIDA']=='T1-TARIMA 150 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*150;
           }

           if(prod.Data['UNIDAD DE MEDIDA']=='T10-TARIMA 200 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*200;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T11-TARIMA 250 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*250;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T12-TARIMA 320 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*320;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T13-TARIMA 350 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*350;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T14-TARIMA 420 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*420;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T15-TARIMA 500 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*500;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T2-TARIMA 120 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*120;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T3-TARIMA 90 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*90;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T7-TARIMA 64 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*64;
           }
           if(prod.Data['UNIDAD DE MEDIDA']=='T8-TARIMA 180 PIEZAS'){
             scrap.Data.cantidad=scrap.Data.cantidad*180;
           }

          console.log(scrap.Data.cantidad);

        return scrap.Data.cantidad;

  }

  switch_uni(){


    let total=0;
    let obj_grafica:any=[];
    let obj_mayor:any=[];
    let obj_menores:any=[];

    this.service.getrSincro(this.fecha,'rp_produccion_diario').subscribe(res=>{
      if (res!=null) {
        let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
        if(sincro.justificacion=='actualización diaria'){
          this.service.getrp_info_unidad(sincro.sincro_id,'rp_produccion_diarias').subscribe(res=>{
            if (res!=null) {
               

                let produccion=JSON.parse(JSON.stringify(res)).Datas;

                for(let prod of produccion){
                  
                  
                  
                  prod.Data.name=this.cat_productos.find(cat=>cat.Data.codigo==prod.Data.codigo);

                  if(prod.Data.name!=undefined && prod.Data.name!=null){
                    prod.Data.cantidad=this.setUnidades(prod.Data.name,prod);
                    prod.Data.name=prod.Data.name.Data.nombre;
                     
                  
                  }else{
                  
                    prod.Data.name='Sin Nombre('+prod.Data.codigo+')';
                  
                  }

                  if(obj_grafica!=null && obj_grafica.length!=0){
                    
                    //console.log(obj_grafica);
                    let cambio=false;

                    for(let obj of obj_grafica){
                      if(!cambio){
                         if(obj.extra.codigo==prod.Data.codigo){
                           obj.value=obj.value+parseFloat(prod.Data.cantidad);
                           obj.extra.val_form=obj.value;
                           cambio=true;
                         }
                      }
                    }

                    if(!cambio){
                      obj_grafica.push({
                      name: prod.Data.name,
                      value: parseFloat(prod.Data.cantidad) , 
                      extra:{codigo:prod.Data.codigo, val_form:prod.Data.cantidad}
                      });
                    }


                  }else{
                    obj_grafica.push({
                      name: prod.Data.name,
                      value: parseFloat(prod.Data.cantidad) , 
                      extra:{codigo:prod.Data.codigo, val_form:prod.Data.cantidad}
                    });
                  }

                  total=total+parseFloat(prod.Data.cantidad);
                  //console.log(prod);

                   
                }

                console.log(obj_grafica[0]);
                this.totalDia=new Intl.NumberFormat('es-MX').format(total)+" Pzas";

                   this.subtitulo="Producción Acumulada"
                   this.num=5;
                   this.dia_hinabil="";

                   obj_menores=obj_grafica.filter(l=> l.name != obj_grafica[0].name);

                   
                   this.grafica_1=obj_grafica;

                   /*
                   if(obj_menores.length!=0){
                     this.grafica_1=obj_menores;
                     this.one_bar=[obj_grafica[0]];

                   }else{
                     this.grafica_1=obj_grafica;
                      this.one_bar=[];
                   }*/



                  

                  

            }else{
              //info en 0
            }
          });

        }else{
          
          if(sincro.justificacion=='dia inhabil'){
             this.totalDia=new Intl.NumberFormat('es-MX').format(0)+" Tarimas";
             this.grafica_1=[];
                  
                   this.subtitulo="Producción del Día"
                   this.num=5;
                   this.dia_hinabil="Día Inhábil";
                   this.one_bar=[];
          }else{
            //sin info del dia
          }
        }
      }else{

         this.totalDia=new Intl.NumberFormat('es-MX').format(0)+" Tarimas";
         this.grafica_1=[];
         this.one_bar=[];
                  
         this.subtitulo="Producción del Día"
         this.num=5;
         this.dia_hinabil="Sin Información del Día";
        //si no sincornizacion en la fecha
      }
    });

    /*
      switch (this.fecha) {

        case "2020-08-14":
       
        this.fecha_sv=this.fecha;
        this.totalDia=new Intl.NumberFormat('es-MX').format(1039.75)+" Tarimas";
        this.subtitulo="Producción Acumulada"
     
        
        this.dia_hinabil="";
         this.grafica_1=this.grafica_1=this.grafic_1_set([
         
          {nombre:"  Bloque 15 liso normal " ,value: 429.00 , extra:{codigo:"20LACN", val_form:429.00 } },
          {nombre:"  Bloque 20 liso alta resistencia " ,value: 222.00 , extra:{codigo:"20LACN", val_form:222.00} },
          {nombre:"Bloque 12 liso normal  " ,value: 213.49 , extra:{codigo:"20LACN", val_form:213.49} },
          
         
          {nombre:"  otros " ,value: 170.26   , extra:{codigo:"20LACN", val_form:170.26} },
                        
        ]);
            break;

        case "2020-08-31":
       
        this.fecha_sv=this.fecha;
        this.totalDia=new Intl.NumberFormat('es-MX').format(2384.61)+" Tarimas";
        this.subtitulo="Producción Acumulada";


        
        this.dia_hinabil="";
         this.grafica_1=this.grafica_1=this.grafic_1_set([
         
          {nombre:"Bloque 15 liso normal" ,value: 744.03 , extra:{codigo:"20LACN", val_form:744.03} },
          {nombre:"Bloque 12 liso normal" ,value: 444.92  , extra:{codigo:"20LACN", val_form:444.92  } },
          {nombre:"Bloque 20 liso alta resistencia" ,value:387.00 , extra:{codigo:"20LACN", val_form:387.00} },
          {nombre:"Bloque 15 alta resistencia" ,value: 251.50 , extra:{codigo:"20LACN", val_form:251.50} },
          {nombre:"Bloque 12 alta resistencia" ,value: 259.50 , extra:{codigo:"20LACN", val_form:259.50} },

          {nombre:" Otros " ,value:    311.57   , extra:{codigo:"20LACN", val_form:   311.57} },
                        
        ]);
            break;


        
        default:
              this.fecha=this.fecha_sv;
              alert("No se encontro Información para esta fecha");

          break;
      }

    */
  }



  grafic_1_set(objeto_1){

        let objs=[];
        for(let obj of objeto_1){
            objs.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(obj.value)}  ,
                  name:obj.nombre  ,
                  value:obj.value});
        }

        return objs;


  }

  setData_cards(financiera,anticipos,maniobras){
    let val=[
      {
       "name": "Venta Financiera del Día",
       "value": financiera

      },
       {
           "name": "Anticipos Financieros del Día",
           "value": anticipos

      },
      {
           "name": "Maniobras Financieras del Día",
           "value": maniobras
      
      }];

      return val;


  }


  setData_cards_unidad(financiera,anticipos,maniobras,unidad_anticpo,unidad_maniobar){
    let val=[
      {
       "name": "Ventas por Unidades del Día",
       "value": financiera

      },
       {
           "name": "Número de Anticipos del Día",
           "value": anticipos

      },
      {
           "name": "Número de Maniobras del Día",
           "value": maniobras
      
    }];

      return val;


  }

  
  page="p2";

  single=[{
  		
        "name": "Total producido",
        "value": 53621
   
  }];

  grafica_1:any=[
  	{	extra:{codigo:"15LNCN", val_form:new Intl.NumberFormat('es-MX').format(24940.00 )}	,name:"Bloque 15 liso normal"	,value:24940.00 
    },
    	{	extra:{codigo:"15LACN", val_form:new Intl.NumberFormat('es-MX').format(21411.00 )}	,name:"Bloque 15 alta resistencia"	,value:21411.00 
    },	
    	{	extra:{codigo:"15PACN", val_form:new Intl.NumberFormat('es-MX').format(2962.00 )}	,name:"Bloque 15 cara de piedra gris"		,value:2962.00 
    },
    	{ extra:{codigo:"15PACA" ,val_form:new Intl.NumberFormat('es-MX').format(1680.00 )}	,name:"Bloque 15 cara de piedra amarillo paja"	,value:1680.00 
    },	
    	{ extra:{codigo:"CUADRADOCR", val_form:new Intl.NumberFormat('es-MX').format(1500.00 )}	,name:"Adoquin cuadrado rojo ocre",value:1500.00 
    },			
    	{ extra:{codigo:"12LNCN", val_form:new Intl.NumberFormat('es-MX').format(450.00)} ,name:"	Bloque 12 liso normal"		,value:450.00 },	
    	{ extra:{codigo:"15LACR", val_form:new Intl.NumberFormat('es-MX').format(360)}	,name:"Bloque 15 liso rojo ocre"		,value:360.00 },	
  	{ extra:{codigo:"VENECIACR", val_form:new Intl.NumberFormat('es-MX').format(318)}	,name:"Adoquin venecia rojo ocre"			,value:318.00 },

  ]

    

    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient_bar: boolean = false;
    showLegend: boolean = false;
    showXAxisLabel: boolean = true;
    yAxisLabel: string = '';
    showYAxisLabel: boolean = true;
    xAxisLabel: string = '';

    num=4;


    solar:any={
       
      domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  
  }



  loadPage(page){
  	this.page_now=page;
  }

  parseTitlesCard(value){
    return new Intl.NumberFormat('es-MX').format(value);
  }

   parseTitlesCards(value){
    console.log(value)
    if(value.label=="CEMENTO A GRANEL"){
      return new Intl.NumberFormat('es-MX').format(value.value) +" Kg";
    }
    if(value.label=="TRITURADO 3/16 A FINOS"){
      return new Intl.NumberFormat('es-MX').format(value.value) +" M3";
    }
    if(value.label=="GRAVA/ SELLO  3/8"){
      return new Intl.NumberFormat('es-MX').format(value.value) +" M3";
    }

 



  }

}
