import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ClientesService } from '../services/clientes.service';
import { QueriesLogoService } from '../services/queries-logo.service';
declare var $: any;

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css'],
  providers: [ QueriesLogoService]
})
export class VentasComponent implements OnInit {
  clientes: any = [];
  cliente: any;
  semaforo = 'green';
  ifClientes = true;

  ventas: any = [];
  ventas_respaldo: any = [];
  encuentro: any;

  desarrollos: any;
  promotores: any;
  t_inmuebles: any;
  user_id:any;

  constructor(private router: Router, private service: QueriesLogoService) { }

  ngOnInit() {
    this.user_id=sessionStorage.getItem('token');
    // this.getClientes();
    this.getVentas();
    this.getDesarrollos();
    this.getPromotores();
    this.getTiposInmueble();
  }



  getDesarrollos() {
    this.service.getDesarrollos().subscribe(
      result => {
        console.log(result);
        let eco = JSON.stringify(result);
        let json = JSON.parse(eco);
        this.desarrollos = json.Desarrollo;


      },
      error => {
        console.log(error);
      }
    );
  }

  getTiposInmueble() {
    this.service.getTipoInmueble().subscribe(
      result => {
        console.log(result);
        let eco = JSON.stringify(result);
        let json = JSON.parse(eco);
        this.t_inmuebles = json.T_Inmueble;




      },
      error => {
        console.log(error as any);
      }
    );
  }

  getPromotores() {
    this.service.getPromotores().subscribe(
      result => {
        console.log(result);
        let eco = JSON.stringify(result);
        let json = JSON.parse(eco);
        this.promotores = json.Promotor;


      },
      error => {
        console.log(error as any);
      }
    );
  }

  getVentas() {
    this.service.getVentas().subscribe(data => {
      let eco = JSON.stringify(data);
      let json = JSON.parse(eco);
      this.ventas = json.Venta;
      this.ventas_respaldo = this.ventas;
      console.log(json.Venta);
      this.ifClientes = true;
    }, error => {
      console.log(error);
    });
  }

  getEncuentro(id){
    console.log("el id: " + id);
    //this.ifClientes = false;
    this.service.getEncuentro(id).subscribe(data => {
      console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.encuentro = json.Encuentro;
      console.log(this.encuentro);

    }, error => {
      console.log(error);
    });
  }

  limpiarform(form) {
    form.reset();
    this.getDesarrollos();
    this.getPromotores();
    this.getTiposInmueble();
  }

  addEncuentro(form) {
    console.log('promotor' + form.form.value.promotor_id.valueOf());
    console.log('tipo' + form.form.value.tipo.valueOf());
    console.log('desarrollo' + form.form.value.desarrollo_id.valueOf());

    this.service.insertEncuentro(this.user_id,form.form.value).subscribe(data => {
      console.log(data);
      this.limpiarform(form);
      //this.getEncuentros();
    }, error => {
      console.log(error);
    });
  }

  addPreventa(){

  }

  abrirVenta(venta_id,cliente_id){
    console.log("Id de la Venta: " + venta_id);
    this.router.navigate(["venta/" + venta_id+'/'+cliente_id]);
  }


  filtro(ev)
  {
    if (ev=='') {
      this.ventas=this.ventas_respaldo;
    }else{
      let val = ev.target.value;
      console.log(val);
      if (val && val.trim() != '') {
        this.ventas = this.ventas.filter((item) => {
          return (item.Ventas.nombres.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

        if(this.ventas.length==0){
          this.ventas = this.ventas_respaldo.filter((item) => {
            return (item.Ventas.venta_inmueble_id.toLowerCase().indexOf(val.toLowerCase())  > -1);
          });
        }

      }else{
        this.ventas=this.ventas_respaldo;
      }
    }
  }


}
