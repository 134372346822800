import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indseccion2',
  templateUrl: './indseccion2.component.html',
  styleUrls: ['./indseccion2.component.css']
})
export class Indseccion2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
