import { Component, OnInit } from '@angular/core';
import { UsersService} from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-departamentos',
  templateUrl: './departamentos.component.html',
  styleUrls: ['./departamentos.component.css']
})
export class DepartamentosComponent implements OnInit {
	areas:any=[];
	setarea:any;
	areas_active:any;
  oldAreas:any;
  lvls:any;
  puestos:any;
  new_area:any;
  empleados_vig:any;
  cargando=true;


  	constructor(private services:UsersService) { }

	ngOnInit() {
		localStorage.setItem('page','7');
  	  
  	  	
        this.getlvl();
        this.getEmpleadosVigentes();
       
	}

  getEmpleadosVigentes(){
    this.services.getEmleados().subscribe(res=>{
      res!=null?this.empleados_vig=JSON.parse(JSON.stringify(res)).Datas:null;
      this.getAreas_active();
    });

    
  }


  getAreas(){
   this.areas=[];
  	this.services.getDepartamentos().subscribe(res=>{
  	 
      

       if(res != null){

        this.areas=JSON.parse(JSON.stringify(res)).Datas;
        this.oldAreas=this.areas;
        for(let dep of this.areas){

            if(dep.Data.area_id!=0){
              dep.Data.area_name=this.areas_active.find(area=>area.Data.area_id == dep.Data.area_id).Data.titulo;
            }

            if(dep.Data.user_id!=0){
                 dep.Data.nombre_lider=this.empleados_vig.find(area=>area.Data.id+""+area.Data.nombre == dep.Data.user_id).Data
                 dep.Data.nombre_lider= dep.Data.nombre_lider.nombre;

                 
            }else{
              dep.Data.nombre_lider="No Asignado."
            }
        }

       }
       this.cargando=false;
  	});
  
  }

  getAreas_active(){
  	this.services.getAreas().subscribe(res=>{
  		res != null ? this.areas_active=JSON.parse(JSON.stringify(res)).Datas:null;
  		console.log(this.areas_active);
        this.getAreas();
  	});
  
  }


  getlvl(){
    this.services.getlvl('lvl_departamentos').subscribe(res=>{
      res != null ? this.lvls=JSON.parse(JSON.stringify(res)).Datas:null;

      console.log(this.lvls);
    });
  
  }


  crearNivel(form){
    if(form.form.value.lvl_departamento_id!=''){
    let mensaje=confirm("Estas a punto de crear un nuevo nivel,¿Seguro que quieres continuar?");
      if(mensaje){
        let ms:any=[];
        ms.titulo=form.form.value.lvl_departamento_id;
        this.services.Insertlvl(ms,'lvl_departamento_id','lvl_departamentos').subscribe(res=>{
         this.getlvl();
      
          alert("Se crea Nivel con exito");
    
        });
      }
    }else{
      alert("Ingresa el nombre del nuevo Nivel.");
    }
  }
 
 Msj(m){
    alert(m);
  }

  createNewArea(form){
   
    if(form.form.value.area_id!=""){
       let ifCreate=confirm("Estas a punto de craear una nueva Área. ¿Seguro que quieres continuar?");
       let data={
       titulo:form.form.value.area_id,
       lvl_area_id:0
     }
       this.services.InsertAreas(data).subscribe(res=>{
         alert("Se creao Área con éxito.");
         this.getAreas_active();
       });
    }else{
      this.Msj("Para crear una nueva Área es neceario que ingreses el nombre de la Área.");
    }

  }





   insertArea(form){
 
     let name=form.form.value.user_id;
    form.form.value.area_id=this.areas_active.find(area=>area.Data.titulo == form.form.value.area_id).Data.area_id;
    form.form.value.lvl_departamento_id=0;

    form.form.value.user_id=this.empleados_vig.find(area=>area.Data.nombre == form.form.value.user_id).Data

     

    form.form.value.user_id= form.form.value.user_id.id+name;

    
      
    this.services.InsertDepartamento(form.form.value).subscribe(res=>{

  		form.resetForm();
  		this.getAreas();
  		
  		alert("Se crea Departamento con exito");
  		$('#createArea').modal('hide');
  	});
  }

  
  setArea(data){

    
    if (data.user_id!='0') {

         console.log(data);

         data.user_id=this.empleados_vig.find(area=>area.Data.id+""+area.Data.nombre == data.user_id).Data
         data.user_id= data.user_id.nombre;
    }else{
      data.user_id="";
    }

    let id=data.area_id;

  	this.setarea=data;

    this.setarea.area_id=this.areas_active.find(area=>area.Data.area_id == id).Data;
  
    this.setarea.area_id=this.setarea.area_id.titulo;
  }

  update(form){

    let id=form.form.value.area_id;
    

    form.form.value.area_id=this.areas_active.find(area=>area.Data.titulo == id).Data.area_id;

    form.form.value.user_id=this.empleados_vig.find(area=>area.Data.nombre == form.form.value.user_id).Data
    form.form.value.user_id= form.form.value.user_id.id+form.form.value.user_id.nombre;

  	this.services.UpdateDepartamento(form.form.value,this.setarea.departamento_id).subscribe(res=>{
  		
  		this.getAreas();
  		
  		alert("Se actualizo Departamento con exito");
  		$('#updateArea').modal('hide');
  	});
  
  }

  cerrarUpdate(){
      $('#updateArea').modal('hide');
      this.ngOnInit();
  }


  delete(){
  	
  	let seguro=confirm("¿Estás seguro? Al hacerlo, se eliminara este Departamento");
  	if(seguro){this.services.DeleteDepartamento(this.setarea.departamento_id).subscribe(res=>{
  	  		
  	  		this.getAreas();
  	  		
  	  		alert("Se Elimino Departamento con exito");
  	  		$('#updateArea').modal('hide');
  	  	});}
  }


  

  filtro(ev)
    {
      if (ev=='') {
       this.areas=this.oldAreas;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.areas = this.areas.filter((item) => {
          return (item.Data.titulo.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.areas=this.oldAreas;
      }
     }
    }


}
