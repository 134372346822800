import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';

@Component({
  selector: 'app-desarrollos',
  templateUrl: './desarrollos.component.html',
  styleUrls: ['./desarrollos.component.css'],
  providers:[CatalogosService]
})
export class DesarrollosComponent implements OnInit {
 desarrollos:any=[];
 nombre:any;
 id_update:any;
  descrip:any;
  titulo:any;
 constructor( private servicios:CatalogosService) {

 }

  ngOnInit() {
  	this.nombre="Desarrollos";
    this.servicios.getCatalogo("desarrollos").subscribe(result=>{ 
        if(result!=null){
         let temp=JSON.stringify(result);
         let temp2=JSON.parse(temp);
         this.desarrollos=temp2.Datas;
        }
    });
  }


    setid(id,des){
    this.id_update=id;
    this.descrip=des;
  }


  setiddelete(id){
    this.id_update=id;
  }
    postStatus(form){
      this.servicios.postDesarrollos('desarrollos','desarrollo_id',form.form.value.status).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
    }
   putStatus(form){
     this.servicios.putDesarrollos('desarrollos','desarrollo_id',form.form.value.status,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));
   }
   deleteCatlogo(){
     this.servicios.deleteCatalogo('desarrollos','desarrollo_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
   }

}
