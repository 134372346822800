import { Component, OnInit } from '@angular/core';
import { UsersService} from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;


@Component({
  selector: 'app-niveles',
  templateUrl: './niveles.component.html',
  styleUrls: ['./niveles.component.css']
})
export class NivelesComponent implements OnInit {
areas:any=[];
	setarea:any;
  oldAreas:any;
  


  constructor(private services:UsersService) { }


  ngOnInit() {
  	 localStorage.setItem('page','8');
  	  this.getAreas();
  }

  getAreas(){
    this.areas=[];
  	this.services.getNiveles().subscribe(res=>{
  		res != null ? this.areas=JSON.parse(JSON.stringify(res)).Datas:null;
      this.oldAreas=this.areas;
  	});
  
  }

   insertArea(area){
  	this.services.InsertNiveles(area.form.value).subscribe(res=>{
  		area.resetForm();
  		this.getAreas();
  		
  		alert("Se crea Nivel con exito");
  		$('#createArea').modal('hide');
  	});
  }

  
  setArea(data){
  	this.setarea=data;
  	console.log(this.setarea.titulo);
  }
  update(form){
  	this.services.UpdateNiveles(form.form.value,this.setarea.nivel_id).subscribe(res=>{
  		form.resetForm();
  		this.getAreas();
  		
  		alert("Se actualizo Nivel con exito");
  		$('#updateArea').modal('hide');
  	});
  }


  delete(){
  	
  	let seguro=confirm("¿Estás seguro? Al hacerlo, se eliminara este Nivel");
  	if(seguro){this.services.DeleteNiveles(this.setarea.nivel_id).subscribe(res=>{
  	  		
  	  		this.getAreas();
  	  		
  	  		alert("Se Elimino Area con exito");
  	  		$('#updateArea').modal('hide');
  	  	});}
  }

  

  filtro(ev)
    {
      if (ev=='') {
       this.areas=this.oldAreas;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.areas = this.areas.filter((item) => {
          return (item.Data.titulo.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.areas=this.oldAreas;
      }
     }
    }


}
