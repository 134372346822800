import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-ev-ingresos-dgo-block',
  templateUrl: './ev-ingresos-dgo-block.component.html',
  styleUrls: ['./ev-ingresos-dgo-block.component.css']
})
export class EvIngresosDgoBlockComponent implements OnInit {



  fecha='2020-08-14';
  fecha_sv="2020-08-14";
  fecha_agen='2020-08-14';

 page="p1";
    
 view: any[] = [220, 110];
 view_test: any[] = [750, 110];
 view_pia_grid: any[] = [750, 500];
 totalDia:any=0;
 single=[{
      "name": "Total Venta Financiera de Día",
      "value": 74171.92

 }];
  single2=[{
      "name": "Total Venta Financiera de Día",
      "value": 171121

 }];
  single3=[{
      "name": "Total Venta Financiera de Día",
      "value": 5000

 }];

 date_cards:any=[
 {
      "name": "Total Venta Financiera de Día",
      "value": 143544.92

 },
  {
      "name": "Anticipos",
      "value": 114454.92

 },
 {
      "name": "Maniobras",
      "value": 4544.92

 
 }];









  single_Adm_25=[{
      "name": "Ppto. Adm.",
      "value": 20000

 }];
  single_Adm_24=[{
      "name": "Ppto. Adm.",
      "value": 30000

 }];





   single_pres=[{
      "name": "Total Egresos",
      "value": 21928.71

  },{
      "name": "Total Presupuesto",
      "value": 32000

  }];
   solar:any={
     
    domain: [ '#E44D25', '#3CC099','#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  
  }




  


showLegend: boolean = false

 
  subtitulo='';
  

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient_bar: boolean = false;
  
  showXAxisLabel: boolean = true;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  xAxisLabel = "Productos \n ";


  cardColor: string = '#232837';
  
  grafica_1:any=[];

  Titulo_grafica="Ventas Por Productos (80/20)";
  set_format=true;

  Titulo_grafica_agentes="Ventas Por Agente";
  xAxisLabel_agente="Agentes";

     agentes_rp=[];

  constructor(private service:UsersService) { }

  ngOnInit() {
    this.fecha='2020-08-14';
    this.service.getrp_agentes().subscribe(res_agente=>{
               if(res_agente!=null){
                 this.agentes_rp=JSON.parse(JSON.stringify(res_agente)).Datas;
               }
             });
    
    this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1279589.14);
    this.subtitulo="Ingreso Total Facturado Acumulado."
    this.date_cards=this.setData_cards(998933.34,272282.18,8373.62);

    this.Titulo_grafica="Ventas Financieras por Producto (80/20)";
    this.service.getcod_ventas().subscribe(res=>{
      if (res!=null) {
          this.cod_ventas=JSON.parse(JSON.stringify(res)).Datas;
          
      }
      this.setInit();
    });
  }

  obj_ventas:any=[];
   cod_ventas=[];

  setInit(){
  
    let totalDia=0;
    let totalAticipos=0;
    let totalManiobras=0;
    let subtotal=0;
    let obj_vent:any=[];



    this.service.getrSincro(this.fecha,'rp_ventas_acumulado').subscribe(res=>{

      if(res!=null){
      
          let obj = JSON.parse(JSON.stringify(res)).Datas[0].Data;
          console.log(obj);

          if(obj.justificacion==='actualización diaria'){

          
            this.service.getrp_info(obj.sincro_id,'rp_ventas_acumuladas').subscribe(res=>{
              if(res!=null){
                this.obj_ventas=JSON.parse(JSON.stringify(res)).Datas;
                for(let venta of this.obj_ventas){
                
                  venta.Data.nombre=this.cod_ventas.find(cod=>cod.Data.codigo==venta.Data.codigo);
                  
                  venta.Data.value=venta.Data.efectivo;

                  venta.Data.nombre!=undefined ? venta.Data.nombre=venta.Data.nombre.Data.nombre : venta.Data.nombre="Sin Nombre( "+venta.Data.codigo+")" ;

                  if(venta.Data.codigo=='MANIOBRAS') {
                     venta.Data.nombre='Maniobras' ;
                     totalManiobras=totalManiobras+parseFloat(venta.Data.efectivo);
                   }
                   
                   if(venta.Data.codigo=='ANT01') {
                    venta.Data.nombre='Anticipo';
                    totalAticipos=totalAticipos+parseFloat(venta.Data.efectivo);
                   }

                    if(venta.Data.codigo!='ANT01' && venta.Data.codigo!='MANIOBRAS') {
                    
                    subtotal=subtotal+parseFloat(venta.Data.efectivo);
                   
                   }
                   
               
                  totalDia=totalDia+parseFloat(venta.Data.efectivo);
                

                }



                let otros=0;
                

                let obj:any=this.obj_ventas.filter(ven=>ven.Data.codigo!='ANT01' && ven.Data.codigo!='MANIOBRAS' && ven.Data.codigo!='0');
                  console.log(obj)

                    console.log('el logitud = '+obj.length);
                
                   let frist_step=true;
                  let limite=((80*subtotal)/100).toFixed(2);

                  console.log(limite);
                  let subLimite:any=0;

                  for(let venta_clear of obj){
                   
                    subLimite=subLimite+ parseFloat(venta_clear.Data.value);
                    

                     if(frist_step){
                       frist_step=false;
                        obj_vent.push(
                      {
                        'name': venta_clear.Data.nombre,'value': parseFloat(venta_clear.Data.value) , 
                        'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(parseFloat(venta_clear.Data.value))} 
                      }
                      );
                     }else{

                      if( parseFloat (limite)  >= subLimite  ){
                        console.log(subLimite);
                         obj_vent.push(
                        {
                          'name': venta_clear.Data.nombre,'value': parseFloat(venta_clear.Data.value) , 
                          'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(parseFloat(venta_clear.Data.value))} 
                        }
                        );
                       } 
                       else{
                         console.log(otros);
                        otros=otros+parseFloat(venta_clear.Data.value);
                      }

                    }

                   

                  }


                   obj_vent.push(
                      {
                        'name': 'Otros','value': otros , 
                        'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(otros)} 
                      }
                      );

                   otros=0;

            
                this.totalDia="$"+new Intl.NumberFormat('es-MX').format(totalDia);
                this.date_cards=this.setData_cards(subtotal,totalAticipos,totalManiobras);

                this.fecha_sv=  this.fecha;

                //console.log(this.obj_ventas);
                this.grafica_1=obj_vent;
              }
            });
          }else{
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                this.date_cards=this.setData_cards(0,0,0);

                this.dia_hinabil=obj.justificacion;


                //console.log(this.obj_ventas);
                this.grafica_1=[];
          }
        }else{
           this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                this.date_cards=this.setData_cards(0,0,0);

                this.dia_hinabil='Sin Información Disponible';


                //console.log(this.obj_ventas);
                this.grafica_1=[]; 
        }

      });

  }


  initAgentes(){
    this.fecha='2020-08-14';

    let obj_arr:any=[];
    let total=0;
    this.service.getrSincro(this.fecha,'rp_ventas_acumulado_agentes').subscribe(res=>{
      if(res!=null){
        let obj_res=JSON.parse(JSON.stringify(res)).Datas[0].Data;
        this.service.getrp_info(obj_res.sincro_id,'rp_ventas_agente_acumulado').subscribe(res_rp=>{
          let obj_rp=JSON.parse(JSON.stringify(res_rp)).Datas;

          console.log(obj_rp);

            for(let agente of this.agentes_rp)
            {
              let obj_segun_agente=obj_rp.filter(rp=>rp.Data.agente==agente.Data.agente);
             
              let subtotal =0;

              if(obj_segun_agente!=undefined && obj_segun_agente!=null){

                for(let venta of obj_segun_agente){


                  if(venta.Data.codigo!='ANT01' && venta.Data.codigo!='MANIOBRAS' ) {
                    subtotal=subtotal+parseFloat(venta.Data.efectivo);  
                     total=total+parseFloat(venta.Data.efectivo);   
                  }
                }

                 obj_arr.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(subtotal)}  ,
                    name:agente.Data.agente,
                    value:subtotal
                 });  
              
              }else{
                obj_arr.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(0)}  ,
                    name:agente.Data.agente,
                    value:0
                    });
               }


            }
            console.log(obj_arr);

            this.grafica_1=obj_arr;
            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total);
            this.dia_hinabil="";
            this.Titulo_grafica_agentes="Ventas Financeieras por Agente del Día";  
      
              

              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta por Agentes"

        });

      }else{
         this.grafica_1=[];
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);

         this.dia_hinabil="Sin Información";
            this.Titulo_grafica_agentes="Ventas Financeieras por Agente del Día";  
      
              

              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta por Agentes"


      }
    });

  
  }
   grafic_diesel=[];

  initGasolina(){

    this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
    this.subtitulo="Número de Embarques Acumulados"
    this.fecha='2020-08-14';
    this.dia_hinabil="Sin Información al Día"
    this.Titulo_grafica="";

    this.grafic_diesel=this.grafic_1_set([

      
      
                      
    ]);
  }
  dia_hinabil="";

  cambioFecha_diecel(){
    switch (this.fecha) {
      case "2020-08-02":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        
        
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
         case "2020-08-09":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
       
       
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
         case "2020-08-16":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        
       
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
      
      default:
         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        
       
        
        this.Titulo_grafica="";
         this.dia_hinabil="Sin Información del Día";
        break;
    }

  }


  parseTitlesCard(value){
    



    let val="$"+new Intl.NumberFormat('es-MX').format(value.value);

    if(value.label=="Número de Anticipos del Día"){
      val= Intl.NumberFormat('es-MX').format(value.value);
    }

    if(value.label=="Número de Maniobras del Día"){
      val =Intl.NumberFormat('es-MX').format(value.value);
    }

    if(value.label=="Ventas por Unidades del Día"){
     val= Intl.NumberFormat('es-MX').format(value.value)+" Pzas.";
    }
    
    return val;
  }

  parseTitlesCard2(value){

     
    if(value.label=="Anticipos"){
      return Intl.NumberFormat('es-MX').format(value.value);
    }
   
    if(value.label=="Ventas Por Productos Unidades"){
      return Intl.NumberFormat('es-MX').format(value.value)+"Pzas.";
    }
  }

  cambioFecha(form){
         this.fecha=form.form.value.fecha;
        console.log(this.fecha);
        console.log(form.form.value.cat);
    
    switch (form.form.value.cat) {
      case "fin":
            this.Titulo_grafica="Ventas Financieras por Producto (80/20)";
            
            this.switch_fin(); 
            this.subtitulo="Ingreso Total Facturado Acumulado"
            this.set_format=true;  
              
        break;
        case "prod":
          this.Titulo_grafica="Ventas en Unidades por Producto (80/20)";
          this.switch_uni(); 
          this.subtitulo="Total Unidades del Acumuladas."
          this.set_format=false;     
        break;
      
      default:
            alert("No se encontro Informacion valida para esta categoria");
        break;
    }
    
  }


  cambioFecha2(form){
    console.log(form);
      this.fecha=form.form.value.fecha;
        console.log(this.fecha);
      switch (form.form.value.cat) {
      case "fin":
            this.subtitulo="Total Ventas Acumuladas por Agentes"
            
            this.switch_ByAllAgente();
            
            this.set_format=true;  
              
        break;
        case "prod":
         this.subtitulo="Unidades Vendidas Acumuladas por Agentes"
          this.switch_ByAllAgente_prod(); 
         
          this.set_format=false;     
        break;
      
      default:
            alert("No se encontro Informacion valida para esta categoria");
        break;
    }
     
      
    
  }


  switch_fin(){
   let totalDia=0;
    let totalAticipos=0;
    let totalManiobras=0;
    let subtotal=0;
    let obj_vent:any=[];

    this.service.getrSincro(this.fecha,'rp_ventas_acumulado').subscribe(res=>{

      if(res!=null){
      
          let obj = JSON.parse(JSON.stringify(res)).Datas[0].Data;
          console.log(obj);
          if(obj.justificacion==='actualización diaria'){

          
            this.service.getrp_info(obj.sincro_id,'rp_ventas_acumuladas').subscribe(res=>{
              if(res!=null){
                this.obj_ventas=JSON.parse(JSON.stringify(res)).Datas;
                for(let venta of this.obj_ventas){
                
                  venta.Data.nombre=this.cod_ventas.find(cod=>cod.Data.codigo==venta.Data.codigo);
                  
                  venta.Data.value=venta.Data.efectivo;

                  venta.Data.nombre!=undefined ? venta.Data.nombre=venta.Data.nombre.Data.nombre : venta.Data.nombre="Sin Nombre( "+venta.Data.codigo+")" ;

                  if(venta.Data.codigo=='MANIOBRAS') {
                     venta.Data.nombre='Maniobras' ;
                     totalManiobras=totalManiobras+parseFloat(venta.Data.efectivo);
                   }
                   
                   if(venta.Data.codigo=='ANT01') {
                    venta.Data.nombre='Anticipo';
                    totalAticipos=totalAticipos+parseFloat(venta.Data.efectivo);
                   }

                    if(venta.Data.codigo!='ANT01' && venta.Data.codigo!='MANIOBRAS') {
                    
                    subtotal=subtotal+parseFloat(venta.Data.efectivo);
                   
                   }
                   
               
                  totalDia=totalDia+parseFloat(venta.Data.efectivo);
                

                }



                let otros=0;
                

                let obj:any=this.obj_ventas.filter(ven=>ven.Data.codigo!='ANT01' && ven.Data.codigo!='MANIOBRAS' && ven.Data.codigo!='0');
                  console.log(obj)

                    console.log('el logitud = '+obj.length);
                
                   let frist_step=true;
                  let limite=((80*subtotal)/100).toFixed(2);

                  console.log(limite);
                  let subLimite:any=0;

                  for(let venta_clear of obj){
                   
                    subLimite=subLimite+ parseFloat(venta_clear.Data.value);
                    

                     if(frist_step){
                       frist_step=false;
                        obj_vent.push(
                      {
                        'name': venta_clear.Data.nombre,'value': parseFloat(venta_clear.Data.value) , 
                        'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(parseFloat(venta_clear.Data.value))} 
                      }
                      );
                     }else{

                      if( parseFloat (limite)  >= subLimite  ){
                        console.log(subLimite);
                         obj_vent.push(
                        {
                          'name': venta_clear.Data.nombre,'value': parseFloat(venta_clear.Data.value) , 
                          'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(parseFloat(venta_clear.Data.value))} 
                        }
                        );
                       } 
                       else{
                         console.log(otros);
                        otros=otros+parseFloat(venta_clear.Data.value);
                      }

                    }

                   

                  }


                   obj_vent.push(
                      {
                        'name': 'Otros','value': otros , 
                        'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(otros)} 
                      }
                      );

                   otros=0;

            
                this.totalDia="$"+new Intl.NumberFormat('es-MX').format(totalDia);
                this.date_cards=this.setData_cards(subtotal,totalAticipos,totalManiobras);

                this.fecha_sv=  this.fecha;

                //console.log(this.obj_ventas);
                this.grafica_1=obj_vent;
              }
            });
          }else{
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                this.date_cards=this.setData_cards(0,0,0);

                this.dia_hinabil=obj.justificacion;


                //console.log(this.obj_ventas);
                this.grafica_1=[];
          }
        }else{
           this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
                this.date_cards=this.setData_cards(0,0,0);

                this.dia_hinabil='Sin Información Disponible';


                //console.log(this.obj_ventas);
                this.grafica_1=[]; 
        }

      });
  }

  switch_uni(){
     let totalDia=0;
    let totalAticipos=0;
    let totalManiobras=0;
    let subtotal=0;
    let obj_vent:any=[];


    this.service.getrSincro(this.fecha,'rp_ventas_acumulado').subscribe(res=>{

      if(res!=null){
      
          let obj = JSON.parse(JSON.stringify(res)).Datas[0].Data;
          console.log(obj);
          if(obj.justificacion==='actualización diaria'){

          
            this.service.getrp_info_unidad(obj.sincro_id,'rp_ventas_acumuladas').subscribe(res=>{
              if(res!=null){
                this.obj_ventas=JSON.parse(JSON.stringify(res)).Datas;
                for(let venta of this.obj_ventas){
                
                  venta.Data.nombre=this.cod_ventas.find(cod=>cod.Data.codigo==venta.Data.codigo);
                  
                  venta.Data.value=venta.Data.cantidad;

                  venta.Data.nombre!=undefined ? venta.Data.nombre=venta.Data.nombre.Data.nombre : venta.Data.nombre="Sin Nombre( "+venta.Data.codigo+")" ;

                  if(venta.Data.codigo=='MANIOBRAS') {
                     venta.Data.nombre='Maniobras' ;
                     totalManiobras=totalManiobras+parseFloat(venta.Data.cantidad);
                   }
                   
                   if(venta.Data.codigo=='ANT01') {
                    venta.Data.nombre='Anticipo';
                    totalAticipos=totalAticipos+parseFloat(venta.Data.cantidad);
                   }

                    if(venta.Data.codigo!='ANT01' && venta.Data.codigo!='MANIOBRAS') {
                    
                    subtotal=subtotal+parseFloat(venta.Data.cantidad);
                   
                   }
                   
               
                  totalDia=totalDia+parseFloat(venta.Data.cantidad);
                

                }



                let otros=0;

                let obj:any=this.obj_ventas.filter(ven=>ven.Data.codigo!='ANT01' && ven.Data.codigo!='MANIOBRAS');

                let frist_step=true;
                  let limite=((80*subtotal)/100).toFixed(2);

                  console.log(limite);
                  let subLimite:any=0;

                  for(let venta_clear of obj){
                   
                    subLimite=subLimite+ parseFloat(venta_clear.Data.cantidad);
                    

                     if(frist_step){
                       frist_step=false;
                        obj_vent.push(
                      {
                        'name': venta_clear.Data.nombre,'value': parseFloat(venta_clear.Data.cantidad) , 
                        'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(parseFloat(venta_clear.Data.cantidad))} 
                      }
                      );
                     }else{

                      if( parseFloat (limite)  >= subLimite  ){
                        console.log(subLimite);
                         obj_vent.push(
                        {
                          'name': venta_clear.Data.nombre,'value': parseFloat(venta_clear.Data.cantidad) , 
                          'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(parseFloat(venta_clear.Data.cantidad))} 
                        }
                        );
                       } 
                       else{
                         console.log(otros);
                        otros=otros+parseFloat(venta_clear.Data.cantidad);
                      }

                    }

                   

                  }


                   obj_vent.push(
                      {
                        'name': 'Otros','value': otros , 
                        'extra':{'codigo':"20LACN", 'val_form':new Intl.NumberFormat('es-MX').format(otros)} 
                      }
                      );

                   otros=0;


            
                this.totalDia=new Intl.NumberFormat('es-MX').format(totalDia);
                this.date_cards=this.setData_cards_unidad(subtotal,totalAticipos,totalManiobras,0,0);

                this.fecha_sv=  this.fecha;

                //console.log(this.obj_ventas);
                this.grafica_1=obj_vent;
              }
            });
          }else{
              this.totalDia=new Intl.NumberFormat('es-MX').format(0);
                this.date_cards=this.setData_cards_unidad(0,0,0,0,0);

                this.dia_hinabil=obj.justificacion;


                //console.log(this.obj_ventas);
                this.grafica_1=[];
          }
        }else{
           this.totalDia=new Intl.NumberFormat('es-MX').format(0);
                this.date_cards=this.setData_cards_unidad(0,0,0,0,0);

                this.dia_hinabil='Sin Información Disponible';


                //console.log(this.obj_ventas);
                this.grafica_1=[]; 
        }

      });
  }


 switch_ByAllAgente(){
     let obj_arr:any=[];
    let total=0;
    this.service.getrSincro(this.fecha,'rp_ventas_acumulado_agentes').subscribe(res=>{
      if(res!=null){

        let obj_res=JSON.parse(JSON.stringify(res)).Datas[0].Data;
        this.service.getrp_info(obj_res.sincro_id,'rp_ventas_agente_acumulado').subscribe(res_rp=>{
          
          if(res_rp!=null){
            let obj_rp=JSON.parse(JSON.stringify(res_rp)).Datas;
             for(let agente of this.agentes_rp)
            {
              let obj_segun_agente=obj_rp.filter(rp=>rp.Data.agente==agente.Data.agente);
             
              let subtotal =0;

              if(obj_segun_agente!=undefined && obj_segun_agente!=null){

                for(let venta of obj_segun_agente){


                  if(venta.Data.codigo!='ANT01' && venta.Data.codigo!='MANIOBRAS' ) {
                    subtotal=subtotal+parseFloat(venta.Data.efectivo);  
                     total=total+parseFloat(venta.Data.efectivo);   
                  }
                }

                 obj_arr.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(subtotal)}  ,
                    name:agente.Data.agente,
                    value:subtotal
                 });  
              
              }else{
                obj_arr.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(0)}  ,
                    name:agente.Data.agente,
                    value:0
                    });
               }


            }
             console.log(obj_arr);

            this.grafica_1=obj_arr;
            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total);
            this.dia_hinabil="";
            this.Titulo_grafica_agentes="Ventas Financeieras por Agente del Día";  
      
              

              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta por Agentes"


          }else{

            this.grafica_1=[];
            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);

            this.dia_hinabil=obj_res.justificacion;
            this.Titulo_grafica_agentes="Ventas Financeieras por Agente del Día";  
      
              

              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta por Agentes"

          }
          
            



            
           

        });

      }else{
         this.grafica_1=[];
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);

         this.dia_hinabil="Sin Información";
            this.Titulo_grafica_agentes="Ventas Financeieras por Agente del Día";  
      
              

              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta por Agentes"


      }


    });

  

     
  }

  switch_ByAllAgente_prod(){
     let obj_arr:any=[];
    let total=0;
    this.service.getrSincro(this.fecha,'rp_ventas_acumulado_agentes').subscribe(res=>{
      if(res!=null){

        let obj_res=JSON.parse(JSON.stringify(res)).Datas[0].Data;
        this.service.getrp_info_unidad(obj_res.sincro_id,'rp_ventas_agente_acumulado').subscribe(res_rp=>{
          
          if(res_rp!=null){
            let obj_rp=JSON.parse(JSON.stringify(res_rp)).Datas;
             for(let agente of this.agentes_rp)
            {
              let obj_segun_agente=obj_rp.filter(rp=>rp.Data.agente==agente.Data.agente);
             
              let subtotal =0;

              if(obj_segun_agente!=undefined && obj_segun_agente!=null){

                for(let venta of obj_segun_agente){


                  if(venta.Data.codigo!='ANT01' && venta.Data.codigo!='MANIOBRAS' ) {
                    subtotal=subtotal+parseFloat(venta.Data.cantidad);  
                     total=total+parseFloat(venta.Data.cantidad);   
                  }
                }

                 obj_arr.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(subtotal)}  ,
                    name:agente.Data.agente,
                    value:subtotal
                 });  
              
              }else{
                obj_arr.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(0)}  ,
                    name:agente.Data.agente,
                    value:0
                    });
               }


            }
             console.log(obj_arr);

            this.grafica_1=obj_arr;
            this.totalDia=new Intl.NumberFormat('es-MX').format(total);
            this.dia_hinabil="";
            this.Titulo_grafica_agentes="Ventas Financeieras por Agente del Día";  
      
              

              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta Unidades por Agentes"


          }else{

            this.grafica_1=[];
            this.totalDia=new Intl.NumberFormat('es-MX').format(0);

            this.dia_hinabil=obj_res.justificacion;
            this.Titulo_grafica_agentes="Ventas Financeieras por Agente del Día";  
      
              

              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta Unidades por Agentes"

          }
          
            



            
           

        });

      }else{
         this.grafica_1=[];
        this.totalDia=new Intl.NumberFormat('es-MX').format(0);

         this.dia_hinabil="Sin Información";
            this.Titulo_grafica_agentes="Ventas de Unidades por Agente del Día";  
      
              

              this.fecha_sv=  this.fecha;
              this.subtitulo="Total Venta Unidades por Agentes"


      }


    });

  
      
  }

 

  single_Set(title,value){
    let val=[{
            "name": title,
            "value": value

       }];
    return  val;

  }

   


  grafic_1_set(objeto_1){

        let objs=[];
        for(let obj of objeto_1){
            objs.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(obj.value)}  ,
                  name:obj.nombre  ,
                  value:obj.value});
        }

        return objs;


  }

  setData_cards(financiera,anticipos,maniobras){
    let val=[
      {
       "name": "Venta Financiera del Día",
       "value": financiera

      },
       {
           "name": "Anticipos Financieros del Día",
           "value": anticipos

      },
      {
           "name": "Maniobras Financieras del Día",
           "value": maniobras
      
      }];

      return val;


  }


  setData_cards_unidad(financiera,anticipos,maniobras,unidad_anticpo,unidad_maniobar){
    let val=[
      {
       "name": "Ventas por Unidades del Día",
       "value": financiera

      },
       {
           "name": "Número de Anticipos del Día",
           "value": anticipos

      },
      {
           "name": "Número de Maniobras del Día",
           "value": maniobras
      
      }];

      return val;


  }





}