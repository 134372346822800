import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-add-clientes',
  templateUrl: './add-clientes.component.html',
  styleUrls: ['./add-clientes.component.css']
})
export class AddClientesComponent implements OnInit {

	
  constructor(private router:Router) { }

  ngOnInit() {
    if (sessionStorage.getItem('token')==null) {
      this.router.navigate(["/login"]);
    }
  }

  Regresar(){
     this.router.navigate(["clientes"]);
  }
  AddConyugue(){
  	this.router.navigateByUrl('');
     this.router.navigate(["clientes/add/conyugue"]).then( (e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

}
