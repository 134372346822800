import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';

import * as bootstrap from 'bootstrap';
declare var $: any;



@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
	roles:any;
  rol_old:any;

	
	agreagarActividad:any=[];
	actividades:any;
  old_actividades:any;
  rol:any;
  eq_rol:any=[];
  ifDuplica=false;

  newNivelnew=""

  departamentos:any=null;

  constructor(private service:UsersService) { }

  ngOnInit() {



    this.newNivelnew="";

    localStorage.setItem('page','3');
    this.roles=[];
    this.getlvl();

    this.agreagarActividad=[];


    

   

  	this.service.getActividades().subscribe(result=>{
  		if ( result != null ) {
  			this.actividades=JSON.parse(JSON.stringify(result)).Datas;

        console.log(this.actividades);

        this.old_actividades=this.actividades;
  			
  		}
  	});

  	
    this.getAreas_active();

    this.getEntregables();

    this.getEmpleados();

    this.getDepartamentos();
  }


  lvls:any;

  getDepartamentos(){
     this.service.getDepartamentos().subscribe(res=>{
      res!=null? this.departamentos=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }



 getlvl(){
    
    this.service.getlvlpuestos('lvl_puestos').subscribe(res=>{
      res != null ? this.lvls=JSON.parse(JSON.stringify(res)).Datas:null;

      this.service.getRoles().subscribe(res=>{
      if (res!=null) {

        this.roles=JSON.parse(JSON.stringify(res)).Datas;
        this.rol_old=this.roles;
        
        for(let puesto of this.roles){

            this.service.getColabyRol(puesto.Data.rol_id).subscribe(res2=>{
              
              res2!=null? puesto.Data.cola=JSON.parse(JSON.stringify(res2)).Datas :  null;
              


            });

           

            puesto.Data.Nombre_lvl=this.lvls.find(dep=>dep.Data.lvl_puesto_id==puesto.Data.lvl_puesto_id);

            if(puesto.Data.Nombre_lvl!=undefined && puesto.Data.Nombre_lvl!="undefined" && puesto.Data.Nombre_lvl!=null){
              puesto.Data.Nombre_lvl=puesto.Data.Nombre_lvl.Data.titulo+" "+puesto.Data.Nombre_lvl.Data.lvl+""+puesto.Data.Nombre_lvl.Data.sublvl;
            }
           // puesto.Data.Nombre_lvl=puesto.Data.Nombre_lvl.titulo+" "+puesto.Data.Nombre_lvl.lvl+""+puesto.Data.Nombre_lvl.sublvl;
           //puesto.Data.Nombre_lvl=  puesto.Data.Nombre_lvl.Data.titulo+"-"+ puesto.Data.Nombre_lvl.Data.lvl+ puesto.Data.Nombre_lvl.Data.sublvl;
           console.log(puesto.Data.Nombre_lvl);
         }
      }

      console.log(this.roles)
    });
    });
  
  }


  insertArea(area){
    this.service.Insertlvl(area.form.value,'lvl_puesto_id','lvl_puestos').subscribe(res=>{
      
      this.getlvl();
      this.newNivelnew=area.form.value.titulo+"-"+area.form.value.lvl+area.form.value.sublvl;
      area.resetForm();
      alert("Se crea Nivel con exito");
      $('#createArea').modal('hide');
    });
  }


  crearNivel(form){
    console.log(form.form.value);
    if(form.form.value.lvl_puesto_id!=''){
    let mensaje=confirm("Estas a punto de crear un nuevo nivel,¿Seguro que quieres continuar?");
      if(mensaje){
        let ms:any=[];
        ms.titulo=form.form.value.lvl_puesto_id;
        this.service.Insertlvl(ms,'lvl_puesto_id','lvl_puestos').subscribe(res=>{
         this.getlvl();
      
          alert("Se crea Nivel con exito");
    
        });
      }
    }else{
      alert("Ingresa el nombre del nuevo Nivel.");
    }
  }
 Msj(m){
    alert(m);
  }


  addCliente(form){


   
    form.form.value.lvl_puesto_id=this.lvls.find(lvl=>
    lvl.Data.titulo+"-"+lvl.Data.lvl+lvl.Data.sublvl==form.form.value.lvl_puesto_id).Data.lvl_puesto_id;

    console.log(form.form.value);
    
   
        

    		this.service.postrol(form.form.value).subscribe(result=>{
    		  let obj=JSON.parse(JSON.stringify(result)).Datas;
          let lastId=obj[0].Data.rol_id;

          if(form.form.value.encargado!=""){
            
              
              let objt={
                rol_id:lastId
              }

          }
    		  

    		  this.pushActividad(lastId);

        
    	  });

        this.newDep="";

        form.resetForm();
    	

  

    }




  pushActividad(id){

  	console.log(this.agreagarActividad);

  	for(let actividad of this.agreagarActividad){
  		console.log(actividad);

  		if(actividad.actividad_id!=0 ){
  			this.service.postAct_rol(id,actividad.actividad_id).subscribe(result=>{
  				console.log(result);
  			});
  		}

  		
  	}

  	alert("Se Creo Puesto Con Exito");

    this.agreagarActividad=[];

    this.ngOnInit();

  	this.cerrar("addEquipo");

  }

 

  reloadAct(){


    this.service.getActividades().subscribe(res=>{

        let obs=JSON.parse(JSON.stringify(res)).Datas;

        let pos=obs.length-1;

        this.actividades=obs;

        this.agreagarActividad.push({ nombre :obs[pos].Data.nombre,actividad_id: obs[pos].Data.actividad_id});
        
        this.cerrar('Actividadteam');
        
        alert("Función Agregada");

    });
  }



  newActvAddlist:any=[]

  agregarlist(data,form){

  	 let Data={nombre:data.nombre,actividad_id:data.actividad_id};
    this.agreagarActividad.push({Data:Data});
    this.newActvAddlist.push({ nombre:data.nombre,actividad_id:data.actividad_id});
    form.resetForm();

  	$("#AgregarActividad").modal('hide');

    

    alert("Función Agregada");

  }


  quitarList(lista){
    console.log(lista.actividad_id);

       let mensaje=confirm("Al quiar la Actividad del Rol, Tambien se quitara de los equipos donde esta asignado este Rol.¿Seguro que se desas continuar?");
    
    if (mensaje) {

      this.service.deleteAct_rol(lista.rol_actividad_rol).subscribe(res=>{
            this.agreagarActividad=null;

            this.service.getRol_act_idacrualiza(this.rol.rol_id).subscribe(res=>{
              res != null ? this.agreagarActividad=JSON.parse(JSON.stringify(res)).Datas : null;
              console.log(res); 
            });
      });
      
      
    

        
    }
     
  }


  cerrar(modal){
    let md='#'+modal;
     $(md).modal('hide');
  }


  cerrar2(modal){
    
    this.agreagarActividad=[];
    this.ngOnInit();
    this.newNivelnew="";
    let md='#'+modal;
    $(md).modal('hide');
     
     
  }


  rolSet(data){

    
    this.agreagarActividad=[];

    console.log(data);

    this.ifDuplica=false;
   
      

  
    this.service.geteq_rol(data.rol_id).subscribe(res=>{
      res != null ? this.eq_rol=JSON.parse(JSON.stringify(res)).Datas : null;
       
    });

    this.rol=data;

     this.service.getRol_act_idacrualiza(this.rol.rol_id).subscribe(res=>{
      res != null ? this.agreagarActividad=JSON.parse(JSON.stringify(res)).Datas : null;
      console.log(res); 
      });
   
    
    

    this.rol.lvl_puesto_id=this.lvls.find(dep=>
      dep.Data.lvl_puesto_id==this.rol.lvl_puesto_id);



    this.rol.lvl_puesto_id= this.rol.lvl_puesto_id.Data.titulo+"-"+this.rol.lvl_puesto_id.Data.lvl+this.rol.lvl_puesto_id.Data.sublvl;
    
  
    this.newNivelnew=this.rol.lvl_puesto_id;


  }


quitarListActivida(lista){

  console.log(lista);

  let mensaje=confirm("Al quiar la Actividad del Rol, Tambien se quitara de los equipos donde esta asignado este Rol.¿Seguro que se desas continuar?");
    
    if (mensaje) {

      this.service.deleteAct_rol(this.rol.rol_actividad_rol).subscribe(res=>{

      });
      
      
      this.agreagarActividad=null;

      this.service.getRol_act_idacrualiza(this.rol.rol_id).subscribe(res=>{
      res != null ? this.agreagarActividad=JSON.parse(JSON.stringify(res)).Datas : null;
      console.log(res); 
      });
        
    }else{

    }
      
}




actauilizaRol(form){
  //???
  
  console.log(form.form.value);

    let savlvl=form.form.value.lvl_puesto_id;


    form.form.value.lvl_puesto_id=this.lvls.find(lvl=>
    lvl.Data.titulo+"-"+lvl.Data.lvl+lvl.Data.sublvl==form.form.value.lvl_puesto_id).Data.lvl_puesto_id;

 

     console.log(form.form.value);


    

    this.service.updateRol(this.rol.rol_id,form.form.value).subscribe(res=>{
      alert("Puesto actulizado");

      this.ngOnInit();
      this.cerrar2('actualizar2');
    });

    for(let act of this.newActvAddlist){
           this.service.postAct_rol(this.rol.rol_id,act.actividad_id).subscribe(result=>{
          console.log(result);
        });
    }

  form.form.value.lvl_puesto_id=savlvl;
  this.newActvAddlist=[];

}

deleteRol(){
 let mensaje= confirm("Si se elimina el Puesto se quitara de todos los Perfiles que lo tengan asignado.¿Seguro que se desea continuar?");
 if(mensaje){
   this.service.delete_Rol(this.rol.rol_id).subscribe(res=>{
     
   });

   this.service.getAct_rol_id(this.rol.rol_id).subscribe(resu=>{

     resu != null ? this.deletAll(JSON.parse(JSON.stringify(resu)).Datas) : null;
    
   });

    this.ngOnInit();
     this.cerrar2('actualizar2');
     alert("Se elimino Puesto");
 }
}

deletAll(data){
  for(let eq_rol of data){
     this.service.delete_Rol_eqp(eq_rol.Data.eq_rol).subscribe(res=>{
         
       });
  }

  
  
}



agregarActAfter(form){

  console.log(form);

  this.service.postAct_rol(this.rol.rol_id,form.actividad_id).subscribe(result=>{
          console.log(result);
        });

  for(let eq of this.eq_rol){
    this.service.postAct_eq(eq.Data.eq_rol,form.actividad_id).subscribe(result=>{

    });
  }

  this.agreagarActividad=null;

      this.service.getRol_act_idacrualiza(this.rol.rol_id).subscribe(res=>{
      res != null ? this.agreagarActividad=JSON.parse(JSON.stringify(res)).Datas : null;
      console.log(res); 
      });


     alert("Función Agregada");
}


crearActAfter(form){
  this.service.postActividad(form.form.value).subscribe(res=>{
    this.setActOnRol_EQ(JSON.parse(JSON.stringify(res)).Datas);
  });
  form.resetForm();
}

setActOnRol_EQ(act){
    let position=act.length-1;

    this.service.postAct_rol(this.rol.rol_id,act[position].Data.actividad_id).subscribe(result=>{
          console.log(result);
        });


  for(let eq of this.eq_rol){
    this.service.postAct_eq(eq.Data.eq_rol,act[position].Data.actividad_id).subscribe(result=>{

    });
  }

  this.agreagarActividad=null;

      this.service.getRol_act_idacrualiza(this.rol.rol_id).subscribe(res=>{
      res != null ? this.agreagarActividad=JSON.parse(JSON.stringify(res)).Datas : null;
      console.log(res); 
      });


     alert("Función Agregada");
     this.cerrar("CrearNewActAfter");

}

 duplicar(form){

    if(this.agreagarActividad.length != 0){

      this.service.postrol(form.form.value).subscribe(result=>{
      console.log(result);
      let obj=JSON.parse(JSON.stringify(result)).Datas;
      let lastId=obj[obj.length-1].Data.rol_id;

      this.pushActividad2(lastId);
      
    });
    }else{
      alert("No hay ninguna Función");
    }

    


  }


  pushActividad2(id){
    console.log(this.agreagarActividad);

    for(let actividad of this.agreagarActividad){
      console.log(actividad);

      if(actividad.actividad_id!=0 ){
        this.service.postAct_rol(id,actividad.Data.actividad_id).subscribe(result=>{
          console.log(result);
        });
      }

      
    }

    alert("Se duplico Puesto Con exito");

    this.agreagarActividad=[];
    this.ngOnInit();

    this.cerrar("actualizar2");

  }



  filtroRol(ev){

      if (ev==='') {
       this.roles=this.rol_old;

      }else{
        this.roles=this.rol_old;
        let val = ev.target.value;
        console.log(val);
        
        if (val && val.trim() != '') {
        this.roles = this.roles.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.roles=this.rol_old;
      }
     }
  }

  entregables:any;

  getEntregables(){
    this.service.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }


 


   addListEntregable2(form){
    
    this.misEntregables.push(this.entregables.find(entregable=>entregable.Data.titulo==form.form.value.entregable));
    console.log(this.misEntregables);
    alert("Entregable agregado");
    form.resetForm();
    this.newEntregable="";
    $('#addEntregable2').modal('hide');
    
  }

  newEntregable:any;
  misEntregables:any=[];
  actividad_old:any;

   quitarLista(id){
    this.misEntregables=this.misEntregables.filter(entregable=>entregable.Data.entregable_id!=id);
  }


  ////Activiadad nueva

  addActividades(form){

    
    if(this.misEntregables.length!=0){

      this.service.postActividad(form.form.value).subscribe(res=>{

        
         
          this.service.getActividades().subscribe(result=>
          {
            if (result!=null) {

              this.actividades=JSON.parse(JSON.stringify(result)).Datas;
             
              this.actividad_old=this.actividades;

              let lastPosition=this.actividades.length-1;

              for(let entregables of this.misEntregables)
              {
                  this.service.insertEntre_act(
                    this.actividades[lastPosition].Data.actividad_id,
                    entregables.Data.entregable_id).subscribe(res=>{
                  
                  
             
                });
              }

             

            }

          });
        
      });

    

    }else{
      let sinEntregable=confirm("No hay ningun entregable asignado a esta función. ¿Seguro que deseas continuar?");
        if(sinEntregable){
            this.service.postActividad(form.form.value).subscribe(res=>{
                $('#Actividadteam').modal('hide');
                alert("Se Agrego Con Exito");
            });
        }
    }

    this.getActividades(form.form.value.nombre).then(res=>{

    }).catch().then(()=>{

  

      form.resetForm();

      $('#CreateActividad').modal('hide');
    });

     
  }




 async getActividades(name){


    this.service.getActividades().subscribe(result=>{
      if ( result != null ) {
        this.actividades=JSON.parse(JSON.stringify(result)).Datas;
        this.old_actividades=this.actividades;


        let target={
            value:name
            }

        let f={
          target:target
        }

            this.filtro(f);
        
      }
    });
  }





filtro(ev){
      if (ev=='') {
       this.actividades=this.old_actividades;

      }else{
        this.actividades=this.old_actividades;

        let val = ev.target.value;
        
        
        if (val && val.trim() != '') {
        this.actividades = this.actividades.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.actividades=this.old_actividades;
      }
     }
}

empleados:any;

getEmpleados(){
  this.service.getEmleados().subscribe(res=>{
    res!=null?this.empleados=JSON.parse(JSON.stringify(res)).Datas:null;
  });
}


//crear depa
  newDep:any="";
areas_active:any;

 getAreas_active(){

    this.service.getAreas().subscribe(res=>{
      res != null ? this.areas_active=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.areas_active);
       
    });
  
  }

//
  createNewArea(form){
   
    if(form.form.value.area_id!=""){
       let ifCreate=confirm("Estas a punto de craear una nueva Área. ¿Seguro que quieres continuar?");
       let data={
       titulo:form.form.value.area_id,
       lvl_area_id:0
     }
       this.service.InsertAreas(data).subscribe(res=>{
         alert("Se creao Área con éxito.");

         this.getAreas_active();
       });

    }else{
      this.Msj("Para crear una nueva Área es neceario que ingreses el nombre de la Área.");
    }

    }

   


    newDepa(form){
     console.log(form.form.value);

    form.form.value.area_id=this.areas_active.find(area=>area.Data.titulo == form.form.value.area_id).Data.area_id;
    form.form.value.lvl_departamento_id=0;
     
    this.service.InsertDepartamento(form.form.value).subscribe(res=>{

      this.newDep=form.form.value.titulo;
      form.resetForm();
      this.getDepartamentos();
      
 
      alert("Se crea Departamento con exito");
      $('#crearNewDepartamento').modal('hide');
    });
  
  }






}


