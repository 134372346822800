import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler } from '@angular/common/http';

@Injectable()
export class ConfiguracionesService{

public url="http://67.205.145.224/durango/dashboard_api.php?type=";

	constructor(private _http: HttpClient) {
		
	}

	getIndicdores(id_negocio){
		return this._http.get(this.url+'sc_getindicadores&negocio_id='+id_negocio);
	}
	getcategoria(id_categoria){
		return this._http.get(this.url+'sc_getcategoria&negocio_id='+id_categoria);
	}

	updatestatus(id_categoria,status){

		return this._http.get(this.url+'sc_update_categorias&categoria='+id_categoria+'&status='+status);
	}
	updateidicador(id_negocio,id_indicador){
		return this._http.get(this.url+'sc_update_indicador&id_negocio='+id_negocio+'&id_indicador='+id_indicador);
	}

	updateidicador2(id_negocio,id_indicador){
		return this._http.get(this.url+'sc_update_indicador2&id_negocio='+id_negocio+'&id_indicador='+id_indicador);
	}

	getVentas(){
		return this._http.get(this.url+'sc_getGanancia');
	}

	

	getlabels(){
		return this._http.get(this.url+'sc_getlabels');
	}
	getvalues(val){
		return this._http.get(this.url+'sc_getvalues&label='+val);
	}
	
	//----------------------------------------------------------------------

	insertVentas(id_categoria,precio,comentario){
		// tslint:disable-next-line: max-line-length
		return this._http.get(this.url+'sc_insertVenta&id_categoria='+id_categoria+'&precio='+precio+'&comentario='+comentario);
	}

	getCategorias(){
		// tslint:disable-next-line: max-line-length
		return this._http.get(this.url+'sc_getCategorias');
	}

	getDesarrollos(){
		// tslint:disable-next-line: max-line-length
		return this._http.get(this.url+'sc_getDesarrollos');
	}

	







}