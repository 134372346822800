import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UsersService } from '../services/users.service';
declare var $: any;

@Component({
  selector: 'app-indseccion1',
  templateUrl: './indseccion1.component.html',
  styleUrls: ['./indseccion1.component.css']
})
export class Indseccion1Component implements OnInit {
	//grafica 1
	single: any[];
  view: any[] = [225, 200];
  view_card: any[] = [300, 110];
  pai_view:any[]=[520,300];
  
  view_bar:any=[600,500];
  solar:any={
     domain: ['#000000', '#FF0000']
  }

  percentTickFormatting(value){
     return "$"+new Intl.NumberFormat("es-MX").format(value);
  }
  parseTitlesCard(value){
    console.log(value)
    return "$"+new Intl.NumberFormat("es-MX").format(value.value);
  }
  // options
  gradient: boolean = false;
  animations: boolean = true;

  
  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  cardColor: string = '#232837';


  secciones:any=[];


  //grafica 1


  // options
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient_bar: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Efectivo';


   colorBar = {
    domain: ['#5AA454', '#9CB14DFF', '#53AB94', '#3E7E6D']
  };

  constructor(private service:UsersService) { }
  id:any=[];
  indicador:any=[]
  ngOnInit(){
  	this.id=localStorage.getItem('idindi');
  	this.getIndicador(this.id);
  }

   seccion1:any=[];
   seccion2:any=[];
   seccion3:any=[];
   barSeccion1:any=[];
   barSeccion2:any=[];



  getIndicador(id){
  	this.service.getConfigIndicador_id(id).subscribe(res=>{

  		if(res!=null){
  				let indi=JSON.parse(JSON.stringify(res)).Datas[0].Data;
  				
  				this.indicador=indi;
  				

  		}
  	});










  	this.service.get_secciones(id).subscribe(res=>{
  		if(res!=null){
        this.secciones=JSON.parse(JSON.stringify(res)).Datas;

        for(let _seccion of this.secciones){
           
            console.log("Seccion ----------------");
           
            let _nombreSeccion=_seccion.Data.titulo;
            let _dataBar=[];
            console.log("------------------");

            this.service.getsegmentos(_seccion.Data.seccion_id).subscribe(res=>{
              if (res!=null) {

                let segmentos=JSON.parse(JSON.stringify(res)).Datas;
                let posisciones=segmentos.length;
                let  suma_total_principal=0;
                _seccion.Data.grafic_segments=[];

                for(let segmento of segmentos){
                    
                  let tipo1=0;
                  let tipo2=0;
                  let tipo3=0;

                  let tipo="000";
                  let cat="";
                  let sub="";
                  let prod="";


                  if (segmento.Data.clasificador!='' && segmento.Data.clasificador != null) {
                   tipo1=1;
                   cat=segmento.Data.clasificador;
                  }

                  if (segmento.Data.sub_clasificador!='' && segmento.Data.sub_clasificador != null) {
                    tipo2=1;
                    sub=segmento.Data.sub_clasificador;
                  }

                  if (segmento.Data.prod!='' && segmento.Data.prod != null) {
                    tipo3=1;
                    prod=segmento.Data.prod;
                  }

                  tipo=""+tipo1+tipo2+tipo3;
                  
                  console.log(tipo);

                  let partes=[];


                    switch (tipo) {
                     case "100":
                       this.service.getDatesbyCategoria(cat).subscribe(res=>{
                        let arreglo=[];
                        if(res!=null)  {
                             
                             partes=JSON.parse(JSON.stringify(res)).Datas;
                             
                             let suma_partes=[];
                           
                             
                             for(let parte of partes){
                               console.log(segmento);
                               if (segmento.Data.algoritmo=="Suma") {
                                 suma_total_principal=suma_total_principal+parseFloat(parte.Data.total);
                               }
                               if(segmento.Data.algoritmo=="Resta"){
                                 suma_total_principal=suma_total_principal-parseFloat(parte.Data.total);
                               }
                               
                               
                              
                              _seccion.Data.grafic_data=[{
                              "name": _nombreSeccion,
                              "value": suma_total_principal
                              }];

                               let valores={"name" : parte.Data.nombre,
                                    "value" : parte.Data.total,
                                    "extra":{
                                      "efectivo":"$"+new Intl.NumberFormat("es-MX").format(parte.Data.total),
                                      "Data":parte.Data}
                                  };
                                  arreglo.push(valores);

                              
                           }
                           _seccion.Data.grafic_segments=arreglo;
                           


                         }else{
                            let valores={"name" : prod,
                                    "value" : 0};
                                  arreglo.push(valores);

                         }
                          _seccion.Data.grafic_segments=arreglo;
                           
                         });

                     break;     
                    
                     case "111":
                     this.service.getDatesbyCat_sub_prod(cat,sub,prod).subscribe(res=>{
                         let arreglo=[];
                          if(res!=null)  {
                             
                             partes=JSON.parse(JSON.stringify(res)).Datas;
                             
                             let suma_partes=[];
                           
                             
                             for(let parte of partes){
                               console.log(segmento);
                               if (segmento.Data.algoritmo=="Suma") {
                                 suma_total_principal=suma_total_principal+parseFloat(parte.Data.total);
                               }
                               if(segmento.Data.algoritmo=="Resta"){
                                 suma_total_principal=suma_total_principal-parseFloat(parte.Data.total);
                               }
                               
                               
                              
                                        _seccion.Data.grafic_data=[{
                                        "name": _nombreSeccion,
                                        "value": suma_total_principal
                                        }];

                                  let valores={"name" : parte.Data.nombre,
                                    "value" : parte.Data.total,
                                    "extra":{
                                      "efectivo":"$"+new Intl.NumberFormat("es-MX").format(parte.Data.total),
                                      "Data":parte.Data}
                                  };
                                  arreglo.push(valores);

                              
                           }
                           _seccion.Data.grafic_segments=arreglo;
                           


                         }else{
                            let valores={"name" : prod,
                                    "value" : 0};
                                  arreglo.push(valores);

                         }
                          _seccion.Data.grafic_segments=arreglo;
                           
                         });

                     break;     
                     // clasifcador/sub/prod
                     break;
                     case "110": 
                       this.service.getDatesbyCat_sub(cat,sub).subscribe(res=>{
                         let arreglo=[];
                         if(res!=null)  {
                             
                             partes=JSON.parse(JSON.stringify(res)).Datas;
                             
                             let suma_partes=[];
                           
                             
                             for(let parte of partes){
                               console.log(segmento);
                               if (segmento.Data.algoritmo=="Suma") {
                                 suma_total_principal=suma_total_principal+parseFloat(parte.Data.total);
                               }
                               if(segmento.Data.algoritmo=="Resta"){
                                 suma_total_principal=suma_total_principal-parseFloat(parte.Data.total);
                               }
                               
                               
                              
                                        _seccion.Data.grafic_data=[{
                                        "name": _nombreSeccion,
                                        "value": suma_total_principal
                                        }];

                                 let valores={"name" : parte.Data.nombre,
                                    "value" : parte.Data.total,
                                    "extra":{
                                      "efectivo":"$"+new Intl.NumberFormat("es-MX").format(parte.Data.total),
                                      "Data":parte.Data}
                                  };
                                  arreglo.push(valores);

                              
                           }
                           _seccion.Data.grafic_segments=arreglo;
                           


                         }else{
                            let valores={"name" : prod,
                                    "value" : 0};
                                  arreglo.push(valores);

                         }
                          _seccion.Data.grafic_segments=arreglo;
                           
                         });

                     break;     
                      //clasificador/sub
                     

                     case "101":
                     this.service.getDatesbyCat_prod(cat,prod).subscribe(res=>{
                         let arreglo=[];
                         if(res!=null)  {
                             
                             partes=JSON.parse(JSON.stringify(res)).Datas;
                             
                             let suma_partes=[];
                           
                             
                             for(let parte of partes){
                               console.log(segmento);
                               if (segmento.Data.algoritmo=="Suma") {
                                 suma_total_principal=suma_total_principal+parseFloat(parte.Data.total);
                               }
                               if(segmento.Data.algoritmo=="Resta"){
                                 suma_total_principal=suma_total_principal-parseFloat(parte.Data.total);
                               }
                               
                               
                      
                              _seccion.Data.grafic_data=[{
                                "name": _nombreSeccion,
                                "value": suma_total_principal
                              }];

                              let valores={"name" : parte.Data.nombre,
                                "value" : parte.Data.total,
                                "extra":{
                                  "efectivo":"$"+new Intl.NumberFormat("es-MX").format(parte.Data.total),
                                  "Data":parte.Data}
                              };
                              arreglo.push(valores);

                              
                           }
                           _seccion.Data.grafic_segments=arreglo;
                           


                         }else{
                            let valores={"name" : prod,
                                    "value" : 0};
                                  arreglo.push(valores);

                         }
                          _seccion.Data.grafic_segments=arreglo;
                           
                         });

                        
                     break; 

                     case "010":
                     // sub

                      this.service.getDatesbysub_Cat(sub).subscribe(res=>{
                         let arreglo=[];
                          if(res!=null)  {
                             
                             partes=JSON.parse(JSON.stringify(res)).Datas;
                             
                             let suma_partes=[];
                           
                             
                             for(let parte of partes){
                               console.log(segmento);
                               if (segmento.Data.algoritmo=="Suma") {
                                 suma_total_principal=suma_total_principal+parseFloat(parte.Data.total);
                               }
                               if(segmento.Data.algoritmo=="Resta"){
                                 suma_total_principal=suma_total_principal-parseFloat(parte.Data.total);
                               }
                               
                               
                              
                                _seccion.Data.grafic_data=[{
                                "name": _nombreSeccion,
                                "value": suma_total_principal
                                }];

                                let valores={"name" : parte.Data.nombre,
                                  "value" : parte.Data.total,
                                  "extra":{
                                    "efectivo":"$"+new Intl.NumberFormat("es-MX").format(parte.Data.total),
                                    "Data":parte.Data}
                                };

                                arreglo.push(valores);

                              
                           }
                           _seccion.Data.grafic_segments=arreglo;
                           


                         }else{
                            let valores={"name" : prod,
                                    "value" : 0};
                                  arreglo.push(valores);

                         }
                          _seccion.Data.grafic_segments=arreglo;
                           
                         });

                     break;     
                   

                     case "001":
           
                     //prod
                     this.service.getDatesby_prod(prod).subscribe(res=>{
                        let arreglo=[];
                         if(res!=null)  {
                             
                             partes=JSON.parse(JSON.stringify(res)).Datas;
                             
                             let suma_partes=[];
                           
                             
                             for(let parte of partes){
                               console.log(segmento);
                               if (segmento.Data.algoritmo=="Suma") {
                                 suma_total_principal=suma_total_principal+parseFloat(parte.Data.total);
                               }
                               if(segmento.Data.algoritmo=="Resta"){
                                 suma_total_principal=suma_total_principal-parseFloat(parte.Data.total);
                               }
                               
                            
                                _seccion.Data.grafic_data=[{
                                "name": _nombreSeccion,
                                "value": suma_total_principal
                                }];

                                console.log(parte.Data.nombre);

                                let valores={"name" : parte.Data.nombre,
                                    "value" : parte.Data.total,
                                    "extra":{
                                      "efectivo":"$"+new Intl.NumberFormat("es-MX").format(parte.Data.total),
                                      "Data":parte.Data}
                                  };
                                  arreglo.push(valores);

                              
                           }

                           _seccion.Data.grafic_segments=arreglo;
                           


                         }else{

                            let valores={"name" : prod,
                                    "value" : 0};
                                  arreglo.push(valores);

                         }
                          _seccion.Data.grafic_segments=arreglo;
                           
                         });

                     break;     
                  
                  }
 

                }
                    
              }

            });

            
        }
      
           
          
       
      } 


      

  	});

    this.infoPrincipal(id);
	 }

	  infoPrincipal(id){
     this.service.getsegmentos_principal(id).subscribe(res=>{
       if(res!=null){
         let segmentos=JSON.parse(JSON.stringify(res)).Datas;
         let posisciones=segmentos.length;
         let  suma_total_principal=0;

         for(let segmento of segmentos){
            
           let tipo1=0;
           let tipo2=0;
           let tipo3=0;

           let tipo="000";
           let cat="";
           let sub="";
           let prod="";


           if (segmento.Data.clasificador!='' && segmento.Data.clasificador != null) {
             tipo1=1;
             cat=segmento.Data.clasificador;
           }

           if (segmento.Data.sub_clasificador!='' && segmento.Data.sub_clasificador != null) {
            tipo2=1;
            sub=segmento.Data.sub_clasificador;
           }

           if (segmento.Data.prod!='' && segmento.Data.prod != null) {
            tipo3=1;
            prod=segmento.Data.prod;
           }

           tipo=""+tipo1+tipo2+tipo3;
          
           
           let secciones=[];

         switch (tipo) {
         case "100":
           this.service.getDatesbyCategoria(cat).subscribe(res=>{
             
              if(res!=null)  {
                 secciones=JSON.parse(JSON.stringify(res)).Datas;
               }
               return secciones;

           });
         break;
         case "111":
         this.service.getDatesbyCat_sub_prod(cat,sub,prod).subscribe(res=>{
              
              if(res!=null)  {
                 secciones=JSON.parse(JSON.stringify(res)).Datas;
               }
               return secciones;
           });
         // clasifcador/sub/prod
         break;
         case "110": 
           this.service.getDatesbyCat_sub(cat,sub).subscribe(res=>{
            
              if(res!=null)  {
                 secciones=JSON.parse(JSON.stringify(res)).Datas;
               }
               return secciones;
           });
          //clasificador/sub
         break;

         case "101":
         this.service.getDatesbyCat_prod(cat,prod).subscribe(res=>{
             
              if(res!=null)  {
                 secciones=JSON.parse(JSON.stringify(res)).Datas;
               }
               return secciones;
           });
         //clasificador/prod
         break;

         case "011":
         // sub/prod
          this.service.getDatesbysub_Cat_prod(sub,prod).subscribe(res=>{
       
              if(res!=null)  {
                
                 secciones=JSON.parse(JSON.stringify(res)).Datas;
                 
                 for(let seccion of secciones){
                   suma_total_principal=suma_total_principal+parseFloat(seccion.Data.total);
                   
                   this.setValuePrincipal(this.indicador,suma_total_principal);
                 }
               }
               
               
           });
         break; 

         case "010":
         // sub

          this.service.getDatesbysub_Cat(sub).subscribe(res=>{
           
              if(res!=null)  {
                 secciones=JSON.parse(JSON.stringify(res)).Datas;

                 
                 for(let seccion of secciones){
                   
                   suma_total_principal=suma_total_principal+parseFloat(seccion.Data.total);
                 
                   this.setValuePrincipal(this.indicador,suma_total_principal);
                 }

                 
                  
               }
               
           });
         break;

         case "001":
         //prod
         this.service.getDatesby_prod(prod).subscribe(res=>{
            
              if(res!=null)  {
                 secciones=JSON.parse(JSON.stringify(res)).Datas;
               }
               return secciones;
           });

         break;     
      
     }

         }
       }

     })

	 }


    async getValues(tipo1,cat,sub,prod){
     let value=[];
     switch (tipo1) {
         case "100":
           this.service.getDatesbyCategoria(cat).subscribe(res=>{
             
              if(res!=null)  {
                 value=JSON.parse(JSON.stringify(res)).Datas;
               }
               return value;

           });
         break;
         case "111":
         this.service.getDatesbyCat_sub_prod(cat,sub,prod).subscribe(res=>{
              
              if(res!=null)  {
                 value=JSON.parse(JSON.stringify(res)).Datas;
               }
               return value;
           });
         // clasifcador/sub/prod
         break;
         case "110": 
           this.service.getDatesbyCat_sub(cat,sub).subscribe(res=>{
            
              if(res!=null)  {
                 value=JSON.parse(JSON.stringify(res)).Datas;
               }
               return value;
           });
          //clasificador/sub
         break;

         case "101":
         this.service.getDatesbyCat_prod(cat,prod).subscribe(res=>{
             
              if(res!=null)  {
                 value=JSON.parse(JSON.stringify(res)).Datas;
               }
               return value;
           });
         //clasificador/prod
         break;

         case "011":
         // sub/prod
          this.service.getDatesbysub_Cat_prod(sub,prod).subscribe(res=>{
       
              if(res!=null)  {
                 value=JSON.parse(JSON.stringify(res)).Datas;
               }
               
               return value;
           });
         break; 

         case "010":
         // sub

          this.service.getDatesbysub_Cat(sub).subscribe(res=>{
           
              if(res!=null)  {
                 value=JSON.parse(JSON.stringify(res)).Datas;
                 console.log(value);
                 return value;
               }
               
           });
         break;

         case "001":
         //prod
         this.service.getDatesby_prod(prod).subscribe(res=>{
            
              if(res!=null)  {
                 value=JSON.parse(JSON.stringify(res)).Datas;
               }
               return value;
           });

         break;     
      
     }

     

   }

   setValuePrincipal(indi,total){
     this.single=[{
            "name": indi.titulo,
            "value": total
            }]
   }
   setBarra=false;
   infoBarravalue=[];

   setBarraOpen(value){
     this.setBarra=true;
     this.infoBarravalue=value.value;
     $('#toasts').toast('show');
   }
   setBarraclose(){
      this.setBarra=false;
       $('#toasts').toast('hide');
   }

	


}
