import { Component, OnInit } from '@angular/core';
import { UsersService} from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-lblgenric',
  templateUrl: './lblgenric.component.html',
  styleUrls: ['./lblgenric.component.css']
})
export class LblgenricComponent implements OnInit {

		page:any;
    carga=false;
    datas:any;

  areas:any=[];
  setarea:any; 
  oldAreas:any;
  
  constructor(private services:UsersService) { }

 async ngOnInit() {
  	this.loadDates().then(res=>{

    }).catch().then(()=>{
      this.carga=true;
    });
  }

   async loadDates(){
    this.page=await localStorage.getItem("page");
 
    switch (this.page) {
      case "8":
              this.datas={
                "titulo":"Áreas",
                "singular":"Área",
                "col":"lvl_area_id",
                "tbl":"lvl_areas"
              }
        break;
        case "9":
              this.datas={
                "titulo":"Colaboradores",
                "singular":"Colaborador",
                "col":"lvl_colaborador_id",
                "tbl":"lvl_colaboradores"
              }
        break;
        case "10":
              this.datas={
                "titulo":"Depatamentos",
                "singular":"Departamento",
                "col":"lvl_departamento_id",
                "tbl":"lvl_departamentos"
              }
        break;
        case "11":
              this.datas={
                "titulo":"Puestos",
                "singular":"Puesto",
                "col":"lvl_puesto_id",
                "tbl":"lvl_puestos"
              }
        break;
    }


    this.getAreas();
    
  }


  getAreas(){
    this.areas=[];

    this.services.getlvlpuestos(this.datas.tbl).subscribe(res=>{
      res != null ? this.areas=JSON.parse(JSON.stringify(res)).Datas:null;
      this.oldAreas=this.areas;
    });
  }




  insertArea(area){
    this.services.Insertlvl(area.form.value,this.datas.col,this.datas.tbl).subscribe(res=>{
      area.resetForm();
      this.getAreas();
      
      alert("Se crea Nivel con exito");
      $('#createArea').modal('hide');
    });
  }


  update(form){
   

    this.services.Updatelvl(form.form.value,this.setarea.lvl_puesto_id,this.datas.col,this.datas.tbl).subscribe(res=>{
      form.resetForm();
      this.getAreas();
      
      alert("Se Actualizo Nivel con Exito");
      $('#updateArea').modal('hide');
    });
  }


  delete(){
    
    let seguro=confirm("¿Estás seguro? Al hacerlo, se eliminara este Nivel");
    if(seguro){this.services.Deletelvl(this.setarea.lvl_puesto_id,this.datas.col,this.datas.tbl).subscribe(res=>{
          
          this.getAreas();
          
          alert("Se Elimino Nivel con Exito");
          $('#updateArea').modal('hide');
        });}
  }


 
setArea(data){
    this.setarea=data;
    console.log(this.setarea.lvl_area_id);
  }



  filtro(ev)
    {
      if (ev=='') {
       this.areas=this.oldAreas;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.areas = this.areas.filter((item) => {
          return (item.Data.titulo.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.areas=this.oldAreas;
      }
     }
    }




}
