import { Component, OnInit } from '@angular/core';
import { InmobiliariasServices } from '../services/inmobiliarias.service';

@Component({
  selector: 'app-inmobiliarias',
  templateUrl: './inmobiliarias.component.html',
  styleUrls: ['./inmobiliarias.component.css'],
  providers:[InmobiliariasServices]
})
export class InmobiliariasComponent implements OnInit {
	nombre="Inmobiliarias";
	inmobiliarias:any=[];
	id_update:any;
	inmo:any;
  constructor(private services:InmobiliariasServices) { }

  ngOnInit() {
  	this.services.getCatalogo('inmobiliarias').subscribe(result=>{
  		if (result!=null) {
  			this.inmobiliarias=JSON.parse(JSON.stringify(result)).Datas;
  		}
  	});
  }
 setiddelete(id){
    this.id_update=id;
  }
  postInmobiliarias(form){
  	this.services.postInmobiliarios(form.form.value).subscribe(result=>{ location.reload();	});
  }
  setid(data){
  	console.log(data);
  	this.inmo=data;
  }

  putInmobiliarias(form){
  	this.services.putInmobiliarios(form.form.value,this.inmo.inmobiliaria_id).subscribe(
  		resut=>{
  			location.reload();
  		});
  }

  deleteCatlogo(){
   	this.services.deleteCatalogo('inmobiliarias','inmobiliaria_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
   }



}
