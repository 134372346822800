import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pc-ingresos-dgo-block',
  templateUrl: './pc-ingresos-dgo-block.component.html',
  styleUrls: ['./pc-ingresos-dgo-block.component.css']
})
export class PcIngresosDgoBlockComponent implements OnInit {

	fecha='2020-05-24';
	fecha_sv="2020-05-24";

   page="p1";
    
 view: any[] = [235, 200];
 single=[{
      "name": "Pronóstico Financiero",
      "value": 30000

 }];

  single_Adm_25=[{
      "name": "Pronóstico Financiero",
      "value": 20000

 }];
  single_Adm_24=[{
      "name": "Pronóstico Financiero",
      "value": 30000

 }];





   single_pres=[{
      "name": "Total Pronóstico",
      "value": 21928.71

  },{
      "name": "Total Pronóstico",
      "value": 32000

  }];
   solar:any={
     
    domain: [ '#E44D25', '#3CC099','#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  
  }

   single_2=[{
      "name": "Total Diesel",
      "value": 20000

  }];

  single_prod=[{
      "name": "Total Pzas",
      "value": 60000

  }];

   grafica_1=[
  
  {  
    extra:{codigo:"ADMMMI001", val_form:new Intl.NumberFormat('es-MX').format(30000)}  ,
    name:"MANTENIMIENTO Y MEJORAS INSTALACIONES"  ,
    value:30000
  },
  {  
    extra:{codigo:"", val_form:new Intl.NumberFormat('es-MX').format(2000)}  ,
    name:"Otros"  ,
    value:2000
  }
 

]

   grafica_3=[
  {
    "name": "MANTENIMIENTO Y MEJORAS INSTALACIONES",
    "series": [
      {
        "name": "Gasto",
        "value": 20577.91,
        "extra":{name:"MANTENIMIENTO Y MEJORAS INSTALACIONES",codigo:"ADMMMI001", val_form:new Intl.NumberFormat('es-MX').format(20577.91)}
      },
      {
        "name": "Presupuesto",
        "value": 30000.00,
        "extra":{name:"MANTENIMIENTO Y MEJORAS INSTALACIONES",codigo:"ADMMMI001", val_form:new Intl.NumberFormat('es-MX').format(30000.00)}
      },
    ]
  },
    {
    "name": "MANTENIMIENTO MAQUINARIA Y EQUIPO",
    "series": [
      {
        "name": "Gasto",
        "value": 834.88,
        "extra":{name:"MANTENIMIENTO MAQUINARIA Y EQUIPO",codigo:"ADMMMI001", val_form:new Intl.NumberFormat('es-MX').format(834.88)}
      },
      {
        "name": "Presupuesto",
        "value": 1000,
        "extra":{name:"MANTENIMIENTO MAQUINARIA Y EQUIPO",codigo:"ADMMMI001", val_form:new Intl.NumberFormat('es-MX').format(1000)}
      },

    ]
  },
  {
    "name": "MANTENIMIENTO EQUIPO DE ACARREO",
    "series": [
      {
        "name": "Gasto",
        "value": 515.92,
        "extra":{name:"MANTENIMIENTO EQUIPO DE ACARREO",codigo:"ADMMMI001", val_form:new Intl.NumberFormat('es-MX').format(515.92)}
      },
      {
        "name": "Presupuesto",
        "value": 1000,
        "extra":{name:"MANTENIMIENTO EQUIPO DE ACARREO",codigo:"ADMMMI001", val_form:new Intl.NumberFormat('es-MX').format(1000)}
      },

    ]
  },

  ];


showLegend: boolean = false

 grafica_2=[
  
  {  
    extra:{codigo:"ADMCUL001", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
    name:"Adopoasto  amarillo paja"  ,
    value:15000
  },
  {  
    extra:{codigo:"ADMCUL002", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
    name:"Adoquin hueso café rosa"  ,
    value:15000
  },
  {  
    extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(5577.91)}  ,
    name:"Bloque 12 mitad promoción"  ,
    value:13000
  },
  {  
    extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
    name:"Bloque 12 alta resistencia"  ,
    value:12000
  },
  {  
    extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
    name:"Otros"  ,
    value:5000
  },

]

  

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient_bar: boolean = false;
  
  showXAxisLabel: boolean = true;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  xAxisLabel = "Productos \n (80/20)";


  cardColor: string = '#232837';
  constructor() { }

  ngOnInit() {
  }

   totalDia:any;
  subtitulo:any;
  dia_hinabil:any;
  Titulo_grafica:any;
   grafic_diesel=[];

  initGasolina(){

    this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
    this.subtitulo="Pronóstico de Ventas"
    this.fecha='2020-08-14';
    this.dia_hinabil="Sin Información del Día"
    this.Titulo_grafica="";

    this.grafic_diesel=this.grafic_1_set([

      
      
                      
    ]);
  }

    initProduccion(){

    this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
    this.subtitulo="Pronóstico de Producción"
    this.fecha='2020-08-14';
    this.dia_hinabil="Sin Información del Día"
    this.Titulo_grafica="";

    this.grafic_diesel=this.grafic_1_set([

      
      
                      
    ]);
  }


 	parseTitlesCard(value){
    return "$"+new Intl.NumberFormat('es-MX').format(value.value);
  }

   cambioFecha_diecel(){
    switch (this.fecha) {
      case "2020-08-02":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        
        
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
         case "2020-08-09":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
       
       
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
         case "2020-08-16":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        
       
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
      
      default:
         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        
       
        
        this.Titulo_grafica="";
         this.dia_hinabil="Sin Información del Día";
        break;
    }

  }

  cambioFecha(form){
  	console.log(form.form.value.cat);
  	
  	switch (form.form.value.cat) {
  		case "VTA":
  					this.switch_ADM();			
  			break;
  		
  		default:
  					alert("No se encontro Informacion valida para esta categoria");
  			break;
  	}
    
  }

    cambioFecha_pl_prod(form){
    console.log(form.form.value.cat);
    
    
     this.switch_ADM_prod();      
        
    
    
  }



  switch_ADM(){

  		switch (this.fecha) {
  			case "2020-05-24":
  								this.single=this.single_Adm_24;
  								this.grafica_2=this.grafic_1_set([{  
												    
												    nombre:"MANTENIMIENTO Y MEJORAS INSTALACIONES"  ,
												    value:30000
												  },
												  {  
												   
												    nombre:"Otros"  ,
												    value:2000
												  }])
  								this.fecha_sv=this.fecha;
  				break;

  				case "2020-05-25":
  							this.single=this.single_Adm_25;
  							this.grafica_1=this.grafic_1_set([{  
												    
												    nombre:"DIESEL AUTOMOTRIZ"  ,
												    value:10000
												  },
												  {  
												    
												    nombre:"DIESEL INDUSTRIAL"  ,
												    value:8000
												  },
												  {  
												   
												    nombre:"Otros"  ,
												    value:2000
												  }])
  							this.fecha_sv=	this.fecha;
  				break;
  				case "2020-05-26":
  							this.single=	this.single=this.single_Set("Pronóstico Financiero",26000);
  							this.grafica_1=this.grafic_1_set([{  
												    
												    nombre:"DIESEL AUTOMOTRIZ"  ,
												    value:11000
												  },
												  {  
												    
												    nombre:"DIESEL INDUSTRIAL"  ,
												    value:9000
												  },
												  {  
												   
												    nombre:"Otros"  ,
												    value:6000
												  }])
  							this.fecha_sv=	this.fecha;
  				break;
  				case "2020-05-27":
  							this.single=this.single_Set("Pronóstico Financiero",25000);
  							this.grafica_1=this.grafic_1_set([{  
												    
												    nombre:"MANTENIMIENTO Y MEJORAS INSTALACIONES"  ,
												    value:20000
												  },
												  {  
												   
												    nombre:"Otros"  ,
												    value:5000
												  }])
  							this.fecha_sv=	this.fecha;
  				break;
  				case "2020-05-28":
  							this.single=this.single_Set("Pronóstico Financiero",15000);
  							this.grafica_1=this.grafic_1_set([{  
												    
												    nombre:"MANTENIMIENTO Y MEJORAS INSTALACIONES"  ,
												    value:13000
												  },
												  {  
												   
												    nombre:"Otros"  ,
												    value:2000
												  }])
  							this.fecha_sv=	this.fecha;
  				break;
  			
  			default:
  						this.fecha=this.fecha_sv;
  						alert("No se encontro Informacion valida para esta fecha");

  				break;
  		}
  }


    switch_ADM_prod(){

      switch (this.fecha) {
        case "2020-05-24":
                  this.single_prod= this.single=this.single_Set("Total Pzas",60000);
                  this.grafica_2=this.grafic_2_set([

                        
                        {  
                          extra:{codigo:"ADMCUL001", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adopoasto  amarillo paja"  ,
                          value:15000
                        },
                        {  
                          extra:{codigo:"ADMCUL002", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adoquin hueso café rosa"  ,
                          value:15000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(5577.91)}  ,
                          name:"Bloque 12 mitad promoción"  ,
                          value:13000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Bloque 12 alta resistencia"  ,
                          value:12000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Otros"  ,
                          value:5000
                        },
                    ])
                  this.fecha_sv=this.fecha;
          break;

          case "2020-05-25":
                 this.single_prod= this.single=this.single_Set("Total Pzas",70000);
                  this.grafica_2=this.grafic_2_set([

                        
                        {  
                          extra:{codigo:"ADMCUL001", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adopoasto  amarillo paja"  ,
                          value:20000
                        },
                        {  
                          extra:{codigo:"ADMCUL002", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adoquin hueso café rosa"  ,
                          value:20000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(5577.91)}  ,
                          name:"Bloque 12 mitad promoción"  ,
                          value:13000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Bloque 12 alta resistencia"  ,
                          value:12000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Otros"  ,
                          value:5000
                        },
                    ])
                  this.fecha_sv=this.fecha;
          break;
          case "2020-05-26":
                  this.single_prod= this.single=this.single_Set("Total Pzas",40000);
                  this.grafica_2=this.grafic_2_set([

                        
                        {  
                          extra:{codigo:"ADMCUL001", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adopoasto  amarillo paja"  ,
                          value:20000
                        },
                        {  
                          extra:{codigo:"ADMCUL002", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adoquin hueso café rosa"  ,
                          value:15000
                        },
                        
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Otros"  ,
                          value:5000
                        },
                    ])
                  this.fecha_sv=this.fecha;
          break;
          case "2020-05-27":
                  this.single_prod= this.single=this.single_Set("Total Pzas",60000);
                  this.grafica_2=this.grafic_2_set([

                        
                        {  
                          extra:{codigo:"ADMCUL001", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adopoasto  amarillo paja"  ,
                          value:15000
                        },
                        {  
                          extra:{codigo:"ADMCUL002", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adoquin hueso café rosa"  ,
                          value:15000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(5577.91)}  ,
                          name:"Bloque 12 mitad promoción"  ,
                          value:13000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Bloque 12 alta resistencia"  ,
                          value:12000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Otros"  ,
                          value:5000
                        },
                    ])
                  this.fecha_sv=this.fecha;
          break;
          case "2020-05-28":
                  this.single_prod= this.single=this.single_Set("Total Pzas",52000);
                  this.grafica_2=this.grafic_2_set([

                        
                        {  
                          extra:{codigo:"ADMCUL001", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adopoasto  amarillo paja"  ,
                          value:25000
                        },
                        {  
                          extra:{codigo:"ADMCUL002", val_form:new Intl.NumberFormat('es-MX').format(10000)}  ,
                          name:"Adoquin hueso café rosa"  ,
                          value:15000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(5577.91)}  ,
                          name:"Bloque 12 mitad promoción"  ,
                          value:5000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Bloque 12 alta resistencia"  ,
                          value:5000
                        },
                        {  
                          extra:{codigo:"PROCUL001", val_form:new Intl.NumberFormat('es-MX').format(6577.91)}  ,
                          name:"Otros"  ,
                          value:2000
                        },
                    ])
                  this.fecha_sv=this.fecha;
          break;
        
        default:
              this.fecha=this.fecha_sv;
              alert("No se encontro Informacion valida para esta fecha");

          break;
      }
  }



  single_Set(title,value){
  	let val=[{
													      "name": title,
													      "value": value

													 }];
  	return  val;

  }


  grafic_1_set(objeto_1){

  			let objs=[];
  			for(let obj of objeto_1){
  					objs.push({
  									extra:{val_form:new Intl.NumberFormat('es-MX').format(obj.value)}  ,
							    name:obj.nombre  ,
							    value:obj.value});
  			}

  			return objs;


  }


  grafic_2_set(objeto_1){

        let objs=[];
        for(let obj of objeto_1){
            objs.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(obj.value)}  ,
                  name:obj.name  ,
                  value:obj.value});
        }

        return objs;


  }

}

