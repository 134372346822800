import { Component, OnInit } from '@angular/core';
import { Columna } from '../modals/columna';


@Component({
  selector: 'app-demotables',
  templateUrl: './demotables.component.html',
  styleUrls: ['./demotables.component.css']
})
export class DemotablesComponent implements OnInit {
 public columnas:Array<Columna>;

  constructor() {
  	this.columnas=[new Columna("Nombre","String")]
   }

  ngOnInit() {
  	console.log(this.columnas);
  }

}
