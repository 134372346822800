import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
@Component({
  selector: 'app-addroles',
  templateUrl: './addroles.component.html',
  styleUrls: ['./addroles.component.css']
})
export class AddrolesComponent implements OnInit {
	crearActividad:any=[];
	agreagarActividad:any=[];
	actividades:any;
  constructor(private service:UsersService) { }

  ngOnInit(){
  	this.service.getActividades().subscribe(result=>{
  		if ( result != null ) {
  			this.actividades=JSON.parse(JSON.stringify(result)).Datas;
  			console.log(this.actividades);
  		}
  	});

  }
  addCliente(form){
  	if(this.crearActividad!=null){
  		this.service.postrol(form.form.value).subscribe(result=>{
  		console.log(result);
  		let obj=JSON.parse(JSON.stringify(result)).Datas;
  		let lastId=obj[obj.length-1].Data.rol_id;

  		this.pushActividad(lastId);
  	});
  	}else{
  		alert("No hay ninguna actividad");
  	}

  	


  }


  pushActividad(id){
  	console.log(this.agreagarActividad);

  	for(let actividad of this.agreagarActividad){
  		
  		if(actividad.actividad_id!=0 ){
  			this.service.postAct_rol(id,actividad.actividad_id).subscribe(result=>{
  				console.log(result);
  			});
  		}
  		
  	}

  	alert("Se Creo Rol Con Exito");

  }

  addActividades(addActividad){

  	this.crearActividad.push({nombre:addActividad.form.value.nombre});

  	console.log(this.crearActividad);
  	alert("Actividad Agregada");
  	location.reload();

  }


  agregarlist(form){
  	let value=this.actividades.find(d=>d.Data.actividad_id=form.form.value.id);
  	
  	this.agreagarActividad.push({ nombre :value.Data.nombre,actividad_id:value.Data.actividad_id});
  	alert("Actividad Agregada");

  }


}
