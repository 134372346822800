import { Component, OnInit,ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { ClientesService } from '../services/clientes.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
  providers:[ClientesService]
})
export class ClientesComponent implements OnInit {

	clientes :any=[];
  clientes_respaldo :any=[];
  cliente:any;
	semaforo="green";
	ifClientes=true;
  idCliente;
  conyugue:any=[];
  element:any;
  ifConyugue=false;
  promotores:any=[];
  promotor=2;
  user_id:any;

  constructor(private el:ElementRef,private router:Router, private service:ClientesService) {
    }

  ngOnInit() {
       this.user_id=sessionStorage.getItem("token");

  	  this.getClientes();
     

  }

  getClientes(){
     this.conyugue=null;
      this.service.getClientes().subscribe(data=>{
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.clientes=json.Clientes;
      this.clientes_respaldo=this.clientes;
      this.ifClientes=true;
    },error=>{  
      console.log(error);
    });
  }

  updateContacto(form){
    console.log(this.cliente[0].Cliente);
    console.log(form.form.value);
  }

   add(){
    this.router.navigate(["clientes/add"]);
  }
  getCliente(id){
    this.router.navigate(["cliente/"+id]);
  }



  addCliente(form){
    console.log(form.form.value);
    this.service.unsertCliente(form.form.value,this.user_id).subscribe(data=>{
       console.log(data);
    },error=>{
      console.log(error);
    });

  }
  filtro(ev)
    {
      if (ev=='') {
       this.clientes=this.clientes_respaldo;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.clientes = this.clientes.filter((item) => {
          return (item.Cliente.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

        if(this.clientes.length==0){
          this.clientes = this.clientes_respaldo.filter((item) => {
          return (item.Cliente.apellido_paterno.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });
        }


      }else{
       this.clientes=this.clientes_respaldo;
      }
     }
    }


}
