import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, Params } from "@angular/router";


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  status:any;
  menu:any;

  constructor(private router:Router, private ar:ActivatedRoute) { }

  ngOnInit() {

   
     if(sessionStorage.getItem('id')!=null){
        this.menu=localStorage.getItem('page');
        this.status=sessionStorage.getItem('status');
    }else{
      this.router.navigate(["login"]);
    }
  }


  irlvl(lvl){
   
    switch (lvl) {
      case "8":
             
            this.router.navigate(['/lvlarea']);  
        break;
        case "9":
               this.router.navigate(['/lvlcol']);
        break;
        case "10":
              this.router.navigate(['lvldep']);
        break;
        case "11":
               this.router.navigate(['/lvlpuesto']);
        break;

      


    
    }
    
     localStorage.setItem('page',lvl);
   
  }

    logout(){
    sessionStorage.clear();
    localStorage.clear();
    location.reload();
  }
}
