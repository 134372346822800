import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class AvaluosService{

public url="http://67.205.145.224/durango/dashboard_api.php?type=";
public urls="http://67.205.145.224/durango/dashboard_api.php";


	constructor(private http: HttpClient) {

		
	}

	getAvaluos(){
		return this.http.get(this.url+'sc_getAvaluos');
	}

	getCatalogo(catalogo){
		return this.http.get(this.url+"sc_getCatalogo&tabla="+catalogo);
	}
	getInmuebles(){
		return this.http.get(this.url+"sc_getInmuebles");
	}
	postAvaluo(data){
		return this.http.get(this.url+"sc_postAvaluos&inmueble_id="+data.inmueble_id+"&valuador_id="+data.valuador_id
			+"&esquema_id="+data.esquema_id+"&valor_concluido="+data.valor_concluido
			+"&fecha_solicitud_avaluo="+data.fecha_solicitud_avaluo+"&gasto_avaluo="+data.gasto_avaluo
			+"&fecha_cierre_avaluo="+data.fecha_cierre_avaluo+"&status_avaluo="+data.status_avaluo+"&fecha_vencimiento="+data.fecha_vencimiento);
	}

	putAvaluo(data,id){
		return this.http.get(this.url+"sc_putAvaluos&inmueble_id="+data.inmueble_id+"&valuador_id="+data.valuador_id
			+"&esquema_id="+data.esquema_id+"&valor_concluido="+data.valor_concluido
			+"&fecha_solicitud_avaluo="+data.fecha_solicitud_avaluo+"&gasto_avaluo="+data.gasto_avaluo
			+"&fecha_cierre_avaluo="+data.fecha_cierre_avaluo+"&status_avaluo="+data.status_avaluo
			+"&fecha_vencimiento="+data.fecha_vencimiento+"&id="+id);
	}


	


	



}