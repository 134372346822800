import { ModuleWithProviders ,NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router' ;


import { LoginComponent } from './login/login.component';
import { UsuariosAdminComponent } from './usuarios-admin/usuarios-admin.component';
import { InicioComponent } from './inicio/inicio.component';
import { EquiposComponent } from './equipos/equipos.component';
import { ActividadesComponent } from './actividades/actividades.component';
import { RolesComponent } from './roles/roles.component';
import { MyRolsComponent } from './my-rols/my-rols.component';

import { CargandoComponent } from './cargando/cargando.component';
import { Rol2Component } from './rol2/rol2.component';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { ResportesComponent } from './resportes/resportes.component';
import { NivelesComponent } from './niveles/niveles.component';

import { DepartamentosComponent } from './departamentos/departamentos.component';
import { AreasComponent } from './areas/areas.component';
import { EntregablesComponent } from './entregables/entregables.component';
import { LblgenricComponent } from './lblgenric/lblgenric.component';
import { LblareaComponent } from './lblarea/lblarea.component';
import { LblcolaComponent } from './lblcola/lblcola.component';
import { LbldepComponent } from './lbldep/lbldep.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { OnlyComponent } from './only/only.component';


const routes: Routes =[
	

	

	{path:'login',component: LoginComponent},
	
	{path:'incio',component: OnlyComponent},
	/*
	{path:'admin',component: UsuariosAdminComponent},
	{path: 'equipos',component: EquiposComponent },
	{path: 'actividades',component: ActividadesComponent },
	{path: 'roles',component: RolesComponent },
	{path: 'misroles',component: MyRolsComponent },
	{path: 'carga',component: CargandoComponent },
	
	{path: 'indicadores',component: IndicadoresComponent },
	{path: 'roles321',component: Rol2Component },
	{path: 'reportes',component: ResportesComponent },
	{path: 'niveles',component: NivelesComponent },

	{path: 'entregables',component: EntregablesComponent },
	{path: 'departamentos',component: DepartamentosComponent },
	{path: 'niveles',component: NivelesComponent },
	{path: 'areas',component: AreasComponent },
	{path: 'lvlpuesto',component: LblgenricComponent },
	{path: 'lvlarea',component: LblareaComponent },
	{path: 'lvlcol',component: LblcolaComponent },
	{path: 'lvldep',component: LbldepComponent },
	{path: 'colaboradores',component: ColaboradoresComponent },
*/

	]; 
	
      
    

export const appRoutingProviders:any[]=[];
export const routing:ModuleWithProviders=RouterModule.forRoot(routes);
