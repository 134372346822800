import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';



@Component({
  selector: 'app-grafica1',
  templateUrl: './grafica1.component.html',
  styleUrls: ['./grafica1.component.css']
})
export class Grafica1Component implements OnInit {

  constructor() { }
	barChartPlugins = [pluginDataLabels]; 
	barChartType: ChartType = 'bar';
	barChartLegend = true;
	barChartData: ChartDataSets[] = 
		[{ 
			data: [25000,26000,25000],
			label: 'Produccion' 
		},
		{ 
			data: [30000,30000,30000],
			label: 'Pronostico' 
		},
		{
			data:[150,126,174],
			label:'Merma'
		}];

		barChartLabels=['Enero','Febrero','Marzo'];
	barColors: any[] = [
	  { 
	    backgroundColor:["#5CBC42FF", "#5CBC42FF", "#5CBC42FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#FF9160FF"] 
	  },
	  {
	    backgroundColor:["#FFD800FF", "#FFD800FF", "#FFD800FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#FF9160FF"] 
	  },
	   {
	    backgroundColor:["#B12B1FFF", "#B12B1FFF", "#B12B1FFF","#FFD800FF","#4800FFFF","#A0A0A0FF","#FF9160FF"] 
	  }];


  barChartOptions: ChartOptions = {
    responsive: true,
    
    
    plugins: {

       
      datalabels: {
        anchor: 'end',
        
        color:'#000000FF',
         formatter: function(value, context) {
                    return new Intl.NumberFormat("es-MX").format(value);;
                },
         
        font: {
          size: 10,
          weight: 'bold',
        }
        
      }
    }
  };

  ngOnInit() {
  }

}
