import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';


@Component({
  selector: 'app-status-avaluos',
  templateUrl: './status-avaluos.component.html',
  styleUrls: ['./status-avaluos.component.css'],
  providers:[CatalogosService]

})
export class StatusAvaluosComponent implements OnInit {
	status_avaluos:any=[];
	nombre:any;
	id_update:any;
	descrip:any;
  	constructor(private servicios:CatalogosService) { }

  	ngOnInit(){
  		  this.nombre="Estatus Avaluos";
            this.servicios.getCatalogo("status_avaluos").subscribe(result=>{
               if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.status_avaluos=temp2.Datas;
               }
            });
  	}
  	setid(id,des){
    this.id_update=id;
    this.descrip=des;
  }
  setiddelete(id){
    this.id_update=id;
  }
    postStatus(form){
    	this.servicios.postCatalogo('status_avaluos','status_avaluo_id',form.form.value.status).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
    }
   putStatus(form){
   	this.servicios.putCatalogo('status_avaluos','status_avaluo_id',form.form.value.status,this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));
   }
   deleteCatlogo(){
   	this.servicios.deleteCatalogo('status_avaluos','status_avaluo_id',this.id_update).subscribe(result=>{
        location.reload();
      },error=>console.log(error));  
   }

}
