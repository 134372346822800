import { Component, OnInit } from '@angular/core';
import { UsersService} from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.css']
})
export class AreasComponent implements OnInit {
	areas:any=[];
	setarea:any; 
  oldAreas:any;
  lvlAreas:any;
  lvls:any;
  
  constructor(private services:UsersService) { }

  ngOnInit() {
  	 localStorage.setItem('page','6');
  	 this.getAreas();
     this.getlvlAreas();
  }
  crearNivel(form){
    if(form.form.value.id_nivel!=''){
    let mensaje=confirm("Estas a punto de crear un nuevo nivel,¿Seguro que quieres continuar?");
      if(mensaje){
        let ms:any=[];
        ms.titulo=form.form.value.id_nivel;
        this.services.Insertlvl(ms,'lvl_area_id','lvl_areas').subscribe(res=>{
         this.getlvlAreas();
      
          alert("Se crea Nivel con exito");
    
        });
      }
    }else{
      alert("Ingresa el nombre del nuevo Nivel.");
    }
  }
  getlvls(){
    this.services.getlvl('lvl_areas').subscribe(res=>{
      res != null ? this.lvls=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.lvls);
    });
  }

  getAreas(){
    this.areas=[];
  	this.services.getAreas().subscribe(res=>{
  		res != null ? this.areas=JSON.parse(JSON.stringify(res)).Datas:null;
      this.oldAreas=this.areas;
  	});

  }
  Msj(m){
    alert(m);
  }

  getlvlAreas(){
    this.services.getlvl("lvl_areas").subscribe(res=>{
      res!=null?this.lvlAreas=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.lvlAreas);
    })
  }

  insertArea(area){

    area.form.value.lvl_area_id=0;

    
  	this.services.InsertAreas(area.form.value).subscribe(res=>{
  		area.resetForm();
  		this.getAreas();
  		
  		alert("Se crea Area con exito");
  		$('#createArea').modal('hide');
  	});

  }

  
  setArea(data){
  	this.setarea=data;

    
  }

  update(form){

     form.form.value.lvl_area_id=0;
  	this.services.UpdateAreas(form.form.value,this.setarea.area_id).subscribe(res=>{
  		form.resetForm();
  		this.getAreas();
  		
  		alert("Se crea Area con actualizo");
  		$('#updateArea').modal('hide');
  	});
  }


  delete(){
  	
  	let seguro=confirm("¿Estás seguro? Al hacerlo, se eliminara esta Área");
  	if(seguro){this.services.DeleteAreas(this.setarea.area_id).subscribe(res=>{
  	  		
  	  		this.getAreas();
  	  		
  	  		alert("Se crea Area con exito");
  	  		$('#updateArea').modal('hide');
  	  	});}
  }


 




  filtro(ev)
    {
      if (ev=='') {
       this.areas=this.oldAreas;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.areas = this.areas.filter((item) => {
          return (item.Data.titulo.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.areas=this.oldAreas;
      }
     }
    }


}
