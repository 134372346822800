import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';


@Component({
  selector: 'app-sincronizacion',
  templateUrl: './sincronizacion.component.html',
  styleUrls: ['./sincronizacion.component.css']
})
export class SincronizacionComponent implements OnInit {

  constructor(private services: UsersService) { }

  ngOnInit() {
  }

}
