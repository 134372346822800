import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ev-egresos',
  templateUrl: './ev-egresos.component.html',
  styleUrls: ['./ev-egresos.component.css']
})
export class EvEgresosComponent implements OnInit {

  constructor() { }
    page_now="Egresos";
  ngOnInit() {
  }
  loadPage(page){
    this.page_now=page;
  }
}
