import { Component, OnInit } from '@angular/core';
import { ValuadoresService } from '../services/valuadores.service';

@Component({
  selector: 'app-valuadores',
  templateUrl: './valuadores.component.html',
  styleUrls: ['./valuadores.component.css'],
  providers: [ValuadoresService]
})
export class ValuadoresComponent implements OnInit {
  nombre="Valuadores";
  valuadores:any=[];
  valudor:any;
  delete_id:any;
  constructor(private services:ValuadoresService) { }

  ngOnInit() {
  	this.services.getValuadores().subscribe(result=>{
  		if (result!=null) {
  			this.valuadores=JSON.parse(JSON.stringify(result)).Datas;
  		}
  	});
  }

  setid(data_up,id){
  	this.valudor=data_up;
  	
  }
   postValuadores(form){
  	this.services.postValuadores(form.form.value).subscribe(result=>{
  		location.reload();
  	},error=>{
  		console.log(error);
  	});
  }

  putValuadores(form){
  	this.services.putValuadores(form.form.value,this.valudor.valuador_id).subscribe(result=>{
  		location.reload();
  	},error=>{
  		console.log(error);
  	});
  }

  setiddelete(id){
  	this.delete_id=id;

  }

  deleteValuadores(){
  	this.services.deleteValuadores("valuador_id","valuadores",this.delete_id).subscribe(
  		result=>{location.reload();});
  }

}
