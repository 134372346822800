import { Injectable } from '@angular/core'; 
import {Configuracion} from '../modals/configurador';
import { HttpClient , HttpHandler } from '@angular/common/http';


@Injectable()
export class ConfiguradorService{
	public data:any;
	public url="http://67.205.145.224/durango/dashboard_api.php?type=";
	public test:string="https://reqres.in";

	constructor(private _http: HttpClient) {
		//this.data =new Configuracion(0,0,false,true,false,true,true,true,true,true,true,true);
		}

	getData(){
		
		return this._http.get(this.url+'sc_getConfiguracion&negocio_id='+1);
		//return this._http.get(this.test+'/api/users/2');
	}

	update(value){
	
	let indicador=value.indicador;
	let tipo_vista=value.vista_por;
	let alimentos=value.alimentos?1:0;
	let bebidas=value.bebidas?1:0;
	let vinos=value.vinos?1:0;
	let licores=value.licores?1:0;
	let postres=value.postres?1:0;
	let cerveza=value.cervezas?1:0;
	let efectivo=value.efectivo?1:0;
	let credito=value.credito?1:0;
	let tarjeta_credito=value.tarjeta_credito?1:0;
	let tarjeta_debito=value.tarjeta_debito?1:0;



	return this._http.get(this.url+
		'sc_insertConfiguracion&negocio_id='+1
		+'&indicador='+indicador
		+'&pos='+tipo_vista
		+'&alimentos='+alimentos
		+'&bebidas='+bebidas
		+'&vinos='+vinos
		+'&licores='+licores
		+'&postres='+postres
		+'&cerveza='+cerveza
		+'&efectivo='+efectivo
		+'&credito='+credito
		+'&tarjeta_debito='+tarjeta_debito
		+'&tarjeta_credito='+tarjeta_credito
		);
		
	}
}							