import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { Router,ActivatedRoute, Params } from "@angular/router";
import * as bootstrap from 'bootstrap';
declare var $: any;


@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.css']
})
export class ActividadesComponent implements OnInit {
	actividades:any;
  actividad:any;
  actividad_old:any;
  entregables:any;
  misEntregables:any=[];
  newEntregable:any="";
  puestos:any;


  me_id:any;
  status:any;
  colaboradores:any=[];
  myDep:any;
  me:any;

  misPuestos:any=[];

  lista_puestos:any=[];


   listaFechas:any=[];

  constructor(private services:UsersService,private router:Router, private ar:ActivatedRoute) { }

  ngOnInit() {
    localStorage.setItem('page','4');
    this.misEntregables=[];
    this.getPuestos();
    
  	this.services.getActividades().subscribe(result=>
  	{
  		if (result!=null) {
				this.actividades=JSON.parse(JSON.stringify(result)).Datas;
				console.log(this.actividades);
        this.actividad_old=this.actividades;
			}

  	});


    this.me_id=sessionStorage.getItem('id');
    this.status=sessionStorage.getItem('status');
    this.colaboradores=[];

    console.log(this.me_id);

  

    this.services.getMe(this.me_id).subscribe(user=>{
      user!=null? this.me=JSON.parse(JSON.stringify(user)).Datas[0].Data:null;  
        console.log(this.me);
         this.getPuestosbyDep(this.me.departamento_id);
      
    });


    this.getEntregablesAll();
  }


    getPuestosbyDep(id){

      this.services.getPuestosBydep(id).subscribe(res=>{
       if(res!=null){
        this.misPuestos=JSON.parse(JSON.stringify(res)).Datas;
          console.log(this.misPuestos);
          this.lista_puestos=this.misPuestos;
        }
      });

    }

    addFecha(form){
   
    this.listaFechas.push(form.form.value);

    alert("Fecha Agregada");

    form.resetForm();

     $('#exampleModal').modal('hide');

  }

  getPuestos(){
    this.services.getRoles().subscribe(res=>{
      console.log(res);
      if(res!=null){
         this.puestos=JSON.parse(JSON.stringify(res)).Datas;
         for(let puesto of this.puestos){
            this.services.getColabyRol(puesto.Data.rol_id).subscribe(res2=>{
              res2!=null? puesto.Data.cola=JSON.parse(JSON.stringify(res2)).Datas :  puesto.Data.cola="No Hay Colaborador Encargado en este Puesto.";
            });
         }
      }
    });
  }

  getEntregablesAll(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }

  insertArea(area){
      area.form.value.rol_id=this.puestos.find(puesto=>puesto.Data.nombre==area.form.value.rol_id).Data.rol_id;
      
    this.services.InsertEntregables(area.form.value).subscribe(res=>{

        console.log(res);
        let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
        for(let fecha of this.listaFechas){
           this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
             console.log("fecha insertada");
           });
        }
       
       this.newEntregable=area.form.value.titulo;

       this.listaFechas=[];
       area.resetForm();
       $('#createEntrgable').modal('hide');
        this.getEntregablesAll();
      alert("Se creo Entregable con éxito");
    });

  }
  rol_id:any;

  addActividades(form){

    form.form.value.departamento_id=this.me.departamento_id;
    form.form.value.user_id=this.me_id;
    form.form.value.rol=form.form.value.rol_id;

    this.rol_id=this.misPuestos.find(puesto=>puesto.Data.nombre==form.form.value.rol_id).Data.rol_id;
    console.log(this.rol_id);

    //this.setActivi_col();
    

    if(this.misEntregables.length!=0){
      this.services.postActividad(form.form.value).subscribe(res=>{

          console.log("-----------Retur add active");
            console.log(res);
         
         
            

             let actividad_id=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;

               console.log(actividad_id);

              for(let entregables of this.misEntregables)
              {
                  this.services.insertEntre_act(
                  actividad_id,
                  entregables.Data.entregable_id).subscribe(res=>{
                
                });
              }

              this.setActivi_col(actividad_id);
            

         


        //this.ngOnInit();
        //$('#Actividadteam').modal('hide');
        //alert("Se Agrego Con Exito");
      });

    form.resetForm();

    }else{

      let sinEntregable=confirm("No hay ningun entregable asignado a esta función. ¿Seguro que deseas continuar?");
        if(sinEntregable){
            this.services.postActividad(form.form.value).subscribe(res=>{

            console.log("-----------Retur add active");
            console.log(res);
            let actividad_id=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
            console.log(actividad_id);
             this.setActivi_col(actividad_id);
            console.log("-----------Retur add active");
                
                
               
            });
        }
    }
  
  	

  }


  addActividadesbyDep(form){

    form.form.value.departamento_id=this.me.departamento_id;
    form.form.value.user_id=this.me_id;
    form.form.value.rol="";

   // this.rol_id=this.misPuestos.find(puesto=>puesto.Data.nombre==form.form.value.rol_id).Data.rol_id;
    //console.log(this.rol_id);

    //this.setActivi_col();
    

    if(this.misEntregables.length!=0){
      this.services.postActividad(form.form.value).subscribe(res=>{

          console.log("-----------Retur add active");
            console.log(res);
         
         
            

             let actividad_id=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;

               console.log(actividad_id);

              for(let entregables of this.misEntregables)
              {
                  this.services.insertEntre_act(
                  actividad_id,
                  entregables.Data.entregable_id).subscribe(res=>{
                
                });
              }

              this.setActivi_colbyDep(actividad_id);
            

         


        //this.ngOnInit();
        //$('#Actividadteam').modal('hide');
        //alert("Se Agrego Con Exito");
      });

    form.resetForm();

    }else{

      let sinEntregable=confirm("No hay ningun entregable asignado a esta función. ¿Seguro que deseas continuar?");
        if(sinEntregable){
            this.services.postActividad(form.form.value).subscribe(res=>{

            console.log("-----------Retur add active");
            console.log(res);
            let actividad_id=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
            console.log(actividad_id);
             this.setActivi_colbyDep(actividad_id);
            console.log("-----------Retur add active");
                
                
               
            });
        }

        form.resetForm();

    }
  
    

  }


   setActivi_colbyDep(act_id){ 

     for(let puesto of this.lista_puestos){

        this.services.getCol_dep_rol(puesto.Data.rol_id,this.me.departamento_id).subscribe(res=>{
         
         if (res!=null){
           let colaboradores=JSON.parse(JSON.stringify(res)).Datas;

           for(let colab of colaboradores){
               console.log(colab);
             this.services.postAct_col(colab.Data.id_nombre,act_id).subscribe(res=>{
               
             });
             
            }
          }
         
       });
     }

      this.ngOnInit();
      alert("Se Agrego Con Exito");
      this.cerraPorDepa();

     


  }


  setActivi_col(act_id){ 

     this.services.getCol_dep_rol(this.rol_id,this.me.departamento_id).subscribe(res=>{
       
       if (res!=null){
         let colaboradores=JSON.parse(JSON.stringify(res)).Datas;

         for(let colab of colaboradores){
             console.log(colab);
           this.services.postAct_col(colab.Data.id_nombre,act_id).subscribe(res=>{
             
           });
           
          }
        }
        this.ngOnInit();
        alert("Se Agrego Con Exito");
        $('#Actividadteam').modal('hide');
     });


  }

  setAct(date){
    console.log(date);
    this.actividad=date;
    this.getEntregablesbyId();
    this.newEntregable="";

  }

  oldEntregables:any;

  getEntregablesbyId(){
    this.oldEntregables=[];
    this.services.getEntregablebyId(this.actividad.actividad_id).subscribe(res=>{
       res!=null? this.oldEntregables=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }




  actualizarActividades(form){


    form.form.value.id=this.actividad.actividad_id;
    this.services.updateActividad(form.form.value).subscribe(res=>{
     this.ngOnInit();
     $('#Actualizar').modal('hide');
     alert("Se actualzio Con Exito");
    });

   
  }

  formFuncion:any;


  
  setFormulario(form){
    console.log(form.form.value);
    this.formFuncion=form;
  }


   AddnewFuncion(form){

       form.form.value.descripcion=this.formFuncion.form.value.descripcion;
       
       this.misEntregables=this.oldEntregables;

       
       this.addActividades(form);

       $("#Actividadteam").modal('hide');

       $("#Actualizar").modal('hide');
       
       $("#validarAgregarEntregable").modal('hide');

       $("#validarActualizarOduplicar").modal('hide');

       $("#validarEliminarEntregable").modal('hide');
       
  }

  id_delete:any;
  setIdDelete(id){
    this.id_delete=id;
  }


  SiActualizar(){
    this.actualizarActividades(this.formFuncion);
    $("#validarActualizarOduplicar").modal('hide');
  }



  deleteActividad(){

    let msj=confirm("Al eliminar la Actividad se quitara de Rol y Equipos,¿ Seguro que se desea continuar?");
    if (msj) {
       this.services.delete_Acividad(this.actividad.actividad_id).subscribe(re=>{});
       this.services.delete_Aciv_rol(this.actividad.actividad_id).subscribe(re=>{});
       this.services.delete_Aciv_eq(this.actividad.actividad_id).subscribe(re=>{});
       

       alert("Se Elimino Actividad");
       this.ngOnInit();
       $('#Actualizar').modal('hide');
    }
  }

  filtro(ev){

      if (ev==='') {
       this.actividades=this.actividad_old;

      }else{
        this.actividades=this.actividad_old;
        let val = ev.target.value;
        console.log(val);
        
        if (val && val.trim() != '') {
        this.actividades = this.actividades.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.actividades=this.actividad_old;
      }
     }
  }



  addListEntregable2(form){
    let entreg=
    this.misEntregables.push(this.entregables.find(entregable=>entregable.Data.titulo==form.form.value.entregable));
    console.log(this.misEntregables);
    alert("Entregable agregado");
    form.resetForm();
    this.newEntregable="";
    $('#addEntregable2').modal('hide');
    
  }

  siAddEntregable(){
    $('#validarAgregarEntregable').modal('hide');
  }






  quitarLista(id){
    this.misEntregables=this.misEntregables.filter(entregable=>entregable.Data.entregable_id!=id);
  }

  quitarLista_puestos(id){

    let seguro=confirm("¿Seguro que desas continuar? Si continuas no se asignara esta Función a este Puesto.");
    if(seguro){
      this.lista_puestos=this.lista_puestos.filter(puesto=>puesto.Data.rol_id!=id);
      alert("Se quito es puesto con éxito.");
    }
  }


  cerraPorDepa(){
       $('#ActividadDepartamento').modal('hide');
       this.lista_puestos=this.misPuestos;
  }

  addListEntregableold(form){

    if(form.form.value.entregable!=""){
        let id=this.entregables.find(entr=>entr.Data.titulo==form.form.value.entregable).Data.entregable_id;
        this.services.insertEntre_act(
        this.actividad.actividad_id,id).subscribe(res=>{
                  
        this.getEntregablesbyId();

        $('#ActualizarEntregables').modal('hide');
        alert("Se agrego entregable con éxito");
      });
    }else{
      alert("Es necesario seleccionar un Entregable para continuar");
    }
   
  }


  eliminarEntre_act(){
    let  seguro=confirm("¿Seguro que deseas continuar?");
    if (seguro) {
        $('#validarEliminarEntregable').modal('hide');
        this.services.deleteentreAct(this.id_delete).subscribe(res=>{
          this.getEntregablesbyId();
        });
    }
  }

}
