import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-seg-finanzas-dgoblock',
  templateUrl: './seg-finanzas-dgoblock.component.html',
  styleUrls: ['./seg-finanzas-dgoblock.component.css']
})
export class SegFinanzasDgoblockComponent implements OnInit {

	page='p1'
	fecha:any='2020-09-01';
	subtitulo:any='Posición Mensual de Efectivo';
	totalDia:any="$"+new Intl.NumberFormat('es-MX').format(3329638.71);
	dia_hinabil="";


	view_test: any[] = [750, 110];

	 date_cards:any=[
	 {
	      "name": "Saldo Inicial",
	      "value": 1020401.87

	 },
	  {
	      "name": "Total Ingresos",
	      "value": 157463.45

	 },
	 {
	      "name": "Total Erogaciones",
	      "value": -47.07

	 
	}
	,
	 {
	      "name": "Varación Neta de Efectivo",
	      "value": 1020401.87+157463.45-47.07,


	 
	}];

	cardColor: string = '#232837';


	view_barras_ventas_diarias=[700,600];
	gradient_bar: boolean = false;
	showXAxisLabel: boolean = true;
	yAxisLabel: string = '';
	showYAxisLabel: boolean = true;
 xAxisLabel = "";
 showXAxis: boolean = true;
  showYAxis: boolean = true;

	grafica_1:any=[
		{
			value:157463.45,
			name:'COBRO DE CLIENTES POR VENTAS',
			extra:{val_form:new Intl.NumberFormat('es-MX').format(157463.45)}  ,
		},
		{
			value:-47.07,
			name:'COMISIONES',
			extra:{val_form:new Intl.NumberFormat('es-MX').format(-47.07)}  ,
		}
	

	];

	grafic_inter:any=[]


 constructor(private service:UsersService) { }

 ngOnInit() {
 	this.service.get_lastDate('rp_flujo_efectivo').subscribe(res=>{
 			this.fecha=JSON.parse(JSON.stringify(res)).Datas[0].Data['fecha'];
 			this.sw_flujo();
 	});
 	

 }

 InitCxC(){
 	this.subtitulo='Total Pronóstico de Cobranza de Logo';
 	this.fecha='2020-09-10';
 	this.service.get_lastDate('rp_cxc').subscribe(res=>{
 			this.fecha=JSON.parse(JSON.stringify(res)).Datas[0].Data['fecha'];
 			this.sw_CxC();
 	});
 	this.typeGrafic='logos';
 	

 }

 InitCxP(){
 	this.subtitulo='Antigüedad de Saldos y Pronóstico de Pagos a Proveedores INTERCOMPAÑIAS';
 this.service.get_lastDate('rp_cxp').subscribe(res=>{
 			this.fecha=JSON.parse(JSON.stringify(res)).Datas[0].Data['fecha'];
 			this.sw_CxP();
 	});
 	this.typeGrafic='logos';
 	

 }

 una_barra:any=[750,90];
 tres_barra:any=[750,300]
 cinco_barra:any=[750,400];
 sies_barra:any=[750,300];
 grafic_publico=[];
 grafic_mostra=[];
 total_inter:any=0;
 total_publico:any=0;
 total_mostrador:any=0;
 total_otros:any=0;
 grafic_otros:any=[];

 typeGrafic='logos';


 cambioCxC(form){
 	console.log(form.form.value.tipo);
 	this.dia_hinabil='';
 		switch (form.form.value.tipo) {


 			case "logos":
 			this.subtitulo='Total Pronóstico de Cobranza del Grupo';
 				this.sw_CxC();
 				this.typeGrafic='logos';

 				break;

 				case "clientes":
 				
 				this.sw_CxC_2();
 				this.subtitulo='Total Pronóstico de Cobranza de Clientes';
 				this.typeGrafic='clientes';
 				
 				break;
 			
 			default:
 				// code...
 				break;
 		}
 }

 cambioCxP(form){
 	console.log(form.form.value.tipo);
 	this.dia_hinabil='';
 		switch (form.form.value.tipo) {


 			case "logos":
 			this.subtitulo='Antigüedad de Saldos y Pronóstico de Pagos a Proveedores Intercompañias';
 				this.sw_CxP();
 				this.typeGrafic='logos';

 				break;

 				case "clientes":
 				
 				this.sw_CxP_2();
 				this.subtitulo='Antigüedad de Saldos y Pronóstico de Pagos a Proveedores (80/20)';
 				this.typeGrafic='clientes';
 				
 				break;
 			
 			default:
 				// code...
 				break;
 		}
 }

 sw_CxC(){
 	this.subtitulo='Total Pronóstico de Cobranza del Grupo';

 	this.service.getrSincro(this.fecha,'rp_cxc').subscribe(ress=>{
 		
 		if(ress!=null){
 				console.log(ress);
 			let sincro = JSON.parse(JSON.stringify(ress)).Datas[0].Data;

 			
 			this.service.getrp_info_unidad_cx(sincro.sincro_id,'cxc').subscribe(res=>{
 				if(res!=null){
 					let cxc=JSON.parse(JSON.stringify(res)).Datas;
 					let suma=0;
 					let intercompañia=0;
 					let inter:any={
 							day1:0,
 							day2:0,
 							day3:0,
 							day4:0
 					};

 						let cr:any={
 							day1:0,
 							day2:0,
 							day3:0,
 							day4:0
 					};

 					let cruza=0;

 					let logo=0;
 						let lg:any={
 							day1:0,
 							day2:0,
 							day3:0,
 							day4:0
 					};

 					for(let row of cxc){
 							console.log(row.Data['nombre']);
 						if(row.Data['nombre']=='INTERCOMPAÑIAS'){
 							intercompañia=intercompañia+parseFloat(row.Data['total']);
 							inter.day1=parseFloat(row.Data['15day']);
 							inter.day2=parseFloat(row.Data['30day']);
 							inter.day3=parseFloat(row.Data['45day']);
 							inter.day4=parseFloat(row.Data['46day']);

 							suma=suma+parseFloat(row.Data['total']);
 						}

 						if(row.Data['nombre']=='TRACTOSERVICIOS CRUZA SA DE CV'){
 							cruza=cruza+parseFloat(row.Data['total']);
 							cr.day1=parseFloat(row.Data['15day']);
 							cr.day2=parseFloat(row.Data['30day']);
 							cr.day3=parseFloat(row.Data['45day']);
 							cr.day4=parseFloat(row.Data['46day']);
 							suma=suma+parseFloat(row.Data['total']);
 						}
 						
 						if(row.Data['nombre']=='GRUPO CONSTRUCTOR LOGO SA DE CV'){
 							logo=logo+parseFloat(row.Data['total']);
 							lg.day1=parseFloat(row.Data['15day']);
 							lg.day2=parseFloat(row.Data['30day']);
 							lg.day3=parseFloat(row.Data['45day']);
 							lg.day4=parseFloat(row.Data['46day']);
 							suma=suma+parseFloat(row.Data['total']);
 						}
 					}

 					this.totalDia="$"+new Intl.NumberFormat('es-MX').format(suma);

 					this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value":	intercompañia

		 				},
		 				{
		      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		      "value":cruza

		 				},
		 				{
		      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		      "value":logo

		 				}];


		 			this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':inter.day1,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(inter.day1)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':inter.day2,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(inter.day2)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':inter.day3,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(inter.day3)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':inter.day4,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(inter.day4)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':cr.day1,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(cr.day1)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':cr.day2,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(cr.day2)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':cr.day3,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(cr.day3)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':cr.day4,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(cr.day4)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':lg.day1,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(lg.day1)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':lg.day2,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(lg.day2)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':lg.day3,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(lg.day3)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':lg.day4,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(lg.day4)}
	 				 }


	 				 ]

	 				}];
	 				
 				}else{
 						this.dia_hinabil="Día Inhábil";
 						this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
 					let suma=0;
 					let intercompañia=0;
 					

 			

 				this.grafic_inter=[];
					this.date_cards=[];

 				}

 			});
 		}else{
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
 					this.dia_hinabil="Sin Información";
 					let suma=0;
 					let intercompañia=0;
 					let inter:any={
 							day1:0,
 							day2:0,
 							day3:0,
 							day4:0
 					};

 						let cr:any={
 							day1:0,
 							day2:0,
 							day3:0,
 							day4:0
 					};

 					let cruza=0;

 					let logo=0;
 						let lg:any={
 							day1:0,
 							day2:0,
 							day3:0,
 							day4:0
 					};

 					

 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);

 				this.date_cards=[];


		 		this.grafic_inter=[


 				 ];

 		}
 	});
 	
 	/*switch (this.fecha) {

 		case "2020-09-10":
 
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(8089603.31);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value":	7067035.97

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				},
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];

 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':269116.12,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(269116.12)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':217677.59,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(217677.59)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':95182.85,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(110863.72)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6395719.45,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }


	 				 ]

	 				}];
	 				
 			break;
 			case "2020-09-09":
 
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(8000263.35);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value": 6977696.01

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				},
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];

 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':268743.94,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(268743.94)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':194613.83,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(194613.83)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':95182.85,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(110863.72)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6395719.45,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }


	 				 ]

	 				}];

 			break;

 			case "2020-09-08":
 
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 6891152.7+800400+222167.34);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value": 6954260.07

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				},
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];

 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':214606.62,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(244986.71)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':185643.78,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(104215.3)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':95182.85,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(110863.72)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6395719.45,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }


	 				 ]

	 				}];
 	


 				break;

 		case "2020-09-07":
 				this.total_inter=new Intl.NumberFormat('es-MX').format(6891152.7);
 				this.total_publico=new Intl.NumberFormat('es-MX').format(126457.53);
 				this.total_mostrador=new Intl.NumberFormat('es-MX').format(0);
 				this.total_otros=new Intl.NumberFormat('es-MX').format(2346180.58);
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 6891152.7+800400+222167.34);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value": 6891152.7

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				},
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];

 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':214606.62,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(244986.71)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':185643.78,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(104215.3)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':95182.85,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(110863.72)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6395719.45,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }


	 				 ]

	 				}];
 	


 				break;
 			

 			case "2020-09-05":
 			
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(6891152.7+800400+222167.34);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value": 6891152.7

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				}
	 				,
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];


 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':238585.97,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(238585.97)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':147444.45,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(147444.45)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':169207.79,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(169207.79)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6321694.51,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }


	 				 ]

	 				}];

	 		break;


	 		case "2020-09-06":
 				this.dia_hinabil='';
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
 				
 				this.date_cards=[];


 				this.grafic_inter=[];

	 		break;

	 			


	 		case "2020-09-04":
 			
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 6876932.72+800400+222167.34);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value": 6876932.72

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				}
	 				,
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];


	 					


 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':274893.88,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(274893.88)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':79756.69,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79756.69)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':	169207.79,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(	169207.79)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6321694.51,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }

	 				 
	 				 ]

	 				}];

	 		break;

	 		case "2020-09-03":
 			
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 6845552.87+800400+222167.34);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value": 6845552.87

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				}
	 				,
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];


	 					


 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':244986.71,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(274893.88)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':89676.25,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79756.69)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':	125402.77,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(	169207.79)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6321694.51,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }

	 				 
	 				 ]

	 				}];

	 		break;

	 		case "2020-09-02":
 			
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 6781760.24+800400+222167.34);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value": 6781760.24

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				}
	 				,
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];


	 					


 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':244986.71,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(274893.88)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':89676.25,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79756.69)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':	125402.77,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(	169207.79)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6321694.51,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }

	 				 
	 				 ]

	 				}];

	 		break;

	 		case "2020-09-01":
 			
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 6781760.24+800400+222167.34);
 				
 				this.date_cards=[{
	      "name": "INTERCOMPAÑIAS",
	      "value": 6781760.24

	 				},
	 				{
	      "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	      "value":800400

	 				}
	 				,
	 				{
	      "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	      "value":222167.34

	 				}];


	 					


 				this.grafic_inter=[{
 				 "name": "INTERCOMPAÑIAS",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':244986.71,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(244986.71)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':104215.3,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(104215.3)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':	110863.72,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(	110863.72)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':6321694.51,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6321694.51)}
 				 }


 				 ]

	 				},
	 				{
	 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':800400,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(800400)}
	 				 }


	 				 ]

	 				},
	 				{
	 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':222167.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(222167.34)}
	 				 }

	 				 
	 				 ]

	 				}];

	 		break;
 			
 		default:

 				this.dia_hinabil='Sin Información';
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 0);
 				
 				this.date_cards=[];

 			this.grafic_inter=[];
 				
 				this.grafic_publico=[];

 				
 				

 				this.grafic_mostra=
 				[];

 				this.grafic_otros=[];

 			break;
 		}
		*/

 }

	sw_CxP(){
		this.date_cards=[];
		this.grafic_inter=[];


					let total_15day=0;
						let total_30day=0;
						let total_45day=0;
						let total_46day=0;

						let gcl_15day=0;
						let gcl_30day=0;
						let gcl_45day=0;
						let gcl_46day=0;

						let logo_15day=0;
						let logo_30day=0;
						let logo_45day=0;
						let logo_46day=0;

						let cruza_15day=0;
						let cruza_30day=0;
						let cruza_45day=0;
						let cruza_46day=0;
		this.service.getrSincro(this.fecha,'rp_cxp').subscribe(res=>{
			if(res!=null){
				let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
				this.service.getrp_info_unidad_cx(sincro.sincro_id,'cxp').subscribe(res=>{
					if (res!=null) {
						
						let cxp=JSON.parse(JSON.stringify(res)).Datas;
						let cxp_gcl=cxp.find(cx=>cx.Data.codigo=='PRO047');
						let cxp_logo=cxp.find(cx=>cx.Data.codigo=='PRO015');
						let cxp_cruza=cxp.find(cx=>cx.Data.nombre=='TRACTOSERVICIOS CRUZA SA DE CV');

			


						if(cxp_gcl!=undefined){
							console.log(cxp_gcl);
								gcl_15day=parseFloat(cxp_gcl.Data['15day']);
								gcl_30day=parseFloat(cxp_gcl.Data['30day']);;
								gcl_45day=parseFloat(cxp_gcl.Data['45day']);;
							 gcl_46day=parseFloat(cxp_gcl.Data['46day']);;
						}

						if(cxp_logo!=undefined){
							console.log(cxp_logo);
								logo_15day=parseFloat(cxp_logo.Data['15day']);
								logo_30day=parseFloat(cxp_logo.Data['30day']);;
								logo_45day=parseFloat(cxp_logo.Data['45day']);;
							 logo_46day=parseFloat(cxp_logo.Data['46day']);;
						}

							if(cxp_cruza!=undefined){
								console.log(cxp_cruza);
								cruza_15day=parseFloat(cxp_cruza.Data['15day']);
								cruza_30day=parseFloat(cxp_cruza.Data['30day']);;
								cruza_45day=parseFloat(cxp_cruza.Data['45day']);;
							 cruza_46day=parseFloat(cxp_cruza.Data['46day']);;
						}


						 total_15day=gcl_15day+logo_15day+cruza_15day;
						 total_30day=gcl_30day+logo_30day+cruza_30day;
							total_45day=gcl_45day+logo_45day+cruza_45day;
							total_46day=gcl_46day+logo_46day+cruza_46day;

						console.log(cxp_gcl);
						console.log(cxp_cruza);
						console.log(cxp_logo);
	 
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total_15day+total_30day+total_45day+total_46day);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":total_15day

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":total_30day

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":total_45day

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":total_46day

		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':gcl_15day,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(gcl_15day)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':gcl_30day,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(gcl_30day)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':gcl_45day,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(gcl_45day)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':gcl_46day,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(gcl_46day)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':logo_15day,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(logo_15day)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':logo_30day,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(logo_30day)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':logo_45day,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(logo_45day)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':logo_46day,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(logo_46day)}
		 				 }


		 				 ]


		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':cruza_15day,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(cruza_15day)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':cruza_30day,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(cruza_30day)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':cruza_45day,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(cruza_45day)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':cruza_46day,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(cruza_46day)}
		 				 }


		 				 ]
		 				 

		 				}];
						

					}else{
						this.dia_hinabil='Día Inhábil';
						this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
						this.grafic_inter=[];
						this.date_cards=[];
					}
				});
			}else{
				this.dia_hinabil='Sin Información';
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
				this.grafic_inter=[];
						this.date_cards=[];
			}

		});





	/*
	 	
	 		switch (this.fecha) {
	 		 case "2020-09-10":
	 
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22680966.11);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11

		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				}];
	 	


	 		break;

	 		 case "2020-09-09":
	 
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22975182.61);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":294216.50

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11

		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':294216.5,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(294216.5)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 }


		 				 ]

		 				}];
	 	


	 		break;

	 		case "2020-09-08":
	 
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22975182.61);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":294216.50

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11

		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':294216.5,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(294216.5)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 }


		 				 ]

		 				}];
	 	


	 		break;

	 		case "2020-09-07":
	 				
	 			this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22975182.61);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":294216.50

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11



		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':294216.5,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(294216.5)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 }


		 				 ]

		 				}];


	 				break;
	 			

	 		case "2020-09-05":
	 				
	 			this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22975182.61);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":294216.50

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11



		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':294216.5,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(294216.5)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 }


		 				 ]

		 				}];


	 				break;


		 	case "2020-09-06":
	 			this.dia_hinabil='Día Inhábil';
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
	 				
	 				this.date_cards=[];


	 				this.grafic_inter=[];

		 		break;

		 			


		 		case "2020-09-04":
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22975182.61);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":294216.50

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11



		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':294216.5,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(294216.5)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 }


		 				 ]

		 				}];


		 		break;


		 		case "2020-09-03":
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22975182.61);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":294216.50

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11



		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':294216.5,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(294216.5)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 }


		 				 ]

		 				}];


		 		break;
		 		case "2020-09-02":
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22975182.61);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":294216.50

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11



		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':294216.5,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(294216.5)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 }


		 				 ]

		 				}];


		 		break;

		 		case "2020-09-01":
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(22975182.61);
	 				
	 				this.date_cards=[{
		      "name": "0-15 Días",
		      "value":0.0

		 				},
		 				{
		      "name": "16-30 Días",
		      "value":0.0

		 				}
		 				,
		 				{
		      "name": "31-45 Días",
		      "value":294216.50

		 				}
		 				,
		 				{
		      "name": "46 Días o más",
		      "value":22680966.11



		 				}];

	 				this.grafic_inter=[{
	 				 "name": "GCL URBANIZACIONES Y CONCRETOS",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':15719108.23,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15719108.23)}
	 				 }


	 				 ]

		 				},
		 				{
		 				 "name": "GRUPO CONSTRUCTOR LOGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':6961857.88,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6961857.88)}
		 				 }


		 				 ]

		 				},
		 				{
		 				 "name": "TRACTOSERVICIOS CRUZA SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':294216.5,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(294216.5)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 }


		 				 ]

		 				}];


		 		break;
	 			
	 		default:

	 				this.dia_hinabil='Sin Información';
	 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 0);
	 				
	 				this.date_cards=[];

	 			this.grafic_inter=[];
	 				
	 				this.grafic_publico=[];

	 				
	 				

	 				this.grafic_mostra=
	 				[];

	 				this.grafic_otros=[];

	 			break;
	 		}
	*/
 }



 sw_CxC_2(){
 	this.grafic_inter=[];
 	this.grafic_publico=[];
 	this.date_cards=[];
		this.grafic_inter=[];
		this.dia_hinabil='';

 		this.service.getrSincro(this.fecha,'rp_cxc').subscribe(ress=>{
 		
 		if(ress!=null){
 				console.log(ress);
 			let sincro = JSON.parse(JSON.stringify(ress)).Datas[0].Data;

 			
 			this.service.getrp_info_unidad_cx(sincro.sincro_id,'cxc').subscribe(res=>{


 				if(res!=null){
 					let cxc=JSON.parse(JSON.stringify(res)).Datas;
 					let cxc_fil=cxc.filter(cx=>cx.Data.codigo!='999998' &&cx.Data.codigo!='CL0098' &&cx.Data.codigo!='CL0499' &&cx.Data.codigo!='XAX000');
 					let cxc_publico=cxc.find(cx=>cx.Data.codigo=='XAX000');
 					let suma=0;
 					
 					for(let row of cxc_fil){
 						suma=suma+parseFloat(row.Data.total);
 					}


 					let por=(suma*80)/100;
 					console.log("Limite: "+ por);
 					let suma_limite=0;
 				

 					let arr_otro={
 							p15:0,
 							p30:0,
 							p45:0,
 							p46:0
 					}

 					let grafic_ss:any=[];

 					for(let row of cxc_fil){
 								
		 					if(suma_limite<=por){

		 					console.log('Suma '+suma_limite);

		 						grafic_ss.push(
		 							{
					 				 "name": row.Data.nombre,
					 				 "series":
						 				 [ 
							 				 {'name':'0-15 Días',
							 				 	'value':parseFloat(row.Data['15day']),
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(row.Data['15day']))}
							 				 },
							 				 {'name':'16-30 Días',
							 				 	'value':parseFloat(row.Data['30day']),
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(row.Data['30day']))}
							 				 },
							 				 {'name':'31-45 Días',
							 				 	'value':parseFloat(row.Data['45day']),
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(row.Data['45day']))}
							 				 },
							 				 {'name':'46 Días o más',
							 				 	'value':parseFloat(row.Data['46day']),
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(row.Data['46day']))}
							 				 },
						 				 ]
					 				 	});

		 							}else{
		 									arr_otro.p15=arr_otro.p15+parseFloat(row.Data['15day']);
		 									arr_otro.p30=arr_otro.p30+parseFloat(row.Data['30day']);
		 									arr_otro.p45=arr_otro.p45+parseFloat(row.Data['45day']);
		 									arr_otro.p46=arr_otro.p46+parseFloat(row.Data['46day']);
		 							}

		 							suma_limite=suma_limite+parseFloat(row.Data.total);
		 						
 							
 					
 					}

 							grafic_ss.push({
					 				 "name":'Otros',
					 				 "series":
						 				 [ 
							 				 {'name':'0-15 Días',
							 				 	'value':arr_otro.p15,
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(arr_otro.p15)}
							 				 },
							 				 {'name':'16-30 Días',
							 				 	'value':arr_otro.p30,
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(arr_otro.p30)}
							 				 },
							 				 {'name':'31-45 Días',
							 				 	'value':arr_otro.p45,
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(arr_otro.p45)}
							 				 },
							 				 {'name':'46 Días o más',
							 				 	'value':arr_otro.p46,
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(arr_otro.p46)}
							 				 },
						 				 ]
					 				 	})

 					this.grafic_otros=grafic_ss;
 					console.log(this.grafic_otros);





 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value": cxc_publico.Data.total

	 				},
	 				{
	      "name": "Mostrador",
	      "value":0

	 				},
	 				{
	      "name": "Otros (80/20)",
	      "value":suma


	 			}];


	 			this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':parseFloat(cxc_publico.Data['15day']),
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(cxc_publico.Data['15day']) )}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':parseFloat(cxc_publico.Data['30day']),
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(cxc_publico.Data['30day']) )}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':parseFloat(cxc_publico.Data['45day']),
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(cxc_publico.Data['45day']))}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':parseFloat(cxc_publico.Data['46day']),
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(cxc_publico.Data['46day']))}
 				 }


 				 ]

 				}];

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];

 					this.totalDia="$"+new Intl.NumberFormat('es-MX').format(suma);

 			
	 				
 				}else{
 						this.dia_hinabil="Día Inhábil";
 						this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
 						this.grafic_publico=[];
 						this.grafic_otros=[];
 						this.grafic_mostra=[];


 				//sin info en el reporte

 				}





 			});
 		}else{
 					this.dia_hinabil="Sin Información ";
 					this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
 					this.grafic_publico=[];
 					this.grafic_otros=[];
 					this.grafic_mostra=[];
 		}
 	});

 	/*
 		switch (this.fecha) {

 			case "2020-09-10":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 143606.16+1291880.64);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value": 143606.16

	 				},
	 				{
	      "name": "Mostrador",
	      "value":0

	 				},
	 				{
	      "name": "Otros (80/20)",
	      "value":1291880.64


	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0.03,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.03)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':118331.91,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(118331.91)}
 				 }


 				 ]

 				}];

 				console.log(this.grafic_publico)
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];

 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':52202.53,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(52202.53)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':12312,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(12312)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':94571.79,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(94571.79)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':22249.75,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(22249.75)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.01,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.01)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.13)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "IRON DARK INGENIERIA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':54040,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(54040)}
	 				 },
	 				 


	 				 ],


 				},{
	 				 "name": "Otros",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':87971.44,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(87971.44)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':24098.93,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(24098.93)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.02,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.02)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':240953.72,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(240953.72)}
	 				 },
	 				 


	 				 ],


 				}];

 			break;


 			case "2020-09-09":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 122525.94+1318275.73);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value": 122525.94

	 				},
	 				{
	      "name": "Mostrador",
	      "value":0

	 				},
	 				{
	      "name": "Otros (80/20)",
	      "value":1318275.73


	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0.03,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.03)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':118331.91,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(118331.91)}
 				 }


 				 ]

 				}];

 				console.log(this.grafic_publico)
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];

 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':64514.53,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(64514.53)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':72565.95,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(72565.95)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':91178.79,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(91178.79)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':22249.75,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(22249.75)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.01,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.01)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.13)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "IRON DARK INGENIERIA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':54040,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(54040)}
	 				 },
	 				 


	 				 ],


 				},{
	 				 "name": "Otros",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':99352.38,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(99352.38)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':24098.89,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(24098.89)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.02,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.02)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':186913.72,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(186913.72)}
	 				 },
	 				 


	 				 ],


 				}];

 			break;

 			case "2020-09-08":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 1441890.49);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value": 138730.93

	 				},
	 				{
	      "name": "Mostrador",
	      "value":0

	 				},
	 				{
	      "name": "Otros (80/20)",
	      "value":1303159.56


	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0.03,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.03)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':118331.91,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(118331.91)}
 				 }


 				 ]

 				}];

 				console.log(this.grafic_publico)
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];

 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':64514.53,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(64514.53)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':72565.95,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(72565.95)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':91392.27,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(91392.27)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':244.32,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(244.32)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.01,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.01)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.13)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "IRON DARK INGENIERIA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':54040,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(54040)}
	 				 },
	 				 


	 				 ],


 				},{
	 				 "name": "TALLER DE HULE Y MAQUINARIA DE DURANGO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':26063.8,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(26063.8)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':6515.95,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6515.95)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


 				}
	 				,{
	 				 "name": "Otros",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':51069.24,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(51069.24)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':15380.95,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(15380.95)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.02,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.02)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':186913.72,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(186913.72)}
	 				 },
	 				 


	 				 ],


 				}];

 			break;

 			case "2020-09-07":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 118331.91+1450070.77);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value": 118331.91

	 				},
	 				{
	      "name": "Mostrador",
	      "value":0

	 				},
	 				{
	      "name": "Otros (80/20)",
	      "value":1450070.77

	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0.03,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.03)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':118331.91,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(118331.91)}
 				 }


 				 ]

 				}];

 				console.log(this.grafic_publico)
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];

 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':64514.53,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(64514.53)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':72565.95,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(72565.95)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':112526.5,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(112526.5)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':24429.62,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(24429.62)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "Otros",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':77133.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(77133.13)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':21896.90,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(21896.90)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':13031.93,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(13031.93)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':240953.71,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(240953.71)}
	 				 },
	 				 


	 				 ],


 				}];

 			break;

 			case "2020-09-05":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 120125.93+1301359.77);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value":120125.93

		 				},
		 				{
		      "name": "Mostrador",
		      "value":0

		 				},
		 				{
		      "name": "Otros (80/20)",
		      "value":1301359.77

	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':118331.91,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(118331.91)}
 				 }


 				 ]

 				}];

 				
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];

 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':89679.39,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(89679.39)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':35629.95,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(35629.95)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':112526.5,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(112526.5)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':24429.62,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(24429.62)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.01,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.01)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.13)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "IRON DARK INGENIERIA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':54040,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(54040)}
	 				 },
	 				 


	 				 ],


	 				},{
		 				 "name": "TALLER DE HULE Y MAQUINARIA DE DURANGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':26063.9,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(26063.9)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':6515.95,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(6515.95)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':13031.9,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(13031.9)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 


		 				 ],


	 				},{
		 				 "name": "Otros",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':54517.21,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(54517.21)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':11932.98,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(11932.98)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0.05,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.05)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':186913.68,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(186913.68)}
		 				 },
		 				 


		 				 ],


 				}];

 			break;

 			case "2020-09-06":

 				this.dia_hinabil='Día Inhábil';
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 0);
 				
 				this.date_cards=[];

 		
 				
 				this.grafic_publico=[];

 				
 				

 				this.grafic_mostra=
 				[];

 				this.grafic_otros=[];

 			break;

 			case "2020-09-04":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 118331.94+1289588.63);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value":118331.94

		 				},
		 				{
		      "name": "Mostrador",
		      "value":0

		 				},
		 				{
		      "name": "Otros (80/20)",
		      "value":1289588.63

	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':118331.94,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(118331.94)}
 				 }


 				 ]

 				}];

 				
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];

 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':112997.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(112997.34)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':12312,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(12312)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],



	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':136956.1,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(136956.1)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0.02,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.02)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.01,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.01)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.13)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "IRON DARK INGENIERIA SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':54040,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(54040)}
	 				 },
	 				 


	 				 ],


	 				},{
		 				 "name": "TALLER DE HULE Y MAQUINARIA DE DURANGO SA DE CV",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':32579.85,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(32579.85)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':0,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
		 				 },
		 				 


		 				 ],


	 				},{
		 				 "name": "Otros",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':35618.32,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(35618.32)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':11932.98,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(11932.98)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':2.09,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(2.09)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':186911.64,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(186911.64)}
		 				 },
		 				 


		 				 ],


 				}];

 			break;

 			case "2020-09-03":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(118331.94+1357119.37);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value":118331.94

		 				},
		 				{
		      "name": "Mostrador",
		      "value":0

		 				},
		 				{
		      "name": "Otros (80/20)",
		      "value":1357119.37

	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':118331.94,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(118331.94)}
 				 }


 				 ]

 				}];

 				
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];



 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':114706.37,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(114706.37)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0.02,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.02)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.01,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.01)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.13)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':125309.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(125309.34)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],



	 				},{
	 				 "name": "CARRETERAS Y URBANIZACIONES BLANCO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':76529.73,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(76529.73)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0.06,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.06)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
		 				 "name": "Otros",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':64236.57,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(64236.57)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':24964.82,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(24964.82)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':2.09,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(2.09)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':250851.54,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(250851.54)}
		 				 },
		 				 


		 				 ],


 				}];

 			break;

 			case "2020-09-02":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(124447.43+1352386.10);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value":124447.43

		 				},
		 				{
		      "name": "Mostrador",
		      "value":0

		 				},
		 				{
		      "name": "Otros (80/20)",
		      "value":1352386.10

	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':124447.43,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(124447.43)}
 				 }


 				 ]

 				}];

 				
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];



 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':114706.37,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(114706.37)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0.02,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.02)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.01,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.01)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.13)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':125309.34,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(125309.34)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],



	 				},{
	 				 "name": "CARRETERAS Y URBANIZACIONES BLANCO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':76529.73,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(76529.73)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0.06,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.06)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
		 				 "name": "Otros",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':71892.07,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(64236.57)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':24964.82,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(24964.82)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':2.09,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(2.09)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':250851.54,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(250851.54)}
		 				 },
		 				 


		 				 ],


 				}];

 			break;

 			case "2020-09-01":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(118331.94+1381441.43);
 				
 				this.date_cards=[{
	      "name": "Publico en Gral.",
	      "value":118331.94

		 				},
		 				{
		      "name": "Mostrador",
		      "value":0

		 				},
		 				{
		      "name": "Otros (80/20)",
		      "value":1381441.43

	 			}];

 		
 				
 				this.grafic_publico=[{
 				 "name": "Publico en Gral.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':118331.91,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(118331.94)}
 				 }


 				 ]

 				}];

 				
 				

 				this.grafic_mostra=
 				[{
 				 "name": "Mostrador",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				}];



 				this.grafic_otros=
	 				[{
	 				 "name": "ELECTROCONSTRUCCIONES TITAN SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':307543.94,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(307543.94)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "ADMINISTRACION Y EDIFICACION EN RETAIL S DE RL DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':200000,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(200000)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "XDE CONSTRUCCIONES SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':166763.42,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(166763.42)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "HOME DEPOT MEXICO S. DE R. L. DE C. V.",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':114706.37,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(114706.37)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':1095.5,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(1095.5)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0.01,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.01)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0.13,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.13)}
	 				 },
	 				 


	 				 ],


	 				},{
	 				 "name": "GRUPO CONSTRUCTOR SSSYC CHAVEZ SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':99925.95,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(99925.95)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':99925.95,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(99925.95)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],



	 				},{
	 				 "name": "CARRETERAS Y URBANIZACIONES BLANCO SA DE CV",
	 				 "series":[ {'name':'0-15 Días',
	 				 	'value':76529.73,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(76529.73)}
	 				 },
	 				 {'name':'16-30 Días',
	 				 	'value':0.06,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0.06)}
	 				 },
	 				 {'name':'31-45 Días',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 {'name':'46 Días o más',
	 				 	'value':0,
	 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
	 				 },
	 				 


	 				 ],


	 				},{
		 				 "name": "Otros",
		 				 "series":[ {'name':'0-15 Días',
		 				 	'value':85234.50,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(85234.50)}
		 				 },
		 				 {'name':'16-30 Días',
		 				 	'value':24964.80,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(24964.80)}
		 				 },
		 				 {'name':'31-45 Días',
		 				 	'value':2.09,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(2.09)}
		 				 },
		 				 {'name':'46 Días o más',
		 				 	'value':250851.54,
		 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(250851.54)}
		 				 },
		 				 


		 				 ],


 				}];

 			break;
 			
 			default:

 				this.dia_hinabil='Sin Información';
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 0);
 				
 				this.date_cards=[];

 		
 				
 				this.grafic_publico=[];

 				
 				

 				this.grafic_mostra=
 				[];

 				this.grafic_otros=[];

 			break;
 		}*/
 }

 sw_CxP_2(){
 	this.grafic_inter=[];
 	this.date_cards=[];
		this.grafic_inter=[];

 	/*
		(cx=>cx.Data.codigo=='PRO047')
		(cx=>cx.Data.codigo=='PRO015')
		(cx=>cx.Data.nombre=='TRACTOSERVICIOS CRUZA SA DE CV')*/


		this.date_cards=[];

					let total_15day=0;
						let total_30day=0;
						let total_45day=0;
						let total_46day=0;

						let gcl_15day=0;
						let gcl_30day=0;
						let gcl_45day=0;
						let gcl_46day=0;

						let logo_15day=0;
						let logo_30day=0;
						let logo_45day=0;
						let logo_46day=0;

						let cruza_15day=0;
						let cruza_30day=0;
						let cruza_45day=0;
						let cruza_46day=0;
		this.service.getrSincro(this.fecha,'rp_cxp').subscribe(res=>{
			if(res!=null){
				let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
				this.service.getrp_info_unidad_cx(sincro.sincro_id,'cxp').subscribe(res=>{
					if (res!=null) {
						
						let cxp=JSON.parse(JSON.stringify(res)).Datas;

						let cxp_otros=cxp.filter(cx=>cx.Data.codigo!='PRO047' && cx.Data.codigo!='PRO015' && cx.Data.nombre!='TRACTOSERVICIOS CRUZA SA DE CV');
						
						console.log(cxp_otros);

						let total15=0;
						let total30=0;
						let total45=0;
						let total46=0;
						let total=0;


						for(let cxp of cxp_otros){
								total15=total15+parseFloat(cxp.Data['15day']);
								total30=total30+parseFloat(cxp.Data['30day']);
								total45=total45+parseFloat(cxp.Data['45day']);
								total46=total46+parseFloat(cxp.Data['46day']);

								total=total+parseFloat(cxp.Data['total']);
						}

						let pro=(total*80)/100;
						let suma_limite=0;
							let arr_otro={
 							p15:0,
 							p30:0,
 							p45:0,
 							p46:0
 					}

						let grafic_ss:any=[];

 					for(let row of cxp_otros){
 							
		 					if(suma_limite<=pro){
		 					console.log('Suma '+suma_limite);

		 						grafic_ss.push(
		 							{
					 				 "name": row.Data.nombre,
					 				 "series":
						 				 [ 
							 				 {'name':'0-15 Días',
							 				 	'value':parseFloat(row.Data['15day']),
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(row.Data['15day']))}
							 				 },
							 				 {'name':'16-30 Días',
							 				 	'value':parseFloat(row.Data['30day']),
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(row.Data['30day']))}
							 				 },
							 				 {'name':'31-45 Días',
							 				 	'value':parseFloat(row.Data['45day']),
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(row.Data['45day']))}
							 				 },
							 				 {'name':'46 Días o más',
							 				 	'value':parseFloat(row.Data['46day']),
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(row.Data['46day']))}
							 				 },
						 				 ]
					 				 	});

		 							}else{
		 									arr_otro.p15=arr_otro.p15+parseFloat(row.Data['15day']);
		 									arr_otro.p30=arr_otro.p30+parseFloat(row.Data['30day']);
		 									arr_otro.p45=arr_otro.p45+parseFloat(row.Data['45day']);
		 									arr_otro.p46=arr_otro.p46+parseFloat(row.Data['46day']);
		 							}
		 							suma_limite=suma_limite+parseFloat(row.Data.total);
 							
 					
 					}

 							grafic_ss.push({
					 				 "name":'Otros',
					 				 "series":
						 				 [ 
							 				 {'name':'0-15 Días',
							 				 	'value':arr_otro.p15,
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(arr_otro.p15)}
							 				 },
							 				 {'name':'16-30 Días',
							 				 	'value':arr_otro.p30,
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(arr_otro.p30)}
							 				 },
							 				 {'name':'31-45 Días',
							 				 	'value':arr_otro.p45,
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(arr_otro.p45)}
							 				 },
							 				 {'name':'46 Días o más',
							 				 	'value':arr_otro.p46,
							 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(arr_otro.p46)}
							 				 },
						 				 ]
					 				 	})



 							this.grafic_publico=grafic_ss;
					
					this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": total15

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":total30
	     },
	 				{
	      "name": "31-45 Días",
	      "value":total45
	     },
	 				{
	      "name": "46 Días o más",
	      "value":total46


	 			}];
			

					}else{
							//sin datos en el informe
							this.dia_hinabil='Día Inhábil';
							this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
							this.grafic_publico=[];
 					this.grafic_otros=[];
 					this.grafic_mostra=[];
					}

				});
			}else{

				this.dia_hinabil='Sin Información';
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
				this.grafic_publico=[];
 					this.grafic_otros=[];
 					this.grafic_mostra=[];

			}
	 
	 			

		});










 	/*
 		switch (this.fecha) {
 			case "2020-09-10":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(8324247.39);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 946108.80

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":870822.30
	     },
	 				{
	      "name": "31-45 Días",
	      "value":278897.55
	     },
	 				{
	      "name": "46 Días o más",
	      "value":6217113.99


	 			}];

	 				





 		
 				
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':736848.85,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(565096.67)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':242136.66,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(320349.66)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':157316.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79103.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':5531937.82,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(5531937.82)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':209259.95,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(209259.95)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':79385.64,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79385.64)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':121581.54,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(121581.54)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':330276.16,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(330276.16)}
 				 }


 				 ]

 				}


 				];

 			break;

 			case "2020-09-09":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(3034408.41);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 692163.00

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":955639.54
	     },
	 				{
	      "name": "31-45 Días",
	      "value":204490.10
	     },
	 				{
	      "name": "46 Días o más",
	      "value":1085652.19


	 			}];

	 				





 		
 				
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':565096.67,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(565096.67)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':320349.66,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(320349.66)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':79103.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79103.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':395753.02,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(395753.02)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':127066.33,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(127066.33)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':85989.88,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(85989.88)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':125387.09,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(125387.09)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':199057.36,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(199057.36)}
 				 }


 				 ]

 				}


 				];

 			break;

 			case "2020-09-08":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2943860.66);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 779074.08

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":873015.77
	     },
	 				{
	      "name": "31-45 Días",
	      "value":205258.16
	     },
	 				{
	      "name": "46 Días o más",
	      "value":1086512.65


	 			}];

	 				





 		
 				
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':648766.34,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(648766.34)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':238226.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(238226.01)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':79103.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79103.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':395753.02,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(395753.02)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':130307.74,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(130307.74)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':85489.76,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(85489.76)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':126155.15,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(126155.15)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':199917.82,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(199917.82)}
 				 }


 				 ]

 				}


 				];

 			break;

 			case "2020-09-07":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2857776.99);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 697134.39

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":869011.07
	     },
	 				{
	      "name": "31-45 Días",
	      "value":205118.88
	     },
	 				{
	      "name": "46 Días o más",
	      "value":1086512.65



	 			}];

	 		
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':563032.68,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(563032.68)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':238226.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(238226.01)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':79103.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79103.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':395753.02,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(395753.02)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':134101.71,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(130307.74)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':81485.06,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(85489.76)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':126155.15,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(126155.15)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':199917.82,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(199917.82)}
 				 }


 				 ]

 				}


 				];

 			break;

 		case "2020-09-05":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2849052.94);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 664134.71

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":790098.44
	     },
	 				{
	      "name": "31-45 Días",
	      "value":557855.01
	     },
	 				{
	      "name": "46 Días o más",
	      "value":731552.17



	 			}];

	 		
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':556795.65,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(556795.65)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':157289.04,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(238226.01)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':79103.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79103.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':395753.02,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(395753.02)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':107339.06,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(107339.06)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':83509.40,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(83509.40)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':123851.99,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(123851.99)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':199857.35,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(199857.35)}
 				 }


 				 ]

 				}


 				];

 			break;

 			case "2020-09-06":

 				this.dia_hinabil='Día Inhábil';
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 0);
 				
 				this.date_cards=[];

 		
 				
 				this.grafic_publico=[];

 				
 				

 				this.grafic_mostra=
 				[];

 				this.grafic_otros=[];

 			break;

 			case "2020-09-04":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2821853.33);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 664653.66

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":865538.56
	     },
	 				{
	      "name": "31-45 Días",
	      "value":557683.21
	     },
	 				{
	      "name": "46 Días o más",
	      "value":731552.17



	 			}];

	 		
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':556795.65,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(556795.65)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':235502.04,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(238226.01)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':79103.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(79103.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':395753.02,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(395753.02)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':107858.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(107339.06)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':80736.52,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(83509.40)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':123680.19,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(123851.99)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':199857.35,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(199857.35)}
 				 }


 				 ]

 				}


 				];

 			break;

 			case "2020-09-03":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2943180.78);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 710959.94

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":1134810.03
	     },
	 				{
	      "name": "31-45 Días",
	      "value":366529.70
	     },
	 				{
	      "name": "46 Días o más",
	      "value":730881.11



	 			}];

	 		
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':556795.65,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(556795.65)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':393141.69,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(393141.69)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':395753.02,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(395753.02)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':154164.29,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(154164.29)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':192368.34,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(192368.34)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':11629.69,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(11629.69)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':199186.29,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(199186.29)}
 				 }


 				 ]

 				}


 				];

 			break;
 			
 			case "2020-09-02":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2954142.29);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 607990.32

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":1055494.99
	     },
	 				{
	      "name": "31-45 Días",
	      "value":366329.70
	     },
	 				{
	      "name": "46 Días o más",
	      "value":730881.11



	 			}];

	 		
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':479391.75,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(556795.65)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':314065.65,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(393141.69)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':395753.02,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(395753.02)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':128598.57,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(128598.57)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':192129.34,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(192129.34)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':11429.69,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(11429.69)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':199186.29,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(199186.29)}
 				 }


 				 ]

 				}


 				];

 			break;

 			case "2020-09-01":


 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(2760696.12);
 				
 				this.date_cards=[{
	      "name": "0-15 Días",
	      "value": 674753.50

	 				},
	 				{
	      "name": "16-30 Días",
	      "value":993023.81
	     },
	 				{
	      "name": "31-45 Días",
	      "value":362037.70

	     },
	 				{
	      "name": "46 Días o más",
	      "value":730881.11



	 			}];

	 		
 				this.grafic_publico=[{
 				 "name": "CEMEX SAB DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':479391.75,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(556795.65)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':314065.65,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(393141.69)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "KIMAT CAMIONES SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':549300,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(549300)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]


 				},{
 				 "name": "VIBROREGIOS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':395753.02,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(395753.02)}
 				 }


 				 ]

 				},{
 				 "name": "VALKO AUTOMOTRIZ S.A. DE C.V.",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':354900.01,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(354900.01)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 }


 				 ]

 				},{
 				 "name": "ALFA VIVIENDAS SA DE CV",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':0,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(0)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':135941.8,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(135941.8)}
 				 }


 				 ]

 				},{
 				 "name": "Otros",
 				 "series":[ {'name':'0-15 Días',
 				 	'value':195361.75,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(128598.57)}
 				 },
 				 {'name':'16-30 Días',
 				 	'value':129658.16,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(192129.34)}
 				 },
 				 {'name':'31-45 Días',
 				 	'value':7137.69,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(11429.69)}
 				 },
 				 {'name':'46 Días o más',
 				 	'value':199186.29,
 				 	'extra':{val_form:new Intl.NumberFormat('es-MX').format(199186.29)}
 				 }


 				 ]

 				}


 				];

 			break; 			
 			default:

 				this.dia_hinabil='Sin Información';
 				this.totalDia="$"+new Intl.NumberFormat('es-MX').format( 0);
 				
 				this.date_cards=[];

 		
 				
 				this.grafic_publico=[];

 				
 				

 				this.grafic_mostra=
 				[];

 				this.grafic_otros=[];

 			break;
 		}

 	*/
 }



 parseTitlesCard(value){
 	let val="$"+new Intl.NumberFormat('es-MX').format(value.value);
	 return val;
	}

	cambioFecha(form){
		this.fecha=form.form.value.fecha;
		
		this.sw_flujo();
			
				
		

	}

	saldos:any=[];
	flujo_egresos:any=[];
	flujo_ingreos:any=[];
	total_egresos:any=0;
	total_ingresos_s:any=0;

	sw_flujo(){
		this.dia_hinabil='';
		console.log(this.fecha);
		this.total_egresos=0;
		this.total_ingresos_s=0;

			this.service.getrSincro(this.fecha,'rp_flujo_efectivo').subscribe(res=>{

				if(res!=null){

				
					let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;

					this.service.get_flujo_by_id(sincro.sincro_id).subscribe(res=>{
						if(res!=null){
							let flujo=JSON.parse(JSON.stringify(res)).Datas;
							this.flujo_egresos=flujo.filter(d=>d.Data.tipo=="egresos");
							this.flujo_ingreos=flujo.filter(d=>d.Data.tipo=="ingresos");
								if(this.flujo_egresos != null && this.flujo_egresos != undefined){
										for(let egreso of this.flujo_egresos){
												egreso.Data.m=new Intl.NumberFormat('es-MX').format(parseFloat(egreso.Data.total));
												this.total_egresos=this.total_egresos+parseFloat(egreso.Data.total);
										}


										this.total_egresos=new Intl.NumberFormat('es-MX').format(parseFloat(this.total_egresos));

								}

								console.log(this.flujo_ingreos);

								if(this.flujo_ingreos != null && this.flujo_ingreos != undefined){
										for(let egreso of this.flujo_ingreos){
												egreso.Data.m=new Intl.NumberFormat('es-MX').format(parseFloat(egreso.Data.total));
												this.total_ingresos_s=this.total_ingresos_s+parseFloat(egreso.Data.total);
										}

										console.log(this.total_ingresos_s);

										this.total_ingresos_s=new Intl.NumberFormat('es-MX').format(parseFloat(this.total_ingresos_s));
								}
						}else{
							this.dia_hinabil='Dia Inhábil';
							this.saldos.Data.posicion_menusal_m=0;
							this.saldos.Data.saldo_actual_m=0;
							this.saldos.Data.varacion_neta_m=0;
							this.flujo_egresos=[];
							this.flujo_ingreos=[];
						}


					});

					this.service.get_saldos_by_id(sincro.sincro_id).subscribe(res=>{
					
						if(res!=null){
							this.saldos=JSON.parse(JSON.stringify(res)).Datas[0];
							
							this.saldos.Data.saldo_actual_m=new Intl.NumberFormat('es-MX').format(parseFloat(this.saldos.Data.saldo_actual));
							this.saldos.Data.varacion_neta_m=new Intl.NumberFormat('es-MX').format(parseFloat(this.saldos.Data.varacion_neta));
							this.saldos.Data.posicion_menusal_m=new Intl.NumberFormat('es-MX').format(parseFloat(this.saldos.Data.posicion_menusal));
							console.log(this.saldos);
						}else{
							this.dia_hinabil='Dia Inhábil';
							this.saldos.Data.posicion_menusal_m=0;
							this.saldos.Data.saldo_actual_m=0;
							this.saldos.Data.varacion_neta_m=0;
							this.flujo_egresos=[];
							this.flujo_ingreos=[];
						}


					
					});

				}else{
						console.log('sin reporte');
						this.dia_hinabil='Sin Información';
						this.saldos.Data.posicion_menusal_m=0;
						this.saldos.Data.saldo_actual_m=0;
						this.saldos.Data.varacion_neta_m=0;
						this.flujo_egresos=[];
						this.flujo_ingreos=[];
				}


			});
		/*
		this.subtitulo='Posición Mensual de Efectivo (M. X. N.)';
			
		this.dia_hinabil="";
		switch (this.fecha) {

			case "2020-09-01": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1020401.87+157463.45-47.07);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 1020401.87
					},{
					      "name": "Total Ingresos",
					      "value": 157463.45

					},{
					      "name": "Total Erogaciones",
					      "value": -47.07

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 157463.45-47.07,

				}];
				this.grafica_1=[
					{
						value:157463.45,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(157463.45)}  ,
					},
					{
						value:-47.07,
						name:'COMISIONES',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-47.07)}  ,
					}

				];


			break;
			
			case "2020-09-02":


				this.totalDia="$"+new Intl.NumberFormat('es-MX').format((1020401.87+157463.45-47.07)-20.88+77451.41);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 1020401.87+157463.45-47.07
					},{
					      "name": "Total Ingresos",
					      "value": 77451.41

					},{
					      "name": "Total Erogaciones",
					      "value": -20.88

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": -20.88+77451.41,

				}];

				this.grafica_1=[
					{
						value:77451.41,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(77451.41)}  ,
					},
					{
						value:-20.88,
						name:'COMISIONES',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-20.88)}  ,
					}

				];


			break;

			case "2020-09-03":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(
					((1020401.87+157463.45-47.07)-20.88+77451.41)-672985.97-62.64+70106.44

					);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": (1020401.87+157463.45-47.07)-20.88+77451.41
					},{
					      "name": "Total Ingresos",
					      "value": 70106.44

					},{
					      "name": "Total Erogaciones",
					      "value": -672985.97-62.64

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": -672985.97-62.64+70106.44,

				}];

				this.grafica_1=[
					{
						value:70106.44,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(70106.44)}  ,
					},
					{
						value:-62.64,
						name:'COMISIONES',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-62.64)}  ,
					},{
						value:-672985.97,
						name:'PAGOS A PROVEEDORES, COSTOS Y GASTOS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-672985.97)}  ,
					}

				];


			break;

			case "2020-09-04":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(
					(((1020401.87+157463.45-47.07)-20.88+77451.41)-672985.97-62.64+70106.44)+
					213937.64-149.9-167670.4-123753.64

					);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": ((1020401.87+157463.45-47.07)-20.88+77451.41)-672985.97-62.64+70106.44
					},{
					      "name": "Total Ingresos",
					      "value": 213937.64

					},{
					      "name": "Total Erogaciones",
					      "value": -149.9-167670.4-123753.64

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 213937.64-149.9-167670.4-123753.64,

				}];

				this.grafica_1=[
					{
						value:213937.64,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(213937.64)}  ,
					},
					{
						value:-149.9,
						name:'COMISIONES',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-62.64)}  ,
					},{
						value:-167670.4,
						name:'PAGOS A PROVEEDORES, COSTOS Y GASTOS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-167670.4)}  ,
					},{
						value:-123753.64,
						name:' PAGOS DE NOMINA',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-123753.64)}  ,
					}


				];


			break;



			
			case "2020-09-05":

					this.totalDia="$"+new Intl.NumberFormat('es-MX').format(574670.31+495-78213);
				

					this.date_cards=[
				 {
				      "name": "Saldo Inicial",
				      "value": 574670.31

				 },
				  {
				      "name": "Total Ingresos",
				      "value": 495

				 },
				 {
				      "name": "Total Erogaciones",
				      "value": -78213

				 
					}
					,
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 495-78213,
	 
					}];

					this.grafica_1=[
						{
							value:495,
							name:'Cobro de Ventas',
							extra:{val_form:new Intl.NumberFormat('es-MX').format(495)}  ,
						},{
							value:-78213,
							name:'Pagos a Proveedores, Costos y Gastos',
							extra:{val_form:new Intl.NumberFormat('es-MX').format(-78213)}  ,
						}
						

					];


			break;

			case "2020-09-06":

					this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
					
					this.dia_hinabil="Día Inhábil";
					this.grafic_publico=[];
 					this.grafic_otros=[];
 					this.grafic_mostra=[];

					this.grafica_1=[
						];


			break;


			

			case "2020-09-07":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(496952.31+6780.2-20.88);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 496952.31
					},{
					      "name": "Total Ingresos",
					      "value": 6780.2

					},{
					      "name": "Total Erogaciones",
					      "value": -20.88

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 6780.2-20.88,

				}];

				this.grafica_1=[
					{
						value:6780.2,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(6780.2)}  ,
					},
					{
						value:-20.88,
						name:'COMISIONES',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-20.88)}  ,
					}


				];


			break;

			case "2020-09-08":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(503711.63+51475.09);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 503711.63
					},{
					      "name": "Total Ingresos",
					      "value": 51475.09

					},{
					      "name": "Total Erogaciones",
					      "value": 0

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 51475.09,

				}];

				this.grafica_1=[
					{
						value:51475.09,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(6780.2)}  ,
					}


				];


			break;

			case "2020-09-09":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(555186.72+16379.3);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 555186.72
					},{
					      "name": "Total Ingresos",
					      "value": 16379.3

					},{
					      "name": "Total Erogaciones",
					      "value": 0

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 16379.3,

				}];

				this.grafica_1=[
					{
						value:16379.3,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(16379.3)}  ,
					}


				];


			break;

			case "2020-09-10":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(571566.02+32490.3);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 571566.02
					},{
					      "name": "Total Ingresos",
					      "value": 32490.3

					},{
					      "name": "Total Erogaciones",
					      "value": 0

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 32490.3,

				}];

				this.grafica_1=[
					{
						value:32490.3,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(32490.3)}  ,
					}


				];


			break;

			case "2020-09-11":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(604056.32+84187.44-74197.24);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 604056.32
					},{
					      "name": "Total Ingresos",
					      "value": 84187.44

					},{
					      "name": "Total Erogaciones",
					      "value": -74197.24

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 84187.44-74197.24,

				}];

				this.grafica_1=[
					{
						value:84187.44,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(84187.44)}  ,
					},{
						value:-74197.24,
						name:' PAGOS A PROVEEDORES, COSTOS Y GASTOS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-74197.24)}  ,
					}


				];


			break;
				case "2020-09-12":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(614046.52);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value":614046.52
					},{
					      "name": "Total Ingresos",
					      "value": 0

					},{
					      "name": "Total Erogaciones",
					      "value": 0

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 0,

				}];

				this.grafica_1=[
					
					


				];


			break;
				case "2020-09-13":
				this.dia_hinabil='Día Inhábil';

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
				this.grafic_publico=[];
 					this.grafic_otros=[];
 					this.grafic_mostra=[];


			break;
				case "2020-09-14":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(614046.52+71713.46-35.35);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 614046.52
					},{
					      "name": "Total Ingresos",
					      "value": 71713.46

					},{
					      "name": "Total Erogaciones",
					      "value": -35.35

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 71713.46-35.35,

				}];

				this.grafica_1=[
					{
						value:71713.46,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(71713.46)}  ,
					},{
						value:-35.35,
						name:'COMISIONES',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-35.35)}  ,
					}


				];


			break;
				case "2020-09-15":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(685724.63+1127268.9-341864.06);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 685724.63
					},{
					      "name": "Total Ingresos",
					      "value": 1127268.9

					},{
					      "name": "Total Erogaciones",
					      "value": -341864.06

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 1127268.9-341864.06,

				}];

				this.grafica_1=[
					{
						value:1127268.9,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(1127268.9)}  ,
					},{
						value:-341759.66,
						name:' PAGOS A PROVEEDORES, COSTOS Y GASTOS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-341759.66)}  ,
					}
					,{
						value:-104.4,
						name:'COMISIONES',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-104.4)}  ,
					}


				];


			break;
				case "2020-09-16":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1471129.47+8649);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 1471129.47
					},{
					      "name": "Total Ingresos",
					      "value": 8649

					},{
					      "name": "Total Erogaciones",
					      "value": 0

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value":8649,

				}];

				this.grafica_1=[
					{
						value:8649,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(8649)}  ,
					}


				];


			break;
				case "2020-09-17":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1479778.47+390747.6-246619.45);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 1479778.47
					},{
					      "name": "Total Ingresos",
					      "value": 390747.6

					},{
					      "name": "Total Erogaciones",
					      "value": -246619.45

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 390747.6-246619.45,

				}];

				this.grafica_1=[
					{
						value:390747.6,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(390747.6)}  ,
					},{
						value:-159947.18,
						name:'PAGOS A PROVEEDORES, COSTOS Y GASTOS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-159947.18)}  ,
					},{
						value:-86634.47,
						name:'PAGOS DE SEGURIDAD SOCIAL (IMSS)',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-86634.47)}  ,
					},{
						value:-37.8,
						name:'COMISIONES',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-37.8)}  ,
					}


				];


			break;
				case "2020-09-18":

				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(1623906.62+40244.24-158679.21);
				this.date_cards=[{
					"name": "Saldo Inicial",
					"value": 1623906.62
					},{
					      "name": "Total Ingresos",
					      "value": 40244.24

					},{
					      "name": "Total Erogaciones",
					      "value": -158679.21

					 
					},
				 {
				      "name": "Varación Neta de Efectivo",
				      "value": 40244.24-158679.21,

				}];

				this.grafica_1=[
					{
						value:40244.24,
						name:'COBRO DE CLIENTES POR VENTAS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(40244.24)}  ,
					},{
						value:-158679.21,
						name:' PAGOS A PROVEEDORES, COSTOS Y GASTOS',
						extra:{val_form:new Intl.NumberFormat('es-MX').format(-158679.21)}  ,
					}


				];


			break;

			
			default:
				this.subtitulo='Posición Mensual de Efectivo';
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
				this.dia_hinabil="Sin Información";
				this.grafica_1=[];
				this.date_cards=[];
				break;
		}*/
	}

	sw_flujo_dl(){

		this.subtitulo='Posición Mensual de Efectivo (U. S. D)';
			
		this.dia_hinabil="";
		switch (this.fecha) {

			case "2020-09-01": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;

			case "2020-09-02": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
				}];
				this.grafica_1=[
				];


			break;
			case "2020-09-03": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
				}];
				this.grafica_1=[
				];


			break;
			case "2020-09-04": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
				}];
				this.grafica_1=[
				];


			break;
			case "2020-09-05": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
				}];
				this.grafica_1=[
				];


			break;
			case "2020-09-07": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
				}];
				this.grafica_1=[
				];


			break;
			case "2020-09-08": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
				}];
				this.grafica_1=[
				];


			break;

			case "2020-09-09": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
				}];
				this.grafica_1=[
				];


			break;

			case "2020-09-10": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
				}];
				this.grafica_1=[
				];


			break;
				case "2020-09-11": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;
				case "2020-09-12": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;	case "2020-09-13": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;
				case "2020-09-14": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;
				case "2020-09-15": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;
				case "2020-09-16": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;
				case "2020-09-17": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;
				case "2020-09-18": 
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				this.date_cards=[
			 {
			      "name": "Saldo Inicial",
			      "value": 41798.68

			 },
			  {
			      "name": "Total Ingresos",
			      "value": 0

			 },
			 {
			      "name": "Total Erogaciones",
			      "value": 0

			 
				}
				,
			 {
			      "name": "Varación Neta de Efectivo",
			      "value": 0,
 
			}];
				this.grafica_1=[
				];


			break;
			
			case "2020-09-06":

					this.totalDia="$"+new Intl.NumberFormat('es-MX').format(41798.68);
				
				this.dia_hinabil="Día Inhábil";
			this.date_cards=[];

			this.grafica_1=[
				];


			break;


			

	
			
			default:
				this.subtitulo='Posición Mensual de Efectivo';
				this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
				this.dia_hinabil="Sin Información";
				this.grafica_1=[];
				this.date_cards=[];
				break;
		}
	}



}
