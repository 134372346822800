import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
  providers:[DashboardService]
})
export class InicioComponent implements OnInit {

	status:any;

  constructor() {

  	
  }

  ngOnInit() {

  	if(sessionStorage.getItem('id')!=null){
       this.status=sessionStorage.getItem('status');
    }
  }

}
