import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seg-ventas',
  templateUrl: './seg-ventas.component.html',
  styleUrls: ['./seg-ventas.component.css']
})
export class SegVentasComponent implements OnInit {

		page_now:any="Ventas";


  constructor() { }

  ngOnInit() {

  }
  loadPage(page){
  		this.page_now=page;
  }

  

}
