import { Component, OnInit } from '@angular/core';
import { QueriesLogoService } from '../services/queries-logo.service';
import {Router} from "@angular/router";
declare var $: any;

@Component({
  selector: 'app-primer-encuentro',
  templateUrl: './primer-encuentro.component.html',
  styleUrls: ['./primer-encuentro.component.css'],
  providers: [QueriesLogoService]
})
export class PrimerEncuentroComponent implements OnInit {
  clientes: any = [];
  cliente: any;
  semaforo = 'green';
  ifClientes = true;

  encuentros: any = [];
  encuentros_respaldo: any = [];
  encuentro: any;
  statusVentas:any;

  desarrollos: any;
  promotores: any;
  t_inmuebles: any;
  user_id:any;


  constructor(private router: Router, private service: QueriesLogoService) { }

  ngOnInit() {
    // this.getClientes();
    this.user_id=sessionStorage.getItem("token");
    this.getEncuentros();
    this.getDesarrollos();
    this.getPromotores();
    this.getStatusVentas();

  }



  getDesarrollos() {
    this.service.getDesarrollos().subscribe(
      result => {
        console.log(result);
        let eco = JSON.stringify(result);
        let json = JSON.parse(eco);
        this.desarrollos = json.Desarrollo;


      },
      error => {
        console.log(error);
      }
    );
  }

  getTiposInmueble() {

    $('#desarrollo_id').val();
    this.service.getTInmueble($('#desarrollo_id').val()).subscribe(
      result => {
        console.log(result);
        let eco = JSON.stringify(result);
        let json = JSON.parse(eco);
        this.t_inmuebles = json.T_Inmueble;




      },
      error => {
        console.log(error as any);
      }
    );
  }

  getPromotores() {
    this.service.getPromotores().subscribe(
      result => {
        console.log(result);
        let eco = JSON.stringify(result);
        let json = JSON.parse(eco);
        this.promotores = json.Promotor;


      },
      error => {
        console.log(error as any);
      }
    );
  }

  getEncuentros() {
    this.service.getEncuentros().subscribe(data => {
      let eco = JSON.stringify(data);
      let json = JSON.parse(eco);
      this.encuentros = json.Encuentro;
      this.encuentros_respaldo = this.encuentros;
      console.log(json.Encuentro);
      this.ifClientes = true;
    }, error => {
      console.log(error);
    });
  }

  getEncuentro(id){
    console.log("el id: " + id);
    //this.ifClientes = false;
    this.service.getEncuentro(id).subscribe(data => {
      console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.encuentro = json.Encuentro;
      console.log(this.encuentro);

    }, error => {
      console.log(error);
    });
  }

  limpiarform(form) {
    form.reset();
    this.getDesarrollos();
    this.getPromotores();
    this.getTiposInmueble();
  }

  addEncuentro(form) {
    console.log('promotor' + form.form.value.promotor_id.valueOf());
    console.log('tipo' + form.form.value.tipo.valueOf());
    console.log('desarrollo' + form.form.value.desarrollo_id.valueOf());

    this.service.insertEncuentro(this.user_id,form.form.value).subscribe(data => {
      console.log(data);
      this.limpiarform(form);
      this.getEncuentros();
    }, error => {
      console.log(error);
    });
  }

  seguimiento(form){
    console.log("guarda: "+ $('#nombre2').val());
    console.log("orimer: "+ $('#primer_encuentro_id').val());
    console.log("guarda: "+ $('#desarrollo_id2').val());

    this.service.insertVenta(this.user_id,$('#nombre2').val(),$('#apellido_paterno2').val(),$('#apellido_materno2').val(),$('#telefono2').val(),$('#celular2').val(),$('#correo2').val(),$('#nss2').val(),$('#promotor_id2').val(),$('#primer_encuentro_id').val(),$('#status_venta_id').val()).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });

  }

  getStatusVentas(){
    this.service.getStatusVentas().subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.statusVentas = json.StatusVenta;
      //console.log(this.notarias);

    }, error => {
      console.log(error);
    });
  }

  cancelar(form){
    console.log("guarda: "+ $('#nombre2').val());
    console.log("orimer: "+ $('#primer_encuentro_id').val());
    console.log("guarda: "+ $('#desarrollo_id2').val());

    window.location.reload();

  }

  filtro(ev)
  {
    if (ev=='') {
      this.encuentros=this.encuentros_respaldo;
    }else{
      let val = ev.target.value;
      console.log(val);
      if (val && val.trim() != '') {
        this.encuentros = this.encuentros.filter((item) => {
          return (item.Encuentros.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
        this.encuentros=this.encuentros_respaldo;
      }
    }
  }

}
