import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-rol2',
  templateUrl: './rol2.component.html',
  styleUrls: ['./rol2.component.css']
})
export class Rol2Component implements OnInit {
	roles:any;

  constructor(private service:UsersService) { }

  ngOnInit() {
  	localStorage.setItem('page','6');
  	this.service.getRoles().subscribe(res=>{
  		if (res!=null) {
  			this.roles=JSON.parse(JSON.stringify(res)).Datas;
  		}
  	});
  }

}
