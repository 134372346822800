import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserModule } from "@angular/platform-browser";

import { multi } from './data';


@Component({
  selector: 'app-ev-eg-dgoblock',
  templateUrl: './ev-eg-dgoblock.component.html',
  styleUrls: ['./ev-eg-dgoblock.component.css']
})
export class EvEgDgoblockComponent implements OnInit {




  fecha='2020-08-14';
  fecha_sv="2020-08-14";
  fecha_agen='2020-08-14';

 page="p1";
    
 view: any[] = [220, 110];
 una_barra:any=[750,90];
 una_barra2:any=[750,150];
 view_test: any[] = [750, 110];
 view_pia_grid: any[] = [750, 500];
 totalDia:any=0;
 single=[];
  single2=[];
  single3=[];

 date_cards:any=[];






  


showLegend: boolean = false

 
  subtitulo='';
  

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient_bar: boolean = false;
  
  showXAxisLabel: boolean = true;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  xAxisLabel = "Productos \n ";


  cardColor: string = '#232837';

  grafica_1:any=[];

  Titulo_grafica="Ventas Por Productos (80/20)";
  set_format=true;

  Titulo_grafica_agentes="Ventas Por Agente";
  xAxisLabel_agente="Agentes";

  unaBara_date=[];
  sinClasificar=[];
  barra_adm=[];
  barra_adq=[];
  barra_alm=[];
  dia_hinabil="";
  cat_compras:any=[];
  constructor(private service:UsersService) { }

  ngOnInit() {
    this.fecha='2020-08-31';

    this.service.get_cat_compras().subscribe(res=>{
      if(res!=null){
        this.cat_compras=JSON.parse(JSON.stringify(res)).Datas;
        this.initGastos();
      }else{
        console.log('sin cat codigo de compras');
      }


    });

  

   
  }

  total:any = 0;

  initGastos(){

   
    this.subtitulo="Total Egresos Acumulado."
    this.date_cards=this.setData_cards(998933.34,272282.18,8373.62);
    this.dia_hinabil="";
    

     let grafic=[];
     let grafica_adm=[];
     let grafica_VTA=[];
     let grafica_ADQ=[];
     let grafica_ALM=[];

     let grafica_sinclas=[];

     let total=0;


    this.service.getrSincro(this.fecha,'rp_compras_acumulado').subscribe(res=>{
                      if(res!=null){
                        let sincro=JSON.parse(JSON.stringify(res)).Datas[0].Data;
                        this.service.getrp_info(sincro.sincro_id,'rp_compras_acumuladas').subscribe(res=>{
                         
                          if(res!=null){
                            let compras=JSON.parse(JSON.stringify(res)).Datas;
                            for(let compra of compras){

                              let partCodidg=compra.Data.codigo.split('');

                              let prod=partCodidg[0]+partCodidg[1]+partCodidg[2];
                               total=total+parseFloat(compra.Data.efectivo);

                                  if(prod=='PRO'){
                                    let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                    console.log(name);
                                    if (name!=null && name!=undefined) {
                                       grafic.push(
                                        { 
                                          "name":name.Data.nombre ,
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                            
                                    }else{
                                       grafic.push(
                                        { 
                                          "name":'Sin Nombre('+compra.Data.codigo+')',
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                                    }


                                  }

                                  if(prod=='ADM'){
                                    let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                    console.log(name);
                                    if (name!=null && name!=undefined) {
                                       grafica_adm.push(
                                        { 
                                          "name":name.Data.nombre ,
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                            
                                    }else{
                                       grafica_adm.push(
                                        { 
                                          "name":'Sin Nombre('+compra.Data.codigo+')',
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                                    }


                                  }

                                  if(prod=='VTA'){
                                    let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                    console.log(name);
                                    if (name!=null && name!=undefined) {
                                       grafica_VTA.push(
                                        { 
                                          "name":name.Data.nombre ,
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                            
                                    }else{
                                       grafica_VTA.push(
                                        { 
                                          "name":'Sin Nombre('+compra.Data.codigo+')',
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                                    }


                                  }

                                  if(prod=='ADQ'){
                                    let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                    console.log(name);
                                    if (name!=null && name!=undefined) {
                                       grafica_ADQ.push(
                                        { 
                                          "name":name.Data.nombre ,
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                            
                                    }else{
                                       grafica_ADQ.push(
                                        { 
                                          "name":'Sin Nombre('+compra.Data.codigo+')',
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                                    }


                                  }

                                  if(prod=='ALM'){
                                    let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                    console.log(name);
                                    if (name!=null && name!=undefined) {
                                       grafica_ALM.push(
                                        { 
                                          "name":name.Data.nombre ,
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                            
                                    }else{
                                       grafica_ALM.push(
                                        { 
                                          "name":'Sin Nombre('+compra.Data.codigo+')',
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                                    }


                                  }

                                   if(prod!='ALM' && prod!='VTA' && prod!='ADM' && prod!='ADQ' && prod!='PRO' ){
                                    let name=this.cat_compras.find(cat=>cat.Data.codigo==compra.Data.codigo);
                                    console.log(name);
                                    if (name!=null && name!=undefined) {
                                       grafica_sinclas.push(
                                        { 
                                          "name":name.Data.nombre ,
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                            
                                    }else{
                                       grafica_sinclas.push(
                                        { 
                                          "name":'Sin Nombre('+compra.Data.codigo+')',
                                          "value": parseFloat(compra.Data.efectivo),
                                          "extra":{val_form:new Intl.NumberFormat('es-MX').format(parseFloat(compra.Data.efectivo))} 
                                        });
                                    }


                                  }


                                 

                                
                            }


                                this.sinClasificar=[  {
                                  "name": "Gtos. sin Clasificar",
                                  "series": grafica_sinclas} ]

                              this.barra_adq=[{
                               
                                "name": "Adqisición de Activo Fijo",
                                "series": grafica_ADQ},]


                              this.barra_alm=[{

                                "name": "Almacenaje de Productos",
                                "series":grafica_ALM },
                              ]

                             

                              
                            this.barra_adm=[{

                                "name": "Gtos. de Administración",
                                "series":grafica_adm
                              }
                            ]

                            this.unaBara_date=[{
                              "name": "Gtos. de Producción",
                              "series": grafic }];

                            this.grafica_1=[{
                              "name": "Gtos. de Ventas",
                              "series":grafica_VTA 
                            }];

                                            

                            //this.grafica_1=;
                            this.totalDia="$"+new Intl.NumberFormat('es-MX').format(total);
                            this.total=total;
                          }
                        });
                      }
                   });

  }

  initAgentes(){
    this.fecha='2020-08-14';
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(999117.84);
              this.date_cards="$"+this.setData_cards(40062.43,0,0);
    
                this.grafica_1=this.grafic_1_set([{  
                           
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(959556.26)}  ,
                    nombre:"Mostrador"  ,
                    value:959556.26
                  },
                  {  
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(10926.20)}  ,
                    nombre:"Sin Agente"  ,
                    value:10926.20
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(12732.47)}  ,
                    nombre:"Mercedes Pescador"  ,
                    value:12732.47
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(11609.94)}  ,
                    nombre:"Berenice Solis"  ,
                    value:11609.94
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(4108.47)}  ,
                    nombre:"Susana Rodriguez"  ,
                    value:4108.47
                  },
                  ])
                this.subtitulo="Total Ventas Acumuladas por Agentes"
                this.fecha_sv=this.fecha;
  }

   grafic_diesel=[];

  initGasolina(){

    this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
    this.subtitulo="Gtos total Acumulado de Diesel"
    this.fecha='2020-08-14';
    this.dia_hinabil="Sin Información del Día"
    this.Titulo_grafica="";

    this.grafic_diesel=this.grafic_1_set([

      {nombre:"Gtos Acumulado de Diesel" ,value: 0,extra:{val_form:new Intl.NumberFormat('es-MX').format(0)} },
      
                      
    ]);
  }

  cambioFecha_diecel(){
    switch (this.fecha) {
      case "2020-08-02":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        this.subtitulo="Gtos total Acumulado de Diesel"
        
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
         case "2020-08-09":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        this.subtitulo="Gtos total Acumulado de Diesel"
       
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
         case "2020-08-16":
        this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        this.subtitulo="Gtos total Acumulado de Diesel"
       
        this.dia_hinabil="Día Inhábil"
        this.Titulo_grafica="";
        break;
      
      default:
         this.totalDia="$"+new Intl.NumberFormat('es-MX').format(0);
        this.subtitulo="Gtos total Acumulado de Diesel"
       
        
        this.Titulo_grafica="";
         this.dia_hinabil="Sin Información del Día";
        break;
    }

  }



  parseTitlesCard(value){
    



    let val="$"+new Intl.NumberFormat('es-MX').format(value.value);

    if(value.label=="Número de Anticipos del Día"){
      val= Intl.NumberFormat('es-MX').format(value.value);
    }

    if(value.label=="Número de Maniobras del Día"){
      val =Intl.NumberFormat('es-MX').format(value.value);
    }

    if(value.label=="Ventas por Unidades del Día"){
     val= Intl.NumberFormat('es-MX').format(value.value)+" Pzas.";
    }
    
    return val;
  }

  parseTitlesCard2(value){

     
    if(value.label=="Anticipos"){
      return Intl.NumberFormat('es-MX').format(value.value);
    }
   
    if(value.label=="Ventas Por Productos Unidades"){
      return Intl.NumberFormat('es-MX').format(value.value)+"Pzas.";
    }
  }

  cambioFecha(form){

            console.log(this.fecha);
   
            this.Titulo_grafica="Ventas Financieras por Producto (80/20)";
            
            //this.switch_fin(); 

            this.initGastos();
            this.subtitulo="Total Egresos Acumulado."
            this.set_format=true;  
              
       
    
    
  }


  cambioFecha2(form){
    
      
      switch (form.form.value.cat) {
      case "fin":
            this.subtitulo="Total Ventas Acumuladas por Agentes"
            
            this.switch_ByAllAgente();
            
            this.set_format=true;  
              
        break;
        case "prod":
         this.subtitulo="Unidades Vendidas Acumuladas por Agentes"
          this.switch_ByAllAgente_prod(); 
         
          this.set_format=false;     
        break;
      
      default:
            alert("No se encontro Informacion valida para esta categoria");
        break;
    }
     
      
    
  }


  switch_fin(){
    switch (this.fecha) {
        case "2020-08-14":
          this.totalDia="$"+new Intl.NumberFormat('es-MX').format(271002.08);
    this.subtitulo="Total Egresos Acumulado."
    this.date_cards=this.setData_cards(998933.34,272282.18,8373.62);
    this.dia_hinabil="";
     this.unaBara_date=[{
    "name": "Gtos. de Producción",
    "series": [
      
        {name: "PRIMAS DE SEGURO"  ,value: 112721.56,'extra':{val_form:new Intl.NumberFormat('es-MX').format(112721.56)}},
        {name: "MANTENIMIENTO MAQUINARIA Y EQUIPO"  ,value: 38736.59,'extra':{val_form:new Intl.NumberFormat('es-MX').format(38736.59)}},
        {name: 'ENERGIA ELECTRICA (LUZ)',value:  29317.33,'extra':{val_form:new Intl.NumberFormat('es-MX').format(29317.33)}},
        {name: 'CEMENTO A GRANEL' ,value: 14899.97,'extra':{val_form:new Intl.NumberFormat('es-MX').format(14899.97)}},
        {name: 'Otros' ,value: 7705.23,'extra':{val_form:new Intl.NumberFormat('es-MX').format(7705.23)}},
     
    ] }];

    this.barra_adm=[
    {
    "name": "Gtos. de Administración",
    "series": [

        {name: " MANTENIMIENTO Y MEJORAS INSTALACIONES  "  ,value: 8465.79,'extra':{val_form:new Intl.NumberFormat('es-MX').format(8465.79)}},
        {name: 'PAPELERIA Y ARTICULOS DE OFICINA  ',value:  2459.20,'extra':{val_form:new Intl.NumberFormat('es-MX').format(2459.20)}},
        {name: 'ARTICULOS DE LIMPIEZA  ' ,value: 1000.00,'extra':{val_form:new Intl.NumberFormat('es-MX').format(1000.00)}},
        {name: 'Otros' ,value: 2110.05,'extra':{val_form:new Intl.NumberFormat('es-MX').format(2110.05)}},

      
    ]
  },
    ]
    this.sinClasificar=[  {
    "name": "Gtos. sin Clasificar",
    "series": [





        {name: " 12 Ligero  "  ,value: 36609.60 ,'extra':{val_form:new Intl.NumberFormat('es-MX').format(36609.60 )}},
        {name: 'MORTERO   ',value:  4640.00,'extra':{val_form:new Intl.NumberFormat('es-MX').format(4640.00)}},
        {name: 'ARMEX 15-20-4    ' ,value:3494.99,'extra':{val_form:new Intl.NumberFormat('es-MX').format(3494.99)}},
        {name: 'MANTENIMIENTO MAQUINARIA Y EQUIPO  ' ,value: 371.66,'extra':{val_form:new Intl.NumberFormat('es-MX').format(371.66)}},

        


      
    ]


  } ]


    this.grafica_1=[

    

  

  {
    "name": "Gtos. de Ventas",
    "series": [


        {name: " MANTENIMIENTO MAQUINARIA Y EQUIPO  "  ,value: 8854.08 ,'extra':{val_form:new Intl.NumberFormat('es-MX').format(8854.08)}},
        {name: 'MANTENIMIENTO EQUIPO DE TRANSPORTE  ',value:  4531.64,'extra':{val_form:new Intl.NumberFormat('es-MX').format(4531.64)}},
        {name: 'GASOLINA' ,value: 4492.76,'extra':{val_form:new Intl.NumberFormat('es-MX').format(4492.76)}},
        {name: 'MONITOREO Y RASTREO DE VEHICULOS    ' ,value:4292,'extra':{val_form:new Intl.NumberFormat('es-MX').format(4292)}},
        {name: 'Otros ' ,value: 7115.38,'extra':{val_form:new Intl.NumberFormat('es-MX').format(7115.38)}},


      
    ]


  }   
  
          
                      
    ];

            this.barra_adq=[
    {
   
    "name": "Adqisición de Activo Fijo",
    "series": [

     
      
    ]

    
  },
    ]


   this.barra_alm=[
    {
    "name": "Almacenaje de Productos",
    "series": [

 
      
    ]

    
  },
    ]


    this.Titulo_grafica="Ventas Financieras por Producto (80/20)";
      break;

   case "2020-08-31":



    this.totalDia="$"+new Intl.NumberFormat('es-MX').format(271002.08);
    this.subtitulo="Total Egresos Acumulado."
    this.date_cards=this.setData_cards(998933.34,272282.18,8373.62);
    this.dia_hinabil="";
     this.unaBara_date=[{
    "name": "Gtos. de Producción",
    "series": [







      
        {name: "CEMENTO A GRANEL  "  ,value: 871697.37,'extra':{val_form:new Intl.NumberFormat('es-MX').format(871697.37)}},
        {name: "PRIMAS DE SEGURO  "  ,value: 38736.59,'extra':{val_form:new Intl.NumberFormat('es-MX').format(112721.56)}},
        {name: 'RENTA DE MAQUINARIA Y EQUIPO  ',value:  61290.24,'extra':{val_form:new Intl.NumberFormat('es-MX').format(61290.24)}},
        {name: 'MANTENIMIENTO MAQUINARIA Y EQUIPO  ' ,value:61172.57,'extra':{val_form:new Intl.NumberFormat('es-MX').format(61172.57)}},
        {name: 'COLOR PARA CONCRETO  ' ,value:53592,'extra':{val_form:new Intl.NumberFormat('es-MX').format(53592)}},
        {name: 'Otros' ,value: 79595.70,'extra':{val_form:new Intl.NumberFormat('es-MX').format(79595.70)}}
     
    ] }];

    this.barra_adm=[
    {
    "name": "Gtos. de Administración",
    "series": [

        {name: " ENERGIA ELECTRICA (LUZ) "  ,value: 10876.47,'extra':{val_form:new Intl.NumberFormat('es-MX').format(10876.47)}},
        {name: 'MANTENIMIENTO Y MEJORAS INSTALACIONES ',value:  8465.79,'extra':{val_form:new Intl.NumberFormat('es-MX').format(8465.79)}},
       
        {name: 'Otros' ,value: 7591.79,'extra':{val_form:new Intl.NumberFormat('es-MX').format(7591.79)}},

      
    ]
  },
    ]


        this.barra_adq=[
    {
   
    "name": "Adqisición de Activo Fijo",
    "series": [

        {name: " CHASIS CABINA H500 MARCA HYUNDAI "  ,value: 658160 ,'extra':{val_form:new Intl.NumberFormat('es-MX').format(658160)}},
        {name: ' PLATAFORMA MATERIALISTA',value: 74240,'extra':{val_form:new Intl.NumberFormat('es-MX').format(74240)}},
      
    ]

    
  },
    ]


   this.barra_alm=[
    {
    "name": "Almacenaje de Productos",
    "series": [

      {name: " DIESEL "  ,value: 134311.3 ,'extra':{val_form:new Intl.NumberFormat('es-MX').format(134311.3)}},
      {name: ' IEPS DIESEL',value:  1686.35,'extra':{val_form:new Intl.NumberFormat('es-MX').format(2538.65)}},
      
    ]

    
  },
    ]



    this.sinClasificar=[  {
    "name": "Gtos. sin Clasificar",
    "series": [





        {name: " 12 Ligero  "  ,value:73219.2 ,'extra':{val_form:new Intl.NumberFormat('es-MX').format(373219.2 )}},
        {name: 'CEMENTO   ',value:  20859.96,'extra':{val_form:new Intl.NumberFormat('es-MX').format(20859.96)}},
        {name: 'Otros' ,value:20034.94,'extra':{val_form:new Intl.NumberFormat('es-MX').format(20034.94)}},

        


      
    ]


  } ]
    this.grafica_1=[

    

  

  {
    "name": "Gtos. de Ventas",
    "series": [
        {name: " MONITOREO Y RASTREO DE VEHICULOS  "  ,value: 4292.00 ,'extra':{val_form:new Intl.NumberFormat('es-MX').format(4292.00 )}},
        {name: 'GASOLINA    ',value:  1686.35,'extra':{val_form:new Intl.NumberFormat('es-MX').format(1686.35)}},
        {name: 'TELEFONO FIJO    ' ,value: 530.51,'extra':{val_form:new Intl.NumberFormat('es-MX').format(530.51)}},
        {name: 'TELEFONO MOVIL  ' ,value: 200.00,'extra':{val_form:new Intl.NumberFormat('es-MX').format(200.00)}},
        {name: 'Otros ' ,value: 102.25,'extra':{val_form:new Intl.NumberFormat('es-MX').format(102.25)}},


      
    ]


  }   
  ,
  
  
          
                      
    ];


    this.Titulo_grafica="Ventas Financieras por Producto (80/20)";
      break;
      

      
      
      default:
            this.fecha=this.fecha_sv;
            alert("No se encontro Información para esta fecha");

        break;
    }
  }

  switch_uni(){
    switch (this.fecha) {



        case "2020-08-14":
              this.totalDia=new Intl.NumberFormat('es-MX').format(124910);
              this.date_cards=this.setData_cards_unidad(124864,18,28,'Anticipo','Maniobras');

              this.grafica_1=this.grafic_1_set([
                      {nombre:"Bloque 15 liso normal " ,value: 38402 , extra:{codigo:"20LACN", val_form:38402} },
                      {nombre:"Bloque 12 liso normal  " ,value: 25630 , extra:{codigo:"20LACN", val_form:25630 } },
                      {nombre:"Bloque 12 alta resistencia  " ,value: 22462 , extra:{codigo:"20LACN", val_form:22462} },
                      {nombre:"Bloque 15 alta resistencia  " ,value: 14148 , extra:{codigo:"20LACN", val_form:14148} },
                      {nombre:"Bloque 20 liso alta resistencia  " ,value: 4616 , extra:{codigo:"20LACN", val_form: 4616} },
                      {nombre:"BLOCK 15 LN  " ,value: 3750 , extra:{codigo:"20LACN", val_form:3750 } },
                       {nombre:"Adoquin venecia rojo ocre  " ,value: 3600 , extra:{codigo:"20LACN", val_form:3600 } },
                        {nombre:"Bloque 15 cara de piedra café rosa  " ,value: 2812 , extra:{codigo:"20LACN", val_form:2812 } },
                         {nombre:"ADOQUIN VENECIA  2400" ,value: 2400 , extra:{codigo:"20LACN", val_form:2400 } },
                          {nombre:"Bloque 15 cara de piedra rojo ocre" ,value:1390 , extra:{codigo:"20LACN", val_form:1390} },
                           {nombre:"Bloque 15 cara de piedra amarillo paja  " ,value: 1308.00, extra:{codigo:"20LACN", val_form:1308.00 } },
                      {nombre:"Otros" ,value: 4393, extra:{codigo:"20LACN", val_form:4393 } },
                    
                ])
              this.fecha_sv=  this.fecha;
        break;

        

      
      default:
            this.fecha=this.fecha_sv;
            alert("No se encontro Información para esta fecha");

        break;
    }
  }


  switch_ByAllAgente(){
      switch (this.fecha) {

        case "2020-08-14":
              this.totalDia="$"+new Intl.NumberFormat('es-MX').format(999117.84);
              this.date_cards="$"+this.setData_cards(40062.43,0,0);
    
                this.grafica_1=this.grafic_1_set([{  
                           
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(959556.26)}  ,
                    nombre:"Mostrador"  ,
                    value:959556.26
                  },
                  {  
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(10926.20)}  ,
                    nombre:"Sin Agente"  ,
                    value:10926.20
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(12732.47)}  ,
                    nombre:"Mercedes Pescador"  ,
                    value:12732.47
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(11609.94)}  ,
                    nombre:"Berenice Solis"  ,
                    value:11609.94
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(4108.47)}  ,
                    nombre:"Susana Rodriguez"  ,
                    value:4108.47
                  },
                  ])
                this.fecha_sv=this.fecha;
              
        break;
      
        default:
            this.fecha=this.fecha_sv;
            alert("No se encontro Información para esta fecha");

        break;
      }

     
  }

   switch_ByAllAgente_prod(){
      switch (this.fecha) {

        case "2020-08-14":
              this.totalDia=new Intl.NumberFormat('es-MX').format(124866);
              this.date_cards=this.setData_cards(3399,0,0);
    
                this.grafica_1=this.grafic_1_set([{  
                           
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(114637)}  ,
                    nombre:"Mostrador"  ,
                    value:114637
                  },
                  {  
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(837)}  ,
                    nombre:"Sin Agente"  ,
                    value:837
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(7251)}  ,
                    nombre:"Mercedes Pescador"  ,
                    value:7251
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(1691)}  ,
                    nombre:"Berenice Solis"  ,
                    value:1691
                  },
                  {  
                    extra:{ val_form:new Intl.NumberFormat('es-MX').format(450)}  ,
                    nombre:"Susana Rodriguez"  ,
                    value:450
                  },
                  ])
                this.fecha_sv=this.fecha;
              
        break;
        
      
        default:
            this.fecha=this.fecha_sv;
            alert("No se encontro Información para esta fecha");

        break;
      }

     
  }

 

  single_Set(title,value){
    let val=[{
            "name": title,
            "value": value

       }];
    return  val;

  }


    single_Set_stack(title,value){
    let val={
            "name": title,
            "value": value

       };
    return  val;

  }

   


  grafic_1_set(objeto_1){

        let objs=[];
        for(let obj of objeto_1){
            objs.push({
                    extra:{val_form:new Intl.NumberFormat('es-MX').format(obj.value)}  ,
                  name:obj.nombre  ,
                  value:obj.value});
        }

        return objs;


  }

  setData_cards(financiera,anticipos,maniobras){
    let val=[
      {
       "name": "Venta Financiera del Día",
       "value": financiera

      },
       {
           "name": "Anticipos Financieros del Día",
           "value": anticipos

      },
      {
           "name": "Maniobras Financieras del Día",
           "value": maniobras
      
      }];

      return val;


  }


  setData_cards_unidad(financiera,anticipos,maniobras,unidad_anticpo,unidad_maniobar){
    let val=[
      {
       "name": "Ventas por Unidades del Día",
       "value": financiera

      },
       {
           "name": "Número de Anticipos del Día",
           "value": anticipos

      },
      {
           "name": "Número de Maniobras del Día",
           "value": maniobras
      
      }];

      return val;


  }





}