import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class CatalogosService{
	public url="http://67.205.145.224/durango/dashboard_api.php?type=";
	constructor(private http: HttpClient){}

	getPromotores(){
		return this.http.get(this.url+"sc_getPromotores2");
	}

	getCatalogo(catalogo){
		return this.http.get(this.url+"sc_getCatalogo&tabla="+catalogo);
	}

	postPromotores(data){
		return this.http.get(this.url+"sc_postPromotores&nombre="+data.nombre
			+"&$apellido_paterno="+data.$apellido_paterno+"&apellido_materno="+data.apellido_materno
			+"&telefono="+data.telefono+"&correo="+data.correo+"&inmobiliaria_id="+data.inmobiliaria_id);
	}
	putPromotores(data,id){
		return this.http.get(this.url+"sc_putPromotores&nombre="+data.nombre
			+"&$apellido_paterno="+data.$apellido_paterno+"&apellido_materno="+data.apellido_materno
			+"&telefono="+data.telefono+"&correo="+data.correo+"&inmobiliaria_id="+data.inmobiliaria_id+"&id="+id);
	}


	 
            
	

}