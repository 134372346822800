import { Component, OnInit } from '@angular/core';
import { ConfiguradorService } from '../services/configurador.service'
import { Configuracion } from '../modals/configurador';


@Component({
  selector: 'app-configurador',
  templateUrl: './configurador.component.html',
  styleUrls: ['./configurador.component.css'],
  providers:[ConfiguradorService]
})


export class ConfiguradorComponent implements OnInit {
	public indicador:any=0;
	public tipo_vista:any=0;
	public alimentos:boolean;
	public bebidas:boolean;
	public vinos:boolean;
	public licores:boolean;
	public postres:boolean;
	public cerveza:boolean;
	public efectivo:boolean;
	public credito:boolean;
	public tarjeta_credito:boolean;
	public tarjeta_debito:boolean;
  public datos:any;
  public cargando=false;
  public exito=false;

  constructor(private _configuradorService:ConfiguradorService) { 

  }

  ngOnInit() {


    this._configuradorService.getData().subscribe(
      result=>{
        console.log(result);
        var eco = JSON.stringify(result);
        var json = JSON.parse(eco);
        this.datos=json.Configuraciones[0].Configuracion;

        console.log(this.datos.indicador);
        this.indicador=this.datos.indicador;
        this.tipo_vista=this.datos.pos;
        this.alimentos=this.datos.alimentos==1?true:false;
        this.bebidas=this.datos.bebidas==1?true:false;
        this.vinos=this.datos.vinos==1?true:false;
        this.licores=this.datos.licores==1?true:false;
        this.postres=this.datos.postres==1?true:false;
        this.cerveza=this.datos.cerveza==1?true:false;
        this.efectivo=this.datos.efectivo==1?true:false;
        this.credito=this.datos.credito==1?true:false;
        this.tarjeta_credito=this.datos.tarjeta_credito==1?true:false;
        this.tarjeta_debito=this.datos.tarjeta_debito==1?true:false;
 
      },
      error =>{
        console.log(<any>error);
      }
      );

  
  }

  guardar(){

    this.cargando=true;
   
    let configuracion=new Configuracion(this.indicador,this.tipo_vista,this.alimentos,this.bebidas
    ,this.vinos,this.licores,this.postres,this.cerveza,this.efectivo,this.credito
    ,this.tarjeta_credito,this.tarjeta_debito
    );
 

    this._configuradorService.update(configuracion).subscribe(
      result=>{
        console.log(result);

         this.cargando=false;
         this.exito=true;
      },error=>{
        console.log(<any> error);
      });
  }
  msj_cerrar(){
    this.exito=false;
  }
  ngDoCheck(){
  	//console.log(this.indicador);
  }

}
