import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';


@Component({
  selector: 'app-tipos-pagos',
  templateUrl: './tipos-pagos.component.html',
  styleUrls: ['./tipos-pagos.component.css'],
  providers:[CatalogosService]
})
export class TiposPagosComponent implements OnInit {

 	tipo_pagos:any=[];
	id_update:any;
	descrip:any;
	titulo:any;
	nombre:any;
	ifPagos=true;
	esquemas_ids:any=[];
	esquema_id:any;

  constructor(private servicios:CatalogosService) { }

  ngOnInit() {
  	 this.nombre="Tipos de Pagos";
            this.servicios.getCatalogo("tipos_pagos").subscribe(result=>{
               console.log(result);
                if(result!=null){
	                 let temp=JSON.stringify(result);
	                 let temp2=JSON.parse(temp);
                 this.tipo_pagos=temp2.Datas;
               }
            });

        this.servicios.getCatalogo("esquemas").subscribe(result=>{
               console.log(result);
                if(result!=null){
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.esquemas_ids=temp2.Datas;
               }
            });
  }


  setid3(id,tit,des,esquema_id){
    this.id_update=id;
    this.descrip=des;
    this.titulo=tit;
    this.esquema_id=esquema_id;
    
  }

   setiddelete(id){

    this.id_update=id;
  }
   postStatus(form){
		 this.servicios.postTiposPagos(form.form.value.nombre,form.form.value.descripcion,form.form.value.id_indicador).subscribe(result=>{
            location.reload();
          },error=>{
            console.log(error);
          });
   }
   putStatus(form){
   	this.servicios.putTiposPagos(form.form.value,this.id_update).subscribe(result=>{
         location.reload();
      },error=>console.log(error));   
   }
   deleteCatlogo(){
   		this.servicios.deleteCatalogo('tipos_pagos','tipo_pago_id',this.id_update).subscribe(result=>{
         location.reload();
      },error=>console.log(error));  
   }


}
