import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class ValuadoresService{
	public url="http://67.205.145.224/durango/dashboard_api.php?type=";

	constructor(private http: HttpClient){}

	

	getValuadores(){
		return this.http.get(this.url+"sc_getValuadores");
	}
	postValuadores(data){
		return this.http.get(this.url+"sc_postValudores&nombre="+data.nombre+
			"&apellido_paterno="+data.apellido_paterno+"&apellido_materno="+data.apellido_materno
			+"&telefono="+data.telefono+"&correo="+data.correo+"&descripcion="+data.descripcion+"&unidad_valuacion="+data.unidad_valuacion);
	}
	putValuadores(data,id){
		return this.http.get(this.url+"sc_putValudores&nombre="+data.nombre+
			"&apellido_paterno="+data.apellido_paterno+"&apellido_materno="+data.apellido_materno
			+"&telefono="+data.telefono+"&correo="+data.correo+"&descripcion="+data.descripcion+"&id="+id+"&unidad_valuacion="+data.unidad_valuacion);
	}

	deleteValuadores(columna,table,id){
		return this.http.get(this.url+"sc_DeleteCatalogo&table="+table+"&columna="+columna+"&id="+id)
	}
}