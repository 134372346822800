import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-resportes',
  templateUrl: './resportes.component.html',
  styleUrls: ['./resportes.component.css']
})
export class ResportesComponent implements OnInit {
	
	equipos:any;

    myId:any;
  myTeams:any;
  integrantes:any;
  roles:any;
  inteam=false;
  equipo=true;
  rol_activ=false;
  eq_id:any;
  rol:any;
  actividad:any;
  id_actividad:any;
  rol_id:any;
  excusa=false;
  titulo:any;
  integrante:any;
  status:any;
  nombre_rol:any;
  integrantes_byRol:any;
  falta:any=[];  
  inAsis:any=[];
  eq_actual:any=[];

  lisAsistencia:any=[];


  nan="NaN/NaN/NaN";


  id:any;
  equipos_old:any;


  constructor(private services:UsersService) { }

  ngOnInit() {
  	localStorage.setItem('page','7');
  	this.services.getEquipos().subscribe(result => {
			if (result!=null) {
				this.equipos=JSON.parse(JSON.stringify(result)).Datas;

        console.log(this.equipos);
				
        this.equipos_old=this.equipos;
			}
	});


  }
  openTeam(id){

    this.inteam=true;

    this.equipo=false;
    
    this.eq_id=id.eqTrabajo_id;

    this.integrantes=[];
    this.eq_actual=id;

    

    this.services.getRolsbyeqp(id.eqTrabajo_id).subscribe(result=>{
      result != null ? this.roles=JSON.parse(JSON.stringify(result)).Datas : null ;
    });

  }

  returnTeams(){
    this.integrantes=[];
    this.roles=[];
    this.inteam=false;
    this.equipo=true;
  }

   openRol(data){
   
    this.nombre_rol=data.nombre;
    this.rol_activ=true;
    this.inteam=false;
    this.rol_id=data.rol_id;
    this.id=data.eq_rol;
    this.rol=data;

    this.services.getAct_rol_id(data.eq_rol).subscribe(res=>{
      res!=null?this.actividad=JSON.parse(JSON.stringify(res)).Datas : null;
      
    });

  

    this.getIntegrantesByTeam();


    this.services.getRol_act_ids(this.eq_id,data.rol_id).subscribe(res=>{
      
      res!=null?this.rol=JSON.parse(JSON.stringify(res)).Datas[0].Data : null;
     

      let fecha=new Date(this.rol.fecha_programada);
      let mes=fecha.getMonth()+1;
      let dia=fecha.getDate()+1;
      
      this.rol.fecha_programada=dia+"/"+mes+"/"+fecha.getFullYear();
      

    });

  }
  getIntegrantesByTeam(){
      this.services.getInegrantes(this.eq_id).subscribe(result=>{
      if(result != null){
        
        this.integrantes=JSON.parse(JSON.stringify(result)).Datas;
        
        this.getIntegrantesById();
        
      }

    });

  }

   getIntegrantesById(){
    

    this.services.getrol_InAsistencias(this.id).subscribe(res=>{
      
      
      if(res!=null){

         this.falta=JSON.parse(JSON.stringify(res)).Datas;
         
         let lista=this.integrantes;
         for(let ft of this.falta){

             lista=lista.filter(d=>d.Data.integrante_id!=ft.Data.integrante_id);

         }

         this.lisAsistencia=lista;

         console.log(this.lisAsistencia);
      }
    });


    this.services.getrol_Retardos(this.id).subscribe(res=>{
      
      
      if(res!=null){

       this.inAsis=JSON.parse(JSON.stringify(res)).Datas; 
      }

    });



  }

    returnRols(){
    this.rol_activ=false;
    this.inteam=true;
    this.integrantes_byRol=[];
    this.falta=[];  
    this.inAsis=[];
    this.integrantes=[];
  }
  actividadByid:any;
setIdActividad(data){
    
    this.id_actividad=data.act_eq_id;
    this.services.getrol_acct_id(data.act_eq_id).subscribe(res=>{

      res!=null ? this.actividadByid=JSON.parse(JSON.stringify(res)).Datas[0] : null ;
    });
    
  }


  detIntegrantes(){
    alert("EDgar");
  }

  filtroRol(ev){

      if (ev==='') {
       this.equipos=this.equipos_old;

      }else{
        this.equipos=this.equipos_old;
        let val = ev.target.value;
        console.log(val);
        
        if (val && val.trim() != '') {
        this.equipos = this.equipos.filter((item) => {
          return (item.Data.nombre_user.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.roles=this.equipos_old;
      }
     }
  }


}

