import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-funfd',
  templateUrl: './funfd.component.html',
  styleUrls: ['./funfd.component.css']
})
export class FunfdComponent implements OnInit {

  me_id:any;
  status:any;
  me:any;
  entregables:any=[];
  newEntregable:any=[];
  listaFechas:any=[];
  puestos:any=[];
  newEntregable_name="";
  listColaboradores:any=[];
  colaboradores:any=[];

  departamentos:any=[];

  crandoFuncion:any=false;
  paraTodos:any=false;

  funciones:any=[];

  otherFun:any=[];



  constructor(private services:UsersService) { }

  ngOnInit() {
  	this.loadDatas().then().catch(err=>{
  		console.log(err);
  	}).then(()=>{

    console.log(this.me_id);
    console.log(this.status);
  	});
  }


   async loadDatas(){

    this.me_id= await sessionStorage.getItem('id');
     
    this.status= await sessionStorage.getItem('status');

   if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
       this.me={
         name:"Admin",
         user_id:this.me_id
       }


    this.getFunciones();
    this.getEntregablesAll();
    this.getPuestos();
    this.getDepartamentos();
    this.getColaboradores();
    this.getFunciones();
     
   }else{
     this.services.getMe(this.me_id).subscribe(user=>{
      user!=null? this.me=JSON.parse(JSON.stringify(user)).Datas[0].Data:this.me=null;  
      console.log(this.me);
         this.getEntregablesAll();
			   this.getPuestos();
			   this.getDepartamentos();
			   this.getColaboradores();
			   this.getFunciones();
      this.services.getOtherFun(this.me_id,'fp').subscribe(res=>{
            res!=null? this.otherFun=JSON.parse(JSON.stringify(res)).Datas:null;
          });
						        
    });
   }



  		
  }


   cerrarModalFuncioUP(form){

    this.getFunciones();
    form.resetForm();
    $('#Actualizar_fun').modal('hide');
    $('#Actualizar_fun_admin').modal('hide');
     $('#clon_1').modal('hide');
      this.funcionUp=[];
      this.entregablesUp=[];
    

  }

  puesto_by_act=[];
  puesto_by_old=[];

   cerrarModalFuncioUPPuesto(){
      this.puesto_by_act=[];
      this.puesto_by_old=[];
      
      $('#ver_fun_puesto').modal('hide');


  }


  oldFun:any=[];
  getFunciones(){
    this.funciones=[];

    if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){

      this.services.getFuncionesTipo('fd').subscribe(res=>{
            res!=null? this.funciones=JSON.parse(JSON.stringify(res)).Datas:null;
            this.oldFun=this.funciones;
      });

    }else{


      this.services.getFuncionesTipo_byUser("fd",this.me_id).subscribe(res=>{
         res!=null?this.funciones=JSON.parse(JSON.stringify(res)).Datas:null;
          this.oldFun=this.funciones;
      });
    }

    
    

  }
  
    actualizarDatos(form){
    form.form.value.id=this.funcionUp.actividad_id;
     this.services.updateActividad(form.form.value).subscribe(res=>{
       alert("Se actualizaron datos correctamente");
     });
  }

   addListEntregableoldAfter(form){
      console.log(form.form.value.entregable);

      let entregable_id=this.entregables.find(entre=>entre.Data.titulo==form.form.value.entregable).Data.entregable_id;
       console.log(entregable_id);
         this.services.insertEntre_act(
          this.funcionUp.actividad_id,
           entregable_id).subscribe(res=>{
            this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
               console.log(res);
               res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
               form.resetForm();
                $('#AgregarEntregablesAfter').modal('hide');
             });
        });
  }

    addAPuestoAfter(puesto){
    console.log(puesto.form.value)

     let rol_id=this.puestos.find(dep=>dep.Data.nombre== puesto.form.value.nombre).Data.rol_id;
      console.log(rol_id);

      this.services.getColbyPuesto(rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
         
               });
             }
           }

           this.openPuestoByAct();
           alert("Se agrego Funcion con éxito de Puesto");
            $('#AgregarPuesto_after').modal('hide');
        });
       


  }



    openPuestoByAct(){
      this.puesto_by_act=[];
      this.puesto_by_old=[];
     this.services.getpuestoByAct_dep(this.funcionUp.actividad_id,this.me.departamento_id).subscribe(res=>{
       console.log(res);
       
       res!=null?this.puesto_by_act=JSON.parse(JSON.stringify(res)).Datas:null;
       for(let puesto of this.puesto_by_act){

           puesto.Data.puesto=this.puestos.find(depa=>depa.Data.rol_id==puesto.Data.rol_id);

       }

       console.log(this.puesto_by_act);
       this.puesto_by_old=this.puesto_by_act;
    });

  }
  col_old:any=[];
  getColaboradores(){

    this.services.getEmleados().subscribe(res=>{
       res!=null? this.colaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
       this.col_old=this.colaboradores;
    });
  }

  getDepartamentos(){
    if(this.status==5){
      this.services.getDepbyid(this.me.departamento_id).subscribe(res=>{
        let myDep=JSON.parse(JSON.stringify(res)).Datas[0].Data;
            this.services.getDepsByArea(myDep.area_id).subscribe(res=>{
                  res!=null?this.departamentos=JSON.parse(JSON.stringify(res)).Datas:null;
                  for(let dep of this.departamentos){
                     dep.Data.check=false;
                  }
            });
      });
    }else{


  		this.services.getDepartamentos().subscribe(res=>{
  			res!=null?this.departamentos=JSON.parse(JSON.stringify(res)).Datas:null;
  				for(let dep of this.departamentos){
             dep.Data.check=false;
          }
  		});
    }
  }

   getPuestos(){

    this.services.getPuestosBydep(this.me.departamento_id).subscribe(res=>{
     	   	
      res!=null? this.puestos=JSON.parse(JSON.stringify(res)).Datas:null;

         console.log(this.puestos);
      
    });
  }

  getEntregablesAll(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.entregables);
    });
  }

  addListEntregableold(form){

    if(form.form.value.entregable!=""){
        let id=this.entregables.find(entr=>entr.Data.titulo==form.form.value.entregable);

        this.newEntregable.push(id);
        $('#AgregarEntregables').modal('hide');
        alert("Se agrego entregable con éxito");
        form.resetForm();
       	
    }else{
      alert("Es necesario seleccionar un Entregable para continuar");
    }


   
  }

  quitarEntregable(data){
  		console.log(data);
  				let seguro=confirm("¿Seguro que quieres quiatar este Entregable?");

  				if (seguro) {
  						this.newEntregable=this.newEntregable.filter(ent=>ent.Data.entregable_id!=data.entregable_id);
  				}

  	

  }

   addFecha(form){
   
    this.listaFechas.push(form.form.value);

    alert("Fecha Agregada");

    form.resetForm();

     $('#exampleModal').modal('hide');

  }


  insertArea(area){
      area.form.value.rol_id=this.puestos.find(puesto=>puesto.Data.nombre==area.form.value.rol_id).Data.rol_id;
      
    this.services.InsertEntregables(area.form.value).subscribe(res=>{

        console.log(res);
        let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
        for(let fecha of this.listaFechas){
           this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
             console.log("fecha insertada");
           });
        }
       
       this.newEntregable_name=area.form.value.titulo;

       this.listaFechas=[];
       area.resetForm();
       $('#createEntrgable').modal('hide');
        this.getEntregablesAll();
      alert("Se creo Entregable con éxito");
    });

  }


  listPuesto:any=[];


   addListPuesto(form){

    if(form.form.value.entregable!=""){
        let id=this.puestos.find(entr=>entr.Data.nombre==form.form.value.entregable);

        this.listPuesto.push(id);

        $('#AgregarPuesto').modal('hide');

        alert("Se agrego puesto con éxito");

        form.resetForm();
       	
    }else{
      alert("Es necesario seleccionar un puesto para continuar");
    }


   
  }


  creandoFun(form){

    if(form.valid){
      
      if(this.paraTodos){

        let SeguroPara=confirm("¿Seguro que quires Agregar esta funcion a Todos Los Departamentos?")
        if(SeguroPara){
          this.crandoFuncion=true;
          this.createFun(form);
        }
      }else{
        this.crandoFuncion=true;
        this.createFun(form);
      }
      
    }else{
      alert("Para poder crear Una Nueva Función es necesario que llenes los datos que tiene un * Rojo");
    } 
  }

  async createFun(form){


    form.form.value.departamento_id="";
    if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
      form.form.value.user_id='covelAdmins01';
    }else{
       form.form.value.user_id=this.me_id;
    }
   
    form.form.value.rol="";
    form.form.value.tipo="fd";

    let resut:any=[];

   let res=await this.services.postActividad(form.form.value); 

   res.subscribe(res=>{
     resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
    

     this.set_insert_Entregables(resut,form);
     this.getFunciones();
      form.resetForm();
   });

  }

  set_insert_Entregables(actividad_id,form){
    for(let entregables of this.newEntregable)
    {
        this.services.insertEntre_act(
        actividad_id,
        entregables.Data.entregable_id).subscribe(res=>{
        
      });
    }


      if(this.paraTodos){
        this.paraTodosAdd(actividad_id,form);
     }else{

       this.liagarPorDepartamento(actividad_id);
      }
      
    

  }


  paraTodosAdd(actividad_id,form){
       
     for(let cola of this.colaboradores){
         this.services.postAct_col(cola.Data.id+cola.Data.nombre,actividad_id).subscribe(res=>{
 
       });
     }
  
      this.crandoFuncion=false;
      this.cerrarCrearFuncion(form);
      alert("se Creo Función con Éxito");

  }
  list_depas=[];

    cerrarCrearFuncion(form){

    console.log(form.form.value);
    form.resetForm();
    $('#insertFuncion').modal('hide');
    $('#insertFuncion_admin').modal('hide');
    this.listColaboradores=[];
    this.listPuesto=[];
    this.list_depas=[];
    this.newEntregable=[];
    this.listaFechas=[];
    this.newEntregable_name="";

    this.getFunciones();

  }

  liagarPorDepartamento(actividad_id){

    if(this.me_id==="SuperAdmin" || this.me_id==="Admin" || this.status==5){
      for(let dep of this.departamentos){
        if(dep.Data.check){
          this.services.miColbyDep(dep.Data.departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

         });
          dep.Data.check=false;
        }
      }
    }else{

      for(let depa of this.list_depas){
         this.services.miColbyDep(depa.Data.departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

         })
           
      }

      this.ligarPorPuesto(actividad_id);
      }
    
  }

   ligarPorPuesto(actividad_id){

     for(let puesto of this.listPuesto){
        this.services.getColbyPuesto(puesto.Data.rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

        });
     }

     this.ligarporcolaborador(actividad_id);
     
   }

   ligarporcolaborador(actividad_id){
       for(let cola of this.listColaboradores){
                 this.services.postAct_col(cola.Data.id+cola.Data.nombre,actividad_id).subscribe(res=>{
                 
               });
             }

      this.crandoFuncion=false;
         alert("se Creo Función con Éxitos");

          $('#insertFuncion').modal('hide');

      this.listColaboradores=[];
      this.listPuesto=[];
      this.list_depas=[];
      this.newEntregable=[];
      this.listaFechas=[];
      this.newEntregable_name="";




   }

   funcionUp:any=[];
   entregablesUp:any=[];
   lis_deps_funUP:any=[];

  setFuncionUP(funcion){
    this.lis_deps_funUP=[];

     console.log(funcion);
     this.funcionUp=funcion;
     this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
       
       res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
     });

    this.loadDepas();
  }

  depasNoAsig:any=[];

  loadDepas(){

    this.depasNoAsig=this.departamentos;
    this.services.sc_getDeps_by_act_by_dep(this.funcionUp.actividad_id).subscribe(res=>{
      if (res!=null) {
        let deps=JSON.parse(JSON.stringify(res)).Datas;
         deps=deps.filter(d=>d.Data.departamento_id!=0);
        for(let dep of deps){

          this.depasNoAsig=this.depasNoAsig.filter(d=>d.Data.departamento_id!=dep.Data.departamento_id);

          if(dep.Data.departamento_id!=0){
            
            if(this.status==5){
              dep.Data.nombre_departamento=this.departamentos.filter(d=>d.Data.departamento_id==dep.Data.departamento_id);
              if(dep.Data.nombre_departamento.length!=0){
                dep.Data.nombre_departamento=dep.Data.nombre_departamento[0].Data;
              }
              console.log(dep.Data);

            }else{
               dep.Data.nombre_departamento=this.departamentos.filter(d=>d.Data.departamento_id==dep.Data.departamento_id)[0].Data;

            }
           
            
          }else{
             dep.Data.nombre_departamento={titulo:"No Asignado a Departamento"};
          }
         

        }

        this.lis_deps_funUP=deps;
        console.log(this.lis_deps_funUP);
      }
    });
  }

  close_addAftherDeps(){
      $('#addDep_after_admin').modal('hide');
  }

  otras(){
    for(let deps of this.depasNoAsig){
      if(deps.Data.check){
        this.services.miColbyDep(deps.Data.departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
         
               });
             }
           }

         });
          deps.Data.check=false;
      }
      this.loadDepas();
    }
    this.loadDepas();
    alert("Se agrego Función a Departamentos con Éxito");
     $('#addDep_after_admin').modal('hide');
      $('#Actualizar_fun_admin').modal('hide');
      $('#Actualizar_fun').modal('hide');
  }





elimiarActividad(){
  let seguro=confirm("¿Seguro que quiere Eliminar esta Función? al continuar se afectara a todos los Colaboradores que tengan esta Función.");
  if (seguro) {
      this.services.delete_Acividad(this.funcionUp.actividad_id).subscribe(res=>{
        console.log("Se Elimino Funcion con Éxito");
         $('#Actualizar_fun').modal('hide');
         this.getFunciones();
      });
  }
}

 eliminarActByPuesto(puesto){
    console.log(puesto);

    let seguro=confirm("¿Seguro que quieres continuar?, estas apunto de quitar esta Función a todos los integrantes del departamento.");
    if (seguro) {

      this.services.getColbyPuesto(puesto.puesto.Data.rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             console.log(colaboradores)
             
             for(let cola of colaboradores){
                 this.services.Delete_Act_Col(cola.Data.id_nombre,puesto.actividad_id).subscribe(res=>{
         
               });
             }
           }

           this.openPuestoByAct();
           alert("Se quito Funcion con éxito de Puesto");

        });
      
    
    }
  }

   eliminarActByDep(dep){
  
    let seguro=confirm("¿Seguro que quieres continuar?, estas apunto de quitar esta Función a todos los integrantes del departamento.");
    if (seguro) {

       this.services.miColbyDep(dep.departamento_id).subscribe(res=>{
          
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             console.log(colaboradores)
             
             for(let cola of colaboradores){
                 this.services.Delete_Act_Col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
         
               });
             }
           }

           this.lis_deps_funUP=this.lis_deps_funUP.filter(d=>d.Data.departamento_id!=dep.departamento_id);
           alert("Se quito Funcion con éxito de Puesto");

        });
      
    
    }
  }





 async clon_paso_1(){
  let seguro=confirm("¿Seguro que deseas crear una Función nueva apartir de esta Función? esto afectara a Todos los Colaboradores de tu departamento. ");
  if (seguro) {


    console.log(this.puestos);

     

     this.funcionUp.nombre=this.funcionUp.funcion;

     this.funcionUp.departamento_id=this.me.departamento_id;

     this.funcionUp.user_id=this.me_id;

     this.funcionUp.rol="";

     this.funcionUp.tipo="fd";

     console.log(this.funcionUp);

          let resut:any=[];

       let res=await this.services.postActividad(this.funcionUp); 

         res.subscribe(res=>{
         resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
          console.log(resut);

        for(let entregables of this.entregablesUp){
            this.services.insertEntre_act(
            resut,
            entregables.Data.entregable_id).subscribe(res=>{
            
            });
        }



          this.services.miColbyDep(this.me.departamento_id).subscribe(res=>{
           
           if(res!=null){
               let colaboradores=JSON.parse(JSON.stringify(res)).Datas;

               for(let cola of colaboradores){
                   this.services.postAct_col(cola.Data.id_nombre,resut).subscribe(res=>{
           
                 });
               }
             }
           })




         this.crandoFuncion=false;
         alert("se Creo Función con Éxitos");

          $('#clon_1').modal('hide');
          


          this.listColaboradores=[];
          this.listPuesto=[];
          this.list_depas=[];
          this.newEntregable=[];
          this.listaFechas=[];
          this.newEntregable_name="";

          this.getFunciones();

       });


    }



  }


filtro(ev){
      if (ev=='') {
       this.funciones=this.oldFun;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
            this.funciones = this.funciones.filter((item) => {
              return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
            });

          }else{

           this.funciones=this.oldFun;
          }
         }
  }









}
