import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;


@Component({
  selector: 'app-funfp',
  templateUrl: './funfp.component.html',
  styleUrls: ['./funfp.component.css']
})
export class FunfpComponent implements OnInit {
			me_id:any;
  status:any;
  me:any;
  entregables:any=[];
  newEntregable:any=[];
  listaFechas:any=[];
  puestos:any=[];
  newEntregable_name="";
  listColaboradores:any=[];
  colaboradores:any=[];

  departamentos:any=[];

  crandoFuncion:any=false;
  paraTodos:any=false;

  funciones:any=[];
  myPuestosDep:any=[];
  myPuestosDepOD:any=[];
  allColaboradores:any=[];
  otherFun:any=[];
  oldFun:any=[];


  constructor(private services:UsersService) { }

  ngOnInit() {
  		this.loadDatas().then().catch(err=>{
  		console.log(err);
  	}).then(()=>{

    console.log(this.me_id);
    console.log(this.status);
  	});

     this.services.getallCol().subscribe(res=>{
      res!=null ? this.allColaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
    });


  

  }



  async loadDatas(){

    this.me_id= await sessionStorage.getItem('id');
     
    this.status= await sessionStorage.getItem('status');

   if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){

      this.me={
        name:this.me_id,
        user_id:'covelAdmins01'
      }


       this.getFunciones();
       this.getColaboradores();
       this.getDepartamentos();
       this.getPuestos();
       this.getEntregablesAll();

     
   }else{

     this.services.getMe(this.me_id).subscribe(user=>{
      user!=null? this.me=JSON.parse(JSON.stringify(user)).Datas[0].Data:this.me=null;  
      
        this.getEntregablesAll();
        this.getPuestos();
        this.getDepartamentos();
        this.getColaboradores();
        this.getFunciones();

        
      						        
    });

     this.services.getOtherFun(this.me_id,'fp').subscribe(res=>{
         
          res!=null? this.otherFun=JSON.parse(JSON.stringify(res)).Datas:null;
          console.log(res);
        });

    }		
  }


  filtroPuesto_act(ev){
    console.log(this.myPuestosDep);
    if(ev==''){
        this.myPuestosDep=this.myPuestosDepOD;
     }else{

        this.myPuestosDep=this.myPuestosDepOD;

        let val = ev.target.value;
        
        if (val && val.trim() != ''){

        this.myPuestosDep = this.myPuestosDep.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.myPuestosDep=this.myPuestosDepOD;
      }
    }
  }


  cerrarModalFuncioUP(form){

    this.getFunciones();
    form.resetForm();
    this.ngOnInit();
    $('#Actualizar_fun').modal('hide');
     $('#clon_1').modal('hide');
      this.funcionUp=[];
      this.entregablesUp=[];
      this.myPuestosDep=[];
      this.listPuesto=[];

  }

  puesto_by_act=[];
  puesto_by_old=[];

  cerrarModalFuncioUPPuesto(){
      this.puesto_by_act=[];
      this.puesto_by_old=[];
      
      $('#ver_fun_puesto').modal('hide');
  }



  getFunciones(){

     if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){

       this.services.getFuncionesTipo('fp').subscribe(res=>{
        this.funciones=JSON.parse(JSON.stringify(res)).Datas;
        this.oldFun=this.funciones;
      });

    }else{    
    this.funciones=[];
    this.services.getFuncionesTipo_byUser("fp",this.me_id).subscribe(res=>{
       res!=null?this.funciones=JSON.parse(JSON.stringify(res)).Datas:null;
        this.oldFun=this.funciones;
    });

   

        

    }

  }
  
  actualizarDatos(form){
    form.form.value.id=this.funcionUp.actividad_id;
     this.services.updateActividad(form.form.value).subscribe(res=>{
       alert("Se actualizaron datos correctamente");
     });
  }

   addListEntregableoldAfter(form){
      console.log(form.form.value.entregable);

      let entregable_id=this.entregables.find(entre=>entre.Data.titulo==form.form.value.entregable).Data.entregable_id;
       console.log(entregable_id);
         this.services.insertEntre_act(
          this.funcionUp.actividad_id,
           entregable_id).subscribe(res=>{
            this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
               console.log(res);
               res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
               form.resetForm();
                $('#AgregarEntregablesAfter').modal('hide');
             });
        });
  }

  addAPuestoAfter(puesto){
    console.log(puesto.form.value)


     let rol_id=this.puestos.find(dep=>dep.Data.nombre== puesto.form.value.nombre).Data.rol_id;
      console.log(rol_id);

      this.services.getColbyPuesto(rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
         
               });
             }
           }

          
          
            

      this.services.getpuestoByAct(this.funcionUp.actividad_id).subscribe(res=>{
   
       
       res!=null?this.puesto_by_act=JSON.parse(JSON.stringify(res)).Datas:null;

      

       for(let puesto of this.puesto_by_act){

           puesto.Data.puesto=this.puestos.find(depa=>depa.Data.rol_id==puesto.Data.rol_id);

           this.myPuestosDep=this.myPuestosDep.filter(depa=>depa.Data.rol_id!=puesto.Data.rol_id);

       }

        console.log(this.myPuestosDep);
        console.log(this.puesto_by_act);
         this.listPuesto=this.puesto_by_act;
      
          alert("Se agrego Funcion con éxito de Puesto");
          $('#AgregarPuesto_after').modal('hide');
      });
        });
  }



  openPuestoByAct(){
    
      this.puesto_by_act=[];
      this.puesto_by_old=[];

      this.myPuestosDep=this.puestos;



      this.services.getpuestoByAct(this.funcionUp.actividad_id).subscribe(res=>{
   
       
       res!=null?this.puesto_by_act=JSON.parse(JSON.stringify(res)).Datas:null;

       
       console.log(res);
       for(let puesto of this.puesto_by_act){
          console.log(puesto.Data.nombre+" "+puesto.Data.rol_id);
           console.log(this.puestos.find(depa=>depa.Data.rol_id==puesto.Data.rol_id));
           puesto.Data.puesto=this.puestos.find(depa=>depa.Data.rol_id==puesto.Data.rol_id);

           this.myPuestosDep=this.myPuestosDep.filter(depa=>depa.Data.rol_id!=puesto.Data.rol_id);

       }

          console.log(this.puesto_by_act);
       
         this.listPuesto=this.puesto_by_act;
         this.myPuestosDepOD=this.myPuestosDep;
      
       
      });

  }

  getColaboradores(){

    this.services.getallCol().subscribe(res=>{
       res!=null? this.colaboradores=JSON.parse(JSON.stringify(res)).Datas:null;

       console.log("-------------------------------");
       console.log(this.colaboradores);
        console.log("-------------------------------");
    });


    
  }

  getDepartamentos(){

  		this.services.getDepartamentos().subscribe(res=>{
  			res!=null?this.departamentos=JSON.parse(JSON.stringify(res)).Datas:null;
  					console.log("------------------------");
  				
  		});
  }

  getPuestos(){

    if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
      this.services.getRoles().subscribe(res=>{

        res!=null? this.puestos=JSON.parse(JSON.stringify(res)).Datas:null;
          for(let puesto of this.puestos){
             puesto.Data.check=false;
          }
          this.myPuestosDep=this.puestos;
          console.log(this.puestos);
      });

    }else{
      if(this.status==5){
          this.services.getDepbyid(this.me.departamento_id).subscribe(res=>{
              console.log(res);
              if(res!=null){
              let  myDep=JSON.parse(JSON.stringify(res)).Datas[0].Data;
              this.services.getDepsByArea(myDep.area_id).subscribe(resDEps=>{
                  if(resDEps!=null){
                    let depas=JSON.parse(JSON.stringify(resDEps)).Datas;
                    for(let dep of depas){
                         this.services.getPuestosBydep(dep.Data.departamento_id).subscribe(res=>{
                
                        res!=null? this.puestos=JSON.parse(JSON.stringify(res)).Datas:null;
                          for(let puesto of this.puestos){
                             puesto.Data.check=false;
                             this.myPuestosDep.push(puesto);
                          }
                         
                        
                      });
                    }
                     this.myPuestosDep=this.puestos;
                          console.log(this.puestos);
                  }
              });
             
             }
            
          });


      }else{



        this.services.getPuestosBydep(this.me.departamento_id).subscribe(res=>{
         	   	
          res!=null? this.puestos=JSON.parse(JSON.stringify(res)).Datas:null;
            for(let puesto of this.puestos){
               puesto.Data.check=false;
            }
            this.myPuestosDep=this.puestos;
            console.log(this.puestos);
          
        });
     
    }

    }


  }

  getEntregablesAll(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.entregables);
    });
  }


  quitarListPuesto(data){
      console.log(this.listPuesto);

      let seguro=confirm("¿Seguro que desas quiatar la Función de este Puesto?");
      

      if(seguro){

        this.services.getColbyPuesto(data.puesto.Data.rol_id).subscribe(res=>{

            if(res!=null){
               let colaboradores=JSON.parse(JSON.stringify(res)).Datas;

              this.listPuesto=this.listPuesto.filter(ps=>ps.Data.puesto.Data.rol_id!=data.puesto.Data.rol_id);
               
              for(let cola of colaboradores){
                   this.services.Delete_Act_Col(cola.Data.id_nombre,data.actividad_id).subscribe(res=>{
           
                 });
              }
            }

         

         });
      
         
          alert("Se quito Funcion con éxito de Puesto");
    
      }
      
  }

  addListEntregableold(form){

    if(form.form.value.entregable!=""){
        let id=this.entregables.find(entr=>entr.Data.titulo==form.form.value.entregable);

        this.newEntregable.push(id);
        $('#AgregarEntregables').modal('hide');
        alert("Se agrego entregable con éxito");
        form.resetForm();
       	
    }else{
      alert("Es necesario seleccionar un Entregable para continuar");
    }
  
  }

  quitarEntregable(data){
  		console.log(data);
  				let seguro=confirm("¿Seguro que quieres quiatar este Entregable?");

  				if (seguro) {
  						this.newEntregable=this.newEntregable.filter(ent=>ent.Data.entregable_id!=data.entregable_id);
  				}

  }

  addFecha(form){
   
    this.listaFechas.push(form.form.value);

    alert("Fecha Agregada");

    form.resetForm();

     $('#exampleModal').modal('hide');

  }


  insertArea(area){
       
      area.form.value.id_nombre=this.allColaboradores.find(puesto=>puesto.Data.nombre==area.form.value.rol_id).Data.id_nombre;
      console.log(area.form.value);
      if(area.form.value.periodicidad!='Diario'){
    
        if(this.listaFechas.length>=4){
           this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

          this.newEntregable=area.form.value.titulo;

          this.listaFechas=[];
          area.resetForm();
          $('#createEntrgable').modal('hide');
          this.getEntregablesAll();
          alert("Se creo Entregable con éxito");
          });
        }else{
           alert("Para crear este Tipo de Entregable es necesario ingresar 4 o más fechas de Entrega.");
        }


      }else{
          this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

          this.newEntregable=area.form.value.titulo;

          this.listaFechas=[];
          area.resetForm();
          $('#createEntrgable').modal('hide');
          this.getEntregablesAll();
          alert("Se creo Entregable con éxito");
          });
      }

  }


  listPuesto:any=[];


  addListPuesto(form){

     for(let puesto of this.puestos){
       if (puesto.Data.check) {
        
        this.listPuesto.push(puesto);
 
       }
     }

        $('#AgregarPuesto').modal('hide');

        alert("Se agrego puesto con éxito");
   
  }


  creandoFun(form){

    if(form.valid){
      this.crandoFuncion=true;
      this.createFun(form);
    }else{
      alert("Para poder crear Una Nueva Función es necesario que llenes los datos que tiene un * Rojo");
    } 
  
  }

  async createFun(form){


    form.form.value.departamento_id="";
    if(this.me_id==="SuperAdmin" || this.me_id==="Admin")
      {
         form.form.value.user_id='covelAdmins01';
      }else{
        form.form.value.user_id=this.me_id;
    }
    

    form.form.value.rol="";
    form.form.value.tipo="fp";

    let resut:any=[];

    let res=await this.services.postActividad(form.form.value); 

    res.subscribe(res=>{
    resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
    

     this.set_insert_Entregables(resut,form);
     this.getFunciones();
      form.resetForm();
   });

  }

  set_insert_Entregables(actividad_id,form){
    for(let entregables of this.newEntregable)
    {
        this.services.insertEntre_act(
        actividad_id,
        entregables.Data.entregable_id).subscribe(res=>{
        
      });
    }


      if(this.paraTodos){
        this.paraTodosAdd(actividad_id,form);
     }else{

       this.liagarPorDepartamento(actividad_id);
      }
      
  }


  paraTodosAdd(actividad_id,form){

       if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
         
         for(let cola of this.colaboradores){
            this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{

             });
         }

       }else{


      this.services.miColbyDep(this.me.departamento_id).subscribe(res=>{
        if(res!=null){
           let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
           for(let cola of colaboradores){
               this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{

             });
           }
        }

      });

       }
           
        
      this.crandoFuncion=false;
      this.cerrarCrearFuncion(form);
      alert("se Creo Función con Éxito");

  }


  list_depas=[];

  cerrarCrearFuncion(form){

    console.log(form.form.value);
    form.resetForm();
    $('#insertFuncion').modal('hide');

    this.listColaboradores=[];
    this.listPuesto=[];
    this.list_depas=[];
    this.newEntregable=[];
    this.listaFechas=[];
    this.newEntregable_name="";

    this.getFunciones();

  }

  liagarPorDepartamento(actividad_id){

      for(let depa of this.list_depas){
         this.services.miColbyDep(depa.Data.departamento_id).subscribe(res=>{
           if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

         })
           
      }

      this.ligarPorPuesto(actividad_id);
    
  }

  ligarPorPuesto(actividad_id){

     for(let puesto of this.listPuesto){
        this.services.getColbyPuesto(puesto.Data.rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
         
               });
             }
           }

        });
     }

     this.ligarporcolaborador(actividad_id);
     
  }

  ligarporcolaborador(actividad_id){
    for(let cola of this.listColaboradores){
         this.services.postAct_col(cola.Data.id+cola.Data.nombre,actividad_id).subscribe(res=>{
         
       });
     }

    this.crandoFuncion=false;
    alert("se Creo Función con Éxitos");

    $('#insertFuncion').modal('hide');

    this.listColaboradores=[];
    this.listPuesto=[];
    this.list_depas=[];
    this.newEntregable=[];
    this.listaFechas=[];
    this.newEntregable_name="";

  }

   funcionUp:any=[];
   entregablesUp:any=[];


  setFuncionUP(funcion){
     console.log(funcion);
     this.funcionUp=funcion;
     this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
       console.log(res);
       res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
     });

     this.puesto_by_act=[];
     this.puesto_by_old=[];
     this.myPuestosDep=this.puestos;

      
      this.openPuestoByAct();
  
  }


  addpuestoAfther(){


    if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
      
      for(let puesto of this.myPuestosDep){
        if (puesto.Data.check) {

          this.services.getColbyPuesto(puesto.Data.rol_id).subscribe(res=>{
            if(res!=null){
                         let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
                         for(let cola of colaboradores){
                             this.services.postAct_col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
                               console.log(cola.Data.nombre+" :");
                               console.log(res);
                               console.log("/////////////////////////");
                           });
                          }

                         let data={
                           Data:{puesto:puesto}
                         }
                          this.listPuesto.push(data);
                          console.log(this.listPuesto);
                               }
          });

          
        }
      }
      
    }

    else{
      for(let puesto of this.myPuestosDep){

           console.log(puesto.Data.check);
                if (puesto.Data.check) {
                
                 puesto.Data.check=false;



                  this.services.getCol_dep_rol(puesto.Data.rol_id,this.me.departamento_id).subscribe(res=>{
                     
                       if(res!=null){
                         let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
                         for(let cola of colaboradores){
                             this.services.postAct_col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
                               console.log(cola.Data.nombre+" :");
                               console.log(res);
                               console.log("/////////////////////////");
                           });
                          }

                         let data={
                           Data:{puesto:puesto}
                         }
                          this.listPuesto.push(data);
                          console.log(this.listPuesto);
                               }
                         
                   });


          




              }


         }

        
      }

       alert("Se Agrego funcion a Puestos seleccionados");
        
         $('#ver_fun_puesto').modal('hide');

   }




elimiarActividad(){
  let seguro=confirm("¿Seguro que quiere Eliminar esta Función? al continuar se afectara a todos los Colaboradores que tengan esta Función.");
  if (seguro) {
      this.services.delete_Acividad(this.funcionUp.actividad_id).subscribe(res=>{
        console.log("Se Elimino Funcion con Éxito");
         $('#Actualizar_fun').modal('hide');
         this.getFunciones();
      });
  }
}

 eliminarActByPuesto(puesto){
    console.log(puesto);

    let seguro=confirm("¿Seguro que quieres continuar?, estas apunto de quitar esta Función a todos los integrantes del departamento.");
    if (seguro) {

      this.services.getColbyPuesto(puesto.puesto.Data.rol_id).subscribe(res=>{
        if(res!=null){
           let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
           console.log(colaboradores)
           
           for(let cola of colaboradores){
               this.services.Delete_Act_Col(cola.Data.id_nombre,puesto.actividad_id).subscribe(res=>{

             });
           }
        }

        this.openPuestoByAct();
        alert("Se quito Funcion con éxito de Puesto");

      });
        

      }
    }

    quitarEntregableAfter(entregable){
    console.log(entregable);
    let seguro=confirm("¿Seguro que quieres quitar este Entregable?");
    if (seguro) {
        this.services.deleteentreAct(entregable.ent_act_id).subscribe(res=>{
          this.entregablesUp=[];
          this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
             res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
               
             });
        });
    }
  }


  creandoFun2(form){
    let seguro = confirm("¿Seguro que desas crear esta Función para Todos los Puesto?")
    if(seguro){
      this.paraTodos=true;
      if(form.valid){
        this.crandoFuncion=true;
        this.createFun(form);
      }else{
        alert("Para poder crear Una Nueva Función es necesario que llenes los datos que tiene un * Rojo");
      }
     }
  }


  async clon_paso_1(){
    let seguro=confirm("¿Seguro que deseas crear una Función nueva apartir de esta Función?, esto afectara a todos los puestos que seleccionaste.");
    if (seguro) {


      console.log(this.puestos);

        $('#clon_2').modal('hide');

       this.funcionUp.nombre=this.funcionUp.funcion;

       this.funcionUp.departamento_id=this.me.departamento_id;

       this.funcionUp.user_id=this.me_id;

       this.funcionUp.rol="";

       this.funcionUp.tipo="fp";

       console.log(this.funcionUp);

            let resut:any=[];

         let res=await this.services.postActividad(this.funcionUp); 

           res.subscribe(res=>{
           resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
            console.log(resut);

          for(let entregables of this.entregablesUp){
              this.services.insertEntre_act(
              resut,
              entregables.Data.entregable_id).subscribe(res=>{
              
              });
          }

          for(let puesto of this.puestos){
            if(puesto.Data.check){
              this.services.getColbyPuesto(puesto.Data.rol_id).subscribe(res=>{
                if(res!=null){
                   let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
                   for(let cola of colaboradores){
                       this.services.postAct_col(cola.Data.id_nombre,resut).subscribe(res=>{
               
                     });
                   }
                 }

              });
            }

    }



    this.crandoFuncion=false;
    alert("se Creo Función con Éxitos");

    $('#clon_1').modal('hide');
          


    this.listColaboradores=[];
    this.listPuesto=[];
    this.list_depas=[];
    this.newEntregable=[];
    this.listaFechas=[];
    this.newEntregable_name="";

    this.getFunciones();

     });

    }
  
  }


  filtro(ev){
      if (ev=='') {
       this.funciones=this.oldFun;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
            this.funciones = this.funciones.filter((item) => {
              return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
            });

          }else{

           this.funciones=this.oldFun;
          }
         }
  }


}


