import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pc-egresos',
  templateUrl: './pc-egresos.component.html',
  styleUrls: ['./pc-egresos.component.css']
})
export class PcEgresosComponent implements OnInit {

  constructor() { }
    page_now="Egresos";
  ngOnInit() {
  }
  loadPage(page){
    this.page_now=page;
  }
}
