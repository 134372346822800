import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-funfc',
  templateUrl: './funfc.component.html',
  styleUrls: ['./funfc.component.css']
})
export class FunfcComponent implements OnInit {

  me_id:any;
  status:any;
  me:any;
  entregables:any=[];
  newEntregable:any=[];
  listaFechas:any=[];
  puestos:any=[];
  newEntregable_name="";
  listColaboradores:any=[];
  colaboradores:any=[];

  departamentos:any=[];

  crandoFuncion:any=false;
  paraTodos:any=false;

  funciones:any=[];
  list_depas=[];
  myColab:any=[];
  myColab_old:any=[];

  otherFun:any=[];

  oldFun:any=[];
  allColaboradores:any=[];


  constructor(private services:UsersService) { }

  ngOnInit() {
  		this.loadDatas().then().catch(err=>{
  		console.log(err);
  	}).then(()=>{

    console.log(this.me_id);
    console.log(this.status);
  	});

      this.services.getallCol().subscribe(res=>{
      res!=null ? this.allColaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
    });



  }





   async loadDatas(){

    this.me_id= await sessionStorage.getItem('id');
     
    this.status= await sessionStorage.getItem('status');

   if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
       this.me={
         name:"Admin",
         user_id:this.me_id


       }
        this.getColaboradores();
       this.getFunciones();
        this.getPuestos();
       this.getDepartamentos();
       this.getEntregablesAll();
       this.getMyColab();
     
   }else{
     this.services.getMe(this.me_id).subscribe(user=>{
      user!=null? this.me=JSON.parse(JSON.stringify(user)).Datas[0].Data:this.me=null;  
      console.log(this.me);
         this.getEntregablesAll();
						   this.getPuestos();
						   this.getDepartamentos();
						   this.getColaboradores();
						   this.getFunciones();
               this.getMyColab();
						        
    });


   }

     this.services.getOtherFun(this.me_id,'fc').subscribe(res=>{
        res!=null? this.otherFun=JSON.parse(JSON.stringify(res)).Datas:null;
      });



  		
  }



  

  cerrarModalFuncioUP(form){

     this.getFunciones();
    form.resetForm();
    $('#Actualizar_fun').modal('hide');
    $('#clon_1').modal('hide');
      this.funcionUp=[];
      this.entregablesUp=[];
      this.myColab_up=[];
      this.my_NF_col=[];
    

  }

  puesto_by_act=[];
  puesto_by_old=[];


  getMyColab(){
    if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
        this.services.getEmleados().subscribe(res=>{
       res!=null? this.myColab=JSON.parse(JSON.stringify(res)).Datas:null;
       for(let col of this.myColab){
            col.Data.chek=false;
             
          } 
      });
     }else{

      if(this.status==5){
        this.services.getDepbyid(this.me.departamento_id).subscribe(res=>{
          console.log(res);
          if(res!=null){
            let myDep=JSON.parse(JSON.stringify(res)).Datas[0].Data;
            this.services.getDepsByArea(myDep.area_id).subscribe(res=>{
              if(res!=null){
                let departamentos=JSON.parse(JSON.stringify(res)).Datas;
                 for(let dep of departamentos){
                   
                   this.services.miColbyDep(dep.Data.departamento_id).subscribe(col_res=>{
                    
                    if(col_res!=null){
                      
                      let colaborador=JSON.parse(JSON.stringify(col_res)).Datas;
                      
                      for(let col of colaborador){
                        col.Data.depa=dep.Data.titulo;
                        col.Data.chek=false;
                        this.services.getrolByid(col.Data.rol_id).subscribe(res2=>{
                          res2!=null? col.Data.rol=JSON.parse(JSON.stringify(res2)).Datas[0].Data.nombre : col.Data.rol="Sin Asignar";
                          });

                        this.myColab.push(col);
                      }
                      
                    }

                  });

                 }
                 console.log(this.myColab);
              }
            });
          }
        });

      }else{
        this.services.miColbyDep(this.me.departamento_id).subscribe(res=>{
       if(res!=null){
         this.myColab=JSON.parse(JSON.stringify(res)).Datas;
           for(let col of this.myColab){
            col.Data.chek=false;
             
          }  
         
       }

       });
      }
  
     

    }
     
       

        this.myColab_old=this.myColab;
  }


   cerrarModalFuncioUPPuesto(){
      this.puesto_by_act=[];
      this.puesto_by_old=[];
      
      $('#ver_fun_puesto').modal('hide');


  }



  getFunciones(){
    this.funciones=[];

    if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
     

         this.services.getFuncionesTipo('fc').subscribe(res=>{
        res!=null? this.funciones=JSON.parse(JSON.stringify(res)).Datas:null;
          this.oldFun=this.funciones;
      });
    }else{


    this.services.getFuncionesTipo_byUser("fc",this.me_id).subscribe(res=>{
       res!=null?this.funciones=JSON.parse(JSON.stringify(res)).Datas:null;
         this.oldFun=this.funciones;
    });

    }
   


  }
    actualizarDatos(form){
    form.form.value.id=this.funcionUp.actividad_id;
     this.services.updateActividad(form.form.value).subscribe(res=>{
       alert("Se actualizaron datos correctamente");
     });
  }

  addListEntregableoldAfter(form){
      console.log(form.form.value.entregable);

      let entregable_id=this.entregables.find(entre=>entre.Data.titulo==form.form.value.entregable).Data.entregable_id;
       console.log(entregable_id);
         this.services.insertEntre_act(
          this.funcionUp.actividad_id,
           entregable_id).subscribe(res=>{
            this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
               console.log(res);
               res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
               form.resetForm();
                $('#AgregarEntregablesAfter').modal('hide');
             });
        });
  }

  addAPuestoAfter(puesto){
    console.log(puesto.form.value)

     let rol_id=this.puestos.find(dep=>dep.Data.nombre== puesto.form.value.nombre).Data.rol_id;
      console.log(rol_id);

      this.services.getColbyPuesto(rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             for(let cola of colaboradores){
                 this.services.postAct_col(cola.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
         
               });
             }
           }

           this.openPuestoByAct();
           alert("Se agrego Funcion con éxito de Puesto");
            $('#AgregarPuesto_after').modal('hide');
        });
       


  }



    openPuestoByAct(){
      this.puesto_by_act=[];
      this.puesto_by_old=[];
      this.services.getpuestoByAct_dep(this.funcionUp.actividad_id,this.me.departamento_id).subscribe(res=>{
       console.log(res);
       
       res!=null?this.puesto_by_act=JSON.parse(JSON.stringify(res)).Datas:null;
       for(let puesto of this.puesto_by_act){

           puesto.Data.puesto=this.puestos.find(depa=>depa.Data.rol_id==puesto.Data.rol_id);

       }

       console.log(this.puesto_by_act);
       this.puesto_by_old=this.puesto_by_act;
    });

  }

  getColaboradores(){

    this.services.getEmleados().subscribe(res=>{
       res!=null? this.colaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }

  getDepartamentos(){

  		this.services.getDepartamentos().subscribe(res=>{
  			res!=null?this.departamentos=JSON.parse(JSON.stringify(res)).Datas:null;
  					console.log("------------------------");
  				console.log(this.departamentos);
  		});
  }

   getPuestos(){

    this.services.getPuestosBydep(this.me.departamento_id).subscribe(res=>{
     	   	
      res!=null? this.puestos=JSON.parse(JSON.stringify(res)).Datas:null;

         console.log(this.puestos);
      
    });
  }

  getEntregablesAll(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
      console.log(this.entregables);
    });
  }

  addListEntregableold(form){

    if(form.form.value.entregable!=""){
        let id=this.entregables.find(entr=>entr.Data.titulo==form.form.value.entregable);

        this.newEntregable.push(id);
        $('#AgregarEntregables').modal('hide');
        alert("Se agrego entregable con éxito");
        form.resetForm();
       	
    }else{
      alert("Es necesario seleccionar un Entregable para continuar");
    }


   
  }

  quitarEntregable(data){
  		console.log(data);
  				let seguro=confirm("¿Seguro que quieres quiatar este Entregable?");

  				if (seguro) {
  						this.newEntregable=this.newEntregable.filter(ent=>ent.Data.entregable_id!=data.entregable_id);
  				}

  	

  }

  addFecha(form){
   
    this.listaFechas.push(form.form.value);

    alert("Fecha Agregada");

    form.resetForm();

     $('#exampleModal').modal('hide');

  }


  insertArea(area){

      area.form.value.id_nombre=this.allColaboradores.find(puesto=>puesto.Data.nombre==area.form.value.rol_id).Data.id_nombre;
      console.log(area.form.value);
      if(area.form.value.periodicidad!='Diario'){
    
        if(this.listaFechas.length>=4){
           this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

          this.newEntregable=area.form.value.titulo;

          this.listaFechas=[];
          area.resetForm();
          $('#createEntrgable').modal('hide');
          this.getEntregablesAll();
          alert("Se creo Entregable con éxito");
          });
        }else{
           alert("Para crear este Tipo de Entregable es necesario ingresar 4 o más fechas de Entrega.");
        }


      }else{
          this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

          this.newEntregable=area.form.value.titulo;

          this.listaFechas=[];
          area.resetForm();
          $('#createEntrgable').modal('hide');
          this.getEntregablesAll();
          alert("Se creo Entregable con éxito");
          });
      }

  }


  listPuesto:any=[];
  setListPuestosCheck(name,valor){

     console.log(name,valor);

     
      

  }



  addListPuesto(form,fun){

     this.listPuesto=[];
    
    for(let col of this.myColab){
        if (col.Data.chek!=null) {
          if (col.Data.chek) {
            console.log(col.Data.nombre+": "+col.Data.chek);
            this.listPuesto.push(col.Data);
          }
             
        }  
     }
       
        $('#AgregarPuesto').modal('hide');


   
  }

    quitarListPuesto(data){
        console.log(data);
      let seguro=confirm("¿Seguro que desas quiatar este Colaborador?");
      if(seguro){
        this.listPuesto=this.listPuesto.filter(ent=>ent.id_nombre!=data.id_nombre);
       console.log(this.newEntregable);
      }
      
  }


    creandoFun(form){

    if(form.valid){
      this.crandoFuncion=true;
      this.createFun(form);
    }else{
      alert("Para poder crear Una Nueva Función es necesario que llenes los datos que tiene un * Rojo");
    } 
  }

  async createFun(form){


    form.form.value.departamento_id="";
    if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
      form.form.value.user_id='covelAdmins01';
    }else{
      form.form.value.user_id=this.me_id;
    }
    

    form.form.value.rol="";
    form.form.value.tipo="fc";

    let resut:any=[];

   let res=await this.services.postActividad(form.form.value); 

   res.subscribe(res=>{
     resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
    

     this.set_insert_Entregables(resut,form);
     this.getFunciones();
      form.resetForm();
   });

  }

    quitarEntregableAfter(entregable){
    console.log(entregable);
    let seguro=confirm("¿Seguro que quieres quitar este Entregable?");
    if (seguro) {
        this.services.deleteentreAct(entregable.ent_act_id).subscribe(res=>{
        	this.entregablesUp=[];
          this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
             res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
               
             });
        });
    }
  }

  set_insert_Entregables(actividad_id,form){
    for(let entregables of this.newEntregable)
    {
        this.services.insertEntre_act(
        actividad_id,
        entregables.Data.entregable_id).subscribe(res=>{
        
      });
    }


      if(this.paraTodos){
        this.paraTodosAdd(actividad_id,form);
     }else{

       this.ligarporcolaborador(actividad_id);
      }
      
    

  }


  paraTodosAdd(actividad_id,form){

        if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
          for(let cola of this.colaboradores){
             this.services.postAct_col(cola.Data.id+cola.Data.nombre,actividad_id).subscribe(res=>{
           
                 });
          }
        }else{


           this.services.miColbyDep(this.me.departamento_id).subscribe(res=>{
             if(res!=null){
               let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
               for(let cola of colaboradores){
                   this.services.postAct_col(cola.Data.id_nombre,actividad_id).subscribe(res=>{
           
                 });
               }
             }

           });

         }
           
      
      this.crandoFuncion=false;
      this.cerrarCrearFuncion(form);
      alert("se Creo Función con Éxito");

  }


    cerrarCrearFuncion(form){

    console.log(form.form.value);
    form.resetForm();
    $('#insertFuncion').modal('hide');

    this.listColaboradores=[];
    this.listPuesto=[];
    this.list_depas=[];
    this.newEntregable=[];
    this.listaFechas=[];
    this.newEntregable_name="";


    this.getFunciones();

  }




   ligarporcolaborador(actividad_id){

     if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
       for(let col of this.myColab){
         if(col.Data.chek){
           console.log(col.Data);
            this.services.postAct_col(col.Data.id+""+col.Data.nombre,actividad_id).subscribe(res=>{
                    col.Data.chek=false;

               });
             }
            
          }  
     
     }else{

       if(this.status==5){
         for(let col of this.myColab){
         if(col.Data.chek){
           console.log(col.Data);
            this.services.postAct_col(col.Data.id+""+col.Data.nombre,actividad_id).subscribe(res=>{
                    col.Data.chek=false;

               });
             }
            
          }  

       }else{
         for(let cola of this.listPuesto){
                 this.services.postAct_col(cola.id_nombre,actividad_id).subscribe(res=>{
                    cola.Data.chek=false;

               });
             }

         for(let col of this.myColab){
            col.Data.chek=false;
             
          }  
       }

       
      }

      this.crandoFuncion=false;
         alert("se Creo Función con Éxitos");

          $('#insertFuncion').modal('hide');

      this.listColaboradores=[];
      this.listPuesto=[];
      this.list_depas=[];
      this.newEntregable=[];
      this.listaFechas=[];
      this.newEntregable_name="";




   }

   funcionUp:any=[];
   entregablesUp:any=[];


   setFuncionUP(funcion){

      this.funcionUp=[];
      this.entregablesUp=[];
     this.funcionUp=funcion;
     this.services.getEntre_act(this.funcionUp.actividad_id).subscribe(res=>{
       console.log(res);
       res!=null?this.entregablesUp=JSON.parse(JSON.stringify(res)).Datas:null;
     });

      this.getColUp();
   }

  getColUp(){

     this.my_NF_col=this.myColab;

    if(this.me_id==="SuperAdmin" || this.me_id==="Admin"){
      this.services.getcolByAct(this.funcionUp.actividad_id).subscribe(res=>{
        if (res!=null) {
           this.myColab_up=JSON.parse(JSON.stringify(res)).Datas;
           for(let col of this.myColab_up){
             this.my_NF_col=this.my_NF_col.filter(colNF=>colNF.Data.id_nombre!=col.Data.id_nombre);
             this.my_NF_col_old=this.my_NF_col;
           }
       }
     });
    }else{

      if(this.status==5){
           this.services.getcolByAct(this.funcionUp.actividad_id).subscribe(res=>{
        if (res!=null) {
           this.myColab_up=JSON.parse(JSON.stringify(res)).Datas;
           for(let col of this.myColab_up){
             this.my_NF_col=this.my_NF_col.filter(colNF=>colNF.Data.id_nombre!=col.Data.id_nombre);
             this.my_NF_col_old=this.my_NF_col;
           }
       }
     });
      }else{


    this.services.getcolByAct_dep(this.funcionUp.actividad_id,this.me.departamento_id).subscribe(res=>{
       if (res!=null) {
           this.myColab_up=JSON.parse(JSON.stringify(res)).Datas;
           for(let col of this.myColab_up){
             this.my_NF_col=this.my_NF_col.filter(colNF=>colNF.Data.id_nombre!=col.Data.id_nombre);
             this.my_NF_col_old=this.my_NF_col;
           }
       }
     })
    }
    }
  }

myColab_up:any=[];
my_NF_col:any=[];
my_NF_col_old:any=[];


addcolAfter(form){
    console.log(this.my_NF_col);

     for(let  col of this.my_NF_col){
         if(col.Data.chek){
              this.services.postAct_col(col.Data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{});
              
        }
     }

     this.my_NF_col=this.myColab;
     
      this.services.getcolByAct_dep(this.funcionUp.actividad_id,this.me.departamento_id).subscribe(res=>{
       if (res!=null) {
           this.myColab_up=JSON.parse(JSON.stringify(res)).Datas;
           for(let col of this.myColab_up){
             this.my_NF_col=this.my_NF_col.filter(colNF=>colNF.Data.id_nombre!=col.Data.id_nombre);
           }
       }
     })

      alert("Se agrego Función con Exito");

     $('#AgregarPuestoAfter').modal('hide');
     this.getColUp(); 
  }


elimiarActividad(){
  let seguro=confirm("¿Seguro que quiere Eliminar esta Función? al continuar se afectara a todos los Colaboradores que tengan esta Función.");
  if (seguro) {
      this.services.delete_Acividad(this.funcionUp.actividad_id).subscribe(res=>{
        console.log("Se Elimino Funcion con Éxito");
         $('#Actualizar_fun').modal('hide');
         this.getFunciones();
      });
  }
}

quitarFun_Colabardor(data){
  console.log(data)
    let seguro=confirm("¿Seguro que quieres continuar?, estas apunto de quitar esta Función a este Colaborador.");
    if (seguro) {
      this.services.Delete_Act_Col(data.id_nombre,this.funcionUp.actividad_id).subscribe(res=>{
              
              
                 this.getColUp();
            
           });

       alert("Se quito Funcion con éxito de Colaborador");
    }
}

 eliminarActByPuesto(puesto){
    console.log(puesto);

    let seguro=confirm("¿Seguro que quieres continuar?, estas apunto de quitar esta Función a todos los integrantes del departamento.");
    if (seguro) {

      this.services.getColbyPuesto(puesto.puesto.Data.rol_id).subscribe(res=>{
          if(res!=null){
             let colaboradores=JSON.parse(JSON.stringify(res)).Datas;
             console.log(colaboradores)
             
             for(let cola of colaboradores){
                 this.services.Delete_Act_Col(cola.Data.id_nombre,puesto.actividad_id).subscribe(res=>{
         
               });
             }
           }

           this.openPuestoByAct();
           alert("Se quito Funcion con éxito de Puesto");

        });
      
    
    }
  }

filtroCol_act(ev){
  console.log(this.myColab);
    if (ev=='') {
       this.myColab=this.myColab_old;

      }else{
        this.myColab=this.myColab_old;

        let val = ev.target.value;
        
        
        if (val && val.trim() != '') {
        this.myColab = this.myColab.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.myColab=this.myColab_old;
      }
    }
}







filtroCol_act2(ev){
  
    if (ev=='') {
       this.my_NF_col=this.my_NF_col_old;

      }else{
        this.my_NF_col=this.my_NF_col_old;

        let val = ev.target.value;
        
        
        if (val && val.trim() != '') {
        this.my_NF_col = this.my_NF_col.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.my_NF_col=this.my_NF_col_old;
      }
    }
}



async clon_paso_1(){
  let seguro=confirm("¿Seguro que deseas crear una Función nueva apartir de esta Función? esto afectara a los colaboradores que seleccionaste.");
  if (seguro) {
     $('#clon_2').modal('hide');

     this.funcionUp.nombre=this.funcionUp.funcion;

     this.funcionUp.departamento_id=this.me.departamento_id;

     this.funcionUp.user_id=this.me_id;

     this.funcionUp.rol="";

     this.funcionUp.tipo="fc";

     console.log(this.funcionUp);

        let resut:any=[];

       let res=await this.services.postActividad(this.funcionUp); 

         res.subscribe(res=>{
         resut=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
          console.log(resut);

        for(let entregables of this.entregablesUp){
            this.services.insertEntre_act(
            resut,
            entregables.Data.entregable_id).subscribe(res=>{
            
            });
        }

         for(let col of this.myColab){
            if(col.Data.chek){
              this.services.postAct_col(col.Data.id_nombre,resut).subscribe(res=>{
             

              });
            }

              col.Data.chek=false;
          }  

      

      this.crandoFuncion=false;
         alert("se Creo Función con Éxitos");

          $('#clon_1').modal('hide');
          


          this.listColaboradores=[];
          this.listPuesto=[];
          this.list_depas=[];
          this.newEntregable=[];
          this.listaFechas=[];
          this.newEntregable_name="";

          this.getFunciones();
    
        });

        this.getFunciones();
  }





}


filtro(ev){
      if (ev=='') {
       this.funciones=this.oldFun;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
            this.funciones = this.funciones.filter((item) => {
              return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
            });

          }else{

           this.funciones=this.oldFun;
          }
         }
  }



}

