export class Configuracion{
	constructor(
		public indicador:number,
		public vista_por:number,
		public alimentos:boolean,
		public bebidas:boolean,
		public vinos:boolean,
		public licores:boolean,
		public postres:boolean,
		public cervezas:boolean,
		public efectivo:boolean,
		public credito:boolean,
		public tarjeta_credito:boolean,
		public tarjeta_debito:boolean
		){

	}
}