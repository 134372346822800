import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.css']
})
export class CargandoComponent implements OnInit {

  constructor(private router:Router, private ar:ActivatedRoute) { }

  ngOnInit(){
    let pagina=localStorage.getItem('page');
     console.log(pagina);
     this.router.navigate(["/"+pagina]);
    

  	
  	 
  }

}
