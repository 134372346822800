import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confisucursal',
  templateUrl: './confisucursal.component.html',
  styleUrls: ['./confisucursal.component.css']
})
export class ConfisucursalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
