import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {QueriesLogoService} from "../services/queries-logo.service";

@Component({
  selector: 'app-venta',
  templateUrl: './venta.component.html',
  styleUrls: ['./venta.component.css'],
  providers:[QueriesLogoService]
})
export class VentaComponent implements OnInit {

  idVenta:any;
  idCliente:any;
  venta:any;
  contrato:any;
  tipoContrato:any;
  notarias:any;
  notaria:any;
  statusVentas:any;
  statusVenta:any;
  preferencia:any;
  inmuebles:any;
  inmueble:any;
  tiposPagos:any;
  statusPagos:any;
  pagos:any;
  pago:any;
  mejoras:any;
  mejora:any;
  observaciones:any;
  observacion:any;
  statusV :any;
  estadoVenta:any;


  constructor(private router:Router, private route:ActivatedRoute, private service:QueriesLogoService) { }

  ngOnInit() {
    this.route.params.subscribe((data:Params)=>{
      this.idVenta=data.venta_id;

      this.idCliente = data.cliente_id;
      console.log("VID" + this.idVenta);
      console.log("CID" + this.idCliente);
    });

  this.getDatosGenerales(this.idVenta);
  this.getContrato(this.idVenta);
  this.getTiposContrato();
  this.getNotarias();
  this.getPreferencia(this.idCliente);
  this.getStatusPagos();
  this.getTiposPagos();
  this.getPagos(this.idVenta,this.idCliente);
  this.getMejoras(this.idVenta);
  this.getObservaciones(this.idVenta);
  this.getStatusVentas();


  }

  getPreferencia(id){
    //console.log("el id: " + id);
    //this.ifClientes = false;
    this.service.getPreferencia(id).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.preferencia = json.Encuentro;
      //console.log(this.preferencia);

      this.getInmuebles(this.preferencia[0].Encuentros.desarrollo_id,this.preferencia[0].Encuentros.tipo_inmueble_id)

    }, error => {
      console.log(error);
    });
  }

  getDatosGenerales(id){
    //console.log("Id Venta: " + id);
    this.service.getVenta(id).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.venta = json.Venta;
      
      this.venta[0].Ventas.valor_operacions="$"+new Intl.NumberFormat("es-MX").format(this.venta[0].Ventas.valor_operacion);

      console.log(this.venta[0].Ventas.valor_operacions);

      //console.log(this.venta);

      this.getNotaria(this.venta[0].Ventas.notaria_id);
      this.getInmueble(this.venta[0].Ventas.inmueble_id);

      this.statusV = this.venta[0].Ventas.status_venta_id;

      this.getStatusVenta(this.venta[0].Ventas.status_venta_id);

    }, error => {
      console.log(error);
    });

  }

  editDatosGenerales(form){
    this.service.editVenta(this.idVenta,form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }



  getContrato(v){
    this.service.getContrato(v).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.contrato = json.Contrato;
      //console.log(this.contrato);

    }, error => {
      console.log(error);
    });
  }

  getVentas(){
    this.router.navigate(["ventas"]);
  }

  getTiposContrato(){
    this.service.getTiposC().subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.tipoContrato = json.TipoContrato;
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  insertContrato(form){
    console.log(form.form.value);
    this.service.insertContrato(this.idVenta, this.idCliente, form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  getNotarias(){
    this.service.getNotarias().subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.notarias = json.Notaria;
      //console.log(this.notarias);

    }, error => {
      console.log(error);
    });
  }

  getNotaria(id){
    console.log("El id de la notaria es: "+id);
    this.service.getNotaria(id).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.notaria = json.Notaria;
      //console.log(this.notaria);

    }, error => {
      console.log(error);
    });
  }

  insertNotariaVenta(form){
    this.service.insertNotariaVenta(this.idVenta, form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  getInmuebles(d_id,t_id){
    //console.log("El id del desarrollo es: "+d_id);
    //console.log("El id del tipo es: "+t_id);
    this.service.getInmuebles(d_id,t_id).subscribe(data => {
      //console.log("resultInm: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      if (data!=null) {
         this.inmuebles = json.Inmueble;
      }
     
      //console.log(this.inmuebles);

    }, error => {
      console.log(error);
    });
  }


  getInmueble(id){
    console.log("id inmu:  " +id);
    this.service.getInmueble(id).subscribe(data => {
      console.log("resultInm: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.inmueble = json.Inmueble;
      //console.log(this.inmuebles);

    }, error => {
      console.log(error);
    });
  }

  insertInmuebleVenta(form){

    this.service.inserInmuebleVenta(this.idVenta, form.form.value,this.statusV).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  getTiposPagos(){
    this.service.getTiposPagos().subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.tiposPagos = json.TipoPago;
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  getStatusPagos(){
    this.service.getStatusPagos().subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.statusPagos = json.StatusPago;
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  getPagos(v,c){
    this.service.getPagos(v,c).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.pagos = json.Pago;
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  getPago(id){
    this.service.getPago(id).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.pago = json.Pago;
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  insertPago(form){
    this.service.insertPago(this.idVenta,this.idCliente, form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  updatePago(form){
    this.service.updatePago(form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  insertMejora(form){
    this.service.insertMejora(this.idVenta,form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  getMejoras(v){
    this.service.getMejoras(v).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      if (data!=null) {
       this.mejoras = json.Mejora;
      }
      
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  getMejora(id){
    console.log("Id mejora: " + id);
    console.log("Id venta: " + this.idVenta);
    this.service.getMejora(this.idVenta,id).subscribe(data => {
      console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.mejora = json.Mejora;
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  editMejora(form){
    this.service.editMejora(this.idVenta,form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  insertObservacion(form){
    this.service.insertObservacion(this.idVenta,form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  getObservaciones(v){
    this.service.getObservaciones(v).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      if (data!=null) {
        this.observaciones = json.Observacion;
      }
      
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  getObservacion(id){
    console.log("di pbs: " + id);
    this.service.getObservacion(this.idVenta,id).subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.observacion = json.Observacion;
      //console.log(this.tipoContrato);

    }, error => {
      console.log(error);
    });
  }

  editObservacion(form){
    this.service.editObservacion(this.idVenta,form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }

  abrirCliente(id){
    console.log("Id del cliente: " + id);
    this.router.navigate(["cliente/" + id]);
  }

  abrirInmueble(){

    this.router.navigate(["inmuebles"]);
  }

  getStatusVentas(){
    this.service.getStatusVentas().subscribe(data => {
      //console.log("result: " + data);
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.statusVentas = json.StatusVenta;
      console.log(this.statusVenta);

    }, error => {
      console.log(error);
    });
  }

  getStatusVenta(id){
    this.service.getStatusVenta(id).subscribe(data => {
     
      var eco = JSON.stringify(data);
      var json = JSON.parse(eco);
      this.statusVenta = json.StatusVenta;
      

      this.estadoVenta=this.statusVenta[0].StatusVentas;
     

    }, error => {
      console.log(error);
    });
  }

  updateStatusVenta(form){
    this.service.updateStatusVenta(this.idVenta,form.form.value).subscribe(result=>{
      console.log("Bien");
      alert("Guardado con Exito!!");
      window.location.reload();

    },error=>{
      console.log(<any>error);
      console.log("mal");
    });
  }



}
