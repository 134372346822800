import { Component, OnInit } from '@angular/core';
import { UsersService} from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;


@Component({
  selector: 'app-colobydeps',
  templateUrl: './colobydeps.component.html',
  styleUrls: ['./colobydeps.component.css']
})
export class ColobydepsComponent implements OnInit {

	page:any;
	carga=false;
	datas:any;

	areas:any=[];
	setarea:any; 
	oldAreas:any;
	lvls:any;
	puestos:any;
	departamentos:any=[];
	me_id:any;

	me:any;
	status:any;
  colaboradores:any=[];
  myDep:any;

  listAllActiv:any=[];
  allListOldAct:any=[];

  entregables:any;
  newEntregable:any;

  misEntregables:any=[];

  allColaboradores:any=[];
  infoDep:any=[];

  oldCol:any=[];

	constructor(private services:UsersService) { }

	
	ngOnInit() {	
		
		this.me_id=sessionStorage.getItem('id');
		this.status=sessionStorage.getItem('status');
		this.colaboradores=[];

	  console.log(this.me_id);

	

		this.services.getMe(this.me_id).subscribe(user=>{
			user!=null? this.me=JSON.parse(JSON.stringify(user)).Datas[0].Data:null;	
				console.log(this.me);
        this.services.getDepbyid(this.me.departamento_id).subscribe(res=>{
              console.log(res);
              res!=null?this.myDep=JSON.parse(JSON.stringify(res)).Datas[0].Data.titulo:null;
              console.log(this.myDep);
              this.infoDep=JSON.parse(JSON.stringify(res)).Datas[0].Data;
                this.getColMiDep();
          });
      
		});

    this.services.getallCol().subscribe(res=>{
      res!=null ? this.allColaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
    });

			this.getPuestos(); 
      //this.getDepartamentos();
      this.getAllActividades();
      this.getEntregables();



	}



  getEntregables(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }

  filtroAct(ev){

        console.log(ev);
      if (ev=='') {
       this.listAllActiv=this.allListOldAct;

      }else{
        this.listAllActiv=this.allListOldAct;

        let val = ev.target.value;
        
        
        if (val && val.trim() != '') {
        this.listAllActiv = this.listAllActiv.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.listAllActiv=this.allListOldAct;
      }
     }
}

  getAllActividades(){
    
    this.services.getFuncionesTipo_byUser("fc",this.me_id).subscribe(res=>{
      res!=null?this.listAllActiv=JSON.parse(JSON.stringify(res)).Datas:null;
      this.allListOldAct=this.listAllActiv;
    });
  }

  cerrar(){
   this.ngOnInit();
   $('#updateArea').modal('hide');
   
 }


  cerrarAny(id){
      let panel="#"+id;
       this.ngOnInit();
      $(panel).modal('hide');   
    }

  dep:any;
  are:any;
  actividades:any;
  listaActividCol:any=[];


   getColMiDep(){
    if(this.status==5){

      this.services.getDepsByArea(this.infoDep.area_id).subscribe(res=>{
        console.log(res);
        if(res!=null){
          let departamentos=JSON.parse(JSON.stringify(res)).Datas;
          for(let dep of departamentos){
            this.services.miColbyDep(dep.Data.departamento_id).subscribe(col_res=>{
              if(col_res!=null){

                let colaborador=JSON.parse(JSON.stringify(col_res)).Datas;
                
                for(let col of colaborador){
                  col.Data.depa=dep.Data.titulo;
                  this.services.getrolByid(col.Data.rol_id).subscribe(res2=>{
                    res2!=null? col.Data.rol=JSON.parse(JSON.stringify(res2)).Datas[0].Data.nombre : col.Data.rol="Sin Asignar";
                    });
                  this.colaboradores.push(col);
                }
                
              }
            });
          }
          console.log(this.colaboradores);
          this.oldCol=this.colaboradores;
          this.carga=true;
        }
        
      });
    }else{


     this.services.miColbyDep(this.me.departamento_id).subscribe(res=>{
 
       res!=null?this.colaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
       this.carga=true;
       for(let col of this.colaboradores){
       this.services.getrolByid(col.Data.rol_id).subscribe(res2=>{
          res2!=null? col.Data.rol=JSON.parse(JSON.stringify(res2)).Datas[0].Data.nombre : col.Data.rol="Sin Asignar";
          });

           col.Data.depa=this.myDep;
        }
      
     });
     this.oldCol=this.colaboradores;
     }
  }


 setArea(data){

    console.log(data);

    this.are=null;
   
    this.listaActividCol=[];
    let id=data.rol_id;

    this.setarea=data;
    
      this.services.getatc_col(data.id+data.nombre).subscribe(res=>{
        if(res!=null)
           {
             this.listaActividCol=JSON.parse(JSON.stringify(res)).Datas;

             console.log("Apha");
             console.log(this.listaActividCol);

           for(let acti of this.listaActividCol){
               this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
                 if(res!=null){
                   acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
                 }
               });
           }
         }
      });
     

    
 }


 getUpdateAct(){
   this.services.getatc_col(this.setarea.id+this.setarea.nombre).subscribe(res=>{
        if(res!=null)
           {
             this.listaActividCol=JSON.parse(JSON.stringify(res)).Datas;

             console.log("Apha");
             console.log(this.listaActividCol);

           for(let acti of this.listaActividCol){
               this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
                 if(res!=null){
                   acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
                 }
               });
           }
         }
      });
 }


 agregarlist(data,form){

    let Data={nombre:data.nombre,actividad_id:data.actividad_id};

    this.listaActividCol.push({Data:Data});

   this.services.postAct_col(this.setarea.id+this.setarea.nombre,data.actividad_id).subscribe(result=>{

            console.log(result);

            this.getUpdateAct();
    });
    
    form.resetForm();

    $("#AgregarActividad").modal('hide');

    

    alert("Función Agregada");

  }


  quitarList(lista)
  {
    let mensaje=confirm("¿Seguro que se desas Quitar esta Función?");
    
    if (mensaje) {

      this.services.deleteAct_col(lista.col_act_id).subscribe(res=>{
            this.listaActividCol=null;

            this.services.getatc_col(this.setarea.id+this.setarea.nombre).subscribe(res=>{
            if(res!=null)
               {
                 this.listaActividCol=JSON.parse(JSON.stringify(res)).Datas;
 
                     console.log(this.listaActividCol);

               for(let acti of this.listaActividCol){
                   this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
                     if(res!=null){
                       acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
                     }
                   });
               }
             }
          });
      });
      
     
    }
     
  }




  addListEntregable2(form){
    
    this.misEntregables.push(this.entregables.find(entregable=>entregable.Data.titulo==form.form.value.entregable));

    console.log(this.misEntregables);

    alert("Entregable agregado");

    form.resetForm();
    this.newEntregable="";
    $('#addEntregable2').modal('hide');
    
  }


    addActividades(form){

    form.form.value.departamento_id=this.me.departamento_id;

    form.form.value.user_id=this.me_id;

    form.form.value.rol="";
     form.form.value.tipo="fc"

   

    //this.setActivi_col();
    

    if(this.misEntregables.length!=0){
      this.services.postActividad(form.form.value).subscribe(res=>{

          console.log("-----------Retur add active");
            console.log(res);
         
         
            

             let actividad_id=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;

               console.log(actividad_id);

              for(let entregables of this.misEntregables)
              {
                  this.services.insertEntre_act(
                  actividad_id,
                  entregables.Data.entregable_id).subscribe(res=>{
                  
                  this.ngOnInit();
                  $('#Actividadteam').modal('hide');
                  alert("Se Agrego Con Exito");
                });
              }

              this.setActivi_col(actividad_id);
            
      });

    form.resetForm();

    }else{

      let sinEntregable=confirm("No hay ningun entregable asignado a esta función. ¿Seguro que deseas continuar?");
        if(sinEntregable){
            this.services.postActividad(form.form.value).subscribe(res=>{

            console.log("-----------Retur add active");
            console.log(res);
            let actividad_id=JSON.parse(JSON.stringify(res)).Datas[0].Data.actividad_id;
            console.log(actividad_id);
             this.setActivi_col(actividad_id);
            console.log("-----------Retur add active");
                this.ngOnInit();
                $('#Actividadteam').modal('hide');
                alert("Se Agrego Con Exito");
            });
        }
    }
  
    

  }

  setActivi_col(act_id){ 
    this.services.postAct_col(this.setarea.id+this.setarea.nombre,act_id).subscribe(res=>{
        this.getUpdateAct();      
         $('#AgregarActividad').modal('hide');  
         $('#CreateActividad').modal('hide');  
    });


  }

    getPuestos(){
   this.services.getlvl('roles').subscribe(res=>{
     res != null ? this.puestos=JSON.parse(JSON.stringify(res)).Datas:null;
    
   });
 }



  /*

		listAllActiv:any=[];
		allListOldAct:any=[];

	 getAllActividades(){
    this.services.getActividades().subscribe(res=>{
      res!=null?this.listAllActiv=JSON.parse(JSON.stringify(res)).Datas:null;
      this.allListOldAct=this.listAllActiv;
    });
  }



  getDepartamentos(){
   this.services.getDepartamentos().subscribe(res=>{
     res!=null?this.departamentos=JSON.parse(JSON.stringify(res)).Datas:null;
     this.getAreas().then().catch().then(()=>{
     		
     });
     console.log(this.departamentos);
   });
 }


 colaboradores:any=[];
 oldCol:any=[];

	async getAreas(){


			let sinDetalle={
						Data:{departamento_id:0,
						rol_id:0}
			}

	  this.areas=[];

	  this.services.getAllColaborador().subscribe(res=>{

	    if(res != null){

	      this.areas=JSON.parse(JSON.stringify(res)).Datas;
	      
	      this.oldAreas=this.areas;

	      for(let col of this.areas)
	      {
	        let id=col.Data.id+""+col.Data.nombre;

	        col.Data.departamento_id=0;
	   

	        this.services.getCol_detalles(id).subscribe(res=>{
	           if(res!=null){
	               col.Data.detalles=JSON.parse(JSON.stringify(res)).Datas[0];
	               col.Data.rol_id=col.Data.detalles.Data.rol_id;
	               col.Data.departamento_id=col.Data.detalles.Data.departamento_id;
	               col.Data.ifNew=false;




			             if(col.Data.rol_id!=0){

			                  this.services.getrolByid(col.Data.rol_id).subscribe(res2=>{
			                    res2!=null? col.Data.rol=JSON.parse(JSON.stringify(res2)).Datas[0].Data.nombre : col.Data.rol="Sin Asignar";
			                  });

			              }else{
			                 col.Data.rol="Sin Asignar";
			              }

			              

			              if(col.Data.departamento_id != '0' ){
			              
			                col.Data.depa=this.departamentos.find(dep=>dep.Data.departamento_id==col.Data.departamento_id).Data.titulo;
			                
			                   
			              }else{
			                col.Data.depa="Sin Asignar"

			              
			              }

	           }else{
	             col.Data.rol="Sin Asignar";
	             col.Data.rol_id="";
	             col.Data.departamento_id=0;
	             col.Data.ifNew=true;

	             col.Data.depa="Sin Asignar"
	             col.Data.detalles=sinDetalle;
	           }

	           
	            
	            if(col.Data.departamento_id==this.me.departamento_id){
	            		this.colaboradores.push(col);
	            }
	            this.oldCol=this.colaboradores;
	            
	        });

	        

	        	
	      }

	    


	      
	  }



	  	this.loadDatasfiltro();
	   

	  });

	}



	loadDatasfiltro(){
			this.carga=true;
	}

 	dep:any;
  are:any;
  actividades:any;
  newActvAddlist:any=[];
  listaActividCol:any=[];


	setArea(data){

    console.log(data);

    this.actividades=null;
   
    this.listaActividCol=[];
    let id=data.rol_id;


   if( data.rol_id!=0){
   


 this.services.getDepByrol(id).subscribe(res=>{
    
     if(res!=null){

	        this.dep=JSON.parse(JSON.stringify(res)).Datas[0];

	          this.services.getAreabyDep(this.dep.Data.area_id).subscribe(resu=>{

	            resu!=null?this.are=JSON.parse(JSON.stringify(resu)).Datas[0]:null;
	          
	          });
	      }
	    });

	    
	    this.services.getRol_act_idacrualiza(data.rol_id).subscribe(res=>{
	      if(res!=null)
	         {this.actividades=JSON.parse(JSON.stringify(res)).Datas;
	         console.log(this.actividades);
	         for(let acti of this.actividades){
	             this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
	               if(res!=null){
	                 acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
	               }
	             });
	         }
	       }
	    });

	    this.setarea=data;

	      this.services.getatc_col(this.me_id).subscribe(res=>{
	      if(res!=null)
	         {
	           this.listaActividCol=JSON.parse(JSON.stringify(res)).Datas;

	           console.log("Apha");
	         console.log(this.listaActividCol);

	         for(let acti of this.listaActividCol){
	             this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
	               if(res!=null){
	                 acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
	               }
	             });
	         }
	       }
	    });


	    this.setarea.lvl_colaborador_id=0;  

	  

	    this.setarea.rol_id=this.puestos.find(puesto=>puesto.Data.rol_id==data.rol_id);

	    this.setarea.rol_id=this.setarea.rol_id.Data.nombre;

	    

	    
	   	}else{
	      this.setarea=data;
	      this.setarea.lvl_colaborador_id="";
	      this.setarea.rol_id="";
	      
	      this.dep="";
	      this.are=null;
	     
	   	}

	   	if(data.departamento_id!=0){
	       data.departamento_id=this.departamentos.find(dep=>dep.Data.departamento_id==data.departamento_id).Data.titulo;
	   	}else{
	     data.departamento_id="";
	   	}

    
 }



 cerrar(){
   this.ngOnInit();
   $('#updateArea').modal('hide');
   
 }


  cerrarAny(id){
      let panel="#"+id;
       this.ngOnInit();
      $(panel).modal('hide');   
		}




  filtro(ev){
      if (ev=='') {
       this.colaboradores=this.oldCol;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.colaboradores = this.colaboradores.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      		}else{
       		this.colaboradores=this.oldCol;
      		}
   			}
  }

 quitarList(lista){

    let mensaje=confirm("¿Seguro que se desas Quitar esta Función?");
    
    if (mensaje) {

      this.services.deleteAct_col(lista.col_act_id).subscribe(res=>{
            this.listaActividCol=null;

            this.services.getatc_col(this.setarea.id+this.setarea.nombre).subscribe(res=>{
            if(res!=null)
               {
                 this.listaActividCol=JSON.parse(JSON.stringify(res)).Datas;
 
               			console.log(this.listaActividCol);

               for(let acti of this.listaActividCol){
                   this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
                     if(res!=null){
                       acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
                     }
                   });
               }
             }
          });
      });
      
     
    }
     
  }

  cambioPuesto(setarea){
    this.setarea=setarea;
    console.log(setarea);
  }



update(form){

  let col_id=this.setarea.id+this.setarea.nombre;
  
  if (this.setarea.ifNew) {
   this.services.insertCol_ids(col_id).subscribe(res=>{
     this.consultaUpdate(form);
   });

  }else{
    console.log(col_id);
    this.consultaUpdate(form);
  }
 
}

consultaUpdate(form){

  console.log("Entra aqui");

  let col_id=this.setarea.id+this.setarea.nombre;
  
  console.log(form.form.value);

  form.form.value.lvl_colaborador_id=0;

  let depa_id=form.form.value.departamento_id;
  
  
  if(form.form.value.rol_id!="undefined" && form.form.value.rol_id!=null && form.form.value.rol_id!=undefined){


	  form.form.value.rol_id=this.puestos.find(lvl=>lvl.Data.nombre == form.form.value.rol_id);

	  form.form.value.rol_id= form.form.value.rol_id.Data.rol_id;

  }

  if(form.form.value.departamento_id!=''){

     form.form.value.departamento_id=this.departamentos.find(dep=>dep.Data.titulo==depa_id).Data.departamento_id;
     console.log(form.form.value.departamento_id);
  }
 
  

  this.services.updatecolaborador(col_id,form.form.value).subscribe(res=>{

      alert("Se Actualizo Colaborador con exitos");
      form.form.value.user_id=this.setarea.id+this.setarea.nombre;

      
      
  });




   for(let act of this.newActvAddlist){

          this.services.postAct_col(this.me_id,act.actividad_id).subscribe(result=>{

            console.log(result);
        });
    }

  this.newActvAddlist=[];
   this.ngOnInit();
      $('#updateArea').modal('hide');
}


agregarlist(data,form){

    let Data={nombre:data.nombre,actividad_id:data.actividad_id};

    this.listaActividCol.push({Data:Data});

    this.newActvAddlist.push({ nombre:data.nombre,actividad_id:data.actividad_id});
    
    form.resetForm();

    $("#AgregarActividad").modal('hide');

    

    alert("Función Agregada");

  }


  misEntregables:any=[];
  actividad_old:any;
  old_actividades:any;
  idtarget:any;

  addActividades(form){

  	this.idtarget=form.form.value.nombre;

    
    if(this.misEntregables.length!=0){

      this.services.postActividad(form.form.value).subscribe(res=>{

    
          this.services.getActividades().subscribe(result=>
          {
            if (result!=null) {

              this.listAllActiv=JSON.parse(JSON.stringify(result)).Datas;
             
              this.allListOldAct=this.listAllActiv;

              let lastPosition=this.listAllActiv.length-1;

              


              for(let entregables of this.misEntregables)
              {
                  this.services.insertEntre_act(
                    this.listAllActiv[lastPosition].Data.actividad_id,
                    entregables.Data.entregable_id).subscribe(res=>{

                });
              }


              this.misEntregables=[];

            	 let target={
							            value:this.idtarget
							            }

							        let f={
							          target:target
							        }

							            this.filtroAct(f);
											            

             

            }

          });
        
      });

    

    }else{
      let sinEntregable=confirm("No hay ningun entregable asignado a esta función. ¿Seguro que deseas continuar?");



        if(sinEntregable){
            
            this.services.postActividad(form.form.value).subscribe(res=>{
				       			
				       			this.services.getActividades().subscribe(result=>
          {
            if (result!=null) {

				              this.listAllActiv=JSON.parse(JSON.stringify(result)).Datas;
				             
				              this.allListOldAct=this.listAllActiv;

				              let lastPosition=this.listAllActiv.length-1;

				              for(let entregables of this.misEntregables)
				              {
				                  this.services.insertEntre_act(
				                    this.listAllActiv[lastPosition].Data.actividad_id,
				                    entregables.Data.entregable_id).subscribe(res=>{

				                });
				              }


				           

				              this.misEntregables=[];

				             
				             

											        let target={
							            	value:this.idtarget
							            }

											        let f={
											          target:target
											        }

							            this.filtroAct(f);
											            }

				          }); 

				         

            });
        }



    }

   		// this.getActividades(form.form.value.nombre);

      form.resetForm();

      $('#CreateActividad').modal('hide');

      alert("Se Agrego Con Exito");
    

     
  }


  getActividades(name){


    this.services.getActividades().subscribe(result=>{

      if ( result != null ) {
        this.listAllActiv=JSON.parse(JSON.stringify(result)).Datas;
        console.log(this.listAllActiv);
        this.allListOldAct=this.listAllActiv;
         let target={
            value:name
            }

        let f={
          target:target
        }

            this.filtroAct(f);
        
      }

       
    });
  }

  entregables:any;
  newEntregable:any;

  getEntregables(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }


   addListEntregable2(form){
    
    this.misEntregables.push(this.entregables.find(entregable=>entregable.Data.titulo==form.form.value.entregable));

    console.log(this.misEntregables);

    alert("Entregable agregado");

    form.resetForm();
    this.newEntregable="";
    $('#addEntregable2').modal('hide');
    
  }


filtroAct(ev){

				console.log(ev);
      if (ev=='') {
       this.listAllActiv=this.allListOldAct;

      }else{
        this.listAllActiv=this.allListOldAct;

        let val = ev.target.value;
        
        
        if (val && val.trim() != '') {
        this.listAllActiv = this.listAllActiv.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.listAllActiv=this.allListOldAct;
      }
     }
}
	
	 listaFechas:any=[];

 insertArea(area){
      area.form.value.rol_id=this.puestos.find(puesto=>puesto.Data.nombre==area.form.value.rol_id).Data.rol_id;
      
    this.services.InsertEntregables(area.form.value).subscribe(res=>{

        console.log(res);
        let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
        for(let fecha of this.listaFechas){
           this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
             console.log("fecha insertada");
           });
        }
       
       this.newEntregable=area.form.value.titulo;

       this.listaFechas=[];
       area.resetForm();
       $('#createEntrgable').modal('hide');
        this.getEntregablesAll();
      alert("Se creo Entregable con éxito");
    });

  }

   getEntregablesAll(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }

   addFecha(form){
   
    this.listaFechas.push(form.form.value);

    alert("Fecha Agregada");

    form.resetForm();

     $('#exampleModal').modal('hide');

  }

*/

   listaFechas:any=[];

insertArea(area){

      console.log(area);
      area.form.value.id_nombre=this.allColaboradores.find(puesto=>puesto.Data.nombre==area.form.value.rol_id).Data.id_nombre;
      console.log(area.form.value);
      if(area.form.value.periodicidad!='Diario'){
    
        if(this.listaFechas.length>=4){
           this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

          this.newEntregable=area.form.value.titulo;

          this.listaFechas=[];
          area.resetForm();
          $('#createEntrgable').modal('hide');
          this.getEntregablesAll();
          alert("Se creo Entregable con éxito");
          });
        }else{
           alert("Para crear este Tipo de Entregable es necesario ingresar 4 o más fechas de Entrega.");
        }


      }else{
          this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

          this.newEntregable=area.form.value.titulo;

          this.listaFechas=[];
          area.resetForm();
          $('#createEntrgable').modal('hide');
          this.getEntregablesAll();
          alert("Se creo Entregable con éxito");
          });
      }

   
  }

     getEntregablesAll(){
    this.services.getEntregables().subscribe(res=>{
      res!=null? this.entregables=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }

  
addFecha(form){
   
    this.listaFechas.push(form.form.value);

    alert("Fecha Agregada");

    form.resetForm();

     $('#exampleModal').modal('hide');

  }


    filtro(ev){
      if (ev=='') {
       this.colaboradores=this.oldCol;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.colaboradores = this.colaboradores.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

          }else{
           this.colaboradores=this.oldCol;
          }
         }
  }


}
