import { Component, OnInit  } from '@angular/core';
import { UsersService } from '../services/users.service';
import { Router,ActivatedRoute, Params } from "@angular/router";
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-usuarios-admin',
  templateUrl: './usuarios-admin.component.html',
  styleUrls: ['./usuarios-admin.component.css']
})
export class UsuariosAdminComponent implements OnInit {
	users:any;
	user:any;
	err_pass=false;
	users_respaldo:any;
	allPersmisos:any;
	permiso:any;
	empleados:any;
	niveles:any;
	msj:any=[];
	constructor(private services:UsersService,private router:Router, private ar:ActivatedRoute) { }

	ngOnInit() {

		this.msj={
				title:"Esparando Titulo",
				msj:"Esperando mesaje",
				button:"Esperando msj",
				color:"background-color: #85D17AFF;"
		}
		localStorage.setItem('page','1');
		this.services.getUsers().subscribe(result => {
			if (result!=null) {
				this.users=JSON.parse(JSON.stringify(result)).Datas;
				this.users_respaldo=this.users;
			}
		});

		this.services.getNiveles().subscribe(res=>{
				res!=null?this.niveles=JSON.parse(JSON.stringify(res)).Datas: null;
		})

		this.services.getEmleados().subscribe(res=>{
			res!=null?this.empleados=JSON.parse(JSON.stringify(res)).Datas:null;
		});
	}

	isPass(form){
		let data=form.form.value;
		if(data.password==data.password_repit){
			this.err_pass=false;
		}else{
			this.err_pass=true;
		}	
	}

	eliminar(){
		let msj=confirm("Seguro que desas eliminar a este usuario");
		
		if (msj) {
			this.services.delete_user(this.user.user_id).subscribe(el=>{});
			this.ngOnInit();
			$('#postUsuario').modal('hide');
		}

	}



	addCliente(form){


		let data=form.form.value;

		console.log(data);
		if(data.password==data.password_repit){
			this.err_pass=false;
			
			this.services.postUser(data).subscribe(result=>{
				alert("Nuevo Líder Creado");
				this.ngOnInit();
				$('#addUsuario').modal('hide');
			});

			form.resetForm();
			
		}else{
			this.err_pass=true;
		}
	}

	SetUser(user){
		this.user=user;
	}

	putCliente(form){
		let data=form.form.value;
		data.id=this.user.usuario_id;
		if(data.new_password==data.password_repit){
			this.err_pass=false;
			data.password=data.new_pass;
			this.services.putUser(data).subscribe(result=>{
				location.reload();	
			});
			
		}else{
			this.err_pass=true;
		}

	}


	deleteUser(){
		this.services.deleteUser(this.user.usuario_id).subscribe(result=>{
			location.reload();
		});
	}

	getMypermisos(user){
		this.user=user;
		
		this.services.getMypermisosbyId(this.user.usuario_id).subscribe(result=>{
			if (result!=null) {
				this.permiso=JSON.parse(JSON.stringify(result)).Datas;
			}else{
				this.permiso=null;
			}
		});


	}

	actualizar(form){
		console.log(this.user.user_id);
		this.services.updateUser(this.user.user_id,form.form.value).subscribe(res=>{
			alert("Se actualizo usuario");
			this.ngOnInit();
		});
	}

	filtro(ev)
    {
      if (ev=='') {
       this.users=this.users_respaldo;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.users = this.users.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.users=this.users_respaldo;
      }
     }
    }


    quitarPermisos(id){
      this.services.deletepermiso(id).subscribe(result=>{
      	this.getMypermisos(this.user);
      });
    }

    postPermiso(id){
    	this.services.postPermiso(this.user.usuario_id,id).subscribe(result=>{
    		this.getMypermisos(this.user);
    		alert("Se agrego permiso correctamente");
    	});
    }


    addClienteEmpleado(form){


    	let empleado=this.empleados.find(empleado=>empleado.Data.nombre==form.form.value.empleado_id);
    	
    	form.form.value.nombre=empleado.Data.nombre;
    	form.form.value.empleado_id=empleado.Data.id+empleado.Data.nombre;
    	form.form.value.apellido_paterno="";
    	form.form.value.apellido_materno="";
    	console.log(form.form.value);
    	
    	this.services.postUser2(form.form.value).subscribe(res=>{
    		console.log(res);
    		$('#adduserEmpleado').modal('hide');
							form.resetForm();
							this.ngOnInit();
							alert("Usuario Creado con Exito");
    	});

    		$('#adduserEmpleado').modal('hide');

    		
    }

	

}
