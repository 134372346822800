import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-my-rols',
  templateUrl: './my-rols.component.html',
  styleUrls: ['./my-rols.component.css']
})
export class MyRolsComponent implements OnInit {
	myId:any;
	myTeams:any;
	integrantes:any;
	roles:any;
	inteam=false;
	equipo=true;
	rol_activ=false;
	eq_id:any;
	rol:any;
	actividad:any;
  id_actividad:any;
  rol_id:any;
  excusa=false;
  titulo:any;
  integrante:any;
  status:any;
  nombre_rol:any;
  integrantes_byRol:any;
  falta:any=[];  
  asis:any=[];
  setData:any;

  id:any;

  constructor(private services:UsersService) { }

  ngOnInit(){
    localStorage.setItem('page','5');
  	this.myId=sessionStorage.getItem('id');
  	console.log(this.myId);
  	this.services.getEqpbyId(this.myId).subscribe(result=>{
  		result!=null ? this.myTeams=JSON.parse(JSON.stringify(result)).Datas : null ;
  	});


  }

  openTeam(id){

  	this.inteam=true;

  	this.equipo=false;
  	
  	this.eq_id=id.eqTrabajo_id;


    this.integrantes=[];

  	

  	this.services.getRolsbyeqp(id.eqTrabajo_id).subscribe(result=>{
  		result != null ? this.roles=JSON.parse(JSON.stringify(result)).Datas : null ;
  	});
  }

  

  openRol(data){
    this.setData=data;
   
    this.nombre_rol=data.nombre;
  	this.rol_activ=true;
  	this.inteam=false;
    this.rol_id=data.rol_id;
    this.id=data.eq_rol;

  	this.services.getAct_rol_id(data.eq_rol).subscribe(res=>{
  		res!=null?this.actividad=JSON.parse(JSON.stringify(res)).Datas : null;
      
  	});

  

    this.getIntegrantesByTeam();


  	this.services.getRol_act_ids(this.eq_id,data.rol_id).subscribe(res=>{
  		
  		res!=null?this.rol=JSON.parse(JSON.stringify(res)).Datas[0].Data : null;
     

  		let fecha=new Date(this.rol.fecha_programada);
      console.log(fecha);
      let fech=fecha+"";
      if (fech!='Invalid Date') {
        let mes=fecha.getMonth()+1;
        let dia=fecha.getDate()+1;
        this.rol.fecha_programada=dia+"/"+mes+"/"+fecha.getFullYear();
        console.log(this.rol);
      }else{
          this.rol.fecha_programada="N/S"
      }
      if(this.rol.hora==""){
        this.rol.hora="N/S"
      }
  		

  	});

  }
  getIntegrantesByTeam(){
    this.integrantes=[];
      this.services.getInegrantes(this.eq_id).subscribe(result=>{
      if(result != null){
        
        this.integrantes=JSON.parse(JSON.stringify(result)).Datas;
        this.getIntegrantesById();
        
      }

    });

  }



  getIntegrantesById(){
    
    this.integrantes_byRol=[];
    this.falta=[];
    this.services.getAsisByID(this.id).subscribe(res=>{
      
      this.asis=this.integrantes;

      if(res!=null){

        let obj=JSON.parse(JSON.stringify(res)).Datas;

        for(let asis of obj){
         
            
          this.integrantes=this.integrantes.filter(d=>d.Data.integrante_id != asis.Data.integrante_id);
         
          this.integrantes_byRol=this.asis.find(d=>d.Data.integrante_id === asis.Data.integrante_id);
          
      
          this.integrantes_byRol !=null ? this.falta.push({'nombre':this.integrantes_byRol.Data.nombre}) : null; 
        }
        
       let objFaltas=JSON.parse(JSON.stringify(this.falta));
       console.log(objFaltas);
       this.falta=objFaltas;
        
        
      }
    });
  }

  returnRols(){
  	this.rol_activ=false;
  	this.inteam=true;
    this.integrantes_byRol=[];
    this.falta=[];  
    this.asis=[];
    this.integrantes=[];
  }

  returnTeams(){
  	this.integrantes=[];
  	this.roles=[];
  	this.inteam=false;
  	this.equipo=true;
  }

  

  setIdActividad(data){
    
  	this.id_actividad=data.act_eq_id;
    
  }





  updateActividad(form){

      if(form.form.value.realizado!=""){
        let seguro=confirm("¿Estás seguro? Al hacerlo, quedará bloqueado esta Actividad");
        if (seguro) {
          
        
      		
          form.form.value.realizado != 0 ?  form.form.value.status=2 : form.form.value.status=3;
          this.excusa ? null : form.form.value.Justificacion="";

           console.log(form.form.value);
          
          this.services.updateActrol(this.id_actividad,form.form.value).subscribe(res=>{
           

          });

          this.services.getAct_rol_id(this.id).subscribe(res=>{
          res!=null?this.actividad=JSON.parse(JSON.stringify(res)).Datas : null;
          
          });

           alert("Terminada");
           form.resetForm();
           form.form.realizado="";
           this.cerrar('actividadFinal');
           
           }
       }else{
         alert("Debes de indicar si la Actividad sé finalizó");
       }
  }


  refrechAct(){

    this.services.getAct_rol_id(this.rol_id).subscribe(res=>{
      res!=null?this.actividad=JSON.parse(JSON.stringify(res)).Datas : null;
    });

  }


  IfExcusa(val){
    this.excusa= val;
  }

  cerrar(modal){
    let md='#'+modal;
     $(md).modal('hide');
  }

  setInegrante(data,titulo,status){
    this.titulo=titulo;
    this.integrante=data;
    this.status=status;
  }

  putAsistencia(form){
  
    let seguro=confirm('¿Estás seguro? Al hacerlo, ya no podras modifica el retardo o asistencia de este integrante');
    if(seguro){
    this.services.post_inacistencia(this.id,this.integrante.integrante_id,this.status,form.form.value).subscribe(res=>{});

    form.resetForm();
    alert("Se guardo registro con exito");
    this.getIntegrantesByTeam();

    this.cerrar('inasisteciaModal');
    }
  }



  observacion(form){
    console.log(form.form.value);


    if (form.form.value.observa!='') {
       this.services.UpRol_eq(this.id,form.form.value).subscribe(res=>{
          console.log(res);
        });

       this.openRol(this.setData);

    }




  }


  cerrarActividad(){

    let actividad=[]
    actividad=this.actividad.filter(d=> d.Data.status === "1" );
    console.log(actividad.length);

    if(this.rol.observacion != '' && actividad.length == 0){
      let acepta=confirm('¿Estás seguro? Al hacerlo, quedará bloqueado este Rol');
      if (acepta) {
          this.services.getrol_active(this.id).subscribe(res=>{
            this.openRol(this.setData);
          });

         
      }
    }else
    {
      alert("Pará cerrar tu captura, es neesario terminar de capturar las Actividades y escribir una Obsersvación generar");
      
    }

    
  }

}
