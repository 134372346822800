import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';

@Injectable()
export class QueriesLogoService {
    public url = "http://67.205.145.224/durango/dashboardLogo_api.php?type=";

    constructor(private http: HttpClient) { }

    //---------------- Empiezan metodos de consultas -----------------------

    getTipoInmueble() {
        
        return this.http.get(this.url + 'sc_getTipoInmuebles');
    }

    getTInmueble(desarrollo_id) {

      return this.http.get(this.url + 'sc_getTipoInmueble&desarrollo_id='+desarrollo_id);
    }




    getDesarrollos() {
        return this.http.get(this.url + 'sc_getDesarrollos');
    }

    getPromotores(){
        return this.http.get(this.url + 'sc_getPromotores');
    }

    getNotarias(){
        return this.http.get(this.url + 'sc_getNotarias');
    }

    getNotaria(id){
      return this.http.get(this.url + 'sc_getNotaria&notaria_id='+id);
    }

    insertPrimerEncuentro(nombre,apellido_paterno,apellido_materno,telefono,celular,correo,nss,desarrollo_id,tipo,promotor_id){
        return this.http.get(this.url+'sc_insertPrimerEncuentro&nombre='+nombre+'&apellido_paterno='+apellido_paterno
        +'&apellido_materno='+apellido_materno+'&telefono='+telefono+'&celular='+celular+'&correo='+correo
        +'&nss='+nss+'&desarrollo_id='+desarrollo_id+'&tipo_inmueble_id='+tipo+'&promotor_id='+promotor_id);

    }

    insertEncuentro(user_id,data){
      return this.http.get(this.url + 'sc_insertPrimerEncuentro&user_id='+user_id+'&nombre='+data.nombre+'&apellido_paterno='+data.apellido_paterno
        +'&apellido_materno='+data.apellido_materno+'&telefono='+data.telefono+'&celular='+data.celular+'&correo='+data.correo
        +'&nss='+data.nss+'&desarrollo_id='+data.desarrollo_id.valueOf()+'&tipo_inmueble_id='+data.tipo.valueOf()+'&promotor_id='+data.promotor_id.valueOf());

    }

    getEncuentros() {
      return this.http.get(this.url + 'sc_getEncuentros');
    }

    getEncuentro(id){
      return this.http.get(this.url + "sc_getEncuentro&primer_encuentro_id="+id);
    }

    insertVenta(id_user,nombre2,apellido_paterno2,apellido_materno2,telefono2,celular2,correo2,nss2,promotor_id2,primer_encuentro_id,status_venta_id){
      return this.http.get(this.url + 'sc_insertVenta&user_id='+id_user+'&nombre2='+nombre2+'&apellido_paterno2='+apellido_paterno2
        +'&apellido_materno2='+apellido_materno2+'&telefono2='+telefono2+'&celular2='+celular2+'&correo2='+correo2
        +'&nss2='+nss2+'&promotor_id2='+promotor_id2+'&primer_encuentro_id='+primer_encuentro_id+'&status_venta_id='+status_venta_id);

    }

    getVentas(){
      return this.http.get(this.url +'sc_getVentas');
    }

    getVenta(id){
      return this.http.get(this.url + 'sc_getVenta&venta_inmueble_id=' + id);
    }

    editVenta(venta_inmueble_id, data){

      return this.http.get(this.url + 'sc_updateVenta2&venta_inmueble_id='+venta_inmueble_id+'&valor_operacion='+data.valor_operacion);

    }

    getContrato(venta_inmueble_id){
      return this.http.get(this.url+'sc_getContratos&venta_inmueble_id='+venta_inmueble_id);
    }

    insertContrato(venta_inmueble_id,cliente_id, data){
      console.log(data.fecha_firma);
      return this.http.get(this.url+'sc_insertContrato&venta_inmueble_id='+venta_inmueble_id+'&num_contrato='+data.num_contrato
        +'&fecha_firma='+data.fecha_firma+'&tipo_contrato_id='+data.tipo_contrato_id+'&descripcion='+data.descripcion+'&cliente_id='+cliente_id);

    }

    getTiposC(){
      return this.http.get(this.url + 'sc_getTiposContratos');
    }

    insertNotariaVenta(venta_inmueble_id, data){
      return this.http.get(this.url + 'sc_updateNotariaVenta&venta_inmueble_id='+venta_inmueble_id+'&notaria_id='+data.notaria_id);
    }

    getPreferencia(cliente_id){
      return this.http.get(this.url+'sc_getPreferencia&cliente_id='+cliente_id);
    }

    getInmuebles(desarrollo_id,tipo_inmueble_id) {

      return this.http.get(this.url + 'sc_getInmueblesPref&desarrollo_id=' + desarrollo_id +'&tipo_inmueble_id='+tipo_inmueble_id);
    }

    getInmueble(inmueble_id){
      return this.http.get(this.url+'sc_getInmueble&inmueble_id='+inmueble_id);
    }

    inserInmuebleVenta(venta_inmueble_id,data,status_venta_id){
      return this.http.get(this.url +'sc_updateInmuebleVenta&inmueble_id='+data.inmueble_id+'&venta_inmueble_id='+venta_inmueble_id+'&status_venta_id='+status_venta_id);
    }

    getTiposPagos(){
      return this.http.get(this.url + 'sc_getTiposPagos');
    }

    getStatusPagos(){
      return this.http.get(this.url + 'sc_getStatusPagos');
    }

    getPagos(venta_inmueble_id,cliente_id){
      return this.http.get(this.url + 'sc_getPagosVenta&venta_inmueble_id='+venta_inmueble_id+'&cliente_id='+cliente_id);
    }

    getPago (pago_id){
      return this.http.get(this.url + 'sc_getPagoVenta&pago_id='+pago_id);
    }

    insertPago(venta_inmueble_id,cliente_id,data){
      return this.http.get(this.url + 'sc_insertPago&venta_inmueble_id='+venta_inmueble_id+'&cliente_id='+cliente_id+'&cantidad='+data.cantidad
      +'&fecha_programada_pago='+data.fecha_programada_pago+'&fecha_pago='+data.fecha_pago+'&tipo_pago_id='+data.tipo_pago_id+'&status_pago_id='+data.status_pago_id);
    }

    updatePago(data){
      return this.http.get(this.url + 'sc_updatePago&pago_id='+data.pago_id+'&fecha_pago='+data.fecha_pago+'&status_pago_id='+data.status_pago_id);
    }

    insertMejora(venta_inmueble_id,data){

      console.log('Lamejora es: ' + data.mejora);
      return this.http.get(this.url + 'sc_insertMejora&venta_inmueble_id='+venta_inmueble_id+'&mejora='+data.mejora);
    }

    getMejoras(venta_inmueble_id){
      return this.http.get(this.url + 'sc_getMejoras&venta_inmueble_id='+venta_inmueble_id);
    }

    getMejora(venta_inmueble_id,mejora_id){
      return this.http.get(this.url + 'sc_getMejora&venta_inmueble_id='+venta_inmueble_id+'&mejora_id='+mejora_id);
    }

    editMejora(venta_inmueble_id,data){
      return this.http.get(this.url +'sc_updateMejora&venta_inmueble_id='+venta_inmueble_id+'&mejora_id='+data.mejora_id+'&mejora='+data.mejora);
    }

    insertObservacion(venta_inmueble_id,data){
      return this.http.get(this.url + 'sc_insertObservacion&venta_inmueble_id='+venta_inmueble_id+'&observacion='+data.observacion);
    }

    getObservaciones(venta_inmueble_id){
      return this.http.get(this.url + 'sc_getObservaciones&venta_inmueble_id='+venta_inmueble_id);
    }

    getObservacion(venta_inmueble_id,observacion_id){
      return this.http.get(this.url + 'sc_getObservacion&venta_inmueble_id='+venta_inmueble_id+'&observacion_id='+observacion_id);
    }

    editObservacion(venta_inmueble_id,data){
      return this.http.get(this.url +'sc_updateObservacion&venta_inmueble_id='+venta_inmueble_id+'&observacion_id='+data.observacion_id+'&observacion='+data.observacion);
    }

    getStatusVentas(){
        return this.http.get(this.url + 'sc_getStatusVentas');
    }
    getStatusVenta(status_venta_id){
      console.log("StatutsV"+status_venta_id);
      return this.http.get(this.url + 'sc_getStatusVenta&status_venta_id='+status_venta_id);
    }

    updateStatusVenta(venta_inmueble_id, data){
      return this.http.get(this.url + 'sc_updateStatusVenta&venta_inmueble_id='+venta_inmueble_id+'&status_venta_id='+data.status_venta_id);
    }

}
