import { Component } from '@angular/core';
import { UsersService } from './services/users.service';
import { Router,ActivatedRoute, Params } from "@angular/router";
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [UsersService]
})
export class AppComponent {
 iflogin:any;

 constructor(private services:UsersService,private router:Router, private ar:ActivatedRoute){};
  ngOnInit() {
  	if (sessionStorage.getItem('token')!=null) {
  		this.iflogin=true;
  	}else{
  		this.iflogin=false;
       this.router.navigate(["/login"]);
  	}
  }


}
