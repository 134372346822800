import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seg-finanzas',
  templateUrl: './seg-finanzas.component.html',
  styleUrls: ['./seg-finanzas.component.css']
})
export class SegFinanzasComponent implements OnInit {

		page_now:any="Finazas";

  constructor() { }

  ngOnInit() {

  }
  loadPage(page){
  		this.page_now=page;
  }
}
