import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-com-venta',
  templateUrl: './com-venta.component.html',
  styleUrls: ['./com-venta.component.css']
})
export class ComVentaComponent implements OnInit {
		page_now="Compras Gastos";
  constructor() { }

  ngOnInit() {
  }
   loadPage(page){
  		this.page_now=page;
  }

}
