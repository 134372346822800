import { Component, OnInit } from '@angular/core';
	import { UsersService } from '../services/users.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
declare var $: any;
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-col-gcl',
  templateUrl: './col-gcl.component.html',
  styleUrls: ['./col-gcl.component.css']
})
export class ColGclComponent implements OnInit {

   paiOpcion: ChartOptions = {   
    plugins: {

      datalabels: {
        listeners: {
        click: function(value) {    
        }
      },

        
       
        align: 'end',
        color:'#000000FF',
         formatter: function(value, context) {
                    return value+" ";
                },

        font: {
          size: 12,
          weight: 'bold',

        }
        
      }
    }
  };


  pieChartLabels = ['Colaboradores Nuevos','Colaboradores de Baja'];
  pieChartData :any = [0,0];
  
  pieChartType = 'pie';
  chartColors: any[] = [
      { 
        backgroundColor:[ "#3C7FAEFF","#B8610D", "#475EB2FF","#FFD800FF","#4800FFFF","#A0A0A0FF","#5CBC42FF", "#B12B1FFF", "#475EB2FF"] 
      }];


  constructor(private service:UsersService) { }

  empleados=[];

  ngOnInit() {
    this.service.getEmleados().subscribe(res=>{
      
      let Empleados=JSON.parse(JSON.stringify(res)).Datas;
      this.empleados=Empleados.filter(e=>e.Data.frente=="GCL");
      
    });
  }

}
