import { Component, OnInit } from '@angular/core';
import { UsersService} from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-colaboradores',
  templateUrl: './colaboradores.component.html',
  styleUrls: ['./colaboradores.component.css']
})
export class ColaboradoresComponent implements OnInit {

 
  page:any;
	carga=false;
	datas:any;

  areas:any=[];
  setarea:any; 
  oldAreas:any;
  lvls:any;
  puestos:any;
  departamentos:any=[];

  constructor(private services:UsersService) { }

  ngOnInit() {

    this.carga=false;
  	localStorage.setItem('page',"12");
    this.getlvl();
  	this.datas={
  							"titulo":"Colaboradores",
  							"singular":"Colaborador",
  							"col":"user_id",
  							"tbl":"empleados_vigentes"
  			}
      
     
  		
      this.getPuestos(); 
      this.getDepartamentos();
      this.getAllActividades();
  }

  listAllActiv:any=[];

  getAllActividades(){
    this.services.getActividades().subscribe(res=>{
      res!=null?this.listAllActiv=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }


  getPuestos(){
    this.services.getlvl('roles').subscribe(res=>{
      res != null ? this.puestos=JSON.parse(JSON.stringify(res)).Datas:null;
     
    });
  }


  dep:any;
  are:any;
  actividades:any;
 

  setArea(data){

    console.log(data);

    this.actividades=null;
   
    this.listaActividCol=[];
    let id=data.rol_id;


   if( data.rol_id!=0){
   


    this.services.getDepByrol(id).subscribe(res=>{
    
      if(res!=null){

        this.dep=JSON.parse(JSON.stringify(res)).Datas[0];

          this.services.getAreabyDep(this.dep.Data.area_id).subscribe(resu=>{

            resu!=null?this.are=JSON.parse(JSON.stringify(resu)).Datas[0]:null;
          
          });
      }
    });

    
    this.services.getRol_act_idacrualiza(data.rol_id).subscribe(res=>{
      if(res!=null)
         {this.actividades=JSON.parse(JSON.stringify(res)).Datas;
         console.log(this.actividades);
         for(let acti of this.actividades){
             this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
               if(res!=null){
                 acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
               }
             });
         }
       }
    });

    this.setarea=data;

      this.services.getatc_col(this.setarea.id+this.setarea.nombre).subscribe(res=>{
      if(res!=null)
         {
           this.listaActividCol=JSON.parse(JSON.stringify(res)).Datas;

           console.log("Apha");
         console.log(this.listaActividCol);

         for(let acti of this.listaActividCol){
             this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
               if(res!=null){
                 acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
               }
             });
         }
       }
    });


    this.setarea.lvl_colaborador_id=0;  

  

    this.setarea.rol_id=this.puestos.find(puesto=>puesto.Data.rol_id==data.rol_id);

    this.setarea.rol_id=this.setarea.rol_id.Data.nombre;

    

    
   }else{
      this.setarea=data;
      this.setarea.lvl_colaborador_id="";
      this.setarea.rol_id="";
      
      this.dep="";
      this.are=null;
     
   }

   if(data.departamento_id!=0){
       data.departamento_id=this.departamentos.find(dep=>dep.Data.departamento_id==data.departamento_id).Data.titulo;
   }else{
     data.departamento_id="";
   }

    
  }


 getDepartamentos(){
   this.services.getDepartamentos().subscribe(res=>{
     res!=null?this.departamentos=JSON.parse(JSON.stringify(res)).Datas:null;
     this.getAreas();
     console.log(this.departamentos);
   });
 }


  quitarList(lista){

    let mensaje=confirm("¿Seguro que se desas Quitar esta Función?");
    
    if (mensaje) {

      this.services.deleteAct_col(lista.col_act_id).subscribe(res=>{
            this.listaActividCol=null;

            this.services.getatc_col(this.setarea.user_id).subscribe(res=>{
            if(res!=null)
               {
                 this.listaActividCol=JSON.parse(JSON.stringify(res)).Datas;

                
               console.log(this.listaActividCol);

               for(let acti of this.listaActividCol){
                   this.services.getEntre_act(acti.Data.actividad_id).subscribe(res=>{
                     if(res!=null){
                       acti.Data.entregables=JSON.parse(JSON.stringify(res)).Datas;
                     }
                   });
               }
             }
          });
      });
      
     
    }
     
  }

  cambioPuesto(setarea){
    this.setarea=setarea;
    console.log(setarea);
  }

 getlvl(){
   /*
    this.services.getlvl('lvl_colaboradores').subscribe(res=>{
      res != null ? this.lvls=JSON.parse(JSON.stringify(res)).Datas:null;

      console.log(this.lvls);
    });*/
  }


update(form){

  let col_id=this.setarea.id+this.setarea.nombre;
  
  if (this.setarea.ifNew) {
   this.services.insertCol_ids(col_id).subscribe(res=>{
     this.consultaUpdate(form);
   });

  }else{
    console.log(col_id);
    this.consultaUpdate(form);
  }
 
}

consultaUpdate(form){

  console.log("Entra aqui");

  let col_id=this.setarea.id+this.setarea.nombre;
  
  console.log(form.form.value);

  form.form.value.lvl_colaborador_id=0;

  let depa_id=form.form.value.departamento_id;
  
  

  form.form.value.rol_id=this.puestos.find(lvl=>lvl.Data.nombre == form.form.value.rol_id);

  form.form.value.rol_id= form.form.value.rol_id.Data.rol_id;


  if(form.form.value.departamento_id!=''){

     form.form.value.departamento_id=this.departamentos.find(dep=>dep.Data.titulo==depa_id).Data.departamento_id;
     console.log(form.form.value.departamento_id);
  }
 
  

  this.services.updatecolaborador(col_id,form.form.value).subscribe(res=>{

      alert("Se Actualizo Colaborador con exito");
      form.form.value.user_id=this.setarea.user_id;
      $('#updateArea').modal('hide');
      this.ngOnInit();
      
  });




   for(let act of this.newActvAddlist){

          this.services.postAct_col(col_id,act.actividad_id).subscribe(result=>{

            console.log(result);
        });
    }

  this.newActvAddlist=[];
}



  filtro(ev)
    {
      if (ev=='') {
       this.areas=this.oldAreas;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.areas = this.areas.filter((item) => {
          return (item.Data.nombre.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.areas=this.oldAreas;
      }
     }
    }

///no es Area es para traer los colaboradores
  getAreas(){

    this.areas=[];

    this.services.getAllColaborador().subscribe(res=>{

      if(res != null){

        this.areas=JSON.parse(JSON.stringify(res)).Datas;
        
        this.oldAreas=this.areas;

        for(let col of this.areas)
        {
          let id=col.Data.id+""+col.Data.nombre;

          this.services.getCol_detalles(id).subscribe(res=>{

             if(res!=null){
                 col.Data.detalles=JSON.parse(JSON.stringify(res)).Datas[0];
                 col.Data.rol_id=col.Data.detalles.Data.rol_id;
                 col.Data.departamento_id=col.Data.detalles.Data.departamento_id;
                 col.Data.ifNew=false;


               if(col.Data.rol_id!=0){

                  console.log(col.Data.detalles);

                    this.services.getrolByid(col.Data.rol_id).subscribe(res2=>{
                      res2!=null? col.Data.rol=JSON.parse(JSON.stringify(res2)).Datas[0].Data.nombre : col.Data.rol="Sin Asignar";
                    });

                }else{
                   col.Data.rol="Sin Asignar";
                }

                

                if(col.Data.departamento_id != '0' ){
                  console.log(col.Data);
                  console.log("el Id de departamento"+col.Data.departamento_id);

                  col.Data.depa=this.departamentos.find(dep=>dep.Data.departamento_id==col.Data.departamento_id).Data.titulo;
                  
                     
                }else{
                  col.Data.depa="Sin Asignar"

                  console.log(col.Data.depa);
                }

             }else{
               col.Data.rol="Sin Asignar";
               col.Data.rol_id="";
               col.Data.departamento_id="";
               col.Data.ifNew=true;

               col.Data.depa="Sin Asignar"
             }
              
          });



          
        }

        this.carga=true;
    }

     

    });




  }



  cerrar(){
     this.ngOnInit();
    $('#updateArea').modal('hide');
   
  }


  cerrarAny(id){

      let panel="#"+id;
       this.ngOnInit();
      $(panel).modal('hide');
     
    }


   newActvAddlist:any=[];
   listaActividCol:any=[];

  agregarlist(data,form){

    let Data={nombre:data.nombre,actividad_id:data.actividad_id};
    
    this.listaActividCol.push({Data:Data});

    this.newActvAddlist.push({ nombre:data.nombre,actividad_id:data.actividad_id});
    
    form.resetForm();

    $("#AgregarActividad").modal('hide');

    

    alert("Función Agregada");

  }





}
