import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-preuba-formulario',
  templateUrl: './preuba-formulario.component.html',
  styleUrls: ['./preuba-formulario.component.css']
})
export class PreubaFormularioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  validar(){
  	alert("ok");
  }
}
