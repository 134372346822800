import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pc-ingresos',
  templateUrl: './pc-ingresos.component.html',
  styleUrls: ['./pc-ingresos.component.css']
})
export class PcIngresosComponent implements OnInit {
	page_now="Ingresos";
   constructor() { }
	
  ngOnInit() {
  }
  loadPage(page){
  	this.page_now=page;
  }

}
