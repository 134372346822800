import { Injectable } from '@angular/core'; 
import { HttpClient , HttpHandler,HttpHeaders } from '@angular/common/http';


@Injectable()
export class CatalogosService{
	public url="http://67.205.145.224/durango/dashboard_api.php?type=";
	constructor(private http: HttpClient){}

	getCatalogo(catalogo){
		return this.http.get(this.url+"sc_getCatalogo&tabla="+catalogo);
	}
	postCatalogo(catalogo,identificador,status){
		return this.http.get(this.url+"sc_postCatalogo&table="+catalogo+"&columna="+identificador+"&nombre="+status);
	}
	putCatalogo(catalogo,identificador,status,id){
		return this.http.get(this.url+"sc_putCatalogo&table="+catalogo+"&columna="+identificador+"&nombre="+status+"&id="+id);
	}
	postCatalogoTipos(catalogo,identificador,nombre,descripcion){
		return this.http.get(this.url+"sc_postCatalogoTipos&table="+catalogo+"&columna="
			+identificador+"&nombre="+nombre+"&descripcion="+descripcion);

	}
	postTiposPagos(nombre,descripcion,esquema){
		return this.http.get(this.url+"sc_postTipos_Pagos&nombre="+nombre+"&descripcion="+descripcion+"&esquema="+esquema);
	}
	putTiposPagos(data,id){
		return this.http.get(this.url+"sc_putCatalogo3&nombre="+data.nombre+"&descripcion="+data.descripcion+"&esquema="+data.id_indicador+"&id="+id);
	}
	putCatalogoTipos(catalogo,identificador,data,id){
		return this.http.get(this.url+"sc_putCatalogo2&table="+catalogo+"&columna="+identificador+"&nombre="
			+data.titulo+"&id="+id+"&descripcion="+data.descripcion);
	}

	deleteCatalogo(catalogo,identificador,id){
		return this.http.get(this.url+"sc_DeleteCatalogo&table="+catalogo+"&columna="+identificador+"&id="+id);
	}
	getDesarrollo(){
		return this.http.get(this.url+"sc_getDesarrollos");
	}
	postDesarrollos(catalogo,identificador,status){
		return this.http.get(this.url+"sc_postDesarrollos&table="+catalogo+"&columna="+identificador+"&nombre="+status);
	}
	putDesarrollos(catalogo,identificador,status,id){
		return this.http.get(this.url+"sc_putDesarrollos&table="+catalogo+"&columna="+identificador+"&nombre="+status+"&id="+id);
	}

}