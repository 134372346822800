import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rh',
  templateUrl: './rh.component.html',
  styleUrls: ['./rh.component.css']
})
export class RhComponent implements OnInit {

  constructor() { }
  page_now="Recursos Humanos"
  ngOnInit() {
  }

}
