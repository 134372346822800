import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';

@Component({
  selector: 'app-tipos-descuentos',
  templateUrl: './tipos-descuentos.component.html',
  styleUrls: ['./tipos-descuentos.component.css'],
  providers:[CatalogosService]
})
export class TiposDescuentosComponent implements OnInit {
	tipos_descuentos:any=[];
	nombre:any;
	id_update:any;
	descrip:any;
	titulo:any;
  	constructor(private servicios:CatalogosService) { }

  	ngOnInit(){

  		  this.nombre="Tipos de descuentos";
            this.servicios.getCatalogo("tipos_descuentos").subscribe(result=>{
               if(result!=null){
               	console.log(result);
                 let temp=JSON.stringify(result);
                 let temp2=JSON.parse(temp);
                 this.tipos_descuentos=temp2.Datas;
               }
            });
  	}

  	setid2(id,tit,des){
	    this.id_update=id;
	    this.descrip=des;
	    this.titulo=tit;
  	}

  	setiddelete(id){
    this.id_update=id;
  }
   postStatus(form){
   	 this.servicios.postCatalogoTipos('tipos_descuentos','tipo_descuento_id',form.form.value.nombre,form.form.value.descripcion).subscribe(result=>{
        location.reload();
      },error=>console.log(error)); 
   }
   putStatus(form){
   		this.servicios.putCatalogoTipos('tipos_descuentos','tipo_descuento_id',form.form.value,this.id_update).subscribe(result=>{
        	location.reload();
      	},error=>console.log(error)); 
   }

   deleteCatlogo(){
   		this.servicios.deleteCatalogo('tipos_descuentos','tipo_descuento_id',this.id_update).subscribe(result=>{
        	location.reload();
      	},error=>console.log(error));  
   }


}
