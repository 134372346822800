import { Component, OnInit } from '@angular/core';
import { ConfiguracionesService } from '../services/configuraciones.service'
declare var $: any;
@Component({
  selector: 'app-confiindicador',
  templateUrl: './confiindicador.component.html',
  styleUrls: ['./confiindicador.component.css'],
   providers:[ConfiguracionesService]
})
export class ConfiindicadorComponent implements OnInit {

	 indicadores:any;
	 categorias:any;
	 indicadors :any;


  constructor( private _servicio:ConfiguracionesService) {



   }
  setvalues(val){
      this.categorias=val.Indicador;
      for (var i = this.categorias.length - 1; i >= 0; i--) {
            this.categorias[i].Indicadores.status=="true" ?  this.categorias[i].Indicadores.status=true :  this.categorias[i].Indicadores.status=false;  
          
          }    

      
  }
 

  ngOnInit(){
  	this._servicio.getIndicdores(1).subscribe(
  		result =>{
  			
  			var eco = JSON.stringify(result);
    		var json = JSON.parse(eco);
  			this.indicadores=json.Indicador;
  		  
        for (var i = this.indicadores.length - 1; i >= 0; i--) {
            if( this.indicadores[i].Indicadores.status=="true"){
            this.indicadors=this.indicadores[i].Indicadores.id_indicador;


          }
        }
  			

  			

  			this._servicio.getcategoria(this.indicadors).subscribe(
		  		result =>{
		  			var eco = JSON.stringify(result);
		    		var json = JSON.parse(eco);
            this.setvalues(json);
		  		},
		  		 error =>{
		        console.log(<any>error);
		      }
		      );
  		},
  		 error =>{
        console.log(<any>error);
      }
      );  	
  }

  ngDoCheck(){
  	
  }
  

  inidcadorupdate(){
  	console.log("que pedo "+$('#indicador').val());
  	this._servicio.getcategoria($('#indicador').val()).subscribe(
		  		result =>{
		  			console.log(result);
		  			var eco = JSON.stringify(result);
		    		var json = JSON.parse(eco);
		  			this.categorias=json.Indicador;
		  		
		  		},
		  		 error =>{
		        console.log(<any>error);
		      }
		      );
  }


  guardar(){
    console.log("indicador "+$('#indicador').val());

    this._servicio.updateidicador(1,$('#indicador').val()).subscribe(
      result=>{
        console.log("Bien");
      },error=>{
         console.log("mal");
      });

    this._servicio.updateidicador2(1,$('#indicador').val()).subscribe(
      result=>{
        console.log("Bien");
      },error=>{
         console.log("mal");
      });
    
    for (var i = this.categorias.length - 1; i >= 0; i--) {
      console.log(this.categorias[i].Indicadores.nombre);

     this._servicio.updatestatus(this.categorias[i].Indicadores.id_categoria,this.categorias[i].Indicadores.status).subscribe(  
         result=>{
           console.log(result);
         },error=>{
             console.log(<any>error);
           }
       );
    }
    

    alert("Guardado con Exito!!");
  }


}
