import { Component, OnInit } from '@angular/core';
import { UsersService} from '../services/users.service';
import * as bootstrap from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-entregables',
  templateUrl: './entregables.component.html',
  styleUrls: ['./entregables.component.css']
})
export class EntregablesComponent implements OnInit {
  areas:any=[];
  setarea:any;
  ldAreas:any;
  oldAreas:any;
  listaFechas:any;


    allColaboradores:any=[];



  puestos:any;
  constructor(private services:UsersService) { }

  ngOnInit() {
  	 localStorage.setItem('page','5');
     this.listaFechas=[];
     this.getPuestos();
     this.services.getallCol().subscribe(res=>{
      res!=null ? this.allColaboradores=JSON.parse(JSON.stringify(res)).Datas:null;
    });
  }



  getPuestos(){
    this.services.getRoles().subscribe(res=>{
      console.log(res);
      if(res!=null){
         this.puestos=JSON.parse(JSON.stringify(res)).Datas;

         for(let puesto of this.puestos){
            this.services.getColabyRol(puesto.Data.rol_id).subscribe(res2=>{
              res2!=null? puesto.Data.cola=JSON.parse(JSON.stringify(res2)).Datas[0].Data.nombre :  puesto.Data.cola="No Hay Colaborador Encargado en este Puesto.";
            });
         }

      }
      this.getAreas();
    });
  }


  getAreas(){
    this.areas=[];
    this.listaFechas=[];
  	this.services.getEntregables().subscribe(res=>{
  		res != null ? this.areas=JSON.parse(JSON.stringify(res)).Datas:null;
      this.oldAreas=this.areas;

      for(let entregable of this.areas){
            if(entregable.Data.rol_id!=0){
              entregable.Data.name_puesto=this.allColaboradores.find(area=>area.Data.id_nombre == entregable.Data.rol_id).Data;
              
            }
        }
  	});
  
  }


   insertArea(area){

  console.log(area);
      area.form.value.id_nombre=this.allColaboradores.find(puesto=>puesto.Data.nombre==area.form.value.rol_id).Data.id_nombre;
      console.log(area.form.value);
      if(area.form.value.periodicidad!='Diario'){
    
        if(this.listaFechas.length>=4){
           this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }

        

         
          area.resetForm();
          $('#createArea').modal('hide');
          this.ngOnInit();
          alert("Se creo Entregable con éxito");
          });
        }else{
           alert("Para crear este Tipo de Entregable es necesario ingresar 4 o más fechas de Entrega.");
        }


      }else{
          this.services.InsertEntregables(area.form.value).subscribe(res=>{

          console.log(res);
          let id=JSON.parse(JSON.stringify(res)).Datas[0].Data.entregable_id;
          for(let fecha of this.listaFechas){
             this.services.InsertFecha_entre(id,fecha).subscribe(res=>{
               console.log("fecha insertada");
             });
          }


          this.listaFechas=[];
          area.resetForm();
          $('#createArea').modal('hide');
          this.ngOnInit();
          alert("Se creo Entregable con éxito");
          });
      }
     
     
  }

  
  setArea(data){

  	
    

    this.getFechasEntre(data.entregable_id);

    if(data.rol_id!=0)
    {
      data.rol_id=this.allColaboradores.find(puesto=>puesto.Data.id_nombre==data.rol_id).Data.nombre;
  	}else
    {
      data.rol_id="";
    }
   this.setarea=data;
   
  }

  getFechasEntre(id){
    console.log("ID entregable" + id);
    this.listaFechas=[];

    this.services.getFechas_entreByid(id).subscribe(res=>{
      
       res!=null?this.listaFechas=JSON.parse(JSON.stringify(res)).Datas:null;


        console.log(this.listaFechas);
    });

  }

  addFechaAfter(form){

    this.services.InsertFecha_entre(this.setarea.entregable_id,form.form.value).subscribe(res=>{
          alert("Fecha Agregada"); 

         this.getFechasEntre(this.setarea.entregable_id);
         $('#fechaBefore').modal('hide');
         form.resetForm();
     });
  }

  update(form){

    console.log(form.form.value);
    form.form.value.rol_id=this.allColaboradores.find(puesto=>puesto.Data.nombre==form.form.value.rol_id).Data.id_nombre;

  	this.services.UpdateEntregables(form.form.value,this.setarea.entregable_id).subscribe(res=>{
  		
  		this.getAreas();
  		
  		alert("Se actualizo Entregable con exito");

  		$('#updateArea').modal('hide');
  	});


  }


  delete(){
  	
  	let seguro=confirm("¿Estás seguro? Al hacerlo, se eliminara este Entregable");
  	if(seguro){this.services.DeleteEntregable(this.setarea.entregable_id).subscribe(res=>{
  	  		
  	  		this.getAreas();
  	  		
  	  		alert("Se Elimino Entregable con exito");
  	  		$('#updateArea').modal('hide');
  	  	});}
  }

  filtro(ev){
      if (ev=='') {
       this.areas=this.oldAreas;
      }else{
        let val = ev.target.value;
        console.log(val);
        if (val && val.trim() != '') {
        this.areas = this.areas.filter((item) => {
          return (item.Data.titulo.toLowerCase().indexOf(val.toLowerCase())  > -1);
        });

      }else{
       this.areas=this.oldAreas;
      }
     }
  }

  deleteFecha(form){
      let msj=confirm("¿Seguro de desas Eliminar esta Fecha?");
      if (msj) {
        this.services.DeleteFecha_entre(form.entre_fecha).subscribe(re=>{
          this.getFechasEntre(this.setarea.entregable_id);
        });
      }
  }


  addFecha(form){
   
    this.listaFechas.push(form.form.value);

    alert("Fecha Agregada");

    form.resetForm();
    
    $('#exampleModal').modal('hide');
     

  }



}
